<template>
  <layout-default>
      <div class="Profile">
          <div class="py-4">
              <div class="container">
              <div class="row">

              <aside class="col-md-4">
                <div class="box mb-3 shadow-sm border rounded bg-white profile-box text-center">
                  <div class="py-4 px-3 border-bottom">
                    <img v-if="user.avatar" class="img-fluid mt-2 rounded-circle" :src="image_url+'users/'+user.avatar" style="max-height: 150px; max-width: 150px" alt="">
                    <img v-else class="img-fluid mt-2 rounded-circle" src="/assets/avatar.png" style="max-height: 150px; max-width: 150px" alt="">
                    <h5 class="font-weight-bold text-dark mb-1 mt-4">{{user.name}}</h5>
                    <p v-if="user.currentcompany" class="mb-0 text-muted">{{user.current_position}} <br> <router-link :to="'/company/'+user.currentcompany.slug">{{user.currentcompany.name}}</router-link></p>
                    <p v-else class="mb-0 text-muted">{{user.profession}}</p>
                    <span v-if="user.membership" class="text-info"><span v-if="user.membership.membership">{{ user.membership.membership.code }}</span><i v-if="user.membership.status == 1" data-toggle="tooltip" data-placement="top" title="Verified" class="feather-check-circle"></i></span> &nbsp;
                    <span v-if="user.certification" class="text-info"><span v-if="user.certification.certification">{{ user.certification.certification.code }}</span><i v-if="user.certification.status == 1" data-toggle="tooltip" data-placement="top" title="Verified" class="feather-check-circle"></i></span>
                  </div>
                  <div class="d-flex">
                    <div class="col-6 border-right p-3">
                      <h6 class="font-weight-bold text-dark mb-1">358</h6>
                      <p class="mb-0 text-black-50 small">Connections</p>
                    </div>
                    <div class="col-6 p-3">
                      <h6 class="font-weight-bold text-dark mb-1">85</h6>
                      <p class="mb-0 text-black-50 small">Views</p>
                    </div>
                  </div>
                  <div class="overflow-hidden border-top">
                    <router-link class="font-weight-bold p-3 d-block" :to="'/sc/profile/'+id"> View my profile </router-link>
                  </div>
                </div>
              </aside>
              <main class="col-md-8">
              <div class="border rounded bg-white mb-3">
              <div class="box-title border-bottom p-3">
              <h6 class="m-0">Change Account Password</h6>
              </div>
              <div class="box-body p-3">
              <form id="update-form" class="js-validate" novalidate="novalidate">
                <div class="row">

                    <div class="col-sm-12 mb-2">
                        <div class="js-form-message">
                            <label id="nameLabel" class="form-label">
                            Current Password
                            <span class="text-danger">*</span>
                            </label>
                            <div class="form-group">
                                <input type="password" class="form-control" name="current_password" required>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-6 mb-2">
                        <div class="js-form-message">
                            <label id="nameLabel" class="form-label">
                            New Password
                            <span class="text-danger">*</span>
                            </label>
                            <div class="form-group">
                                <input type="password" class="form-control" name="password" required>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-6 mb-2">
                        <div class="js-form-message">
                            <label id="usernameLabel" class="form-label">
                            Confirm New Password
                            <span class="text-danger">*</span>
                            </label>
                            <div class="form-group">
                                <input type="password" class="form-control" name="confirm_password" required>
                            </div>
                        </div>
                    </div>
                </div>              
                <div class="mb-3 text-right">
                    <router-link class="font-weight-bold btn btn-link rounded p-3" to="/"> &nbsp;&nbsp;&nbsp;&nbsp; Cancel &nbsp;&nbsp;&nbsp;&nbsp; </router-link>
                    <a class="font-weight-bold btn btn-primary rounded p-3" @click="update()"> &nbsp;&nbsp;&nbsp;&nbsp; Save Changes &nbsp;&nbsp;&nbsp;&nbsp; </a>
                </div>
              </form>
              </div>
              </div>
              
              </main>
              </div>
              </div>
          </div>
       </div>
  </layout-default>
</template>
<script>
import LayoutDefault from '../../layouts/default.vue';
import axios from 'axios';
import $ from "jquery";

export default {
  name: 'Profile',
  components: {
    LayoutDefault,
  },
 created() {
        this.fetchCompany();
        this.fetchUser();
  },

  watch: {
      $route: {
          immediate: true,
          handler(to) {
              document.title = to.meta.title || 'Change Password';
          }
      },
  }, 

  data() {
        return {
            company: {},
            user: {},
            loading: false,
            id: localStorage.getItem('slug')
        }
  },   

  methods: {
      logout () {
          localStorage.clear();
          this.$router.go();
        },

        fetchCompany () {
            axios(process.env.VUE_APP_API_URL + 'company').then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.company = response.data;
            }).catch({});
        },

        fetchUser () {
            axios(process.env.VUE_APP_API_URL + 'user/details/'+localStorage.getItem('user')).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.user = response.data;
            }).catch({});
        },

        update () {
            this.loading = true;
            var form = $("#update-form")[0];
            var _data = new FormData(form);
            axios.post(process.env.VUE_APP_API_URL + 'user/change-password/'+localStorage.getItem('user'), _data)
            .then((response) => {
            if (response.status === 200) {
                this.$toastr.s(response.message);
                this.$router.push({ name: 'Profile', query: { redirect: '/sc/profile/'+localStorage.getItem('slug') } });
                this.loading = false;
            } else {
                this.$toastr.e(response.data.message);
                this.loading = false;
            }
            }).catch((error) =>{
                this.$toastr.e(error.response.data.message);
                this.loading = false;
            })
        },
    },

    computed: {
        image_url: function() {
            return process.env.VUE_APP_IMAGE_URL;
        }
    },
}
</script>