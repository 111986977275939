<template>
  <layout-default>
      <div class="Profile">
          <div class="py-4">
              <div class="container">
                <div class="row">

                  <aside class="col-md-4">
                    <div class="box mb-3 shadow-sm border rounded bg-white profile-box text-center">
                      <div class="py-4 px-3 border-bottom">
                        <img v-if="user.avatar" class="img-fluid mt-2 rounded-circle" :src="image_url+'users/'+user.avatar" style="max-height: 150px; max-width: 150px" alt="">
                        <img v-else class="img-fluid mt-2 rounded-circle" src="/assets/avatar.png" style="max-height: 150px; max-width: 150px" alt="">
                        <h5 class="font-weight-bold text-dark mb-1 mt-4">{{user.name}}</h5>
                        <p v-if="user.currentcompany" class="mb-0 text-muted">{{user.current_position}} <br> <router-link :to="'/company/'+user.currentcompany.slug">{{user.currentcompany.name}}</router-link></p>
                        <p v-else class="mb-0 text-muted">{{user.profession}}</p>
                      </div>
                      <div class="d-flex">
                        <div class="col-6 border-right p-3">
                          <h6 class="font-weight-bold text-dark mb-1">358</h6>
                          <p class="mb-0 text-black-50 small">Connections</p>
                        </div>
                        <div class="col-6 p-3">
                          <h6 class="font-weight-bold text-dark mb-1">85</h6>
                          <p class="mb-0 text-black-50 small">Views</p>
                        </div>
                      </div>
                      <div class="overflow-hidden border-top">
                        <router-link class="font-weight-bold p-3 d-block" :to="'/sc/profile/'+id"> View my profile </router-link>
                      </div>
                    </div>
                  </aside>
                  <main class="col-md-8">              
                    <div class="border rounded bg-white mb-3">
                      <div class="box-title border-bottom p-3">
                        <h6 class="m-0">Experience</h6>
                        <p class="mb-0 mt-0 small">Tell about your work, job, and other experiences.</p>
                      </div>
                      <div class="box-body px-3 pt-3 pb-0">
                        <div class="row">
                          <div class="col-sm-6 mb-4">
                            <label id="FROM" class="form-label">FROM</label>
                            <div class="input-group">
                              <input type="text" class="form-control" placeholder="From" aria-label="FROM" aria-describedby="FROM">
                            </div>
                          </div>
                          <div class="col-sm-6 mb-4">
                            <label id="TO" class="form-label">TO</label>
                            <div class="input-group">
                              <input type="text" class="form-control" placeholder="TO" aria-label="TO" aria-describedby="TO">
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-6 mb-4">
                            <label id="companyLabel" class="form-label">Company</label>
                            <div class="input-group">
                              <input type="text" class="form-control" placeholder="Enter your company title" aria-label="Enter your company title" aria-describedby="companyLabel">
                            </div>
                          </div>
                          <div class="col-sm-6 mb-4">
                            <label id="positionLabel" class="form-label">Position</label>
                            <div class="input-group">
                              <input type="text" class="form-control" placeholder="Enter your position" aria-label="Enter your position" aria-describedby="positionLabel">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mb-3 text-right">
                      <a class="font-weight-bold btn btn-link rounded p-3" href="#"> &nbsp;&nbsp;&nbsp;&nbsp; Cancel &nbsp;&nbsp;&nbsp;&nbsp; </a>
                      <a class="font-weight-bold btn btn-primary rounded p-3" href="#"> &nbsp;&nbsp;&nbsp;&nbsp; Sava Chenges &nbsp;&nbsp;&nbsp;&nbsp; </a>
                    </div>
                  </main>
                </div>
              </div>
          </div>
       </div>
  </layout-default>
</template>
<script>
import LayoutDefault from '../../layouts/default.vue';
import axios from 'axios';
// import $ from "jquery";

export default {
  name: 'Profile',
  components: {
    LayoutDefault,
  },

  created() {
        this.fetchCompany();
        this.fetchUser();
        this.fetchCountries();
  },

  data() {
        return {
            company: {},
            user: {},
            country: [],
            state: [],
            city: [],
            loading: false,
            id: localStorage.getItem('slug')
        }
  },   

  methods: {
      logout () {
          localStorage.clear();
          this.$router.go();
        },

        fetchCompany () {
            axios(process.env.VUE_APP_API_URL + 'company').then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.company = response.data;
            }).catch({});
        },

        fetchUser () {
            axios(process.env.VUE_APP_API_URL + 'user/details/'+localStorage.getItem('user')).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.user = response.data;
            }).catch({});
        },

        fetchCountries () {
            axios(process.env.VUE_APP_API_URL + 'country').then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.country = response.data;
            }).catch({});
        },

        fetchStates (event) {
            axios(process.env.VUE_APP_API_URL + 'state/'+event.target.value).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.state = response.data;
            }).catch({});
        },

        fetchCities (event) {
            axios(process.env.VUE_APP_API_URL + 'city/'+event.target.value).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.city = response.data;
            }).catch({});
        },
    },

    computed: {
        image_url: function() {
           return process.env.VUE_APP_IMAGE_URL;
        }
    },
}
</script>