<template>
  <layout-default>
      <div>
        <div class="bg-white shadow-sm border-bottom">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="d-flex align-items-center py-3">
                  <div class="profile-left">
                    <h5 class="font-weight-bold text-dark mb-1 mt-0">{{details.service}}</h5>
                    <p class="mb-0 text-muted"><i class="feather-map-pin"></i> Posted: {{formatDate(details.created_at)}},  Deadline: {{formatDate2(details.end_date)}} </p>
                  </div>
                  <div class="profile-right ml-auto">
                    <button v-if="id == details.user_id" type="button" class="btn btn-light mr-1" @click="openUpdateService()">  <i class="feather-edit"></i> Edit </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="py-4">
          <div class="container">
            <div class="row">

              <main class="col col-xl-6 order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12">
                <div class="box shadow-sm rounded bg-white mb-3 overflow-hidden">
                    <ul class="nav border-bottom osahan-line-tab" id="myTab" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Overview</a>
                        </li>
                        <li v-if="id == details.user_id" class="nav-item">
                            <a class="nav-link" id="project-comment-tab" data-toggle="tab" href="#project-comments" role="tab" aria-controls="project-listing" aria-selected="false">Applicants</a>
                        </li>
                    </ul>
                </div>

                <div class="tab-content" id="myTabContent">
                  <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                    <div class="box shadow-sm border rounded bg-white mb-3">
                      <div class="box-title border-bottom p-3">
                        <h6 class="m-0">Overview</h6>
                      </div>

                      <div class="box-body p-3">
                        <p v-html="details.description"></p>
                      </div>
                    </div>
                    <div class="box shadow-sm border rounded bg-white mb-3">
                      <div class="box-title border-bottom p-3">
                        <h6 class="m-0">Service Details</h6>
                      </div>
                      <div class="box-body">
                        <table class="table table-borderless mb-0">
                          <tbody>
                            <tr class="border-bottom">
                              <th class="p-3">Service Needed</th>
                              <td class="p-3">{{details.service}} </td>
                            </tr>
                            <tr class="border-bottom">
                              <th class="p-3">Job Location</th>
                              <td class="p-3">{{details.city?details.city.name+',':''}} {{details.state?details.state.name+',':''}} {{details.country?details.country.name:''}}</td>
                            </tr>
                            <tr class="border-bottom">
                              <th class="p-3">Address</th>
                              <td class="p-3">{{details.address}} </td>
                            </tr>
                            <tr class="border-bottom">
                              <th class="p-3">Application Deadline</th>
                              <td class="p-3">{{formatDate2(details.end_date)}} </td>
                            </tr>
                            <tr v-if="id != details.user_id" class="border-bottom">
                              <th class="p-3">Think you can handle this service?</th>
                              <td class="p-3"> 
                                <button v-if="has_applied > 0" type="button" class="btn btn-outline-primary pl-4 pr-4"> Applied </button> 
                                <button v-else type="button" data-toggle="modal" data-target="#provide-modal" class="btn btn-outline-primary pl-4 pr-4"> Provide Service </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div class="tab-pane fade" id="project-comments" role="tabpanel" aria-labelledby="home-tab">
                    <div class="box shadow-sm border rounded bg-white mb-3">
                      <div class="box-title border-bottom p-3">
                        <h6 class="m-0">Applicants</h6>
                      </div>
                      <div v-if="details.applicants">
                        <div class="p-3">
                                <div class="row">
                                    <div v-for="(data, index) in details.applicants" :key="index" class="col-md-12">
                                        <div class="border network-item rounded mb-3">
                                            <div class="p-3 d-flex align-items-center network-item-header">
                                                <div class="dropdown-list-image mr-3">
                                                    <img v-if="data.user.avatar" class="rounded-circle" :src="image_url+'users/'+data.user.avatar" alt="">
                                                    <img v-else class="rounded-circle" src="/assets/avatar.png" alt="">
                                                </div>
                                                <div class="font-weight-bold">
                                                    <router-link v-if="data.user.type == 'user'" :to="'/sc/profile/'+data.user.slug">
                                                        <div class="text-truncate">{{data.user.name.substring(0,18)+".."}}</div>
                                                        <div class="small text-gray-500">{{data.user.profession}}</div>
                                                        <div v-if="data.user.country" class="small text-gray-500"><i class="feather-map-pin"></i> {{data.user.country?data.user.country.name+',':''}} {{data.user.state?data.user.state.name+',':''}}</div>
                                                    </router-link>
                                                    <router-link v-else :to="'/company/'+data.user.company.slug">
                                                        <div class="text-truncate">{{data.user.name.substring(0,18)+".."}}</div>
                                                        <div v-if="data.user.country" class="small text-gray-500"><i class="feather-map-pin"></i> {{data.user.country?data.user.country.name+',':''}} {{data.user.state?data.user.state.name+',':''}}</div>
                                                    </router-link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>                        
                                </div>
                            </div>
                      </div>
                    </div>
                  </div>

                </div>
              </main>
              <aside class="col col-xl-3 order-xl-1 col-lg-6 order-lg-2 col-md-6 col-sm-6 col-12">
                
                <div class="card-body">
                  <h5 class="card-title mb-4"></h5>
                  <div id="featured-properties" class="carousel slide" data-ride="carousel">
                    <ol class="carousel-indicators">
                      <li data-target="#featured-properties" data-slide-to="0" class=""></li>
                      <li data-target="#featured-properties" data-slide-to="1" class="active"></li>
                    </ol>
                    <div class="carousel-inner">        
                      <div v-for="data in adverts.slice(1)" :key="data.id" class="carousel-item">
                        <div class="border rounded bg-white job-item">
                          <a :href="data.link" target="_blank"><img class="img-fluid ml-auto" :src="image_url+'advert/'+data.image" alt=""></a>
                        </div>
                      </div>
                      <div v-for="data in adverts.slice(0,1)" :key="data.id" class="carousel-item active">
                        <div class="border rounded bg-white job-item">
                          <a :href="data.link" target="_blank"><img class="img-fluid ml-auto" :src="image_url+'advert/'+data.image" alt=""></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </aside>
              <aside class="col col-xl-3 order-xl-3 col-lg-6 order-lg-3 col-md-6 col-sm-6 col-12">
                <div class="box mb-3 shadow-sm border rounded bg-white profile-box text-center">
                  <div v-if="details.user" class="py-4 px-3 border-bottom">
                    <a v-if="details.user.type == 'company'" :href="'/company/'+details.user.company.slug" target="_blank">
                      <img v-if="details.user.avatar" class="img-fluid mt-2 rounded-circle" :src="image_url+'users/'+details.user.avatar" style="max-height: 150px; max-width: 150px" alt="">
                      <img v-else class="img-fluid mt-2 rounded-circle" src="/assets/avatar.png" style="max-height: 150px; max-width: 150px" alt="">
                      <h5 class="font-weight-bold text-dark mb-1 mt-4">{{details.user?details.user.name:''}}</h5>
                      <p class="mb-0 text-muted">{{details.user.state?details.user.state.name+',':''}} {{details.user.country?details.user.country.name:''}}</p>
                    </a>
                    <a v-else :href="'/sc/profile/'+details.user.slug" target="_blank">
                      <img v-if="details.user.avatar" class="img-fluid mt-2 rounded-circle" :src="image_url+'users/'+details.user.avatar" style="max-height: 150px; max-width: 150px" alt="">
                      <img v-else class="img-fluid mt-2 rounded-circle" src="/assets/avatar.png" style="max-height: 150px; max-width: 150px" alt="">
                      <h5 class="font-weight-bold text-dark mb-1 mt-4">{{details.user?details.user.name:''}}</h5>
                      <p v-if="details.user.currentcompany" class="mb-0 text-muted">{{details.user.current_position}} <br> <router-link :to="'/company/'+details.user.currentcompany.slug">{{details.user.currentcompany.name}}</router-link></p>
                      <p v-else class="mb-0 text-muted">{{details.user.profession}}</p>
                      <p class="mb-0 tedetails.xt-muted">{{details.user.state?details.user.state.name+',':''}} {{details.user.country?details.user.country.name:''}}</p>
                      <span v-if="details.user.membership" class="text-info"><span v-if="details.user.membership.membership">{{ details.user.membership.membership.code }}</span><i v-if="details.user.membership.status == 1" data-toggle="tooltip" data-placement="top" title="Verified" class="feather-check-circle"></i></span> &nbsp;
                      <span v-if="details.user.certification" class="text-info"><span v-if="details.user.certification.certification">{{ details.user.certification.certification.code }}</span><i v-if="details.user.certification.status == 1" data-toggle="tooltip" data-placement="top" title="Verified" class="feather-check-circle"></i></span>
                    </a>
                  </div>
                </div>
                <!-- <div class="box shadow-sm mb-3 rounded bg-white ads-box text-center overflow-hidden">
                  <div class="p-3">
                    Think you can handle this service?
                    <button type="button" data-toggle="modal" data-target="#send-message" class="btn btn-outline-primary pl-4 pr-4"> Provide Service </button>                    
                  </div>              
                </div> -->
              </aside>
            </div>

          </div>
        </div>





        <!-- update service Modal -->
      <div class="modal fade" id="service-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Update Service Details </h5>
              <button type="button" class="close" @click="closeModal()" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form id="message-form">
                <div class="row">
                  <div class="col-md-12 mb-12 mb-sm-12">
                    <div class="form-group">
                        <label class="form-label">Service Provider Needed <span class="text-danger">*</span></label>
                        <select class="form-control custom-select" name="service" v-model="details.service">
                        <option value="" disabled selected>Select Service</option>
                        <option v-for="(data, index) in professions" :key="index" :value="data.name">{{data.name}}</option>
                        </select>
                    </div>
                    <div class="js-form-message">
                        <label id="nameLabel" class="form-label">Job Description
                        <span class="text-danger">*</span>
                        </label>
                        <div class="form-group">
                            <vue-editor id="job_editor" v-model="details.description" :editorToolbar="customToolbar"></vue-editor>
                        </div>
                    </div>

                    <label class="form-label">Project Location
                      <span class="text-danger">*</span>
                      </label>
                      <div class="row">
                        <div class="col-md-4 mb-3 mb-sm-4">
                          <div class="js-form-message">
                            <div class="form-group">
                              <select class="form-control custom-select" name="country_id" v-model="details.country_id" data-msg="Please select country." data-error-class="u-has-error" data-success-class="u-has-success" @change='fetchStates($event)'>
                                <option value="" disabled selected>Country</option>
                                <option v-for="data in country" :key="data.id" :value="data.id">{{data.name}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 mb-3 mb-sm-4">
                          <div class="js-form-message">
                            <div class="form-group">
                              <select class="form-control custom-select" name="state_id" v-model="details.state_id" data-msg="Please select state." data-error-class="u-has-error" data-success-class="u-has-success" @change='fetchCities($event)'>
                                <option value="" disabled selected>State</option>
                                <option v-for="data in state" :key="data.id" :value="data.id">{{data.name}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 mb-3 mb-sm-4">
                          <div class="js-form-message">
                            <div class="form-group">
                              <select class="form-control custom-select" name="city_id" v-model="details.city_id" data-msg="Please select city." data-error-class="u-has-error" data-success-class="u-has-success">
                                <option value="" disabled selected>City</option>
                                <option v-for="data in city" :key="data.id" :value="data.id">{{data.name}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Address</label>
                            <div class="form-group">
                              <textarea class="form-control" name="address" v-model="details.address"></textarea>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Deadline for Job Application</label>
                            <div class="form-group">
                              <input type="date" class="form-control" name="end_date" v-model="details.end_date" />
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
              </form>

            </div>
            <div class="modal-footer">
              <div v-if="loading">
                <button type="button" class="btn btn-primary" data-dismiss="modal">Loading.....</button>
              </div>
              <div v-else>
                <button type="button" class="btn btn-secondary" @click="closeModal()" data-dismiss="modal">Close</button>&nbsp;
                <button type="button" @click="updateRequest()" class="btn btn-primary">Update Request</button>
              </div>
            </div>

          </div>
        </div>
      </div>





      <!-- send message Modal -->
      <div class="modal fade" id="provide-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-sm" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="form-card">
                  <h2 class="fs-title text-center">Confirm!! </h2>
                  <!-- <div class="row justify-content-center">
                      <div class="col-3"> <img src="https://img.icons8.com/color/96/000000/ok--v2.png" class="fit-image"> </div>
                  </div> <br><br> -->
                  <div class="row justify-content-center">
                      <div class="col-7 text-center">
                          <h6>Please click the confirm button below to apply to this service.</h6>
                      </div>
                  </div>
              </div>
            </div>
            <div class="modal-footer">
             <div v-if="loading">
                <button type="button" class="btn btn-primary" data-dismiss="modal">Loading.....</button>
              </div>
              <div v-else>
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>&nbsp;
                <button type="button" @click="provideService()" class="btn btn-primary">Confirm</button>
              </div>
            </div>

          </div>
        </div>
      </div>




      </div>
  </layout-default>
</template>
<script>
import LayoutDefault from '../../layouts/default.vue';
import axios from 'axios';
import moment from 'moment';
import { VueEditor } from "vue2-editor";
// import $ from "jquery";

export default {
  name: 'projectdetail',
  components: {
    LayoutDefault,
    VueEditor
  },

  created() {
        this.fetchDetails();
        this.fetchAdverts();
        this.fetchProfessions();
        this.fetchCountries();
  },

  watch: {
      $route: {
          immediate: true,
          handler(to) {
            let subject = to.params.id.substring(0, to.params.id.length - 18);
            document.title = to.meta.title || subject.replace(/-/g, " ")+' - SC Listings';
          }
      },
  }, 

  data() {
        return {
            details: {},
            has_applied: null,
            loading: false,
            id: localStorage.getItem('user'),  
            visible: false,
            adverts: [],
            professions: [],
            country: [],
            state: [],
            city: [],
            customToolbar: [
              ["bold", "italic", "underline"],
              [{ list: "ordered" }, { list: "bullet" }],
              // ["image", "code-block"]
            ],
        }
  },   

  methods: {
      logout () {
          localStorage.clear();
          this.$router.go();
        },

        fetchDetails () {
            axios(process.env.VUE_APP_API_URL + 'service/details/'+this.$route.params.id).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.details = response.data;
                this.checkApplication(response.data.id)
                this.fetchStates2(response.data.country_id)
                this.fetchCities2(response.data.state_id)
            }).catch({});
        },

        checkApplication(service) {
            axios(process.env.VUE_APP_API_URL + 'service/check-application/'+service+'/'+localStorage.getItem('user')).then(response =>{
                if (! response.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.has_applied = response.data;
            }).catch({});
        },

        fetchAdverts () {
          axios(process.env.VUE_APP_API_URL + 'adverts').then(response =>{
              if (! response.data.status == 200) {
                  return this.errorMessage = 'Could not fetch data';
              }
              this.adverts = response.data;
          }).catch({});
        },

        fetchProfessions () {
            axios(process.env.VUE_APP_API_URL + 'professions').then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.professions = response.data;
            }).catch({});
        },

        fetchCountries () {
            axios(process.env.VUE_APP_API_URL + 'country').then(response =>{
                if (! response.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.country = response.data;
            }).catch({});
        },

        fetchStates (e) {
            axios(process.env.VUE_APP_API_URL + 'state/'+e.target.value).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.state = response.data;
            }).catch({});
        },

        fetchCities (e) {
            axios(process.env.VUE_APP_API_URL + 'city/'+e.target.value).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.city = response.data;
            }).catch({});
        },

        fetchStates2 (id) {
            axios(process.env.VUE_APP_API_URL + 'state/'+id).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.state = response.data;
            }).catch({});
        },

        fetchCities2 (id) {
            axios(process.env.VUE_APP_API_URL + 'city/'+id).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.city = response.data;
            }).catch({});
        },

        updateRequest() {
            this.loading = true;
            axios.post(process.env.VUE_APP_API_URL + 'service/update', this.details)
            .then((response) => {
            if (response.status === 200) {
                this.$toastr.s(response.message);
                this.$root.$emit('action')
                window.$('#service-modal').modal('hide');
                this.loading = false;
            } else {
                this.$toastr.e(response.data.message);
                this.loading = false;
            }
            }).catch((error) =>{
                this.$toastr.e(error.response.data.message);
                this.loading = false;
            })
        },

        provideService() {
            this.loading = true;
            axios.post(process.env.VUE_APP_API_URL + 'service/provide-service', {user_id: localStorage.getItem('user'), service_id: this.details.id})
            .then((response) => {
            if (response.status === 200) {
                this.$toastr.s(response.message);
                this.$root.$emit('action')
                window.$('#provide-modal').modal('hide');
                this.loading = false;
            } else {
                this.$toastr.e(response.data.message);
                this.loading = false;
            }
            }).catch((error) =>{
                this.$toastr.e(error.response.data.message);
                this.loading = false;
            })
        },

        openUpdateService(){
          window.$('#service-modal').modal('show');
        },

        closeModal(){
          window.$('#service-modal').modal('hide');
        },

        formatDate(date){
          return moment(date).fromNow();
        },

        formatDate2(date) {
				return moment(date).format('DD MMMM, YYYY');
			},

    },

    computed: {
        image_url: function() {
           return process.env.VUE_APP_IMAGE_URL;
        }
    },

    mounted: function () {
      this.$root.$on('action', ()=>{
        this.fetchDetails()
        this.fetchCountries()
      })
    },
}
</script>

<style>
  .banner-container { 
    position: relative; 
  } 
  .banner-container img { 
    width: 100%; 
    height: 100%; 
    box-sizing: border-box; 
  } 
  .banner-container button { 
    position: absolute; 
    top: 0; 
    right: 0; 
  }

  .project-image-container { 
    position: relative; 
  } 
  .project-image-container img { 
    width: 100%; 
    height: 100%; 
    box-sizing: border-box; 
  } 
  .project-image-container button { 
    position: absolute; 
    top: 0; 
    right: 0; 
  }
</style>