<template>
  <layout-default>
    <div class="BlogDetail">
      <div style="background-color: navy" class="py-5">
        <div class="container">
          <div class="row">
            <div class="col-md-12 mx-auto">
              <h1 class="text-white font-weight-light"><span class="font-weight-bold">{{details.title}} </span></h1>
              <p class="mb-2 text-white-50">Posted On: {{formatDate2(details.created_at)}} <br> 
                <span v-if="details.is_user">
                  <router-link v-if="details.user.type == 'company'" :to="'/company/'+details.user.slug">Published By: {{details.user?details.user.name:''}}</router-link>
                  <router-link v-else :to="'/sc/profile/'+details.user.slug">Published By: {{details.user?details.user.name:''}}</router-link>
                </span>
                <span v-else>Published By: Admin</span>
              </p>
              <div v-if="id == details.user_id" style="float:right" class="profile-right ml-auto">
                <button type="button" class="btn btn-light mr-1" @click="openUpdateBlog()">  <i class="feather-edit"></i> Edit </button>
              </div>
            </div>            
          </div>
        </div>
      </div>
      <div class="py-5">
        <div class="container">
          <div class="row">
            <div class="col-lg-8 col-md-8">
              <div class="blog-card padding-card box shadow-sm rounded bg-white mb-3 border-0">
                <img v-if="details.image" class="card-img-top" :src="details.image" alt="">
                <img v-else v-for="(image, index) in (details.images || []).slice(0,1)" :key="index" class="card-img-top" :src="image.path" alt="">
                <div class="card-body">
                  <span class="badge badge-primary">{{details.category?details.category.name:''}}</span>
                  <h2>{{details.title}}</h2>
                  <h6 class="mb-3"><i class="feather-calendar"></i> {{formatDate2(details.created_at)}} / {{details.comments?details.comments.length:0}} Comments / {{details.views}} Views</h6>
                  <p v-html="details.description"></p>      
                </div>
                <div v-if="details.status == 1" class="card-footer border-0">
                  <div class="footer-social"><span>Share</span> : &nbsp;
                    <a :href="'http://www.facebook.com/sharer.php?u='+frontend_url+'blog/'+details.slug" target="_blank"><i class="feather-facebook"></i></a>&nbsp;
                    <a :href="'http://twitter.com/share?text='+details.title+'&url='+frontend_url+'blog/'+details.slug" target="_blank"><i class="feather-twitter"></i></a>&nbsp;
                    <a :href="'http://www.linkedin.com/shareArticle?mini=true&url='+frontend_url+'blog/'+details.slug" target="_blank"><i class="feather-linkedin"></i></a>&nbsp;
                  </div>
                </div>
              </div>
              <div v-if="details.status == 1" class="padding-card reviews-card box shadow-sm rounded bg-white mb-3 border-0">
                <div class="card-body">
                  <h5 class="card-title mb-4">{{details.comments?details.comments.length:0}} Reviews</h5>
                  <div v-for="data in details.comments" :key="data.id" class="media">
                    <img v-if="data.user.avatar" class="d-flex mr-3 rounded" :src="image_url+'users/'+data.user.avatar" alt="">
                    <img v-else class="d-flex mt-3 rounded" src="/assets/avatar.png" alt="">
                    <div class="media-body">
                    <h5 class="mt-0">{{data.user?data.user.name:''}} <small>{{formatDate2(data.created_at)}}</small>
                      <span v-if="id" class="star-rating float-right"><a style="color:primary; cursor:pointer" @click="replyComment(data.id)">Reply</a></span>
                      <span v-else class="star-rating float-right"><a style="color:primary; cursor:pointer" data-toggle="modal" data-target="#login2-modal">Reply</a></span>
                    </h5>
                    <p>{{data.comment}}</p>
                      <div v-for="sub in data.sub" :key="sub.id" class="media mt-4">
                        <img v-if="sub.user.avatar" class="d-flex mr-3 rounded" :src="image_url+'users/'+sub.user.avatar" alt="">
                        <img v-else class="d-flex mt-3 rounded" src="/assets/avatar.png" alt="">
                        <div class="media-body">
                          <h5 class="mt-0">{{sub.user?sub.user.name:''}} <small>{{formatDate2(sub.created_at)}}</small> 
                          <span class="star-rating float-right"></span></h5>
                          <p>{{sub.comment}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="id" class="padding-card box shadow-sm rounded bg-white mb-3 border-0">
                <div class="card-body">
                  <h5 class="card-title mb-4">Leave a Comment</h5>
                  <form name="sentMessage" id="comment-form">
                    <div class="control-group form-group">
                      <div class="controls">
                        <label>Review <span class="text-danger">*</span></label>
                        <textarea rows="5" cols="100" name="comment" class="form-control"></textarea>
                      </div> 
                    </div>
                    <input class="form-control" name="blog_id" :value="details.id" type="hidden">
                    <button type="button" @click="submitComment()" class="btn btn-primary">SUBMIT</button>
                  </form>
                </div>
              </div>
              <div v-else class="padding-card box shadow-sm rounded bg-white mb-3 border-0">
                <div class="card-body">
                  <h5 class="card-title mb-4">Sign In to comment</h5>
                  <form name="sentMessage" id="login-here">
                    <div class="form-group">
                        <label class="mb-1">Email</label>
                        <div class="position-relative icon-form-control">
                            <i class="feather-user position-absolute"></i>
                            <input type="email" name="email" class="form-control">
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="mb-1">Password</label>
                        <div class="position-relative icon-form-control">
                            <i class="feather-unlock position-absolute"></i>
                            <input type="password" name="password" class="form-control">
                        </div>
                    </div>
                    <button type="button" @click="LoginHere()" class="btn btn-primary">SUBMIT</button>
                  </form>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-4">
              <div class="sidebar-card box shadow-sm rounded bg-white mb-3 border-0">
                <div class="card-body">
                  <h5 class="card-title mb-4">Blog Search</h5>
                  <form action="/blog-search">
                  <div class="input-group">
                    <input class="form-control" name="search" placeholder="Type and hit enter" type="text">
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary" type="button"><i class="feather-arrow-right"></i></button>
                    </div>
                  </div>
                  </form>
                </div>
              </div>
              <div class="sidebar-card box shadow-sm rounded bg-white mb-3 border-0">
                <div class="card-body">
                  <h5 class="card-title mb-4">Featured Posts</h5>
                  <div v-for="(data, index) in featured" :key="index">
                    <a :href="'/blog/'+data.slug"><h6>{{data.title}}</h6></a>
                    <p class="mb-0"><i class="feather-calendar"></i> {{formatDate2(data.created_at)}}</p>
                    <hr>
                  </div>
                </div>
              </div>
              <div class="sidebar-card box shadow-sm rounded bg-white mb-3 border-0">
                <div class="card-body">
                  <h5 class="card-title mb-3">Categories</h5>
                  <ul class="sidebar-card-list">
                    <li v-for="(data, index) in categories" :key="index"><a :href="'/blog/category/'+data.slug"><i class="feather-arrow-right-circle"></i> {{data.name}}</a></li>
                  </ul>
                </div>
              </div>
              <div class="sidebar-card box shadow-sm rounded bg-white mb-3 border-0">
                <div class="card-body">
                  <h5 class="card-title mb-4"></h5>
                  <div id="featured-properties" class="carousel slide" data-ride="carousel">
                    <ol class="carousel-indicators">
                      <li data-target="#featured-properties" data-slide-to="0" class=""></li>
                      <li data-target="#featured-properties" data-slide-to="1" class="active"></li>
                    </ol>
                    <div class="carousel-inner">        
                      <div v-for="data in adverts.slice(1)" :key="data.id" class="carousel-item">
                        <div class="border rounded bg-white job-item">
                          <a :href="data.link" target="_blank"><img class="img-fluid ml-auto" :src="image_url+'advert/'+data.image" alt=""></a>
                        </div>
                      </div>
                      <div v-for="data in adverts.slice(0,1)" :key="data.id" class="carousel-item active">
                        <div class="border rounded bg-white job-item">
                          <a :href="data.link" target="_blank"><img class="img-fluid ml-auto" :src="image_url+'advert/'+data.image" alt=""></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>





      <!-- reply comment Modal -->
      <div class="modal fade" id="reply-comment" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close" @click="closeReplyCommentModal" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="padding-card box shadow-sm rounded bg-white mb-3 border-0">
                <div class="card-body">
                  <h5 class="card-title mb-4">Leave a Reply</h5>
                  <form name="sentMessage" id="reply-form">
                    <div class="control-group form-group">
                      <div class="controls">
                        <label>Review <span class="text-danger">*</span></label>
                        <textarea rows="5" cols="100" name="comment" class="form-control"></textarea>
                      </div> 
                    </div>
                    <input class="form-control" name="blog_id" :value="details.id" type="hidden">
                    <input class="form-control" name="parent_id" :value="parent_id" type="hidden">
                    <button type="button" @click="submitReplyComment()" class="btn btn-primary">SUBMIT</button>
                  </form>
                </div>
              </div>
            </div>
            <!-- <div class="modal-footer">
              <div>
                <button type="button" @click="closeModal()" class="btn btn-secondary" data-dismiss="modal">Close</button>&nbsp;
              </div>
            </div> -->

          </div>
        </div>
      </div>





      <!-- update blog Modal -->
      <div class="modal fade" id="update-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Update Blog </h5>
              <button type="button" class="close" @click="closeUpdateModal" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form id="message-form">
                <div class="row">
                  <div class="col-md-12 mb-12 mb-sm-12">
                    <div class="js-form-message">
                        <label id="nameLabel" class="form-label">Blog Title</label>
                        <div class="form-group">
                          <input type="text" class="form-control" name="title" v-model="details.title" />
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="form-label">Blog Category <span class="text-danger">*</span></label>
                        <select class="form-control custom-select" name="cat_id" v-model="details.cat_id">
                        <option value="" disabled selected>Select Category</option>
                        <option v-for="(data, index) in categories" :key="index" :value="data.id">{{data.name}}</option>
                        </select>
                    </div>
                    <div class="js-form-message">
                        <label id="nameLabel" class="form-label">Blog Description
                        <span class="text-danger">*</span>
                        </label>
                        <div class="form-group">
                            <vue-editor id="job_editor" v-model="details.description" :editorToolbar="customToolbar"></vue-editor>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Add Blog Images<span class="text-danger">* </span></label>
                            <div class="box-body p-3">
                              <div class="gallery-box-main">
                                <div class="">
                                  <div class="row">
                                    <div v-for="(data, index) in details.images" :key="index" class="col-md-3 col-sm-4 col-xs-4" style="margin-bottom: 10px">
                                      <div class="project-image-container">
                                        <img style="object-fit:cover; width: 60px; height:60px" :src="data.path" alt="">
                                        <button type="button" style="float:right; border:0; background-color:white" @click="deleteBlogImage(data.id)"><i style="color: red" class="feather-x"></i></button>
                                      </div>
                                    </div>
                                  </div>                                  
                                </div>
                              </div>
                            </div>
                            <div id="my-strictly-unique-vue-upload-multiple-image" style="display: flex; justify-content: center;">
                              <vue-upload-multiple-image
                                @upload-success="uploadImage"
                                @before-remove="beforeRemove"
                                @edit-image="editImage"
                                @data-change="dataChange"
                                :data-images="images"
                                dragText="Drag and drop images"
                                browseText="Browse Images"
                                primaryText="added"
                                markIsPrimaryText="	Set default image"
                                popupText="Description default image"
                                :maxImage=3
                                ></vue-upload-multiple-image>

                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
              </form>

            </div>
            <div class="modal-footer">
              <div v-if="loading">
                <button type="button" class="btn btn-primary" data-dismiss="modal">Loading.....</button>
              </div>
              <div v-else>
                <button type="button" class="btn btn-secondary" @click="closeUpdateModal" data-dismiss="modal">Close</button>&nbsp;
                <button type="button" @click="updateBlog()" class="btn btn-primary">Submit</button>
              </div>
            </div>

          </div>
        </div>
      </div>



    </div>
  </layout-default>
</template>
<script>
import LayoutDefault from "@/layouts/default";
import axios from 'axios';
import { VueEditor } from "vue2-editor";
import moment from 'moment';
import VueUploadMultipleImage from 'vue-upload-multiple-image';
import $ from "jquery";

export default {
  name: 'BlogDetail',
  components: {LayoutDefault, VueEditor, VueUploadMultipleImage},

  created() { 
    this.fetchIp();   
    this.fetchData();
    this.fetchFeaturedBlog();
    this.fetchAdverts();
    this.fetchCategories();
  },

  watch: {
      $route: {
          immediate: true,
          handler(to) {
            let subject = to.params.id.substring(0, to.params.id.length - 10);
            document.title = to.meta.title || 'Structurecity | Blog - '+subject.replace(/-/g, " ");
          }
      },
  }, 

  data() {
    return {
      loading: false,
      ip: '',
      id: localStorage.getItem('user'), 
      details: {
        update_images: '',
      },
      featured: [],
      categories:[],
      parent_id: '',
      adverts: [],
      images: [],
      customToolbar: [
          ["bold", "italic", "underline"],
          [{ list: "ordered" }, { list: "bullet" }],
          // ["image", "code-block"]
      ],
    }
  },

  methods: {
    fetchIp () {
      axios('https://api.ipify.org?format=json').then(response =>{
        this.ip = response.ip;
        this.updateViewCount(this.ip);
        }).catch({});
      
    },

    updateViewCount (ip_address) {
        axios.post(process.env.VUE_APP_API_URL + 'blog/update-view-count', {ip: ip_address, user_id: localStorage.getItem('user'), slug: this.$route.params.id}).then(response =>{
            if (! response.data.status == 200) {
                return this.errorMessage = 'Could not update view';
            }
            this.$root.$emit('refresh-action')
        }).catch({});
    },

    fetchData () {
        axios(process.env.VUE_APP_API_URL + 'blog/detail/'+this.$route.params.id).then(response =>{
            if (! response.data.status == 200) {
                return this.errorMessage = 'Could not fetch data';
            }
            this.details = response.data;
            // if(response.data.status != 1){
            //   this.$router.push('/404');
            // }
        }).catch({});
    },

    fetchFeaturedBlog () {
        axios(process.env.VUE_APP_API_URL + 'blog/featured').then(response =>{
            if (! response.data.status == 200) {
                return this.errorMessage = 'Could not fetch data';
            }
            this.featured = response.data;
        }).catch({});
    },

    fetchAdverts () {
        axios(process.env.VUE_APP_API_URL + 'adverts').then(response =>{
            if (! response.data.status == 200) {
                return this.errorMessage = 'Could not fetch data';
            }
            this.adverts = response.data;
        }).catch({});
    },

    fetchCategories () {
        axios(process.env.VUE_APP_API_URL + 'blog/categories').then(response =>{
            if (! response.data.status == 200) {
                return this.errorMessage = 'Could not fetch data';
            }
            this.categories = response.data;
        }).catch({});
    },

    submitComment () {
      this.loading = true;
      var form = $("#comment-form")[0];
      var _data = new FormData(form);
      _data.append('user_id',localStorage.getItem('user'));
      axios.post(process.env.VUE_APP_API_URL + 'blog/comment', _data)
      .then((response) => {
      if (response.status === 200) {
          this.$toastr.s(response.message);
          $("#comment-form")[0].reset();
          this.$root.$emit('refresh-action')
          this.loading = false;
      } else {
          this.$toastr.e(response.data.message);
          this.loading = false;
      }
      }).catch((error) =>{
          this.$toastr.e(error.response.data.message);
          this.loading = false;
      })
    },

    LoginHere () {
          this.loading = true;
          var form = $("#login-here")[0];
          var _data = new FormData(form);
          axios.post(process.env.VUE_APP_API_URL + 'auth/login', _data)
          .then((response) => {
          if (response.status === 200) {
              this.$toastr.s(response.message);
              localStorage.setItem('user', response.data.data.id);
              localStorage.setItem('slug', response.data.data.slug);
              // this.$router.push('/feeds')
              this.$router.go();
              this.loading = false;
          } else {
              this.$toastr.e(response.data.message);
              this.loading = false;
          }
          }).catch((error) =>{
              this.$toastr.e(error.response.data.message);
              this.loading = false;
          })
      },

    submitReplyComment () {
      this.loading = true;
      var form = $("#reply-form")[0];
      var _data = new FormData(form);
      _data.append('user_id',localStorage.getItem('user'));
      axios.post(process.env.VUE_APP_API_URL + 'blog/comment', _data)
      .then((response) => {
      if (response.status === 200) {
          this.$toastr.s(response.message);
          this.$root.$emit('refresh-action')
           $("#reply-form")[0].reset();
          window.$('#reply-comment').modal('hide');
          this.loading = false;
      } else {
          this.$toastr.e(response.data.message);
          this.loading = false;
      }
      }).catch((error) =>{
          this.$toastr.e(error.response.data.message);
          this.loading = false;
      })
    },

    replyComment (id) {
      this.parent_id = id;
      window.$('#reply-comment').modal('show');
    },

    closeReplyCommentModal(){
        window.$('#reply-comment').modal('hide');
      },

    updateBlog() {
      this.loading = true;
      axios.post(process.env.VUE_APP_API_URL + 'blog/update-blog', this.details)
      .then((response) => {
      if (response.status === 200) {
          this.$toastr.s(response.message);
          this.details.update_images = '';
          this.images = [];
          this.$root.$emit('refresh-action')
          this.closeUpdateModal();
          // window.$('#service-modal').modal('hide');
          this.loading = false;
      } else {
          this.$toastr.e(response.data.message);
          this.loading = false;
      }
      }).catch((error) =>{
          this.$toastr.e(error.response.data.message);
          this.loading = false;
      })
    },

    deleteBlogImage (id) {
        axios(process.env.VUE_APP_API_URL + 'blog/delete-blog-image/'+id)
        .then((response) => {
        if (response.status === 200) {
            this.$toastr.s(response.message);
            this.$root.$emit('refresh-action')
        } else {
            this.$toastr.e(response.data.message);
        }
        }).catch((error) =>{
            this.$toastr.e(error.response.data.message);
        })
    },

    uploadImage(formData, index, fileList) {
        this.details.update_images = fileList;
    },

    beforeRemove (index, done, fileList) {
      console.log('index', index, fileList)
      var r = confirm("remove image")
      if (r == true) {
        done()
      } else {
        ''
      }
    },

    editImage (formData, index, fileList) {
      console.log('edit data', formData, index, fileList)
    },

    dataChange (data) {
      console.log(data)
    },

    closeUpdateModal(){
      window.$('#update-modal').modal('hide');
    },

    openUpdateBlog(){
      window.$('#update-modal').modal('show');
    },

    formatDate2(date) {
      return moment(date).format('MMMM DD, YYYY');
    },
  },

  computed: {
    image_url: function() {
      return process.env.VUE_APP_IMAGE_URL;
    },

    url: function() {
      return process.env.VUE_APP_BASE_URL;
    },

    frontend_url: function() {
      return process.env.VUE_APP_FRONTEND_URL;
    }
  },

  mounted: function () {
    this.$root.$on('refresh-action', ()=>{
      this.fetchData()
    })
  },
}
</script>