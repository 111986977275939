<template>
    <div>
    <div class="main_cont">
        <nav class="navbar navbar-expand-lg secs mt-4 sticky-top d-flex justify-content-center" style="z-index: 1000; background: white; height:80px">
            <div class="container index_cont">
              <router-link class="navbar-brand" to="/"><img class="index_logo" style="max-height: 150px; max-width: 100px" :src="image_url+'logo/'+company.logo" alt="logo"></router-link>
              <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                <span class="navbar-toggler-icon"></span>
              </button>
              <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                <div class="offcanvas-header">
                  <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div class="offcanvas-body">
                  <ul class="navbar-nav justify-content-end align-items-center flex-grow-1 pe-3">
                    <li class="nav-item">
                      <a class="nav-link" href="https://store.structurecity.com" target="_blank">Stores</a>
                    </li>
                    <li class="nav-item">
                      <router-link class="nav-link" to="/contact">Contact</router-link>
                    </li>
                    <li class="nav-item me-2">
                      <router-link class="nav-link" to="/blog">Blog</router-link>
                    </li>
                    <li class="nav-item me-3">
                        <a class="btn btn-danger mb-lg-0 mb-3 ms-lg-0 ms-3 px-3 rounded-pill" @click="loginModal">Log in</a>
                    </li>
                    <li class="nav-item">
                        <a class="btn btn-secondary px-3 rounded-pill" @click="registerModal">Sign up</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
          <div class="first_section">
            <div class="d-flex justify-content-center land-text">
            <h1 class="text-sm-center text-left"> <span style="color: #313E7E;">No 1. Community,</span> of Construction & Real Estate Enthusiasts in Africa </h1>
          </div>
          <div style="width: 90%;" class="m-auto">
            <button class="btn btn-danger rounded-pill px-3 d-sm-none d-block" @click="registerModal" style="font-size: 24px;font-weight: 500;">Get started</button>

          </div>
          <div class="d-flex justify-content-center mt-5 d-sm-flex d-none ">
            <div style="cursor: pointer" @click="scrollDown()" class="position-relative down_btn">
                <img src="/assets/Vector2.png" alt="">
                <img class="position-absolute" style="left: 23px; top: 33px" src="/assets/Vector1.png" alt=""><br>
                <span style="margin-left: -15px">How it Works</span>
            </div>
          </div>

          <div class="d-flex designs">
            <div class="design1 d-sm-block d-none me-2">
                <div class="first mb-2 position-relative">
                    <img src="/assets/SCLISTING/img1.png" width="100%" height="100%" alt="">
                    <div class="overlay position-absolute"></div>
                </div>
                <div class="second position-relative">
                    <img src="/assets/SCLISTING/img2.png" width="100%" height="100%" alt="">
                    <div class="overlay yellow position-absolute"></div>   
                </div>
            </div>
            <div class="design2 me-2">
                <div class="contentT position-relative">
                    <img src="/assets/SCLISTING/img3.png" width="100%" height="100%" alt="">
                    <div class="overlay2 position-absolute"></div>   
                </div>
            </div>
            <div class="design3 d-lg-block d-none me-2">
                <div class="contentT position-relative">
                    <img src="/assets/SCLISTING/img4.png" width="100%" height="100%" alt="">
                    <div class="overlay3 position-absolute"></div>   
                </div>
            </div>
            <div class="design3 me-2">
                <div class="contentT position-relative">
                    <img src="/assets/SCLISTING/img5.png" width="100%" height="100%" alt="">
                    <div class="overlay3 white position-absolute"></div>   

                </div>
            </div>
            <div class="design3 d-lg-block d-none me-2">
                <div class="contentT position-relative">
                    <img src="/assets/SCLISTING/img6.png" width="100%" height="100%" alt="">
                    <div class="overlay3 yellow position-absolute"></div>   
                </div>
            </div>
            <div class="design2 me-sm-2 me-0 design2s ">
                <div class="contentT position-relative">
                    <img src="/assets/SCLISTING/img7.png" width="100%" height="100%" alt="">
                    <div class="overlay2 overlay2s red position-absolute"></div>   

                </div>
            </div>
            <div class="design4 d-md-block d-none">
                <div class="first mb-2 position-relative">
                    <img src="/assets/SCLISTING/img8.png" width="100%" height="100%" alt="">
                    <div class="overlay4 red position-absolute"></div>                 
                </div>
                <div class="second position-relative">
                    <img src="/assets/SCLISTING/img2.png" width="100%" height="100%" alt="">
                    <div class="overlay4 position-absolute"></div>                
                </div>
            </div>
          </div>
        </div>
      
    <div class="portfolio-cont secs second_section my-5 site-height position-relative" id='second_section'>
      <div class="design">
          <div class="position-relative" style="width: 100%; height: 100%;">
              <img src="/assets/port1.png" class='first' alt="">
              <img src="/assets/port2.png" alt="" class="second">
              <img src="/assets/port3.png" alt="" class="third">

              
              <div class="page-content d-flex justify-content-center align-items-center">
                  <div class="d-flex flex-md-row flex-column align-items-center content justify-content-around">
                      <div class="port-text">
                          <h1>Create your 
                              portfolio
                          </h1>
                          <h3>and enjoy high visibility
                              on your products/services
                          </h3>
                      </div>
                      <div class="port-img" style="z-index: 1;">
                          <img src="/assets/Coder.png" width="100%" />
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
  
  <div class="client-cont secs third_section my-5 site-height position-relative">
    <div class="design">
        <div class="position-relative" style="width: 100%; height: 100%;">
            <img src="/assets/client1.png" class='first' alt="design">
            <img src="/assets/client2.png" class="second" alt="design">
            <img src="/assets/client3.png" class="third " alt="design">

            
            <div class="page-content client-page d-flex justify-content-center align-items-center">
                <div class="d-flex flex-md-row flex-column align-items-center content justify-content-around">
                    <div class="client-img" style="z-index: 1;">
                        <img src="/assets/client4.png" style="border-radius: 50%; width: 100%;" alt="">
                    </div>
                    <div class="client-text text-end">
                        <h1>Meet new 
                            clients
                        </h1>
                        <p class="mb-4">in need of your services</p>
                        <button class="btn btn-primary rounded-pill mb-2 px-4" style="background: #C80000; border: none;" @click="loginModal('request_service')">Request a Service </button><br>
                        <button class="btn btn-primary rounded-pill px-4" style="background: #FF6B6B; border: none;" @click="loginModal('provide_service')">Provide Service </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="portfolio-cont secs fourth_section my-5 site-height position-relative">
  <div class="design">
      <div class="position-relative" style="width: 100%; height: 100%;">
          <img src="/assets/blog1.png" class='first' alt="">
          <img src="/assets/blog2.png" alt="" class="second">
          <img src="/assets/blog3.png" alt="" class="third">

          
          <div class="page-content d-flex justify-content-center align-items-center">
              <div class="d-flex flex-md-row flex-column align-items-center content justify-content-around">
                  <div class="port-text">
                      <h1><router-link to="/blog">Blog</router-link></h1>
                      <h3>Stay up to date on latest 
                          trends in the industry
                      </h3>
                  </div>
                  <div class="port-img" style="z-index: 1;">
                      <img src="/assets/blog4.png" width="100%" />
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>



<div class="build-cont secs fifth_section site-height position-relative">
  <div class="design">
      <div class="position-relative" style="width: 100%; height: 100%;">
          <img src="/assets/build1.png" class='first' alt="design">
          <img src="/assets/build2.png" class="second" alt="design">
          <img src="/assets/build3.png" class="third " alt="design">

          
          <div class="page-content d-flex justify-content-center align-items-center">
              <div class="d-flex flex-lg-row flex-column-reverse align-items-center content justify-content-around">
                  <div class="build-img" style="z-index: 1;">
                      <img src="/assets/build4.png" style="border-radius: 50%; width: 100%;" alt="">
                  </div>
                  <div class="build-text text-lg-end text-start">
                      <h1 style="font-size: 55px">Buy and sell building materials,</h1>
                      <p style="font-size: 25px">equipments and other products from our stores</p>
                      <a href="https://store.structurecity.com/" target="_blank" class="btn btn-primary rounded-pill px-4" style="background: #FF6B6B; border: none;">Get started</a>
                  </div>
              </div>
          </div>
          
      </div>
      
  </div>
</div>

<div class="py-md-4 secs py-5 build-footer-cont" id="fpad" style="background: #F9F6F6;">
    <div class="d-flex flex-lg-row flex-column align-items-center justify-content-around">
        <div>
        <router-link class="navbar-brand" to="/"><img style="max-height: 150px; max-width: 100px" :src="image_url+'logo/'+company.logo" /></router-link>
        </div>
        <div class="my-lg-0 my-4">
            <span class="me-3"><a href="https://store.structurecity.com" target="_blank">Stores</a></span>    
            <span class="me-3"><router-link to="/contact">Contact</router-link></span>    
            <span class="me-3"><router-link to="/blog">Blog</router-link></span>      
            <button class="btn me-3 margin btn-outline-secondary rounded-pill" @click="loginModal">Log in</button>
            <button class="btn btn-outline-secondary rounded-pill" @click="registerModal">Sign up</button>
        </div>
        <div style="margin-bottom: 30px" class="build-footer">
        <ul class="list-inline mb-0">
            <li class="list-inline-item">
            <a class="btn btn-sm btn-light" :href="company.facebook" target="_blank">
            <span class="feather-facebook"></span>
            </a>
            </li>
            <li class="list-inline-item">
            <a class="btn btn-sm btn-light" :href="company.instagram" target="_blank">
            <span class="feather-instagram"></span>
            </a>
            </li>
            <li class="list-inline-item">
            <a class="btn btn-sm btn-light" :href="company.twitter" target="_blank">
            <span class="feather-twitter"></span>
            </a>
            </li>
            <li class="list-inline-item">
            <a class="btn btn-sm btn-light" :href="company.linkedin" target="_blank">
            <span class="feather-linkedin"></span>
            </a>
            </li>
        </ul>
        </div>
    </div>
    <h2>Copyright © 2022 <a href="https://www.structurecity.com/company/structurecity-associates-limited-1666183265" target="_blank">{{company.short_name}}.</a> All rights reserved</h2>
    </div>
    </div>





    <!-- login modal -->
<div class="modal fade" style="justify-content: center" id="login" ref="login-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content land-modal-content">
            <div class="modal-header">
              <button type="button" class="btn-close modal-close pe-5 pt-5" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="m-auto px-5 pb-5 landpage-modal">
                <div class="d-flex justify-content-center">
                    <img style="max-height: 150px; max-width: 100px" :src="image_url+'logo/'+company.logo" alt="logo">
                </div>
                <h1 class="text-center mt-3">Welcome to {{company.short_name}}</h1>
                <p class="text-center">Don’t miss your next opportunity
                    Sign in to stay updated on your professional world.
                </p>
                <form id="login-form" class="px-md-5 px-1">
                    <div class="mb-3">
                        <label for="email" class="form-label">Email address</label>
                        <input type="email" class="form-control rounded-pill" name="email">
                    </div>
                    <div class="mb-3" style="position: relative">
                        <label for="password" class="form-label">Password</label>
                        <input :type="show ? 'text' : 'password'" class="form-control rounded-pill" name="password">
                        <div class="eyeButton">
                            <span @mousedown="show = !show" @mouseup="show = !show" @touchstart="show = !show" @touchend="show = !show" style="cursor: pointer;">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <span><a style="cursor:pointer" @click="forgotPasswordModal()">Forgot your password?</a></span>                    
                    <div class="mt-3">
                        <button v-if="loading" type="button" class="form-control btn btn-danger rounded-pill">Loading......</button>
                        <button v-else type="button" @click="login" class="form-control btn btn-danger rounded-pill">Log in</button>
                    </div>
                    <!-- <div class="row text-center mt-3 border-bottom pb-3">
                        <p class="small text-muted">Or login with</p>
                        <div class="row">
                            <div class="col-4">
                                <button type="button" @click="AuthProvider('google')" class="btn btn-sm btn-outline-google btn-block"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 488 512" style="max-height:20px"><path d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"/></svg> Google</button>
                            </div>
                            <div class="col-4">
                                <button type="button" @click="AuthProvider('facebook')" class="btn btn-sm btn-outline-facebook btn-block"><i class="feather-facebook"></i> Facebook</button>
                            </div>
                            <div class="col-4">
                                <button type="button" @click="AuthProvider('linkedin')" class="btn btn-sm btn-outline-facebook btn-block"><i class="feather-linkedin"></i> Linkedin</button>
                            </div>
                        </div>
                    </div><br> -->
                    <div class="d-flex justify-content-between mt-3">                        
                        <span>New to Structurecity?</span>
                        <span class="text-danger"><a style="cursor:pointer" @click="registerModal()">Register Here</a></span>
                    </div>
                </form>
            </div>
          </div>
        </div>
      </div>


      <!-- signup modal -->
    <div class="modal fade" id="signup" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content land-modal-content">
            <div class="modal-header">
              <button type="button" style="padding-right: 30px" class="btn-close modal-close pe-5 pt-5" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="m-auto px-5 pb-5 landpage-modal">
                <div class="d-flex justify-content-center">
                    <img style="max-height: 150px; max-width: 100px" :src="image_url+'logo/'+company.logo" alt="logo">
                </div>
                <h1 class="text-center mt-3">Welcome to {{company.short_name}}</h1>
                <p class="text-center">Don’t miss your next opportunity
                    Sign up to stay updated on your professional world.
                </p>
                <form id="register-form" class="px-md-5 px-1">
                    <div class="mb-3">
                        <label for="first_name" class="form-label">First Name</label>
                        <input type="text" class="form-control rounded-pill" name="first_name">
                    </div>
                    <div class="mb-3">
                        <label for="last_name" class="form-label">Last Name</label>
                        <input type="text" class="form-control rounded-pill" name="last_name">
                    </div>
                    <div class="mb-3">
                        <label for="email" class="form-label">Email address</label>
                        <input type="email" class="form-control rounded-pill" name="email">
                    </div>
                    <div class="mb-3">
                        <label for="phone_number" class="form-label">Phone Number</label>                        
                        <div class="row">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-md-4 col-sm-4 col-xs-4">
                                        <countryCode/>
                                    </div>
                                    <div class="col-md-8 col-sm-8 col-xs-8">
                                        <input type="text" class="form-control rounded-pill" name="phone_number" placeholder="080---" oninput="this.value = this.value.replace(/[^0-9+]/g, '').replace(/(\..*?)\..*/g, '$1');" maxlength="11" minlength="9">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group mb-0">
                            <label class="mb-1 w-100">Are you a Construction Professional?</label>
                            <div style="padding-left: 30px" class="custom-control custom-radio d-inline mr-3">
                                <input type="radio" class="custom-control-input" name="is_professional" value="yes" v-model="is_professional" id="customCheck_yes">
                                <label class="custom-control-label" for="customCheck_yes">Yes</label>
                            </div>
                            <div style="padding-left: 30px" class="custom-control custom-radio d-inline mr-3">
                                <input type="radio" class="custom-control-input" name="is_professional" value="no" v-model="is_professional" id="customCheck_no">
                                <label class="custom-control-label" for="customCheck_no">No</label>
                            </div>
                        </div>
                    </div><br/>
                    <div v-if="is_professional == 'yes'" class="mb-3">
                        <label for="password" class="form-label">Select Your Profession </label>
                        <select class="form-control rounded-pill" name="profession" v-model="profession">
                          <option value="" disabled selected>--select--</option>
                          <option v-for="(data, index) in professions" :key="index" :value="data.name">{{data.name}}</option>
                          <option value="others">Others</option>
                        </select>
                    </div>
                    <div v-if="is_professional == 'yes' && profession == 'others'" class="mb-3">
                        <label for="other_profession" class="form-label">Your Profession</label>
                        <input type="text" class="form-control rounded-pill" name="other_profession">
                    </div>
                    <div class="mb-3" style="position: relative">
                        <label for="password" class="form-label">Password</label>
                        <input :type="show ? 'text' : 'password'" class="form-control rounded-pill" name="password">
                        <div class="eyeButton">
                            <span @mousedown="show = !show" @mouseup="show = !show" @touchstart="show = !show" @touchend="show = !show" style="cursor: pointer;">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div class="mb-3" style="position: relative">
                        <label for="password" class="form-label">Confirm Password</label>
                        <input :type="show ? 'text' : 'password'" class="form-control rounded-pill" name="confirm_password">
                        <div class="eyeButton">
                            <span @mousedown="show = !show" @mouseup="show = !show" @touchstart="show = !show" @touchend="show = !show" style="cursor: pointer;">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                                </svg>
                            </span>
                        </div>
                    </div>
                    
                    <div class="mt-3">
                      <button v-if="loading" type="button" class="form-control btn btn-danger rounded-pill">Loading......</button>
                      <button v-else type="button" @click="register" class="form-control btn btn-danger rounded-pill">Sign Up</button>
                    </div>
                    <!-- <div class="row text-center mt-3 border-bottom pb-3">
                        <p class="small text-muted">Or register with</p>
                        <div class="row">
                            <div class="col-4">
                                <button type="button" @click="AuthProvider('google')" class="btn btn-sm btn-outline-google btn-block"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 488 512" style="max-height:20px"><path d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"/></svg> Google</button>
                            </div>
                            <div class="col-4">
                                <button type="button" @click="AuthProvider('facebook')" class="btn btn-sm btn-outline-facebook btn-block"><i class="feather-facebook"></i> Facebook</button>
                            </div>
                            <div class="col-4">
                                <button type="button" @click="AuthProvider('linkedin')" class="btn btn-sm btn-outline-facebook btn-block"><i class="feather-linkedin"></i> Linkedin</button>
                            </div>
                        </div>
                    </div><br> -->
                    <div class="d-flex justify-content-between mt-3">
                        <span>Already have an account?</span>
                        <span class="text-danger"><a style="cursor:pointer" @click="loginModal()">Login Here</a></span>
                    </div>
                </form>
            </div>
          </div>
        </div>
      </div>


      <!-- forgot password modal -->
<div class="modal fade" id="forgot_password" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content land-modal-content">
            <div class="modal-header">
              <button type="button" class="btn-close modal-close pe-5 pt-5" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="m-auto px-5 pb-5 landpage-modal">
                <div class="d-flex justify-content-center">
                    <img style="max-height: 150px; max-width: 100px" :src="image_url+'logo/'+company.logo" alt="logo">
                </div>
                <h1 class="text-center mt-3">Forgot Password</h1>
                <p class="text-center">Type in your account email address to receive reset link</p>
                <form id="forgot-passord-form" class="px-md-5 px-1">
                    <div class="mb-3">
                        <label for="email" class="form-label">Email address</label>
                        <input type="email" class="form-control rounded-pill" name="email">
                    </div>
                    <div class="mt-3">
                      <button v-if="loading" type="button" class="form-control btn btn-danger rounded-pill">Loading.....</button>
                      <button v-else type="button" @click="forgotPassword" class="form-control btn btn-danger rounded-pill">Submit</button>
                    </div>
                    <div class="d-flex justify-content-between mt-3">
                        <span>Have remembered your login?</span>
                        <span class="text-danger"><a style="cursor:pointer" @click="loginModal()">Login Here</a></span>
                    </div>
                </form>
            </div>
          </div>
        </div>
      </div>




       <!-- verify modal -->
    <div class="modal fade" id="verify" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content land-modal-content">
            <div class="modal-header">
              <button type="button" class="btn-close modal-close pe-5 pt-5" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="m-auto px-5 pb-5 landpage-modal">
                <div class="d-flex justify-content-center">
                    <img style="max-height: 150px; max-width: 100px" :src="image_url+'logo/'+company.logo" alt="logo">
                </div>
                <h1 class="text-center mt-3">Verify Account</h1>
                <p class="text-center">Input the code sent to your email and phone number</p>
                <form id="verify-form" class="px-md-5 px-1">
                    <div class="mb-3">
                        <label for="email" class="form-label">Verification Code</label>
                        <input type="email" class="form-control rounded-pill" name="code">
                    </div>
                    <div class="mt-3">
                      <button v-if="loading" type="button" class="form-control btn btn-danger rounded-pill">Loading.....</button>
                      <button v-else type="button" @click="verifyAccount" class="form-control btn btn-danger rounded-pill">Submit</button>
                    </div>
                </form>
            </div>
          </div>
        </div>
      </div>
      
    </div>
</template>
<script>
import axios from 'axios';
import $ from "jquery";
import countryCode from '../components/Countrycode.vue';

export default {
  components: {countryCode},

  created() {
    this.fetchProfessions();
    this.fetchCompany();
    this.fetchUser();    
  },

  mounted() {
    if(this.$route.query.page == 'login'){
        this.showLoginModal();       
    }
        
},

  data() {
        return {
          login_type: null,
          company: {},
          user: {},
          professions:[],
          profession:'',
          loading: false,
          id: localStorage.getItem('slug'),
          is_professional:'',
          show:false,
          country_tel_code: "234"
        }
  },   

  methods: {
        logout () {
          localStorage.clear();
          this.$router.go();
        },

        fetchCompany () {
            axios(process.env.VUE_APP_API_URL + 'company').then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.company = response.data;
            }).catch({});
        },

        fetchProfessions () {
            axios(process.env.VUE_APP_API_URL + 'professions').then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.professions = response.data;
            }).catch({});
        },

        fetchUser () {
            axios(process.env.VUE_APP_API_URL + 'user/details/'+localStorage.getItem('user')).then(response =>{
                if (! response.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.user = response.data;
            }).catch({});
        },

        login () {
            this.loading = true;
            var form = $("#login-form")[0];
            var _data = new FormData(form);
            axios.post(process.env.VUE_APP_API_URL + 'auth/login', _data)
            .then((response) => {
            if (response.status === 200) {
                this.$toastr.s(response.message);                
                localStorage.setItem('user', response.data.data.id);
                localStorage.setItem('slug', response.data.data.slug);
                // this.$router.go();
                window.$('#login').modal('hide');
                if(this.login_type == 'request_service'){
                    this.$router.push('/my-service-requests')
                } else if(this.login_type == 'provide_service'){
                    this.$router.push('/services')
                } else {
                    this.$router.push('/feeds')
                }
                location.reload();
                this.loading = false;
            } else {
                this.$toastr.e(response.data.message);
                this.loading = false;
            }
            }).catch((error) =>{
                this.$toastr.e(error.response.data.message);
                this.loading = false;
            })
        },

        register () {
                this.loading = true;
                var form = $("#register-form")[0];
                var _data = new FormData(form);
                axios.post(process.env.VUE_APP_API_URL + 'auth/register', _data)
            .then((response) => {
            if (response.status === 200) {
                $("#register-form")[0].reset();
                this.is_professional = '';
                this.profession = '';
                window.$('#signup').modal('hide');
                window.$('#verify').modal('show');
                this.$toastr.s(response.message);
                this.loading = false;
            } else {
                this.$toastr.e(response.data.message);
                this.loading = false;
            }
            }).catch((error) =>{
                this.$toastr.e(error.response.data.message);
                this.loading = false;
            })
        },

        verifyAccount () {
            this.loading = true;
            var form = $("#verify-form")[0];
            var _data = new FormData(form);
            var code = _data.get("code");
            this.loading = false;
            window.location.href = '/verify/account/'+code
            // this.$router.push('/verify/account/'+code)
            // this.$router.go();
            // this.$router.push({ name: 'VerifyAccount', query: { redirect: '/verify/account/'+code } });                
        },

        forgotPassword () {
                this.loading = true;
                var form = $("#forgot-passord-form")[0];
                var _data = new FormData(form);
                axios.post(process.env.VUE_APP_API_URL + 'auth/forgot-password', _data)
            .then((response) => {
            if (response.status === 200) {
                window.$('#forgot_password').modal('hide');
                window.$('#login').modal('show');
                this.$toastr.s(response.message);
                // this.$router.push({ name: 'Login', query: { redirect: '/login' } });
                this.loading = false;
            } else {
                this.$toastr.e(response.data.message);
                this.loading = false;
            }
            }).catch((error) =>{
                this.$toastr.e(error.response.data.message);
                this.loading = false;
            })
        },

        showLoginModal(){
            window.$('#login').modal('show');
        },

        loginModal(to){
            this.login_type = to;
            window.$('#signup').modal('hide');
            window.$('#forgot_password').modal('hide');
            setTimeout(() => {
                window.$('#login').modal('show');
            }, 350);
        },

        registerModal(){
            window.$('#login').modal('hide');
            setTimeout(() => {
                window.$('#signup').modal('show');
            }, 350);
        },

        forgotPasswordModal(){
            window.$('#login').modal('hide');
            setTimeout(() => {
                window.$('#forgot_password').modal('show');
            }, 350);
        },

        scrollDown(){
            let second_section = document.querySelector('.second_section');
            second_section.scrollIntoView({behavior: 'smooth'})
        },

        AuthProvider(provider) {

            var self = this

            this.$auth.authenticate(provider).then(response =>{

            self.SocialLogin(provider,response)

            }).catch(err => {
                console.log({err:err})
            })

        },

        SocialLogin(provider,response){

            console.log(provider, response)

            axios.get(process.env.VUE_APP_BASE_URL + 'social/login/'+provider, response).then(res => {

                console.log(res, response)

            }).catch(err => {
                console.log({err:err})
            })
        },
    },

//     watch: {
//       $route: {
//           immediate: true,
//           handler(to) {
//             let subject = to.params.id.substring(0, to.params.id.length - 10);
//             document.title = to.meta.title || subject.replace(/-/g, " ")+' - Structurecitys';
//           }
//       },
//   }, 

    computed: {
        image_url: function() {
          return process.env.VUE_APP_IMAGE_URL;
        }
    },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Poppins');

body{
    font-family: Poppins !important;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.nav-link {
    font-weight: 300 !important;
    font-size: 24px !important;
    color: #313E7E !important;
}
.nav-item button {
    font-weight: 700;
    font-size: 24px;
}
.land-text {
    margin-top: 130px;
}
.land-text h1 {
    width: 50%;
    font-weight: 700;
    font-size: 48px;
}
.modal-header {
    border-bottom: none !important;
}
.landpage-modal h1 {
    font-weight: 700;
    font-size: 24px;
    color: #313E7E;
}
.landpage-modal p {
    font-weight: 300;
    font-size: 15px;
    color: #4C4D52;
}
.landpage-modal label {
    font-weight: 600;
    font-size: 14px;
}
.landpage-modal span {
    font-weight: 600;
    font-size: 14px;
}
.modal.show .modal-dialog {
    margin-top: 125px;
}
.modal.show {
    display: flex;
}
.land-modal-content {
    background-color: rgba(255, 255, 255, 0.958) !important;
    border-radius: 5% !important;
}
.modal-close {
    background-image: url('/assets/closebtn.png') !important;
    background-size: 50% !important;
}

.modal-header .btn-close {
    /* margin-top: 5px; */
    padding-right: 20px;
}
.navbar-toggler-icon {
    background-image: var(--bs-navbar-toggler-icon-bg);
}
.offcanvas {
    height: 50vh;
}
.offcanvas-header .btn-close {
    margin-top: 20px;
    padding-left: 20px;
}
.designs{
    margin-top: -190px;
}
.design1 .first {
    width: 100%;
    height: 340px;
    background-color: #313E7E;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
}
.design1 img {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
}
.designs img {
    object-fit: cover;
}
.yellow {
    background-color: rgba(255, 255, 0, 0.448) !important;
}
.red {
    background-color: rgba(255, 0, 0, 0.448) !important;
}
.white {
    background-color: rgba(255, 255, 255, 0.448) !important;
}
.designs .overlay {
    width: 100%;
    height: 100%;
    background-color: #313e7e64;
    top: 0;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
}
.design4 .first {
    width: 100%;
    height: 340px;
    background-color: #313E7E;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
}
.design1, .design4 {
    width: 10%;
}

.design1 .second {
    width: 100%;
    height: 180px;
    background-color: #313E7E;
    border-top-right-radius: 30px;
}
.design4 .second {
    width: 100%;
    height: 180px;
    background-color: #313E7E;
    border-top-left-radius: 30px;
}
.design4 img {
    border-bottom-left-radius: 30px;
    border-top-left-radius: 30px;
}
.designs .overlay4 {
    border-bottom-left-radius: 30px;
    border-top-left-radius: 30px;
    width: 100%;
    height: 100%;
    background-color: #313e7e64;
    top: 0;
}
.design2 {
    width: 16%;
    margin-top: auto;
}
.design2 .contentT {
    height: 360px;
    background-color: #313E7E;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
}
.design2 img {
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
}
.designs .overlay2 {
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    width: 100%;
    height: 100%;
    background-color: #313e7e64;
    top: 0;
}
.design3 {
    width: 16%;
    margin-top: auto;
}
.design3 .contentT {
    height: 300px;
    background-color: #313E7E;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
}
.design3 img {
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
}
.designs .overlay3 {
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    width: 100%;
    height: 100%;
    background-color: #313e7e64;
    top: 0;
}

@media (max-width: 991px) {
    .design2, .design3 {
        width: 26.7%;
    }
    .land-text h1 {
        width: 60%;
    }
}

@media (max-width: 767px) {
    .design2, .design3 {
        width: 30%
    }
    .land-text h1 {
        width: 70%;
        font-size: 40px;
    }
}

@media (max-width: 575px) {
    .design3 {
        width: 70%;
    }
    .design2 {
        width: 30%;
    }
    .design2s {
        border-bottom-left-radius: 30px;
        border-top-left-radius: 30px;
    }
    .design2s img {
        border-top-right-radius: 0 !important;
        border-top-left-radius: 30px !important;
    }
    
    .design2 {
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
    }
    .design2 img {
        border-top-right-radius: 30px;
        border-top-left-radius: 0;
    }
    .land-text {
        margin-top: 50px;
    }
    .land-text h1 {
        margin-bottom: 30px;
        width: 90%;
        font-weight: 700;
        font-size: 32px;
    }
    .designs {
        margin-top: 40px;
    }
    /* .design2s */
}




/* porfolio */
.portfolio-cont .design {
    height: 870px;
    position: absolute;
    top: 0;
    width: 100%;
}
.portfolio-cont .design .first {
    position: absolute;
    top: 0;
    width: 62%;
}
.portfolio-cont .design .second {
    position: absolute;
    bottom: 0;
    width: 13%;
}
.portfolio-cont .design .third {
    height: 88%;
    width: 45%;
    position: absolute;
    right: 0;
    bottom: 0;
}


.port-text {
    width: 50%;
}
.port-text h1 {
    font-weight: 500;
    font-size: 64px;
    color: #313E7E;
}
.port-text h3 {
    font-weight: 300;
    font-size: 36px;   
    color: #585858;

}
.port-img .second {
    width: 45%;
}


@media (max-width: 991px) {
    .port-text {
        z-index: 1;
    }
    .port-text h1 {
        font-size: 50px;
    }
    .port-text h3 {
        font-size: 28px;
    }
    .port-img .second {
        width: 60%;
    }
}

@media (max-width: 767px) {
    .portfolio-cont .design .third {
        height: 40%;
        width: 140%;
    }
    .portfolio-cont .design .second {
        top: 0;
        right: 0;
        width: 25%;
        height: 35%;
        transform: rotate(180deg);
    }
    .port-img {
        width: 80%;
    }
    .port-text {
        width: 100%;
        margin-bottom: 70px;
    }
}

@media (max-width: 575px) {
    .port-text h1 {
        font-size: 36px;
    }
    .port-text h3 {
        font-size: 20px;
    }
    .port-img {
        width: 100%;
    }
}



/* client */
.site-height {
    height: 870px;
}
.client-cont .design {
    height: 870px;
    position: absolute;
    top: 0;
    width: 100%;
}
.client-cont .design .first {
    position: absolute;
    top: 0;
    width: 62%;
    right: 20px;
}
.client-cont .design .second {
    position: absolute;
    bottom: 0;
    width: 13%;
    right: 0;
}
.client-cont .design .third {
    height: 70%;
    width: 45%;
    position: absolute;
    left: 0;
    bottom: 0;
}
.page-content {
    width: 100%;
    height: 100%;
}


.client-img {
    width: 50%;
}
.client-text h1 {
    font-weight: 500;
    font-size: 64px;
    padding-left: 40%;
    color: #DC0000;
}
.client-text p {
    font-weight: 300;
    font-size: 36px;
    color: #585858;

}
.client-text {
    width: 45%;
}
.content {
    width: 85%;
}

@media (max-width: 991px) {
    .client-text {
        z-index: 1;
    }
    .client-text h1 {
        font-size: 50px;
    }
    .client-text p {
        font-size: 28px;
    }
    .client-text h1 {
        padding-left: 20%;
    }
    .client-img {
        width: 60%;
    }
    .client-cont .design {
        height: 870px;
    }
}

@media (max-width: 767px) {
    .client-cont .design .first {
        bottom: 0;
        top: auto;
    }
    .client-cont .design .second {
        /* top: -170px; */
        bottom: auto;
        width: 100%;
        height: 40%;
        left: 0;
        transform: rotate(180deg);
    }
    .client-cont .design {
        height: 870px;
    }
    .client-cont .design .third {
        height: 40%;
        width: 30%;
        left: -70px;
    }
    .client-cont .design .first {
        transform: rotate(180deg);
        width: 80%;
        right: 0;
    }
    .client-page {
        height: 90%;
    }
    .client-text {
        margin-top: 30px;
    }
    .client-text h1 {
        padding-left: 10%;
    }
    .client-text {
        width: 70%;
    }
}

@media (max-width: 575px) {
    .client-text h1 {
        font-size: 36px;
    }
    .client-text p {
        font-size: 20px;
    }
    .client-img{
        width: 116%;
    }
    .client-text h1 {
        padding-left: 0;
    }
    .client-text {
        width: 90%;
    }
}









/* build */

.build-cont .design {
    height: 870px;
    position: absolute;
    top: 0;
    width: 100%;
}
.build-cont .design .first {
    position: absolute;
    top: 0;
    width: 62%;
    right: 20px;
}
.build-cont .design .second {
    position: absolute;
    bottom: 0;
    width: 2.5%;
    right: 0;
}
.build-cont .design .third {
    height: 70%;
    width: 45%;
    position: absolute;
    left: 0;
    bottom: 0;
}
.build-img {
    width: 68%;
}
.build-text {
    width: 32%;
}
.build-text h1 {
    font-weight: 500;
    font-size: 64px;
    color: #367CFF;
}
.build-text p {
    font-weight: 300;
    font-size: 36px;
    color: #585858;
}
.build-text button {
    font-weight: 500;
    font-size: 36px;
}
.build-footer img {
    width: 30px;
}
.build-footer-cont span {
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 0.045em;
    color: #757070;
}
.build-footer-cont h2 {
    font-weight: 300;
    font-size: 18px;
    letter-spacing: 0.28em;
    color: #757070;
    text-align: center;
    margin-top: 30px;
}

@media (max-width: 1136px) {
    .build-text {
        z-index: 1;
    }
    .build-text h1 {
        font-size: 50px;
    }
    .build-text p {
        font-size: 28px;
    }
    .build-text button {
        font-size: 28px;
    }
}
@media (max-width: 991px) {
    .build-text {
        width: 60%;
    }
    .build-img {
        width: 80%;
    }
}

@media (max-width: 767px) {
    .build-cont .design .third {
        top: 70px;
        bottom: auto;
        height: 200px;
        transform: rotate(90deg);
        left: -150px;
        width: 50%;
    }
    .build-cont .design .first {
        display: none;
    }
    .build-cont .design .second {
        top: 0;
        bottom: auto;
        width: 4%;
    }
    .build-img {
        margin-top: 30px;
    }
    .build-footer-cont {
        margin-top: -100px;
        border-top-left-radius: 50%;
        border-top-right-radius: 50%;
    }
}

@media (max-width: 575px) {
    .build-text h1 {
        font-size: 36px;
    }
    .build-text p {
        font-size: 20px;
    }
    .build-text button {
        font-size: 20px;
    }
    .build-img{
        width: 100%;
    }
    .build-text {
        width: 90%;
    }
    .build-footer-cont span {
        display: block;
        font-size: 13px;
    }
    .build-footer-cont button {
        font-size: 12px;
        transform: translateY(-60px)
    }
    .build-footer img {
        width: 20px;
        transform: translateY(-80px)
    }
    .margin { 
        margin-left: 80px !important;
    }
    .build-footer-cont h2 {
        font-size: 11px;
        margin-top: 0 !important;
        padding: 0 20%;
        transform: translateY(-30px);
    }
    #fpad {
        padding-bottom: 0px !important;
    }
}

.eyeButton {
  position: absolute;
  right: 16px;
  bottom: 4px;
  top: 30px;
}

.main_cont {
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
    height: 100vh;
}
.secs {
    scroll-snap-align: start;
}

@media (min-width: 1200px) {
    .index_cont {
        max-width: 100% !important;
    }
    .index_logo {
        margin-left: 25px !important;
    }
}

</style>