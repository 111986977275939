<template>
<layout-default>
    <div class="Terms">
        <div class="bg-white">
            <div class="container">
                <div class="bg-white py-5">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-9">
                                <div id="intro" class="mb-4">
                                    <div class="border-bottom pb-5 mb-5">
                                        <h1 class="text-primary font-weight-light"><span class="font-weight-bold">Our</span> Terms &amp; conditions of Use</h1>
                                        <p class="mb-0"></p>
                                    </div>
                                    <!-- <div class="mb-3">
                                        <h2 class="h5">Welcome to {{company.name}}</h2>
                                    </div> -->
                                    <p v-html="company.terms"></p>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="mb-3 border rounded list-sidebar overflow-hidden">
                                    <div class="box-title p-3 border-bottom">
                                        <h6 class="m-0">All List</h6>
                                    </div>
                                    <ul class="list-group list-group-flush">
                                        <li class="active">
                                           <router-link class="list-group-item p-3 list-group-item-action font-weight-medium text-primary" to="/terms-and-conditions">1. Terms and Conditions</router-link>
                                        </li>
                                        <li class="list-group-item list-group-item-action p-3">
                                           <router-link class="list-group-item-action" to="/privacy-policy">2. Privacy Policy</router-link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </layout-default>
</template>
<script>
import LayoutDefault from "@/layouts/default";
import axios from 'axios';

export default {
    name: 'Terms',
    components: {
        LayoutDefault,
    },

    created() {
        this.fectchCompany();
    },

    watch: {
      $route: {
          immediate: true,
          handler(to) {
            document.title = to.meta.title;
          }
      },
  }, 

    methods: {
        fectchCompany () {
            axios(process.env.VUE_APP_API_URL + 'company').then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.company = response.data;
            }).catch({});
        },
    },
    data() {
        return {
            company: {},
            loading: false
        }
    },
    computed: {
        image_url: function() {
            return process.env.VUE_APP_IMAGE_URL;
        }
    },
}
</script>