<template>
  <layout-default>
    <div class="Home">
      <div class="py-4">
        <div class="">
          <div class="info d-flex flex-lg-row flex-column-reverse">
            <div class="left py-5 d-flex justify-content-center">
              <div class="left-container">
                <div class="user-info d-flex justify-content-center py-5">
                  <div class="left-container2">
                    <div class="d-flex justify-content-center align-items-center">
                      <a v-if="user.avatar" @click="() => showSingle(image_url+'users/'+user.avatar)"><img class="img-fluid mt-2 rounded-circle" :src="image_url+'users/'+user.avatar" style="max-height: 150px; max-width: 150px" alt=""></a>
                      <a v-else @click="() => showSingle(image_url+'assets/avatar.png')"><img class="img-fluid mt-2 rounded-circle" src="/assets/avatar.png" style="max-height: 150px; max-width: 150px" alt=""></a>
                    </div>
                    <div class="mt-3">
                      <h2>{{user.name}}</h2>
                      <p v-if="user.currentcompany" style="text-align: center" class="mb-0 text-muted">{{user.current_position}} <br> <router-link :to="'/company/'+user.currentcompany.slug">{{user.currentcompany.name}}</router-link></p>
                      <p v-else style="text-align: center" class="mb-0 text-muted">{{user.profession}}</p>
                      <div class="d-flex text-center my-3">
                        <div style="width: 50%; border-right: 1px solid black">
                          <h3>{{total_followers}}</h3>
                          <h4>Followers</h4>
                        </div>
                        <div style="width: 50%">
                          <h3>{{total_project}}</h3>
                          <h4>Projects</h4>
                        </div>
                      </div>
                      <div class="text-center">
                        <router-link :to="'/sc/profile/'+id" class="btn btn-danger rounded-pill px-3">
                          View my profile
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-end right" style="width: 100%; margin-top: 10px">
              
              <div class="main-content d-flex justify-content-center">
                <div class="main-container">
                  <div style="background-color: navy; margin-bottom:50px;" class="py-5">
                    <div v-if="feeds.length" class="container">
                      <div class="row">
                        <div class="col-md-12 mx-auto">
                          <h3 class="text-white font-weight-light"><span class="font-weight-bold">{{feeds[0].interest.name}}</span> </h3>
                          <p class="mb-2 text-white-50"></p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="right-row">
                    <div v-for="(data, index) in feeds" :key="index" class="longstyle">
                      <div class="group">
                        <span v-if="data.post">
                        <div class="longstyleimg">
                          <router-link v-for="image in data.post.images.slice(0,1)" :key="image.id" :to="'/post/'+data.post.slug">
                          <img
                            :src="image.path"
                            alt=""
                            style="
                              width: 100%;
                              height: 100%;
                              border-radius: 30px;
                              object-fit: cover;
                            "
                          />
                        </router-link>
                        </div>
                        <span class="badge badge-info">property</span>
                        <p class="my-2"><router-link :to="'/post/'+data.post.slug">{{data.post.name}}</router-link></p>
                        <div class="d-flex align-items-center">
                          <div class="p-2 d-flex justify-content-center align-items-center">
                            <img v-if="data.post.user.avatar" class="rounded-circle" :src="image_url+'users/'+data.post.user.avatar" style="max-height: 30px; max-width: 30px" alt="">
                            <img v-else class="rounded-circle" src="/assets/avatar.png" style="max-height: 30px; max-width: 30px" alt="">
                            <!-- <span class="text-white">VC</span> -->
                          </div>
                          <h5 v-if="data.post.user.company" class="ms-2"><router-link :to="'/company/'+data.post.user.company.slug" style="color: black">{{data.post.user?data.post.user.name:''}}</router-link></h5>
                          <h5 v-else class="ms-2"><router-link :to="'/sc/profile/'+data.post.user.slug" style="color: black">{{data.post.user?data.post.user.name:''}}</router-link></h5>
                        </div>
                      </span>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="feeds.length" class="box border rounded mb-3">
            <div class="infinite-wrapper">
              <infinite-loading
                force-use-infinite-wrapper=".infinite-wrapper"
                @distance="3"
                @infinite="fetchData2"
              ></infinite-loading>
            </div>
            <!-- <center><button type="button" @click="fetchData()" class="btn btn-outline-primary btn-sm">Load More</button></center> -->
          </div>
        </div>
      </div>

      
    </div>
  </layout-default>
</template>
<script>
  import LayoutDefault from '../../layouts/default.vue';
  import axios from 'axios';
  import $ from "jquery";
  import moment from 'moment';
  import InfiniteLoading from 'vue-infinite-loading';

  export default {
    name: 'Feed',
    components: {
      LayoutDefault,
      InfiniteLoading,
    },
  
    watch: {
        $route: {
            immediate: true,
            handler(to) {
                document.title = to.meta.title || 'Structurecity';
            }
        },
    },
  
    created() {
          this.fetchCompany();
          this.fetchData();
          this.fetchUser();
          this.fetchTotalProjects();
          this.fetchTotalFollowers();
    },
  
    data() {
      return {
        page: 1,
        company: {},
        user: {},
        id: localStorage.getItem('slug'),
        feeds: [],
        total_project: 0,
        total_followers: 0,
      }
    },   
  
    methods: {
          logout () {
            localStorage.clear();
            this.$router.go();
          },
  
          fetchData () {
              axios(process.env.VUE_APP_API_URL + 'property/category/'+this.$route.params.id).then(response =>{
                  if (! response.status == 200) {
                      return this.errorMessage = 'Could not fetch data';
                  }
                  this.feeds = response.data.data;
                  this.page = this.page + 1;
              }).catch({});
          },
  
          fetchData2($state) {
            axios(process.env.VUE_APP_API_URL + 'project/category/'+this.$route.params.id+'?page='+this.page)
                .then(res => {
                    $.each(res.data.data, (key, value) => {
                        this.feeds.push(value);
                    });
                    $state.loaded();
                    this.page = this.page + 1;
                    if(res.data.data.length <= 0){
                     $state.complete();
                    }
                });
          },
  
          fetchCompany () {
              axios(process.env.VUE_APP_API_URL + 'company').then(response =>{
                  if (! response.data.status == 200) {
                      return this.errorMessage = 'Could not fetch data';
                  }
                  this.company = response.data;
              }).catch({});
          },
  
          fetchUser () {
              axios(process.env.VUE_APP_API_URL + 'user/details/'+localStorage.getItem('user')).then(response =>{
                  if (! response.data.status == 200) {
                      return this.errorMessage = 'Could not fetch data';
                  }
                  this.user = response.data;
              }).catch({});
          },
  
          fetchTotalProjects () {
              axios(process.env.VUE_APP_API_URL + 'project/total-project/'+localStorage.getItem('user')).then(response =>{
                  if (! response.data.status == 200) {
                      return this.errorMessage = 'Could not fetch data';
                  }
                  this.total_project = response.data;
              }).catch({});
          },
  
          fetchTotalFollowers () {
              axios(process.env.VUE_APP_API_URL + 'user/total-followers/'+localStorage.getItem('user')).then(response =>{
                  if (! response.data.status == 200) {
                      return this.errorMessage = 'Could not fetch data';
                  }
                  this.total_followers = response.data;
              }).catch({});
          },
  
          formatDate(date){
            return moment(date).fromNow();
          },
      },
  
      mounted: function () {
         this.$root.$on('action', ()=>{
          this.fetchData()
        })
  
        this.$root.$on('action2', ()=>{
          this.fetchData()
        })
      },
  
      computed: {
          image_url: function() {
            return process.env.VUE_APP_IMAGE_URL;
          }
      },
  }
  </script>

<style>
#my-strictly-unique-vue-upload-multiple-image {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

* {
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
}

#grad1 {
  background-color: #9c27b0;
  background-image: linear-gradient(120deg, #ff4081, #81d4fa);
}

#msform {
  text-align: center;
  position: relative;
  margin-top: 20px;
}

#msform fieldset .form-card {
  background: white;
  border: 0 none;
  border-radius: 0px;
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.2);
  padding: 20px 40px 30px 40px;
  box-sizing: border-box;
  width: 94%;
  margin: 0 3% 20px 3%;
  position: relative;
}

#msform fieldset {
  background: white;
  border: 0 none;
  border-radius: 0.5rem;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding-bottom: 20px;
  position: relative;
}

#msform fieldset:not(:first-of-type) {
  display: none;
}

#msform fieldset .form-card {
  text-align: left;
  color: #9e9e9e;
}

#msform input,
#msform textarea {
  padding: 0px 8px 4px 8px;
  border: none;
  border-bottom: 1px solid #ccc;
  border-radius: 0px;
  margin-bottom: 25px;
  margin-top: 2px;
  width: 100%;
  box-sizing: border-box;
  font-family: montserrat;
  color: #2c3e50;
  font-size: 16px;
  letter-spacing: 1px;
}

@media (max-width: 500px) {
  .right-row {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)) !important;
  }
}

#msform input:focus,
#msform textarea:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: none;
  font-weight: bold;
  border-bottom: 2px solid skyblue;
  outline-width: 0;
}

#msform .action-button {
  width: 100px;
  background: skyblue;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 0px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px;
}

#msform .action-button:hover,
#msform .action-button:focus {
  box-shadow: 0 0 0 2px white, 0 0 0 3px skyblue;
}

#msform .action-button-previous {
  width: 100px;
  background: #616161;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 0px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px;
}

#msform .action-button-previous:hover,
#msform .action-button-previous:focus {
  box-shadow: 0 0 0 2px white, 0 0 0 3px #616161;
}

select.list-dt {
  border: none;
  outline: 0;
  border-bottom: 1px solid #ccc;
  padding: 2px 5px 3px 5px;
  margin: 2px;
}

select.list-dt:focus {
  border-bottom: 2px solid skyblue;
}

.card {
  z-index: 0;
  border: none;
  border-radius: 0.5rem;
  position: relative;
}

.fs-title {
  font-size: 25px;
  color: #2c3e50;
  margin-bottom: 10px;
  font-weight: bold;
  text-align: left;
}

#progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  color: lightgrey;
}

#progressbar .active {
  color: #000000;
}

#progressbar li {
  list-style-type: none;
  font-size: 12px;
  width: 25%;
  float: left;
  position: relative;
}

#progressbar #account:before {
  font-family: FontAwesome;
  content: '\f023';
}

#progressbar #personal:before {
  font-family: FontAwesome;
  content: '\f007';
}

#progressbar #payment:before {
  font-family: FontAwesome;
  content: '\f09d';
}

#progressbar #confirm:before {
  font-family: FontAwesome;
  content: '\f00c';
}

#progressbar li:before {
  width: 50px;
  height: 50px;
  line-height: 45px;
  display: block;
  font-size: 18px;
  color: #ffffff;
  background: lightgray;
  border-radius: 50%;
  margin: 0 auto 10px auto;
  padding: 2px;
}

#progressbar li:after {
  content: '';
  width: 100%;
  height: 2px;
  background: lightgray;
  position: absolute;
  left: 0;
  top: 25px;
  z-index: -1;
}

#progressbar li.active:before,
#progressbar li.active:after {
  background: skyblue;
}

.radio-group {
  position: relative;
  margin-bottom: 25px;
}

.radio {
  display: inline-block;
  width: 204;
  height: 104;
  border-radius: 0;
  background: lightblue;
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  cursor: pointer;
  margin: 8px 2px;
}

.radio:hover {
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.3);
}

.radio.selected {
  box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.1);
}

.fit-image {
  width: 100%;
  object-fit: cover;
}

/* button inside texarea start */
.textarea-container {
  position: relative;
}
.textarea-container textarea {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
.textarea-container button {
  position: absolute;
  top: 0;
  right: 0;
}
/* button inside texarea end */

@import url('https://fonts.googleapis.com/css?family=Poppins');

body {
  font-family: Poppins !important;
  overflow-x: hidden !important;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.search-nav {
  width: 60%;
  display: flex;
}
.search-nav .search {
  width: 60%;
}
.search-nav img {
  margin-right: 10px;
}
.search-nav .search input {
  background: #f9f9f9;
  border-right: none !important;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
}
.search-nav .search span {
  background: #f9f9f9;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
}
.nav {
  background-color: white;
  width: 100%;
  z-index: 2;
}
.left {
  /* width: 413px; */
  width: 25%;
  background: #efefef !important;
  left: 0;
  top: 50px;
  height: 100vh;
  z-index: 1;
  overflow: auto;
  position: fixed;
}
.left-container {
  width: 85%;
}
.left-container2 {
  width: 75%;
}
.user-info {
  background: #ffffff;
  border-radius: 38px;
}
.user-info .name {
  background: #313e7e;
  border-radius: 50%;
  padding: 50px 0;
}

.user-info .name h1 {
  font-weight: 700;
  font-size: 128px;
}
.user-info h2 {
  font-weight: 600;
  font-size: 20px;
  line-height: 122.5%;
  text-align: center;
  color: #494949;
}

.user-info h3 {
  font-weight: 600;
  font-size: 16px;
  line-height: 122.5%;
  text-align: center;
  color: #494949;
}
.user-info h4 {
  font-weight: 300;
  font-size: 12px;
  line-height: 122.5%;
  color: #494949;
}
.user-info button {
  font-size: 11px;
  font-weight: 600;
}
.blog {
  height: 470px;
  background-image: url('/assets/SCLISTING/img2.png');
  border-radius: 38px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* margin-bottom: 100px; */
}
.blog h1 {
  font-weight: 600;
  font-size: 32px;
  z-index: 1;
}
.blog .overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.266);
  top: 0;
  border-radius: 38px;
}
.longstyle {
  /* height: 341.29px; */
  /* background-image: url('/assets/SCLISTING/img6.png');
  border-radius: 38px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; */
  grid-row: span 2;
}
.shortstyle {
  /* height: 170.65px; */
  /* background-image: url('/assets/SCLISTING/img6.png');
  border-radius: 38px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; */
  grid-row: span 2;
}
.main-content {
  width: 75%;
}
.main-container {
  width: 95%;
}
.group {
  /* margin-bottom: 10px; */
  width: 100%;
  height: 100%;
}
.right-row {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 15px;
  grid-auto-rows: 200px;
  grid-auto-flow: dense;
}
.shortstyleimg {
  height: 70%;
}
.longstyleimg {
  height: 70%;
}

.group p {
  font-weight: 600;
  font-size: 14px;
}
.logo {
  background: #c80000;
  border-radius: 50%;
  transform: scale(0.7);
}
.group h5 {
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 0;
}
.bottom-nav {
  display: none;
}

@media (max-width: 1199px) {
  .search-nav img {
    margin-right: 0;
  }
}

@media (max-width: 991px) {
  #nav-content {
    max-width: 97% !important;
  }
  .left {
    position: static;
    width: 100%;
    overflow: none !important;
    height: auto;
  }
  .main-content {
    width: 100%;
  }
  .left-container {
    width: 50%;
    display: flex;
    flex-direction: column-reverse;
  }
  .user-info .name {
    padding: 70px 0;
  }
}

@media (max-width: 869px) {
  .search-nav {
    display: none !important;
  }
  .bottom-nav {
    display: flex;
    justify-content: space-evenly;
    padding: 15px 0;
    background-color: white;
    position: fixed;
    bottom: 0;
    z-index: 2;
    width: 100%;
  }
  .bottom-nav img {
    width: 25px;
  }
}

@media (max-width: 575px) {
  .left-container {
    width: 90%;
  }
  .user-info .name {
    padding: 80px 0;
  }
  /* .navbar-brand img {
        width: 150px;
    } */
}

.group2 {
  width: 80%;
}
.group2 h2 {
  font-weight: 600;
  font-size: 20px;
}
.group2 h5 {
  font-weight: 400;
  font-size: 15px;
}
.group2 span {
  font-weight: 400;
  font-size: 11px;
}
.group2 .longstyle {
  height: 500px;
}
.group2 p {
  font-weight: 400;
  font-size: 15px;
}
.profile-img {
  height: 200px;
  background-image: url('/assets/SCLISTING/img1.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 30px;
}
.profile-abbre {
  background: #c80000;
  border: 4px solid white;
  border-radius: 60%;
  margin: -50px auto 0 auto;
  /* padding: ; */
  width: max-content;
  padding: 15px 20px;
}
.profile-abbre span {
  font-weight: 700;
  font-size: 64px;
}
.projects {
  height: 550px;
}
.projects .recent {
  height: 200px;
  background-image: url('/assets/SCLISTING/img7.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 30px;
}
.projects .popular {
  height: 200px;
  background-image: url('/assets/SCLISTING/img6.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 30px;
}
.projects h4 {
  z-index: 1;
  font-weight: 600;
  font-size: 24px;
}
</style>
