<template>
  <layout-default>
      <div class="projectdetail">
        <!-- <div class="profile-cover text-center banner-container">
          <img v-if="details.banner" class="img-fluid" :src="image_url+'projects/'+details.banner" alt="">
          <img v-else class="img-fluid" :src="image_url+'logo/'+settings.profile_banner" alt="">
          <button type="button" style="float:right; border:0; padding-top: 30px" data-toggle="modal" data-target="#update-banner"><i style="font-size: 25px" class="feather-edit"></i></button>
        </div> -->
        <div class="bg-white shadow-sm border-bottom">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="d-flex align-items-center py-3">
                  <div class="profile-left">
                    <h5 class="font-weight-bold text-dark mb-1 mt-0">{{details.name}}</h5>
                    <p class="mb-0 text-muted"><i class="feather-map-pin"></i> {{details.city?details.city.name+',':''}} {{details.state?details.state.name+',':''}} {{details.country?details.country.name:''}} -- DatePosted {{formatDate(details.created_at)}} <br> <a v-for="data in details.interests" :key="data.id" class="mr-2 font-weight-bold" :href="'/post/category/'+data.interest.slug">{{data.interest.name}},&nbsp;</a></p>
                  </div>
                  <div v-if="details.user" class="profile-right ml-auto">
                    <button v-if="details.user.owner_id == id || details.user.id == id" type="button" class="btn btn-light mr-1" @click="openUpdateProject()">  <i class="feather-edit"></i> Edit </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="py-4">
          <div class="container">
            <div class="row">

              <main class="col col-xl-6 order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12">
                <div class="box shadow-sm rounded bg-white mb-3 overflow-hidden">
                    <ul class="nav border-bottom osahan-line-tab" id="myTab" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Overview</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="project-comment-tab" data-toggle="tab" href="#project-comments" role="tab" aria-controls="project-listing" aria-selected="false">Comments</a>
                        </li>
                    </ul>
                </div>

                <div class="tab-content" id="myTabContent">
                  <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                    <div class="box shadow-sm border rounded bg-white mb-3">
                      <div class="box-title border-bottom p-3">
                        <h6 class="m-0">Overview</h6>
                      </div>
                      

                      <carousel v-if="details.images" :per-page="1" navigation-click="forward" :mouse-drag="true" :navigationEnabled="true" navigationNextLabel="<i class='navigation-icon-size'>&#8250;</>" navigationPrevLabel="<i class='navigation-icon-size'>&#8249;</>">
                        <slide v-for="(data, index) in details.images" :key="index">
                          <a v-if="data.path" style="cursor:pointer" @click="() => showSingle(data.path)">
                            <img :src="data.path" style="width: 100%; height: 400px; object-fit: contain">
                          </a>
                        </slide>
                      </carousel>

                      <vue-easy-lightbox
                        :visible="visible"
                        :imgs="imgs"
                        @hide="handleHide"
                      ></vue-easy-lightbox>

                      <div class="box-body p-3">
                        <p v-html="details.description"></p>
                      </div>
                    </div>
                    <div class="box shadow-sm border rounded bg-white mb-3">
                      <div class="box-title border-bottom p-3">
                        <h6 class="m-0">Property Details</h6>
                      </div>
                      <div class="box-body">
                        <table class="table table-borderless mb-0">
                          <tbody>
                            <tr class="border-bottom">
                              <th class="p-3">Location</th>
                              <td class="p-3">{{details.city?details.city.name+',':''}} {{details.state?details.state.name+',':''}} {{details.country?details.country.name:''}}</td>
                            </tr>
                            <tr class="border-bottom">
                              <th class="p-3">Address</th>
                              <td class="p-3">{{details.address}}</td>
                            </tr>
                            <tr class="border-bottom">
                              <th class="p-3">Price</th>
                              <td class="p-3">
                                <span v-if="details.price">{{details.currency?details.currency.symbol:''}}{{formatPrice(details.price)}}</span><br>
                                <span v-if="details.prices">
                                  <span v-for="(data, index) in details.prices" :key="index">{{details.currency?details.currency.symbol:''}}{{formatPrice(data.price)}} - {{data.title}}<br></span>
                                </span>
                              </td>
                            </tr>
                            <tr class="border-bottom">
                              <th class="p-3">Type</th>
                              <td class="p-3">{{details.type}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div class="tab-pane fade" id="project-comments" role="tabpanel" aria-labelledby="home-tab">
                    <div class="box shadow-sm border rounded bg-white mb-3">
                      <div class="box-title border-bottom p-3">
                        <h6 class="m-0">Comments</h6>
                      </div>
                      <div v-if="details.comments">
                        <div v-for="com in details.comments" :key="com.id" class="p-3 align-items-top border-bottom osahan-post-comment">
                          <div class="dropdown-list-image mr-3">
                            <router-link class="nav-link" :to="'/sc/profile/'+com.user.slug">
                              <img v-if="com.user" class="rounded-circle" :src="image_url+'users/'+com.user.avatar" style="max-height: 150px; max-width: 150px" alt="">
                              <img v-else class="rounded-circle" src="/assets/avatar.png" style="max-height: 150px; max-width: 150px" alt="">
                              <div class="status-indicator bg-success"></div>
                            </router-link>
                          </div>
                          <div class="font-weight-bold">
                            <div v-if="com.user.id == id" class="dropdown" style="float: right">
                              <a class="btn btn-icon" style="" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span style="font-size: 25px;">...</span>
                              </a>

                              <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                  <a class="dropdown-item" @click="editPostComment(com.id, com.comment)" style="cursor:pointer">Edit</a>
                                  <a class="dropdown-item" style="cursor:pointer" @click="deleteComment(com.id)">Delete</a>                                
                              </div>
                            </div>
                            <div v-if="com.user" class="text-truncate"> 
                              <router-link class="nav-link" :to="'/sc/profile/'+com.user.slug"> {{com.user.name}} </router-link> 
                              <span class="float-right small">{{formatDate(com.created_at)}}</span>
                            </div>
                            <div class="small text-gray-500">{{com.comment}}</div>
                            <div class="osahan-post-footer">
                              <a style="font-size:12px" class="mr-3 text-secondary"><i class="feather-thumbs-up text-danger"></i> {{com.likes.length}}</a>
                              <a v-if="checkIfUser(com.likes)" style="font-size:12px; cursor: pointer" @click="unlikePostComment(com.id)" class="mr-3 text-secondary">Unlike</a>
                              <a v-else style="font-size:12px; cursor: pointer" @click="likePostComment(com.id)" class="mr-3 text-secondary">Like</a>
                              <a style="font-size:12px; cursor: pointer" @click="replyPostCommentModal(com.id)" class="mr-3 text-secondary">Reply</a>
                            </div>
                          </div>

                          <div v-if="com.sub">
                            <div v-for="sub in com.sub" :key="sub.id" class="p-3 align-items-top osahan-post-comment">
                              <div class="dropdown-list-image mr-3">
                                <router-link class="nav-link" :to="'/sc/profile/'+sub.user.slug">
                                  <img v-if="sub.user" class="rounded-circle" :src="image_url+'users/'+sub.user.avatar" style="max-height: 150px; max-width: 150px" alt="">
                                  <img v-else class="rounded-circle" src="/assets/avatar.png" style="max-height: 150px; max-width: 150px" alt="">
                                  <div class="status-indicator bg-success"></div>
                                </router-link>
                              </div>
                              <div class="font-weight-bold">
                                <div v-if="sub.user.id == id" class="dropdown" style="float: right">
                                  <a class="btn btn-icon" style="" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span style="font-size: 25px;">...</span>
                                  </a>

                                  <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                      <a class="dropdown-item" @click="editPostComment(sub.id, sub.comment)" style="cursor:pointer">Edit</a>
                                      <a class="dropdown-item" style="cursor:pointer" @click="deleteComment(sub.id)">Delete</a>                                
                                  </div>
                                </div>
                                <div v-if="sub.user" class="text-truncate"> 
                                  <router-link class="nav-link" :to="'/sc/profile/'+sub.user.slug"> {{sub.user.name}} </router-link> 
                                  <span class="float-right small">{{formatDate(sub.created_at)}}</span>
                                </div>
                                <div class="small text-gray-500">{{sub.comment}}</div>
                                <div class="osahan-post-footer">
                                  <a style="font-size:12px" class="mr-3 text-secondary"><i class="feather-thumbs-up text-danger"></i> {{sub.likes.length}}</a>
                                  <a v-if="checkIfUser(sub.likes)" style="font-size:12px; cursor: pointer" @click="unlikePostComment(sub.id)" class="mr-3 text-secondary">Unlike</a>
                                  <a v-else style="font-size:12px; cursor: pointer" @click="likePostComment(sub.id)" class="mr-3 text-secondary">Like</a>
                                  <a style="font-size:12px; cursor: pointer" @click="replyPostCommentModal(sub.id)" class="mr-3 text-secondary">Reply</a>
                                </div>
                              </div>

                              <div v-if="sub.sub">
                                <div v-for="sb in sub.sub" :key="sb.id" class="p-3 align-items-top osahan-post-comment">
                                  <div class="dropdown-list-image mr-3">
                                    <router-link class="nav-link" :to="'/sc/profile/'+sb.user.slug">
                                      <img v-if="sb.user" class="rounded-circle" :src="image_url+'users/'+sb.user.avatar" style="max-height: 150px; max-width: 150px" alt="">
                                      <img v-else class="rounded-circle" src="/assets/avatar.png" style="max-height: 150px; max-width: 150px" alt="">
                                      <div class="status-indicator bg-success"></div>
                                    </router-link>
                                  </div>
                                  <div class="font-weight-bold">
                                    <div v-if="sb.user.id == id" class="dropdown" style="float: right">
                                      <a class="btn btn-icon" style="" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <span style="font-size: 25px;">...</span>
                                      </a>

                                      <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                          <a class="dropdown-item" @click="editPostComment(sb.id, sub.comment)" style="cursor:pointer">Edit</a>
                                          <a class="dropdown-item" style="cursor:pointer" @click="deleteComment(sb.id)">Delete</a>                                
                                      </div>
                                    </div>
                                    <div v-if="sb.user" class="text-truncate"> 
                                      <router-link class="nav-link" :to="'/sc/profile/'+sb.user.slug"> {{sb.user.name}} </router-link> 
                                      <span class="float-right small">{{formatDate(sb.created_at)}}</span>
                                    </div>
                                    <div class="small text-gray-500">{{sb.comment}}</div>
                                    <div class="osahan-post-footer">
                                      <a style="font-size:12px" class="mr-3 text-secondary"><i class="feather-thumbs-up text-danger"></i> {{sb.likes.length}}</a>
                                      <a v-if="checkIfUser(sb.likes)" style="font-size:12px; cursor: pointer" @click="unlikePostComment(sb.id)" class="mr-3 text-secondary">Unlike</a>
                                      <a v-else style="font-size:12px; cursor: pointer" @click="likePostComment(sb.id)" class="mr-3 text-secondary">Like</a>
                                      <!-- <a style="font-size:12px; cursor: pointer" @click="replyPostCommentModal(sb.id)" class="mr-3 text-secondary">Reply</a> -->
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                      <div class="p-3">
                        <div class="textarea-container">
                          <textarea placeholder="Add Comment..." class="form-control border-0 p-0 shadow-none" rows="2" :id="'submitCommentButton'+details.id" v-model="comment.description[index]" value="" @keydown.enter.exact.prevent @keyup.enter.exact="submitComment($event, details.id)" @keydown.enter.shift.exact="newline"></textarea>
                          <button v-if="loading" type="button" style="border:0; color:#0056B3; font-size: 20px; background-color: white; padding-top:20px"><i class="feather-send"></i></button>
                          <button v-else type="button" style="border:0; color:#0056B3; font-size: 20px; background-color: white; padding-top:20px" @click="sendSubmitComment(details.id)"><i class="feather-send"></i></button>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </main>
              <aside class="col col-xl-3 order-xl-1 col-lg-6 order-lg-2 col-md-6 col-sm-6 col-12">
                <!-- <div v-if="details.images" class="">
                <div v-if="details.images.length" class="">
                  <div class="row">
                    <div
                      v-for="(src, index) in details.images.slice(1,2)"
                      :key="index"
                      class="col-md-12"
                      @click="() => showSingle(image_url+'posts/'+src.image)"
                    >
                      <img :src="image_url+'posts/'+src.image" style="width: 100%; height: 400px; object-fit: contain;">
                    </div>
                  </div>
                  <div class="box-body">
                    <div class="gallery-box-main">
                      <div class="gallery-box">
                        <img v-for="(data, index) in details.images" :key="index" @click="() => showSingle(image_url+'posts/'+data.image)" class="img-fluid" :src="image_url+'posts/'+data.image" alt="">
                      </div>
                    </div>
                  </div>
                  
                </div>
                </div> -->

                <div class="card-body">
                  <h5 class="card-title mb-4"></h5>
                  <div id="featured-properties" class="carousel slide" data-ride="carousel">
                    <ol class="carousel-indicators">
                      <li data-target="#featured-properties" data-slide-to="0" class=""></li>
                      <li data-target="#featured-properties" data-slide-to="1" class="active"></li>
                    </ol>
                    <div class="carousel-inner">        
                      <div v-for="data in adverts.slice(1)" :key="data.id" class="carousel-item">
                        <div class="border rounded bg-white job-item">
                          <a :href="data.link" target="_blank"><img class="img-fluid ml-auto" :src="image_url+'advert/'+data.image" alt=""></a>
                        </div>
                      </div>
                      <div v-for="data in adverts.slice(0,1)" :key="data.id" class="carousel-item active">
                        <div class="border rounded bg-white job-item">
                          <a :href="data.link" target="_blank"><img class="img-fluid ml-auto" :src="image_url+'advert/'+data.image" alt=""></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </aside>
              <aside class="col col-xl-3 order-xl-3 col-lg-6 order-lg-3 col-md-6 col-sm-6 col-12">
                <div class="box mb-3 shadow-sm border rounded bg-white profile-box text-center">
                  <div v-if="details.user" class="py-4 px-3 border-bottom">
                    <a v-if="details.user.type == 'company'" :href="'/company/'+details.user.company.slug" target="_blank">
                      <img v-if="details.user.avatar" class="img-fluid mt-2 rounded-circle" :src="image_url+'users/'+details.user.avatar" style="max-height: 150px; max-width: 150px" alt="">
                      <img v-else class="img-fluid mt-2 rounded-circle" src="/assets/avatar.png" style="max-height: 150px; max-width: 150px" alt="">
                      <h5 class="font-weight-bold text-dark mb-1 mt-4">{{details.user?details.user.name:''}}</h5>
                      <p class="mb-0 text-muted">{{details.user.state?details.user.state.name+',':''}} {{details.user.country?details.user.country.name:''}}</p>
                    </a>
                    <a v-else :href="'/sc/profile/'+details.user.slug" target="_blank">
                      <img v-if="details.user.avatar" class="img-fluid mt-2 rounded-circle" :src="image_url+'users/'+details.user.avatar" style="max-height: 150px; max-width: 150px" alt="">
                      <img v-else class="img-fluid mt-2 rounded-circle" src="/assets/avatar.png" style="max-height: 150px; max-width: 150px" alt="">
                      <h5 class="font-weight-bold text-dark mb-1 mt-4">{{details.user?details.user.name:''}}</h5>
                      <p v-if="details.user.currentcompany" class="mb-0 text-muted">{{details.user.current_position}} <br> <router-link :to="'/company/'+details.user.currentcompany.slug">{{details.user.currentcompany.name}}</router-link></p>
                      <p v-else class="mb-0 text-muted">{{details.user.profession}}</p>
                      <span v-if="details.user.membership" class="text-info"><span v-if="details.user.membership.membership">{{ details.user.membership.membership.code }}</span><i v-if="details.user.membership.status == 1" data-toggle="tooltip" data-placement="top" title="Verified" class="feather-check-circle"></i></span> &nbsp;
                      <span v-if="details.user.certification" class="text-info"><span v-if="details.user.certification.certification">{{ details.user.certification.certification.code }}</span><i v-if="details.user.certification.status == 1" data-toggle="tooltip" data-placement="top" title="Verified" class="feather-check-circle"></i></span>
                      <p class="mb-0 text-muted">{{details.user.state?details.user.state.name+',':''}} {{details.user.country?details.user.country.name:''}}</p>
                    </a>
                  </div>
                </div>
                <div class="box shadow-sm mb-3 rounded bg-white ads-box text-center overflow-hidden">
                  <div class="p-3">
                    <button v-if="show_details" type="button" @click="hideContactDetails()" class="btn btn-outline-primary pl-4 pr-4"> Hide Contact Details </button>
                    <button v-else type="button" @click="showContactDetails()" class="btn btn-outline-primary pl-4 pr-4"> View Contact Details </button>                    
                  </div>
                  <div v-if="show_details" class="p-3 border-bottom">
                    <!-- <h6 class="font-weight-bold text-dark">Contact Detail</h6> -->
                    <p class="mb-0 text-muted">{{details.user?details.user.email:''}}</p>
                    <p class="mb-0 text-muted">{{details.user?details.user.phone_number:''}}</p>
                  </div>                  
                </div>
                <div class="box shadow-sm mb-3 rounded bg-white ads-box text-center overflow-hidden">
                  <div class="p-3">
                    <button type="button" data-toggle="modal" data-target="#send-message" class="btn btn-outline-primary pl-4 pr-4"> Send a Message </button>                    
                  </div>              
                </div>
              </aside>
            </div>



            <div v-if="related_posts.length > 0">
              <div class="d-flex align-items-center py-3">
                <div class="profile-left">
                  <h5 class="font-weight-bold text-dark mb-1 mt-0">Related Properties</h5>
                </div>
              </div>

              
              <div class="row">
                <div class="col-lg-12 col-md-12">
                  <div class="row">
                    <div v-for="(data, index) in related_posts" :key="index" class="col-lg-4 col-md-4">
                      <div v-if="data.post" class="box shadow-sm rounded bg-white mb-3 blog-card border-0">
                        <a :href="'/post/'+data.post.slug">
                          <span v-if="data.post.images">
                            <img v-if="data.post.images.length > 0" class="card-img-top" :src="data.post.images[0].path" style="height:300px; width:100%; object-fit:cover; border-radius: 20px" alt="">
                          </span>
                        </a>
                        <div class="card-body">
                          <span class="badge badge-success">{{data.interest.name}}</span>
                          <a :href="'/post/'+data.post.slug"><h6 class="text-dark">{{data.post.name}}</h6></a>
                          <!-- <p class="mb-0"><span v-html="data.project.description.substring(0,150)+'....'"></span></p> -->
                        </div>
                        <div class="card-footer border-0">
                          <p v-if="data.post.user" class="mb-0">
                            <router-link v-if="data.post.user.type == 'user'" :to="'/sc/profile/'+data.post.user.slug" target="_blank">
                              <img v-if="data.post.user.avatar" class="rounded-circle" :src="image_url+'users/'+data.post.user.avatar" alt="" />
                              <img v-else class="rounded-circle" :src="image_url+'avatar.png'" alt="" />
                              <strong>{{data.post.user.name}}</strong> &nbsp;
                              <span style="font-size: 10px">{{formatDate(data.post.created_at)}}</span>
                            </router-link>
                            <router-link v-if="data.post.user.type == 'company'" :to="'/company/'+data.post.user.company.slug" target="_blank">
                              <img v-if="data.post.user.avatar" class="rounded-circle" :src="image_url+'users/'+data.post.user.avatar" alt="" />
                              <img v-else class="rounded-circle" :src="image_url+'avatar.png'" alt="" />  
                              <strong>{{data.post.user.name}}</strong> &nbsp;
                              <span style="font-size: 10px">{{formatDate(data.post.created_at)}}</span>
                            </router-link>
                          </p>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <center>
                <!-- <button class="btn btn-default" type="button" @click="handleLoadMore">Load More</button> -->
                <div class="infinite-wrapper">
                <infinite-loading force-use-infinite-wrapper=".infinite-wrapper" @distance="3" @infinite="handleLoadMore"></infinite-loading>
                </div>
              </center>        
            </div>

          </div>
        </div>






      <!-- update property Modal -->
      <div class="modal fade" id="project-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Update Property Details</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="border rounded bg-white mb-3">
                <div>
                  <div class="box-title border-bottom p-3">
                    <h6 class="m-0">Supply Property Details</h6>
                    <p class="mb-0 mt-0 small">update your property details .</p>
                  </div>
                  <div class="box-body p-3">
                    <form id="create-form" class="js-validate" novalidate="novalidate">
                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Property Name
                            <span class="text-danger">*</span>
                            </label>
                            <div class="form-group">
                              <input type="text" class="form-control" name="name" v-model="project.name" required="">
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Property Description
                            <span class="text-danger">*</span>
                            </label>
                            <div class="form-group">
                              <vue-editor v-model="project.description" :editorToolbar="customToolbar"></vue-editor>
                              <!-- <textarea type="text" class="form-control" name="description" rows="5" v-model="project.description" required></textarea> -->
                            </div>
                          </div>
                        </div>
                      </div>

                      <label class="form-label">Property Category
                      <span class="text-danger">*</span>
                      </label>
                      <div class="row">
                        <div class="col-md-4 mb-3 mb-sm-4">
                          <div class="js-form-message">
                            <div class="form-group">
                              <select class="form-control custom-select" name="project_interest_1" v-model="project.project_interest_1" @change='fectchInterestChildren($event)'>
                                <option value="" disabled selected>select Category</option>
                                <option v-for="(data, index) in interests" :key="index" :value="data.id">{{data.name}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 mb-3 mb-sm-4">
                          <div v-if="interest_children.length > 0" class="js-form-message">
                            <div class="form-group">
                              <select class="form-control custom-select" name="project_interest_2" v-model="project.project_interest_2" data-msg="Please select state." data-error-class="u-has-error" data-success-class="u-has-success" @change='fectchInterestChildren2($event)'>
                                <option value="" disabled selected>select category</option>
                                <option v-for="data in interest_children" :key="data.id" :value="data.id">{{data.name}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 mb-3 mb-sm-4">
                          <div v-if="interest_children2.length > 0" class="js-form-message">
                            <div class="form-group">
                              <select class="form-control custom-select" name="project_interest_3" v-model="project.project_interest_3" data-msg="Please select city." data-error-class="u-has-error" data-success-class="u-has-success">
                                <option value="" disabled selected>select category</option>
                                <option v-for="data in interest_children2" :key="data.id" :value="data.id">{{data.name}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Property Type
                            <span class="text-danger">*</span>
                            </label>
                            <div class="form-group">
                              <select type="date" class="form-control" name="type" v-model="project.type" required="">
                                <option value="" disabled selected>select option</option>
                                <option value="Residential">Residential</option>
                                <option value="Commercial">Commercial</option>
                                <option value="Residential | Commercial">Residential | Commercial</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <label class="form-label">Property Location
                      <span class="text-danger">*</span>
                      </label>
                      <div class="row">
                        <div class="col-md-4 mb-3 mb-sm-4">
                          <div class="js-form-message">
                            <div class="form-group">
                              <select class="form-control custom-select" name="country_id" v-model="project.country_id" data-msg="Please select country." @change='fetchStates($event)'>
                                <option value="" disabled selected>Country</option>
                                <option v-for="data in country" :key="data.id" :value="data.id">{{data.name}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 mb-3 mb-sm-4">
                          <div class="js-form-message">
                            <div class="form-group">
                              <select class="form-control custom-select" name="state_id" v-model="project.state_id" data-msg="Please select state." @change='fetchCities($event)'>
                                <option value="" disabled selected>State</option>
                                <option v-for="data in state" :key="data.id" :value="data.id">{{data.name}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 mb-3 mb-sm-4">
                          <div class="js-form-message">
                            <div class="form-group">
                              <select class="form-control custom-select" name="city_id" v-model="project.city_id" data-msg="Please select city.">
                                <option value="" disabled selected>City</option>
                                <option v-for="data in city" :key="data.id" :value="data.id">{{data.name}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mb-3 mb-sm-12">
                          <div class="js-form-message">
                            <div class="form-group">
                              <label class="form-label">Address</label>
                              <input type="text" class="form-control" name="address" v-model="project.address">
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-12 mb-3 mb-sm-12">
                          <div class="js-form-message">
                            <div class="form-group">
                              <label class="form-label">Currency</label>
                              <select class="form-control custom-select" name="currency_id" v-model="project.currency_id">
                                <option value="" disabled selected>select Category</option>
                                <option v-for="data in currency" :key="data.id" :value="data.id">{{data.symbol}} - {{data.name}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Property Single Price
                            <span class="text-danger">*</span>
                            </label>
                            <div class="form-group">
                              <input type="number" class="form-control" name="price" v-model="project.price">
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Property Multiple Prices
                            <span class="text-danger">* </span><span style="font-size: 12px"><a style="cursor:pointer; color:primary" @click="addPrice">Add Price</a></span>
                            </label>
                            <div class="form-group">
                              <div class="row" v-for="(input, index) in details.prices" :key="index">                                
                                <div class="col-lg-5" style="padding-top: 12px">
                                  <label class="form-label" style="font-size: 12px">Price Amount</label>
                                  <input type="text" class="form-control" v-model="input.price" readonly>
                                </div>
                                <div class="col-lg-5" style="padding-top: 12px">
                                  <label class="form-label" style="font-size: 12px">Price Title</label>
                                  <input type="text" class="form-control" v-model="input.title" readonly>
                                </div>
                                <div class="col-lg-2" style="padding-top: 12px">
                                  <button type="button" @click="deletePropertyPrice(input.id)" style="color: red; border:0; background-color: white">X</button>
                                </div>
                                <br>
                              </div>
                              
                              <div class="row" v-for="(input, index) in project.prices" :key="index">
                                <div class="col-lg-5" style="padding-top: 12px">
                                  <label class="form-label" style="font-size: 12px">Price Amount</label>
                                   <input type="text" placeholder="price" class="form-control" v-model="input.price" onkeypress="if ( isNaN(this.value + String.fromCharCode(event.keyCode) )) return false;">
                                </div>
                                <div class="col-lg-5" style="padding-top: 12px">
                                  <label class="form-label" style="font-size: 12px">Price Title</label>
                                   <input type="text" placeholder="price title" class="form-control" v-model="input.title">
                                </div>  
                                <div class="col-lg-2" style="padding-top: 12px">
                                  <button type="button" @click="deletePrice(index)" style="color: red; border:0; background-color: white">X</button>
                                </div>
                                <br>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Property Detail
                            <span class="text-danger">* </span><span style="font-size: 12px"><a style="cursor:pointer; color:primary" @click="addDetail">Add Detail</a></span>
                            </label>
                            <div class="form-group">
                              <div class="row" v-for="(input, index) in details.details" :key="index">
                                <div class="col-lg-5" style="padding-top: 12px">
                                  <input type="text" class="form-control" v-model="input.title" readonly>
                                </div>
                                <div class="col-lg-5" style="padding-top: 12px">
                                  <input type="text" class="form-control" v-model="input.description" readonly>
                                </div>
                                <div class="col-lg-2" style="padding-top: 12px">
                                  <button type="button" @click="deletePropertyDetail(input.id)" style="color: red; border:0; background-color: white">X</button>
                                </div>
                                <br>
                              </div>
                              
                              <div class="row" v-for="(input, index) in project.details" :key="index">
                                <div class="col-lg-5" style="padding-top: 12px">
                                  <input type="text" class="form-control" v-model="input.title">
                                </div>
                                <div class="col-lg-5" style="padding-top: 12px">
                                  <input type="text" class="form-control" v-model="input.description">
                                </div>
                                <div class="col-lg-2" style="padding-top: 12px">
                                  <button type="button" @click="deleteDetail(index)" style="color: red; border:0; background-color: white">X</button>
                                </div>
                                <br>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Property Images
                            <span class="text-danger">* </span>
                            </label>

                            <div class="box-body p-3">
                              <div class="gallery-box-main">
                                <div v-if="details.images" class="">
                                  <div v-if="details.images.length > 0" class="row">
                                    <div v-for="(data, index) in details.images" :key="index" class="col-md-3 col-sm-4 col-xs-4" style="margin-bottom: 10px">
                                      <div class="project-image-container">
                                        <img style="object-fit:cover; width: 60px; height:60px" :src="data.path" alt="">
                                        <button type="button" style="float:right; border:0" @click="deleteProjectImage(data.id)"><i style="color: red" class="feather-x"></i></button>
                                      </div>
                                    </div>
                                  </div>                                  
                                </div>
                              </div>
                            </div>

                            <div id="my-strictly-unique-vue-upload-multiple-image" style="display: flex; justify-content: center;">
                              <vue-upload-multiple-image
                                @upload-success="uploadImageSuccess"
                                @before-remove="beforeRemove"
                                @edit-image="editImage"
                                @data-change="dataChange"
                                :data-images="images"
                                dragText="Drag and drop images"
                                browseText="Browse Images"
                                primaryText="added"
                                markIsPrimaryText="	Set default image"
                                popupText="Description default image"
                                :maxImage=5
                                ></vue-upload-multiple-image>

                            </div>
                          </div>
                        </div>
                      </div>

                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <div v-if="loading">
                <button type="button" class="btn btn-primary" data-dismiss="modal">Loading.....</button>
              </div>
              <div v-else>
                <button type="button" @click="cancelForm()" class="btn btn-secondary" data-dismiss="modal">Close</button>&nbsp;
                <button type="button" @click="updateProject()" class="btn btn-primary">Update Listing</button>
              </div>
            </div>

          </div>
        </div>
      </div>





      <!-- send message Modal -->
      <div class="modal fade" id="send-message" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Contact Property Owner </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form id="message-form">
                <div class="row">
                  <div class="col-md-12 mb-12 mb-sm-12">
                    <div class="js-form-message">
                      <label class="form-label">Message</label>
                      <div class="form-group">
                        <textarea class="form-control" rows="6" name="message" placeholder="your message here...." required></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <input class="form-control" type="hidden" name="id" :value="details.id" required/>
                <input class="form-control" type="hidden" name="type" value="property" required/>

              </form>

            </div>
            <div class="modal-footer">
              <div v-if="loading">
                <button type="button" class="btn btn-primary" data-dismiss="modal">Loading.....</button>
              </div>
              <div v-else>
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>&nbsp;
                <button type="button" @click="sendMessage()" class="btn btn-primary">Send</button>
              </div>
            </div>

          </div>
        </div>
      </div>



      <!-- edit comment Modal -->
      <div class="modal fade" id="edit_comment" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Edit Comment </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form>
                <div class="row">
                  <div class="col-md-12 mb-12 mb-sm-12">
                    <div class="js-form-message">
                      <label class="form-label">Comment</label>
                      <div class="form-group">
                        <textarea class="form-control" rows="6" name="comment" v-model="edit_comment" required></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <input class="form-control" type="hidden" name="id" v-model="edit_comment_id" required/>

              </form>

            </div>
            <div class="modal-footer">
              <div v-if="loading">
                <button type="button" class="btn btn-primary" data-dismiss="modal">Loading.....</button>
              </div>
              <div v-else>
                <button type="button" class="btn btn-secondary" @click="closeEditPostCommentModal" data-dismiss="modal">Close</button>&nbsp;
                <button type="button" @click="editComment()" class="btn btn-primary">Update</button>
              </div>
            </div>

          </div>
        </div>
      </div>




      <!-- reply comment Modal -->
      <div class="modal fade" id="reply_comment" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <form>
                <div class="row">
                  <div class="col-md-12 mb-12 mb-sm-12">
                    <div class="js-form-message">
                      <div class="form-group">
                        <textarea class="form-control" placeholder="write your reply..." name="comment" v-model="edit_comment" required></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <input class="form-control" type="hidden" name="id" v-model="edit_comment_id" required/>

              </form>

            </div>
            <div class="modal-footer">
              <div v-if="loading">
                <button type="button" class="btn btn-primary" data-dismiss="modal">Loading.....</button>
              </div>
              <div v-else>
                <button type="button" class="btn btn-secondary" @click="closeReplyPostCommentModal" data-dismiss="modal">Close</button>&nbsp;
                <button type="button" @click="replyPostComment()" class="btn btn-primary">Update</button>
              </div>
            </div>

          </div>
        </div>
      </div>




      </div>
  </layout-default>
</template>
<script>
import LayoutDefault from '../../layouts/default.vue';
import axios from 'axios';
import moment from 'moment';
import VueEasyLightbox from 'vue-easy-lightbox';
import 'vue-search-select/dist/VueSearchSelect.css'
import VueUploadMultipleImage from 'vue-upload-multiple-image';
import $ from "jquery";
import { Carousel, Slide } from 'vue-carousel';
import { VueEditor } from "vue2-editor";
import InfiniteLoading from 'vue-infinite-loading';

export default {
  name: 'projectdetail',
  components: {
    LayoutDefault,
    VueEasyLightbox,
    VueUploadMultipleImage,
    Carousel,
    Slide,
    VueEditor,
    InfiniteLoading,
  },

  created() {
        this.fetchDetails();
        this.fetchCountries();
        this.fectchInterests();
        this.fetchUsers();
        this.fetchCurrency();
        this.fetchAdverts();
  },

  watch: {
      $route: {
          immediate: true,
          handler(to) {
            let subject = to.params.id.substring(0, to.params.id.length - 10);
            document.title = to.meta.title || 'Structurecity | Property - '+subject.replace(/-/g, " ");
          }
      },
  }, 

  data() {
        return {
            details: {},
            user: {},
            related_posts: [],
            show_details: false,
            images: [],
            currency:[],
            country: [],
            state: [],
            city: [],
            users: [],
            interests: [],
            interest_children: [],
            interest_children2: [],
            currentData:{},
            loading: false,
            id: localStorage.getItem('user'),  
            project: {
              user_id: localStorage.getItem('user'),
              name: '',
              id: '',
              project_interest_1: '',
              project_interest_2: '',
              project_interest_3: '',
              description: '',
              currency_id:'',
              address: '',
              type: '',
              price:'',
              country_id: 160,
              state_id: '',
              city_id: '',
              prices: [],
              details: [],
              images:'',
            },  
            visible: false,
            index: 0,
            imgs: '',             
            comment: {
              description: [],
            },
            adverts: [],
            customToolbar: [
              ["bold", "italic", "underline"],
              [{ list: "ordered" }, { list: "bullet" }],
              // ["image", "code-block"]
            ],
            page: 1,
            edit_comment: "",
            edit_comment_id: "",
        }
  },   

  methods: {
      logout () {
          localStorage.clear();
          this.$router.go();
        },

        fetchDetails () {
            axios(process.env.VUE_APP_API_URL + 'property/details/'+this.$route.params.id).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.details = response.data;
                this.fetchCurrentStates(response.data?response.data.country_id:'');
                this.fetchCurrentCities(response.data?response.data.state_id:'');
                this.interestChildren(response.data.interests[0]?response.data.interests[0].interest_id:0);
                this.interestChildren2(response.data.interests[1]?response.data.interests[1].interest_id:0);
                this.fetchRelatedPosts(response.data.id);
                
                this.project.id = response.data.id;
                this.project.user_id = response.data.user_id;
                this.project.name = response.data.name;
                this.project.description = response.data.description;
                this.project.address = response.data.address;
                this.project.type = response.data.type;
                this.project.price = response.data.price;
                this.project.currency_id = response.data.currency_id;
                this.project.country_id = response.data.country_id;
                this.project.state_id = response.data.state_id;
                this.project.city_id = response.data.city_id;
                this.project.project_interest_1 = response.data.interests[0]?response.data.interests[0].interest_id:'';
                this.project.project_interest_2 = response.data.interests[1]?response.data.interests[1].interest_id:'';
                this.project.project_interest_3 = response.data.interests[2]?response.data.interests[2].interest_id:'';
                
            }).catch({});
        },

        fetchRelatedPosts (post_id) {
            axios(process.env.VUE_APP_API_URL + 'property/related/'+post_id).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.related_posts = response.data.data;
                this.page = this.page + 1;
            }).catch({});
        },

        handleLoadMore($state) {
          axios(process.env.VUE_APP_API_URL + 'property/related/'+this.details.id+'?page=' + this.page)
              .then(res => {
                  $.each(res.data.data, (key, value) => {
                      this.related_posts.push(value);
                  });
                  $state.loaded();
                  this.page = this.page + 1;
                  if(res.data.data.length <= 0){
                   $state.complete();
                  }
              });
        },

        fetchUsers () {
            axios(process.env.VUE_APP_API_URL + 'users').then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.users = response.data;
            }).catch({});
        },

        fetchCurrency () {
            axios(process.env.VUE_APP_API_URL + 'currency').then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.currency = response.data;
            }).catch({});
        },

        fetchCountries () {
            axios(process.env.VUE_APP_API_URL + 'country').then(response =>{
                if (! response.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.country = response.data;
            }).catch({});
        },

        fetchStates (event) {
            axios(process.env.VUE_APP_API_URL + 'state/'+event.target.value).then(response =>{
                if (! response.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.state = response.data;
            }).catch({});
        },

        fetchCities (event) {
            axios(process.env.VUE_APP_API_URL + 'city/'+event.target.value).then(response =>{
                if (! response.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.city = response.data;
            }).catch({});
        },

        fetchCurrentStates(id) {
            axios(process.env.VUE_APP_API_URL + 'state/'+id).then(response =>{
                if (! response.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.state = response.data;
            }).catch({});
        },

        fetchCurrentCities(id) {
            axios(process.env.VUE_APP_API_URL + 'city/'+id).then(response =>{
                if (!response.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.city = response.data;
            }).catch({});
        },
        
        fectchInterests () {
            axios(process.env.VUE_APP_API_URL + 'interest').then(response =>{
                if (! response.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.interests = response.data;
            }).catch({});
        },

        fectchInterestChildren (e) {
            axios(process.env.VUE_APP_API_URL + 'interest/'+e.target.value).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.interest_children = response.data;
            }).catch({});
        },

        fectchInterestChildren2 (e) {
            axios(process.env.VUE_APP_API_URL + 'interest/'+e.target.value).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.interest_children2 = response.data;
            }).catch({});
        },

        interestChildren (id) {
            axios(process.env.VUE_APP_API_URL + 'interest/'+id).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.interest_children = response.data;
            }).catch({});
        },

        interestChildren2 (id) {
            axios(process.env.VUE_APP_API_URL + 'interest/'+id).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.interest_children2 = response.data;
            }).catch({});
        },

        fetchAdverts () {
          axios(process.env.VUE_APP_API_URL + 'adverts').then(response =>{
              if (! response.data.status == 200) {
                  return this.errorMessage = 'Could not fetch data';
              }
              this.adverts = response.data;
          }).catch({});
        },

        updateProject () {
            this.loading = true;
            axios.post(process.env.VUE_APP_API_URL + 'property/update', this.project)
            .then((response) => {
            if (response.status == 200) {
                this.$toastr.s(response.message);
                this.$root.$emit('delete-team-action')
                window.$('#project-modal').modal('hide');
                this.images = [];
                this.project.name = '';
                this.project.description = '';
                this.project.country_id = '';
                this.project.state_id = '';
                this.project.city_id = '';
                this.project.address = '';
                this.project.type = '';
                this.project.prices = [];
                this.project.details = [];
                this.project.images = '';
                this.loading = false;
            } else {
                this.$toastr.e(response.data.message);
                this.loading = false;
            }
            }).catch((error) =>{
                this.$toastr.e(error.response.data.message);
                this.loading = false;
            })
        },

        cancelForm(){
          if(confirm('Are you sure you want to discard this form?')){
            // this.$router.go(0);
            $('#project-modal').modal('hide');
            this.images = [];
                this.project.name = '';
                this.project.description = '';
                this.project.country_id = '';
                this.project.state_id = '';
                this.project.city_id = '';
                this.project.address = '';
                this.project.type = '';
                this.project.prices = [];
                this.project.details = [];
                this.project.images = '';
          }
        },

        sendMessage () {
            this.loading = true;
            var form = $("#message-form")[0];
            var _data = new FormData(form);
            _data.append('user_id',localStorage.getItem('user'));
            axios.post(process.env.VUE_APP_API_URL + 'send-message', _data)
            .then((response) => {
            if (response.status === 200) {
                this.$toastr.s(response.message);
                this.loading = false;
                $("#message-form")[0].reset();
                window.$('#send-message').modal('hide');
            } else {
                this.$toastr.e(response.data.message);
                this.loading = false;
            }
            }).catch((error) =>{
                this.$toastr.e(error.response.data.message);
                this.loading = false;
            })
        },

        deletePropertyPrice (id) {
            axios(process.env.VUE_APP_API_URL + 'property/delete-property-price/'+id)
            .then((response) => {
            if (response.status === 200) {
                this.$toastr.s(response.message);
                this.$root.$emit('delete-team-action')
            } else {
                this.$toastr.e(response.data.message);
            }
            }).catch((error) =>{
                this.$toastr.e(error.response.data.message);
            })
        },

        deletePropertyDetail (id) {
            axios(process.env.VUE_APP_API_URL + 'property/delete-property-detail/'+id)
            .then((response) => {
            if (response.status === 200) {
                this.$toastr.s(response.message);
                this.$root.$emit('delete-team-action')
            } else {
                this.$toastr.e(response.data.message);
            }
            }).catch((error) =>{
                this.$toastr.e(error.response.data.message);
            })
        },

        deleteProjectImage (id) {
            axios(process.env.VUE_APP_API_URL + 'property/delete-property-image/'+id)
            .then((response) => {
            if (response.status === 200) {
                this.$toastr.s(response.message);
                this.$root.$emit('delete-team-action')
            } else {
                this.$toastr.e(response.data.message);
            }
            }).catch((error) =>{
                this.$toastr.e(error.response.data.message);
            })
        },

        openUpdateProject(){
          window.$('#project-modal').modal('show');
        },

        addPrice() {
          this.project.prices.push({
            title: '',
            price: ''
          })
        },

        deletePrice(index) {
            this.project.prices.splice(index,1)
        },

        addDetail() {
          this.project.details.push({
            title: '',
            price: ''
          })
        },

        deleteDetail(index) {
            this.project.details.splice(index,1)
        },
        
        uploadImageSuccess(formData, index, fileList) {
          this.project.images = fileList;
        },

        beforeRemove (index, done, fileList) {
          console.log('index', index, fileList)
          var r = confirm("remove image")
          if (r == true) {
            done()
          } else {
            ''
          }
        },

        editImage (formData, index, fileList) {
          console.log('edit data', formData, index, fileList)
        },

        dataChange (data) {
          console.log(data)
        },

        formatDate(date){
          return moment(date).fromNow();
        },

        formatDate2(date) {
				return moment(date).format('DD MMMM, YYYY');
			},

      showImg (index) {
        this.index = index
        this.visible = true
      },

      showSingle(data) {
        this.imgs = data
        this.show()
      },

      show() {
        this.visible = true
      },

      handleHide () {
        this.visible = false
      },

      showContactDetails() {
        this.show_details = true;
      },

      hideContactDetails() {
        this.show_details = false;
      },

      formatPrice(value) {
        let data = "" + value;
				return data.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
      },

      submitComment(event, id) {
          this.loading = true;
          axios.post(process.env.VUE_APP_API_URL + 'property/comment', {"comment":event.target.value, "post_id":id, "user_id":localStorage.getItem('user')})
          .then((response) => {
          if (response.status === 200) {
              this.$toastr.s(response.message);
              this.comment.description = []
              this.$root.$emit('banner-action')
              this.loading = false;
          } else {
              this.$toastr.e(response.data.message);
              this.loading = false;
          }
          }).catch((error) =>{
              this.$toastr.e(error.response.data.message);
              this.loading = false;
          })
      },

      submitComment2(comment, id) {
          this.loading = true;
          axios.post(process.env.VUE_APP_API_URL + 'property/comment', {"comment":comment, "post_id":id, "user_id":localStorage.getItem('user')})
          .then((response) => {
          if (response.status === 200) {
              this.$toastr.s(response.message);
              this.comment.description = []
              this.$root.$emit('banner-action')
              this.loading = false;
          } else {
              this.$toastr.e(response.data.message);
              this.loading = false;
          }
          }).catch((error) =>{
              this.$toastr.e(error.response.data.message);
              this.loading = false;
          })
      },

      sendSubmitComment (id) {
        var comment = document.getElementById("submitCommentButton"+id).value;
        this.submitComment2(comment, id);
      },

      editPostComment(comment_id, comment){
        this.edit_comment = comment;
        this.edit_comment_id = comment_id;
        window.$('#edit_comment').modal('show');
      },

      closeReplyPostCommentModal(){
        this.edit_comment_id = '';
        window.$('#reply_comment').modal('hide');
      },

      closeEditPostCommentModal(){
        this.edit_comment = '';
        this.edit_comment_id = '';
        window.$('#edit_comment').modal('hide');
        // $("#edit_comment").modal('show');
      },

      editComment() {
          this.loading = true;
          axios.post(process.env.VUE_APP_API_URL + 'property/comment/edit', {"post_id":this.details.id, "comment":this.edit_comment, "comment_id":this.edit_comment_id, "user_id":localStorage.getItem('user')})
          .then((response) => {
          if (response.status === 200) {
              this.$toastr.s(response.message);
              this.edit_comment = ""
              this.edit_comment_id = ""
              window.$('#edit_comment').modal('hide');
              this.$root.$emit('banner-action')
              this.loading = false;
          } else {
              this.$toastr.e(response.data.message);
              this.loading = false;
          }
          }).catch((error) =>{
              this.$toastr.e(error.response.data.message);
              this.loading = false;
          })
      },

      deleteComment(comment_id) {
          this.loading = true;
          axios.post(process.env.VUE_APP_API_URL + 'property/comment/delete', {"post_id":this.details.id, "comment_id":comment_id, "user_id":localStorage.getItem('user')})
          .then((response) => {
          if (response.status === 200) {
              this.$toastr.s(response.message);
              this.edit_comment = ""
              this.edit_comment_id = ""
              this.$root.$emit('banner-action')
              this.loading = false;
          } else {
              this.$toastr.e(response.data.message);
              this.loading = false;
          }
          }).catch((error) =>{
              this.$toastr.e(error.response.data.message);
              this.loading = false;
          })
      },

      likePostComment(comment_id){
        this.loading = true;
          axios.post(process.env.VUE_APP_API_URL + 'property/comment/like', {"comment_id":comment_id, "user_id":localStorage.getItem('user')})
          .then((response) => {
          if (response.status === 200) {
              // this.$toastr.s(response.message);
              this.$root.$emit('banner-action')
              this.loading = false;
          } else {
              this.$toastr.e(response.message);
              this.loading = false;
          }
          }).catch((error) =>{
              this.$toastr.e(error.response.message);
              this.loading = false;
          })
      },

      unlikePostComment(comment_id){
        this.loading = true;
          axios.post(process.env.VUE_APP_API_URL + 'property/comment/unlike', {"comment_id":comment_id, "user_id":localStorage.getItem('user')})
          .then((response) => {
          if (response.status === 200) {
              // this.$toastr.s(response.message);
              this.$root.$emit('banner-action')
              this.loading = false;
          } else {
              this.$toastr.e(response.message);
              this.loading = false;
          }
          }).catch((error) =>{
              this.$toastr.e(error.response.message);
              this.loading = false;
          })
      },

      checkIfUser(source) {
        if(source.length > 0){
          for (var i = 0; i < source.length; i++) {
            if (source[i].user_id == this.id) {
              return true;
            }
          }
          return false;
        }
      },

      replyPostCommentModal(comment_id){
        this.edit_comment_id = comment_id;
        window.$('#reply_comment').modal('show');
      },

      replyPostComment() {
          this.loading = true;
          axios.post(process.env.VUE_APP_API_URL + 'property/comment/reply', {"post_id":this.details.id, "comment":this.edit_comment, "parent_id":this.edit_comment_id, "user_id":localStorage.getItem('user')})
          .then((response) => {
          if (response.status === 200) {
              this.$toastr.s(response.message);
              this.edit_comment = ""
              this.edit_comment_id = ""
              window.$('#reply_comment').modal('hide');
              this.$root.$emit('banner-action')
              this.loading = false;
          } else {
              this.$toastr.e(response.data.message);
              this.loading = false;
          }
          }).catch((error) =>{
              this.$toastr.e(error.response.data.message);
              this.loading = false;
          })
      },

    },

    mounted: function () {
       this.$root.$on('banner-action', ()=>{
        this.fetchDetails()
      })

      this.$root.$on('delete-team-action', ()=>{
        this.fetchDetails()
      })
    },

    computed: {
        image_url: function() {
           return process.env.VUE_APP_IMAGE_URL;
        }
    },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
  .banner-container { 
    position: relative; 
  } 
  .banner-container img { 
    width: 100%; 
    height: 100%; 
    box-sizing: border-box; 
  } 
  .banner-container button { 
    position: absolute; 
    top: 0; 
    right: 0; 
  }

  .project-image-container { 
    position: relative; 
  } 
  .project-image-container img { 
    width: 100%; 
    height: 100%; 
    box-sizing: border-box; 
  } 
  .project-image-container button { 
    position: absolute; 
    top: 0; 
    right: 0; 
  }

  .navigation-icon-size{
    font-size: 70px;
    color: brown
  }
</style>