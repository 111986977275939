<template>
  <layout-default>
    <div class="Followers">
        <div class="py-4">
            <div class="container">
                <div class="row">
                    <aside class="col col-xl-3 order-xl-2 col-lg-12 order-lg-2 col-12">
                        <div class="box mb-3 shadow-sm border rounded bg-white profile-box text-center">
                            <div class="py-4 px-3 border-bottom">
                                <img v-if="user.avatar" class="img-fluid mt-2 rounded-circle" :src="image_url+'users/'+user.avatar" style="max-height: 150px; max-width: 150px" alt="">
                                <img v-else class="img-fluid mt-2 rounded-circle" src="/assets/avatar.png" style="max-height: 150px; max-width: 150px" alt="">
                                <h5 class="font-weight-bold text-dark mb-1 mt-4">{{user.name}}</h5>
                                <p v-if="user.currentcompany" class="mb-0 text-muted">{{user.current_position}} <br> <router-link :to="'/company/'+user.currentcompany.slug">{{user.currentcompany.name}}</router-link></p>
                                <p v-else class="mb-0 text-muted">{{user.profession}}</p>
                                <span v-if="user.membership" class="text-info"><span v-if="user.membership.membership">{{ user.membership.membership.code }}</span><i v-if="user.membership.status == 1" data-toggle="tooltip" data-placement="top" title="Verified" class="feather-check-circle"></i></span> &nbsp;
                                <span v-if="user.certification" class="text-info"><span v-if="user.certification.certification">{{ user.certification.certification.code }}</span><i v-if="user.certification.status == 1" data-toggle="tooltip" data-placement="top" title="Verified" class="feather-check-circle"></i></span>
                            </div>
                            <div class="d-flex">
                                <div class="col-6 border-right p-3">
                                    <h6 class="font-weight-bold text-dark mb-1">{{total_followers}}</h6>
                                    <p class="mb-0 text-black-50 small">Followers</p>
                                </div>
                                <div class="col-6 p-3">
                                    <h6 class="font-weight-bold text-dark mb-1">{{total_project}}</h6>
                                    <p class="mb-0 text-black-50 small">Projects</p>
                                </div>
                            </div>
                            <div class="overflow-hidden border-top">
                                <router-link class="font-weight-bold p-3 d-block" :to="'/sc/profile/'+user.slug"> View my profile </router-link>
                            </div>
                        </div>
                        <!-- <div class="box shadow-sm mb-3 rounded bg-white ads-box text-center">
                            <img src="img/job1.png" class="img-fluid" alt="Responsive image">
                            <div class="p-3 border-bottom">
                            <h6 class="font-weight-bold text-dark">Osahan Solutions</h6>
                            <p class="mb-0 text-muted">Looking for talent?</p>
                            </div>
                            <div class="p-3">
                            <button type="button" class="btn btn-outline-primary pl-4 pr-4"> POST A JOB </button>
                            </div>
                        </div> -->
                    </aside>
                    <main class="col col-xl-9 order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12">
                        <div class="box shadow-sm border rounded bg-white mb-3 osahan-share-post">
                            <h5 class="pl-3 pt-3 pr-3 border-bottom mb-0 pb-3">My Followers</h5>
                            <div class="p-3">
                                <div class="row">
                                    <div v-for="data in followers" :key="data.id" class="col-md-4">
                                        <div class="border network-item rounded mb-3">
                                            <div class="p-3 d-flex align-items-center network-item-header">
                                                <div class="dropdown-list-image mr-3">
                                                    <img v-if="data.user.avatar" class="rounded-circle" :src="image_url+'users/'+data.user.avatar" alt="">
                                                    <img v-else class="rounded-circle" src="/assets/avatar.png" alt="">
                                                </div>
                                                <div class="font-weight-bold">
                                                    <router-link v-if="data.user.type == 'user'" :to="'/sc/profile/'+data.user.slug">
                                                        <div class="text-truncate">{{data.user.name.substring(0,18)+".."}}</div>
                                                        <div class="small text-gray-500">{{data.user.profession}}</div>
                                                        <div v-if="data.user.country" class="small text-gray-500"><i class="feather-map-pin"></i> {{data.user.country?data.user.country.name+',':''}} {{data.user.state?data.user.state.name+',':''}}</div>
                                                    </router-link>
                                                    <router-link v-else :to="'/company/'+data.user.company.slug">
                                                        <div class="text-truncate">{{data.user.name.substring(0,18)+".."}}</div>
                                                        <div v-if="data.user.country" class="small text-gray-500"><i class="feather-map-pin"></i> {{data.user.country?data.user.country.name+',':''}} {{data.user.state?data.user.state.name+',':''}}</div>
                                                    </router-link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>                        
                                </div>
                            </div>
                        </div>
                    </main>
                    
                </div>
            </div>
        </div>
    </div>
  </layout-default>
</template>
<script>
import LayoutDefault from "@/layouts/default";
import axios from 'axios';
// import $ from "jquery";
export default {
  name: 'Followers',
  components: {LayoutDefault},

    created() {
        this.fetchFollowers();
        this.fetchUser();
        this.fetchTotalProjects();
        this.fetchTotalFollowers();
    },

    watch: {
      $route: {
          immediate: true,
          handler(to) {
              document.title = to.meta.title || 'Followers';
          }
      },
  }, 

    data() {
        return {
           followers: [],
           user:{},
           total_project: 0,
           total_followers: 0,
        }
    },

    methods: {
        fetchFollowers () {
            axios(process.env.VUE_APP_API_URL + 'user/following/'+localStorage.getItem('user')).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.followers = response.data;
            }).catch({});
        },

        fetchUser () {
            axios(process.env.VUE_APP_API_URL + 'user/details/'+localStorage.getItem('user')).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.user = response.data;
            }).catch({});
        },

        fetchTotalProjects () {
            axios(process.env.VUE_APP_API_URL + 'project/total-project/'+localStorage.getItem('user')).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.total_project = response.data;
            }).catch({});
        },

        fetchTotalFollowers () {
            axios(process.env.VUE_APP_API_URL + 'user/total-following/'+localStorage.getItem('user')).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.total_followers = response.data;
            }).catch({});
        },
    },

     computed: {
        image_url: function() {
          return process.env.VUE_APP_IMAGE_URL;
        }
    },
}
</script>