<template>
  <layout-default>
      <div class="Profile">
          <div class="py-4">
              <div class="container">
              <div class="row">

              <aside class="col-md-4">
                <div class="box mb-3 shadow-sm border rounded bg-white profile-box text-center">
                  <div class="py-4 px-3 border-bottom">
                    <i style="float: right; font-size: 20px; cursor: pointer" data-toggle="modal" data-target="#update-avatar" title="update profile image" class="feather-edit"></i>
                    <img v-if="user.avatar" class="img-fluid mt-2 rounded-circle" :src="image_url+'users/'+user.avatar" style="max-height: 150px; max-width: 150px" alt="">
                    <img v-else class="img-fluid mt-2 rounded-circle" src="/assets/avatar.png" style="max-height: 150px; max-width: 150px" alt="">
                    <h5 class="font-weight-bold text-dark mb-1 mt-4">{{user.name}}</h5>
                    <p v-if="user.currentcompany" class="mb-0 text-muted">{{user.current_position}} <br> <router-link :to="'/company/'+user.currentcompany.slug">{{user.currentcompany.name}}</router-link></p>
                    <p v-else class="mb-0 text-muted">{{user.profession}}</p>
                    <span v-if="user.membership" class="text-info"><span v-if="user.membership.membership">{{ user.membership.membership.code }}</span><i v-if="user.membership.status == 1" data-toggle="tooltip" data-placement="top" title="Verified" class="feather-check-circle"></i></span> &nbsp;
                    <span v-if="user.certification" class="text-info"><span v-if="user.certification.certification">{{ user.certification.certification.code }}</span><i v-if="user.certification.status == 1" data-toggle="tooltip" data-placement="top" title="Verified" class="feather-check-circle"></i></span>
                  </div>
                  <div class="overflow-hidden border-top">
                    <router-link class="font-weight-bold p-3 d-block" :to="'/sc/profile/'+id"> View my profile </router-link>
                  </div>
                </div>
              </aside>
              <main class="col-md-8">
              <div class="border rounded bg-white mb-3">
                <div class="box-title border-bottom p-3">
                  <h6 class="m-0">Notification Settings</h6>
                  <p class="mb-0 mt-0 small">Setup your email notification.</p>
                </div>
                <div class="box-body p-3">
                  <form id="notification-form" class="js-validate" novalidate="novalidate">
                    <div class="row">
                      <div class="form-row">
                          <div class="form-group mb-0">
                              <label class="mb-1 w-100">Listing</label>
                              <div class="custom-control custom-switch">
                                <input type="checkbox" class="custom-control-input" name="listing_comment" value="1" :checked="user.notify?user.notify.listing_comment == 1 : ''" id="listing_comment">
                                <label class="custom-control-label" for="listing_comment">Notify me on new comment</label>
                              </div>
                              <div class="custom-control custom-switch">
                                <input type="checkbox" class="custom-control-input" name="listing_like" value="1" :checked="user.notify?user.notify.listing_like == 1 : ''" id="listing_like">
                                <label class="custom-control-label" for="listing_like">Notify me on new likes</label>
                              </div>
                              <div class="custom-control custom-switch">
                                <input type="checkbox" class="custom-control-input" name="join_project" value="1" :checked="user.notify?user.notify.join_project == 1 : ''" id="join_project">
                                <label class="custom-control-label" for="join_project">Notify me when someone joins my project</label>
                              </div>
                          </div>
                          
                          <div style="margin-top: 30px" class="form-group mb-0">
                              <label class="mb-1 w-100">Service Requests</label>
                              <div class="custom-control custom-switch">
                                <input type="checkbox" class="custom-control-input" name="service_request" value="1" :checked="user.notify?user.notify.service_request == 1 : ''" id="service_request">
                                <label class="custom-control-label" for="service_request">Notify me on new service requests that matches my profession</label>
                              </div>
                              <div class="custom-control custom-switch">
                                <input type="checkbox" class="custom-control-input" name="service_application" value="1" :checked="user.notify?user.notify.service_application == 1 : ''" id="service_application">
                                <label class="custom-control-label" for="service_application">Notify me on new application for my service requests</label>
                              </div>
                          </div>

                          <div style="margin-top: 30px" class="form-group mb-0">
                              <label class="mb-1 w-100">Messenger</label>
                              <div class="custom-control custom-switch">
                                <input type="checkbox" class="custom-control-input" name="new_message" value="1" :checked="user.notify?user.notify.new_message == 1 : ''" id="new_message">
                                <label class="custom-control-label" for="new_message">Notify me on new messages</label>
                              </div>
                          </div>                       
                      </div> 
                      
                      <div class="form-row">
                        <div style="margin-top: 30px" class="form-group mb-0">
                            <label class="mb-1 w-100">Blog</label>
                            <div class="custom-control custom-switch">
                              <input type="checkbox" class="custom-control-input" name="blog_activation" value="1" :checked="user.notify?user.notify.blog_activation == 1 : ''" id="blog_activation">
                              <label class="custom-control-label" for="blog_activation">Notify me on my blog activation</label>
                            </div>
                            <div class="custom-control custom-switch">
                              <input type="checkbox" class="custom-control-input" name="blog_deactivation" value="1" :checked="user.notify?user.notify.blog_deactivation == 1 : ''" id="blog_deactivation">
                              <label class="custom-control-label" for="blog_deactivation">Notify me on my blog deactivation</label>
                            </div>
                        </div>
                      </div>

                      <div class="form-row">
                        <div style="margin-top: 30px" class="form-group mb-0">
                            <label class="mb-1 w-100">Following</label>
                            <div class="custom-control custom-switch">
                              <input type="checkbox" class="custom-control-input" name="user_following" value="1" :checked="user.notify?user.notify.user_following == 1 : ''" id="user_following">
                              <label class="custom-control-label" for="user_following">Notify me when someone follows me</label>
                            </div>
                        </div>
                      </div>

                    </div>
                  </form>
                </div>
              </div>
              
              <div class="mb-3 text-right">
              <router-link class="font-weight-bold btn btn-link rounded p-3" to="/"> &nbsp;&nbsp;&nbsp;&nbsp; Cancel &nbsp;&nbsp;&nbsp;&nbsp; </router-link>
              <a v-if="loading" class="font-weight-bold btn btn-primary rounded p-3"> &nbsp;&nbsp;&nbsp;&nbsp; loading......... &nbsp;&nbsp;&nbsp;&nbsp; </a>
              <a v-else class="font-weight-bold btn btn-primary rounded p-3" @click="updateNotification()"> &nbsp;&nbsp;&nbsp;&nbsp; Save Changes &nbsp;&nbsp;&nbsp;&nbsp; </a>
              </div>
              </main>
              </div>
              </div>
          </div>


          <!-- update avatar Modal -->
      <div class="modal fade" id="update-avatar" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Change Profile Picture </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div id="banner-form">
                <div class="row">                  
                  <div class="col-md-8 mb-8 mb-sm-12">
                    <div class="js-form-message">
                      <label id="nameLabel" class="form-label">Select Image
                        <span class="text-danger" style="font-size: 10px">* (1500px X 300px)</span>
                      </label>
                      <div class="form-group">
                        <input class="form-control" type="file" name="banner" accept="image/*"  @change="setImage" required/>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 mb-4 mb-sm-12">
                    <div class="js-form-message">
                     <div class="form-group">
                        <img :src="imageSrc" style="width: 100px;" />
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="this.imageSrc" class="row">                  
                  <div class="col-md-12 mb-12 mb-sm-12">
                    <div class="my-3 d-flex align-items-center justify-content-center mx-auto">
                    <vue-cropper
                      class="mr-2 w-50"
                      ref="cropper"
                      :guides="true"
                      :aspect-ratio="500 / 500"
                      :src="imageSrc"
                    ></vue-cropper>
                    </div>
                  </div>
                  <div class="col-md-12 mb-12 mb-sm-12">
                    <!-- Cropped image previewer -->
                    <img class="ml-2 w-50 bg-light my-3 d-flex align-items-center justify-content-center mx-auto" :src="croppedImageSrc" />
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
               <div v-if="loading">
                <button type="button" class="btn btn-primary" data-dismiss="modal">Loading.....</button>
              </div>
              <div v-else>
                <button v-if="this.imageSrc" @click="cropImage" type="button" class="btn btn-primary">Crop</button>&nbsp; &nbsp; 
                <button v-if="this.croppedImageSrc" @click="uploadImage()" type="button" class="btn btn-primary">Upload Image</button>
              </div>
            </div>

          </div>
        </div>
      </div>


       </div>
  </layout-default>
</template>
<script>
import LayoutDefault from '../../layouts/default.vue';
import axios from 'axios';
import $ from "jquery";
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
// import { VueEditor } from "vue2-editor";

export default {
  name: 'Profile',
  components: {
    LayoutDefault,
    VueCropper,
    // VueEditor,
  },

  created() {
    this.fetchCompany();
    this.fetchUser();
    this.fetchCountries();
    this.fectchInterests();
    this.fetchProfessions();
  },

  watch: {
      $route: {
          immediate: true,
          handler(to) {
              document.title = to.meta.title || 'Update Profile';
          }
      },
  }, 

  data() {
        return {
          company: {},
          user: {},
          country: [],
          state: [],
          city: [],
          loading: false,
          id: localStorage.getItem('slug'),
          interests: [],
          professions:[],
          url: null,
          // customToolbar: [
          //   ["bold", "italic", "underline"],
          //   [{ list: "ordered" }, { list: "bullet" }],
          //   // ["image", "code-block"]
          // ],
          imageSrc: "",
          croppedImageSrc: "",
        }
  },   

  methods: {
      logout () {
          localStorage.clear();
          this.$router.go();
        },

        fetchCompany () {
            axios(process.env.VUE_APP_API_URL + 'company').then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.company = response.data;
            }).catch({});
        },

        fetchUser () {
            axios(process.env.VUE_APP_API_URL + 'user/details/'+localStorage.getItem('user')).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.user = response.data;
                this.fetchStates();
                this.fetchCities();
            }).catch({});
        },

        fetchCountries () {
            axios(process.env.VUE_APP_API_URL + 'country').then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.country = response.data;
            }).catch({});
        },

        fetchStates () {
            axios(process.env.VUE_APP_API_URL + 'state/'+this.user.country_id).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.state = response.data;
            }).catch({});
        },

        fetchCities () {
            axios(process.env.VUE_APP_API_URL + 'city/'+this.user.state_id).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.city = response.data;
            }).catch({});
        },

        fetchStates2 (event) {
            axios(process.env.VUE_APP_API_URL + 'state/'+event.target.value).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.state = response.data;
            }).catch({});
        },

        fetchCities2 (event) {
            axios(process.env.VUE_APP_API_URL + 'city/'+event.target.value).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.city = response.data;
            }).catch({});
        },

        fectchInterests () {
            axios(process.env.VUE_APP_API_URL + 'interest/user/'+localStorage.getItem('user')).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.interests = response.data;
            }).catch({});
        },

        fetchProfessions () {
            axios(process.env.VUE_APP_API_URL + 'professions').then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.professions = response.data;
            }).catch({});
        },

        update () {
            this.loading = true;
            var form = $("#update-form")[0];
            var _data = new FormData(form);
            axios.post(process.env.VUE_APP_API_URL + 'user/update-profile/'+localStorage.getItem('user'), _data)
            .then((response) => {
            if (response.status === 200) {
                this.$toastr.s(response.message);
                this.$root.$emit('refresh')
                // this.$router.push({ name: 'Profile', query: { redirect: '/sc/profile/'+localStorage.getItem('slug') } });
                this.loading = false;
            } else {
                this.$toastr.e(response.data.message);
                this.loading = false;
            }
            }).catch((error) =>{
                this.$toastr.e(error.response.data.message);
                this.loading = false;
            })
        },

        updateNotification () {
            this.loading = true;
            var form = $("#notification-form")[0];
            var _data = new FormData(form);
            axios.post(process.env.VUE_APP_API_URL + 'user/update-notification-setting/'+localStorage.getItem('user'), _data)
            .then((response) => {
            if (response.status === 200) {
                this.$toastr.s(response.message);
                this.$root.$emit('refresh')
                this.loading = false;
            } else {
                this.$toastr.e(response.data.message);
                this.loading = false;
            }
            }).catch((error) =>{
                this.$toastr.e(error.response.data.message);
                this.loading = false;
            })
        },

        removeAvatar () {
            axios(process.env.VUE_APP_API_URL + 'user/remove-avatar/'+localStorage.getItem('user')).then(response =>{
                if (! response.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.$toastr.s(response.message);
                this.$router.go();
            }).catch({});
        },

        onFileChange(e) {
          const file = e.target.files[0];
          this.url = URL.createObjectURL(file);
        },

        setImage: function (e) {
            const file = e.target.files[0]
            if (!file.type.includes("image/")) {
              alert("Please select an image file")
              return
            }
            if (typeof FileReader === "function") {
              const reader = new FileReader()
              reader.onload = event => {
                this.imageSrc = event.target.result

                // Rebuild cropperjs with the updated source
                this.$refs.cropper.replace(event.target.result)
              }
              reader.readAsDataURL(file)
            } else {
              alert("Sorry, FileReader API not supported")
            }
          },

          cropImage() {
            // Get image data for post processing, e.g. upload or setting image src
            this.croppedImageSrc = this.$refs.cropper.getCroppedCanvas().toDataURL()
          },

          uploadImage () {
            var self = this;
            self.loading = true;
            this.$refs.cropper.getCroppedCanvas().toBlob(function (blob) {
              let formData = new FormData()
              // Add name for our image
              formData.append("name", "avatar-" + new Date().getTime())
              // Append image file
              formData.append("file", blob)
              formData.append("id", localStorage.getItem('user'))
            axios.post(process.env.VUE_APP_API_URL + 'user/update-profile-image', formData)
              .then((response) => {
              if (response.status === 200) {
                  self.$toastr.s(response.message);
                  self.loading = false;
                  self.$root.$emit('refresh');
                  window.$('#update-avatar').modal('hide');
              } else {
                  self.$toastr.e(response.message);
                  self.loading = false;
              }
              }).catch((error) =>{
                  self.$toastr.e(error.response.message);
                  self.loading = false;
              })
          })
        },
    },

    mounted: function () {
       this.$root.$on('refresh', ()=>{
        this.fetchUser()
        this.fectchInterests()
      })
    },

    computed: {
        image_url: function() {
          return process.env.VUE_APP_IMAGE_URL;
        }
    },
}
</script>