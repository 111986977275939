<template>
  <layout-default>
    <div class="Search">
        <div class="py-4">
            <div class="container">
                <div class="row">

                  <aside class="col col-xl-3 order-xl-1 col-lg-12 order-lg-1 col-12">
                    <div class="box mb-3 shadow-sm border rounded bg-white profile-box text-center">
                    <div class="py-4 px-3 border-bottom">
                      <img v-if="user.avatar" class="img-fluid mt-2 rounded-circle" :src="image_url+'users/'+user.avatar" style="max-height: 150px; max-width: 150px" alt="">
                      <img v-else class="img-fluid mt-2 rounded-circle" src="/assets/avatar.png" style="max-height: 150px; max-width: 150px" alt="">
                    <h5 class="font-weight-bold text-dark mb-1 mt-4">{{user.name}}</h5>
                    <p v-if="user.currentcompany" class="mb-0 text-muted">{{user.current_position}} <br> <router-link :to="'/company/'+user.currentcompany.slug">{{user.currentcompany.name}}</router-link></p>
                    <p v-else class="mb-0 text-muted">{{user.profession}}</p>
                    </div>
                    <div class="d-flex">
                      <div class="col-6 border-right p-3">
                        <h6 class="font-weight-bold text-dark mb-1">{{total_followers}}</h6>
                        <p class="mb-0 text-black-50 small">Followers</p>
                      </div>
                      <div class="col-6 p-3">
                        <h6 class="font-weight-bold text-dark mb-1">{{total_project}}</h6>
                        <p class="mb-0 text-black-50 small">Projects</p>
                      </div>
                    </div>
                    </div>
                    
                  </aside>
                    <main class="col col-xl-6 order-xl-2 col-lg-12 order-lg-2 col-md-12 col-sm-12 col-12">
                      <div class="box shadow-sm border rounded bg-white mb-3">
                        <div class="box-title border-bottom p-3">
                          <h6 class="m-0">Search Results</h6>
                        </div>
                        <div v-if="projects.length">
                          <div v-for="(data, index) in projects" :key="index" class="box-body p-3 border-bottom">
                            <div class="d-flex align-items-top job-item-header pb-2">
                              <div class="mr-2">
                                <router-link :to="'/project/'+data.slug">
                                <h6 class="font-weight-bold text-dark mb-0">{{data.name}}</h6>
                                <div class="text-truncate text-primary">{{data.state?data.state.name+',':''}} {{data.country.name}}.</div>
                                <div class="small text-gray-500">{{formatDate2(data.project_start_date)}} - {{data.status == 1?formatDate2(data.project_end_date):'Present'}} ({{formatDate(data.project_start_date)}}) </div>
                                </router-link>
                              </div>
                              <img class="img-fluid ml-auto mb-auto" :src="image_url+'projects/'+data.images[0].image" alt="">
                            </div>
                            <p class="mb-0" v-if="data.description">{{data.description.substring(0,200)+"...."}} </p>
                            <a style="cursor:pointer" @click="viewTeam(index)">
                            <div class="d-flex align-items-center p-3 job-item-body">
                              <div class="overlap-rounded-circle">
                                <img v-for="connect in data.tags.slice(0,10)" :key="connect.id" class="rounded-circle shadow-sm" data-toggle="tooltip" data-placement="top" title="" :src="connect.user.avatar?image_url+'users/'+connect.user.avatar:'/assets/avatar.png'" alt="" :data-original-title="connect.user.name">
                              </div>
                              <span class="font-weight-bold text-muted">{{data.tags.length}} connections</span>
                            </div>
                            </a>
                          </div>
                        </div>
                      </div>

                      <div v-if="users.length" class="box shadow-sm border rounded bg-white mb-3">
                        <div class="box-title border-bottom p-3">
                            <h6 class="m-0">Professionals</h6>
                        </div>
                        <div class="row box-body p-3">
                          <div v-for="data in users" :key="data.id" class="col-md-6 d-flex align-items-center osahan-post-header mb-3 people-list">
                            <div class="dropdown-list-image mr-3">
                                <router-link :to="'/sc/profile/'+data.slug">
                                <img v-if="data.avatar" class="rounded-circle" :src="image_url+'users/'+data.avatar" alt="">
                                <img v-else class="rounded-circle" src="/assets/avatar.png" alt="">
                                </router-link>
                            </div>
                            <div class="font-weight-bold mr-2">
                                <router-link :to="'/sc/profile/'+data.slug">
                                <div class="text-truncate">{{data.name}}</div>
                                <div v-if="data.profession" class="small text-gray-500">{{data.profession}}</div>
                                <div v-else class="small text-gray-500"><i v-if="data.country" class="feather-map-pin"></i> {{data.city?data.city.name+',':''}} {{data.state?data.state.name+',':''}} {{data.country?data.country.name:''}}</div>    
                                </router-link>                                   
                            </div>
                            <!-- <span class="ml-auto"><button type="button" class="btn btn-light btn-sm text-nowrap"><i class="feather-plus"></i> Follow</button></span> -->
                          </div>
                        </div>
                      </div>

                      <div v-if="business.length" class="box shadow-sm border rounded bg-white mb-3">
                        <div class="box-title border-bottom p-3">
                        <h6 class="m-0">Company / Businesses</h6>
                        </div>
                        <div class="row box-body">
                          <div v-for="data in business" :key="data.id" class="d-flex align-items-center osahan-post-header p-3 border-bottom people-list">
                              <div class="dropdown-list-image mr-3">
                                <router-link :to="'/company/'+data.slug"><img class="rounded-circle" :src="image_url+'users/'+data.logo" alt=""></router-link>
                              </div>
                              <div class="font-weight-bold">
                                <router-link :to="'/company/'+data.slug"><div class="text-truncate">{{data.name}} </div></router-link>
                                <div class="mb-0 text-muted"><i class="feather-map-pin"></i> {{data.state?data.state.name+',':''}} {{data.country.name}}</div>
                              </div>
                            </div>
                        </div>
                      </div>
                    </main>

                    <aside class="col col-xl-3 order-xl-3 col-lg-12 order-lg-3 col-12">
                      <div v-if="users.length" class="box shadow-sm border rounded bg-white mb-3">
                        <div class="box-title border-bottom p-3">
                            <h6 class="m-0">Professionals</h6>
                        </div>
                        <div class="box-body p-3">
                          <div v-for="data in users" :key="data.id" class="d-flex align-items-center osahan-post-header mb-3 people-list">
                            <div class="dropdown-list-image mr-3">
                                <router-link :to="'/sc/profile/'+data.slug">
                                <img v-if="data.avatar" class="rounded-circle" :src="image_url+'users/'+data.avatar" alt="">
                                <img v-else class="rounded-circle" src="/assets/avatar.png" alt="">
                                </router-link>
                            </div>
                            <div class="font-weight-bold mr-2">
                                <router-link :to="'/sc/profile/'+data.slug">
                                <div class="text-truncate">{{data.name}}</div>
                                <div v-if="data.profession" class="small text-gray-500">{{data.profession}}</div>
                                <div v-else class="small text-gray-500"><i v-if="data.country" class="feather-map-pin"></i> {{data.city?data.city.name+',':''}} {{data.state?data.state.name+',':''}} {{data.country?data.country.name:''}}</div>    
                                </router-link>                                   
                            </div>
                            <!-- <span class="ml-auto"><button type="button" class="btn btn-light btn-sm text-nowrap"><i class="feather-plus"></i> Follow</button></span> -->
                          </div>
                        </div>
                      </div>

                      <div v-if="business.length" class="box shadow-sm border rounded bg-white mb-3">
                        <div class="box-title border-bottom p-3">
                        <h6 class="m-0">Company / Businesses</h6>
                        </div>
                          <div class="box-body">
                            <div v-for="data in business" :key="data.id" class="d-flex align-items-center osahan-post-header p-3 border-bottom people-list">
                              <div class="dropdown-list-image mr-3">
                                <router-link :to="'/company/'+data.slug"><img class="rounded-circle" :src="image_url+'users/'+data.logo" alt=""></router-link>
                              </div>
                              <div class="font-weight-bold">
                                <router-link :to="'/company/'+data.slug"><div class="text-truncate">{{data.name}} </div></router-link>
                                <div class="mb-0 text-muted"><i class="feather-map-pin"></i> {{data.state?data.state.name+',':''}} {{data.country.name}}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      <!-- <a href="job-profile.html">
                      <div class="shadow-sm border rounded bg-white job-item mb-3">
                      <div class="d-flex align-items-center p-3 job-item-header">
                      <div class="overflow-hidden mr-2">
                      <h6 class="font-weight-bold text-dark mb-0 text-truncate">Product Director</h6>
                      <div class="text-truncate text-primary">Spotify Inc.</div>
                      <div class="small text-gray-500"><i class="feather-map-pin"></i> India, Punjab</div>
                      </div>
                      <img class="img-fluid ml-auto" src="img/l3.png" alt="">
                      </div>
                      <div class="d-flex align-items-center p-3 border-top border-bottom job-item-body">
                      <div class="overlap-rounded-circle">
                      <img class="rounded-circle shadow-sm" data-toggle="tooltip" data-placement="top" title="" src="img/p9.png" alt="" data-original-title="Sophia Lee">
                      <img class="rounded-circle shadow-sm" data-toggle="tooltip" data-placement="top" title="" src="img/p10.png" alt="" data-original-title="John Doe">
                      <img class="rounded-circle shadow-sm" data-toggle="tooltip" data-placement="top" title="" src="img/p11.png" alt="" data-original-title="Julia Cox">
                      <img class="rounded-circle shadow-sm" data-toggle="tooltip" data-placement="top" title="" src="img/p10.png" alt="" data-original-title="John Doe">
                      <img class="rounded-circle shadow-sm" data-toggle="tooltip" data-placement="top" title="" src="img/p11.png" alt="" data-original-title="Julia Cox">
                      <img class="rounded-circle shadow-sm" data-toggle="tooltip" data-placement="top" title="" src="img/p12.png" alt="" data-original-title="Robert Cook">
                      </div>
                      <span class="font-weight-bold text-muted">18 connections</span>
                      </div>
                      <div class="p-3 job-item-footer">
                      <small class="text-gray-500"><i class="feather-clock"></i> Posted 3 Days ago</small>
                      </div>
                      </div>
                      </a> -->
                    </aside>
                </div>
            </div>
        </div>




        <!-- connections Modal -->
      <div v-if="currentData" class="modal fade" id="team-view" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">{{currentData.name}}</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div v-for="data in currentData.tags" :key="data.id" class="p-3 d-flex align-items-center border-bottom osahan-post-header">
                <div class="dropdown-list-image mr-3">
                  <a :href="'/sc/profile/'+data.user.slug">
                  <img v-if="data.user.avatar" class="rounded-circle" :src="image_url+'users/'+data.user.avatar" style="max-height: 150px; max-width: 150px" alt="">
                  <img v-else class="rounded-circle" src="/assets/avatar.png" style="max-height: 150px; max-width: 150px" alt="">
                  </a>
                </div>
                <div class="font-weight-bold">
                  <div class="text-truncate"><router-link :to="'/sc/profile/'+data.user.slug">{{data.user.name}}</router-link></div>
                  <div class="small text-gray-500">{{data.user.profession}} </div>
                </div>
                <span class="ml-auto"><br><button type="button" @click="follow(data.user.id)" class="btn btn-outline-primary btn-sm">Follow</button></span> 
              </div>
            </div>
            <div class="modal-footer">
              <div>
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>&nbsp;
              </div>
            </div>

          </div>
        </div>
      </div>


    </div>
  </layout-default>
</template>
<script>
import LayoutDefault from '../../layouts/default.vue';
import axios from 'axios';
import moment from 'moment';
// import $ from "jquery";

export default {
  name: 'Search',
  components: {
    LayoutDefault,
  },

  created() {
        this.fetchUser();
        this.fetchData();
  },

  watch: {
      $route: {
          immediate: true,
          handler(to) {
              document.title = to.meta.title || 'Search Listing';
          }
      },
  }, 

  data() {
        return {
          companies: [],
          user: {},
          loading: false,
          id: localStorage.getItem('user'),
          projects: [],
          currentData: {},
          users: [],
          business: [],
          total_project: 0,
          total_followers: 0,
        }
  },   

  methods: {
      logout () {
          localStorage.clear();
          this.$router.go();
        },

        fetchUser () {
            axios(process.env.VUE_APP_API_URL + 'user/details/'+localStorage.getItem('user')).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.user = response.data;
            }).catch({});
        },

        fetchData () {
            axios(process.env.VUE_APP_API_URL + 'user/search/'+this.$route.query.search).then(response =>{
                if (! response.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.projects = response.data.project;
                this.users = response.data.user;
                this.business = response.data.business;
            }).catch({});
        },

        fetchTotalProjects () {
            axios(process.env.VUE_APP_API_URL + 'project/total-project/'+localStorage.getItem('user')).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.total_project = response.data;
            }).catch({});
        },

        fetchTotalFollowers () {
            axios(process.env.VUE_APP_API_URL + 'user/total-following/'+localStorage.getItem('user')).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.total_followers = response.data;
            }).catch({});
        },

        follow (id) {
            this.loading = true;
            axios.post(process.env.VUE_APP_API_URL + 'user/follow', {"user_id":id, "followed_by":localStorage.getItem('user')})
            .then((response) => {
            if (response.status === 200) {
                this.$toastr.s(response.message);
                this.loading = false;
            } else {
                this.$toastr.e(response.data.message);
                this.loading = false;
            }
            }).catch((error) =>{
                this.$toastr.e(error.response.data.message);
                this.loading = false;
            })
        },

        viewTeam (index) {
          this.currentData = this.projects[index];
          window.$('#team-view').modal('show');
        },

      formatDate2(date) {
				return moment(date).format('MMMM, YYYY');
			},

      formatDate(date){
          return moment(date).fromNow();
        },
    },

    computed: {
        image_url: function() {
          return process.env.VUE_APP_IMAGE_URL;
        }
    },
}
</script>