<template>
  <layout-default>
    <div class="Followers">
        <div class="py-4">
            <div class="container">
                <div class="row">
                    <aside class="col col-xl-3 order-xl-2 col-lg-12 order-lg-2 col-12">
                        <div class="box mb-3 shadow-sm border rounded bg-white profile-box text-center">
                            <div class="py-4 px-3 border-bottom">
                                <img v-if="user.avatar" class="img-fluid mt-2 rounded-circle" :src="image_url+'users/'+user.avatar" style="max-height: 150px; max-width: 150px" alt="">
                                <img v-else class="img-fluid mt-2 rounded-circle" src="/assets/avatar.png" style="max-height: 150px; max-width: 150px" alt="">
                                <h5 class="font-weight-bold text-dark mb-1 mt-4">{{user.name}}</h5>
                                <p v-if="user.currentcompany" class="mb-0 text-muted">{{user.current_position}} <br> <router-link :to="'/company/'+user.currentcompany.slug">{{user.currentcompany.name}}</router-link></p>
                                <p v-else class="mb-0 text-muted">{{user.profession}}</p>
                                <span v-if="user.membership" class="text-info"><span v-if="user.membership.membership">{{ user.membership.membership.code }}</span><i v-if="user.membership.status == 1" data-toggle="tooltip" data-placement="top" title="Verified" class="feather-check-circle"></i></span> &nbsp;
                                <span v-if="user.certification" class="text-info"><span v-if="user.certification.certification">{{ user.certification.certification.code }}</span><i v-if="user.certification.status == 1" data-toggle="tooltip" data-placement="top" title="Verified" class="feather-check-circle"></i></span>
                            </div>
                            <div class="d-flex">
                                <div class="col-6 border-right p-3">
                                    <h6 class="font-weight-bold text-dark mb-1">{{total_followers}}</h6>
                                    <p class="mb-0 text-black-50 small">Followers</p>
                                </div>
                                <div class="col-6 p-3">
                                    <h6 class="font-weight-bold text-dark mb-1">{{total_project}}</h6>
                                    <p class="mb-0 text-black-50 small">Projects</p>
                                </div>
                            </div>
                            <div class="overflow-hidden border-top">
                                <router-link class="font-weight-bold p-3 d-block" :to="'/sc/profile/'+user.slug"> View my profile </router-link>
                            </div>
                        </div>
                        <!-- <div class="box shadow-sm mb-3 rounded bg-white ads-box text-center">
                            <img src="img/job1.png" class="img-fluid" alt="Responsive image">
                            <div class="p-3 border-bottom">
                            <h6 class="font-weight-bold text-dark">Osahan Solutions</h6>
                            <p class="mb-0 text-muted">Looking for talent?</p>
                            </div>
                            <div class="p-3">
                            <button type="button" class="btn btn-outline-primary pl-4 pr-4"> POST A JOB </button>
                            </div>
                        </div> -->
                    </aside>
                    <main class="col col-xl-9 order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12">
                        <div class="row" style="float: right; padding-right:20px">
                            <button type="button" data-toggle="modal" data-target="#service-modal" class="btn btn-outline-primary btn-sm"> <i class="feather-plus mr-2"></i> Create New Blog</button>
                        </div>
                        <div class="">
                            <h5 class="pl-3 pt-3 pr-3 border-bottom mb-0 pb-3">Blog History</h5>
                            
                            <div class="p-3">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12">
                                        <div class="row">
                                          <div v-for="(data, index) in details" :key="index" class="col-lg-6 col-md-6">
                                            <div class="box shadow-sm rounded bg-white mb-3 blog-card border-0">
                                              <router-link :to="'/blog/'+data.slug">
                                                <img v-if="data.image" class="card-img-top" :src="data.image" style="height: 250px; max-width: 100%; object-fit:cover" alt="">
                                                <img v-else v-for="image in data.images.slice(0,1)" :key="image.id" class="card-img-top" :src="image.path" style="height: 250px; max-width: 100%; object-fit:cover" alt="">
                                              
                                                <div class="card-body">
                                                  <p>
                                                    <span class="badge badge-primary">{{data.category?data.category.name:''}}</span> &nbsp;&nbsp;&nbsp;
                                                    <span v-if="data.status == 0" class="badge badge-warning">Awaiting</span>
                                                    <span v-if="data.status == 1" class="badge badge-success">Active</span>
                                                    <span v-if="data.status == 2" class="badge badge-danger">Deactivated</span>
                                                  </p>
                                                  <h5 class="text-dark">{{data.title.substring(0,32)+'....'}}</h5>
                                                  <p v-html="data.description.substring(0,100)+'....'" class="mb-0"></p>
                                                </div>
                                              </router-link>
                                                <div class="card-footer border-0">
                                                  <p style="font-size: 16px" class="mb-0"> Posted: {{formatDate2(data.created_at)}} 
                                                    <span style="padding-left:80px">
                                                      <button type="button" @click="openEditBlog(index)" class="btn-sm btn-outline-primary"> <i class="feather-edit mr-1"></i> </button>
                                                    </span>
                                                  </p>
                                                </div>
                                              
                                            </div>
                                          </div>
                                        </div>
                                        <pagination style="float:right;" :pagination="pagination"
                                                @prev="fetchData(pagination.prevPageUrl)"
                                                @next="fetchData(pagination.nextPageUrl)" v-if="details.length > 0">
                                        </pagination>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    
                </div>
            </div>
        </div>



        <!-- create blog Modal -->
      <div class="modal fade" id="service-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Create New Blog </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form id="blog-form">
                <div class="row">
                  <div class="col-md-12 mb-12 mb-sm-12">
                    <div class="form-group mb-0">
                      <label class="form-label">Posting as? <span class="text-danger">*</span></label><br>
                      <div class="custom-control custom-radio d-inline mr-3">
                        <input type="radio" v-model="blog.user_type" value="user" class="custom-control-input" id="customCheck1" :checked="blog.user_type == 'user'">
                        <label class="custom-control-label" for="customCheck1">User</label>
                      </div>
                      <div class="custom-control custom-radio d-inline">
                        <input type="radio" v-model="blog.user_type" value="company" class="custom-control-input" id="customCheck2">
                        <label class="custom-control-label" for="customCheck2">Company</label>
                      </div>
                    </div><br>

                    <div v-if="blog.user_type == 'company'" class="form-group">
                        <label class="form-label">Select Company <span class="text-danger">*</span></label>
                        <select class="form-control custom-select" name="user_id" v-model="blog.user_id">
                        <!-- <option value="" disabled selected>Select company</option> -->
                        <option v-for="(data, index) in companies" :key="index" :value="data.id">{{data.name}}</option>
                        </select>
                    </div>
                    <div v-else class="form-group">
                      <input type="hidden" class="form-control" name="user_id" v-model="blog.user_id" />
                    </div>
                    
                    <div class="js-form-message">
                        <label id="nameLabel" class="form-label">Blog Title</label>
                        <div class="form-group">
                          <input type="text" class="form-control" name="title" v-model="blog.title" />
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="form-label">Blog Category <span class="text-danger">*</span></label>
                        <select class="form-control custom-select" name="cat_id" v-model="blog.cat_id">
                        <option value="" disabled selected>Select Category</option>
                        <option v-for="(data, index) in categories" :key="index" :value="data.id">{{data.name}}</option>
                        </select>
                    </div>
                    <div class="js-form-message">
                        <label id="nameLabel" class="form-label">Blog Description
                        <span class="text-danger">*</span>
                        </label>
                        <div class="form-group">
                          <textarea class="form-control summernote" name="description"></textarea>
                            <!-- <vue-editor useCustomImageHandler @imageAdded="handleImageAdded" id="job_editor" v-model="blog.description"></vue-editor> -->
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Add Blog Images
                            <span class="text-danger">* </span>
                            </label>
                            <div id="my-strictly-unique-vue-upload-multiple-image" style="display: flex; justify-content: center;">
                              <vue-upload-multiple-image
                                @upload-success="uploadImage"
                                @before-remove="beforeRemove"
                                @edit-image="editImage"
                                @data-change="dataChange"
                                :data-images="images"
                                dragText="Drag and drop images"
                                browseText="Browse Images"
                                primaryText="added"
                                markIsPrimaryText="	Set default image"
                                popupText="Description default image"
                                :maxImage=3
                                ></vue-upload-multiple-image>

                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
                <input type="hidden" class="form-control" name="user_type" v-model="blog.user_type" />
              </form>

            </div>
            <div class="modal-footer">
              <div v-if="loading">
                <button type="button" class="btn btn-primary" data-dismiss="modal">Loading.....</button>
              </div>
              <div v-else>
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>&nbsp;
                <button type="button" @click="createBlog()" class="btn btn-primary">Submit</button>
              </div>
            </div>

          </div>
        </div>
      </div>








      <!-- update blog Modal -->
      <div class="modal fade" id="update-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Update Blog </h5>
              <button type="button" class="close" @click="closeUpdateModal" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div v-if="currentData" class="modal-body">
              <form id="update-form" enctype="multipart/form-data">
                <div class="row">
                  <div class="col-md-12 mb-12 mb-sm-12">
                    <div class="form-group mb-0">
                      <label class="form-label">Posting as? <span class="text-danger">*</span></label><br>
                      <div class="custom-control custom-radio d-inline mr-3">
                        <input type="radio" v-model="currentData.user_type" value="user" name="user_type" class="custom-control-input" id="customCheck3" :checked="currentData.user_type == 'user'">
                        <label class="custom-control-label" for="customCheck3">User</label>
                      </div>
                      <div class="custom-control custom-radio d-inline">
                        <input type="radio" v-model="currentData.user_type" value="company" name="user_type" class="custom-control-input" id="customCheck4" :checked="currentData.user_type == 'company'">
                        <label class="custom-control-label" for="customCheck4">Company</label>
                      </div>
                    </div><br>

                    <div v-if="currentData.user_type == 'company'" class="form-group">
                        <label class="form-label">Select Company <span class="text-danger">*</span></label>
                        <select class="form-control custom-select" name="user_id" v-model="currentData.user_id">
                        <!-- <option value="" disabled selected>Select company</option> -->
                        <option v-for="(data, index) in companies" :key="index" :value="data.id">{{data.name}}</option>
                        </select>
                    </div>
                    <div v-else class="form-group">
                      <select class="form-control" name="user_id" v-model="currentData.user_id">
                        <option :value="user_id">{{user.name}}</option>
                      </select>
                    </div>
                    <div class="js-form-message">
                        <label id="nameLabel" class="form-label">Blog Title</label>
                        <div class="form-group">
                          <input type="text" class="form-control" name="title" v-model="currentData.title" />
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="form-label">Blog Category <span class="text-danger">*</span></label>
                        <select class="form-control custom-select" name="cat_id" v-model="currentData.cat_id">
                        <option value="" disabled selected>Select Category</option>
                        <option v-for="(data, index) in categories" :key="index" :value="data.id">{{data.name}}</option>
                        </select>
                    </div>
                    <div class="js-form-message">
                        <label id="nameLabel" class="form-label">Blog Description
                        <span class="text-danger">*</span>
                        </label>
                        <div class="form-group">
                          <textarea class="form-control summernote" id="editdescription" name="description"></textarea>
                            <!-- <vue-editor id="job_editor" v-model="currentData.description" :editorToolbar="customToolbar"></vue-editor> -->
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Add Blog Images<span class="text-danger">* </span></label>
                            <input type="file" id="images" class="form-control" name="uploads" multiple accept="image/*" @change="viewAddedImage" />
                            <div class="box-body p-3">
                              <div class="gallery-box-main">
                                <div class="">
                                  <div class="row">
                                    <div v-for="(data, index) in view_images" :key="index" class="col-md-3 col-sm-4 col-xs-4" style="margin-bottom: 10px">
                                      <div class="project-image-container">
                                        <img style="object-fit:cover; width: 60px; height:60px" :src="data.src" alt="">
                                        <button type="button" style="float:right; border:0" @click="removeImage(index)"><i style="color: red" class="feather-x"></i></button>
                                      </div>
                                    </div>
                                  </div>    
                                  <div class="row">
                                    <div v-for="(data, index) in currentData.images" :key="index" class="col-md-3 col-sm-4 col-xs-4" style="margin-bottom: 10px">
                                      <div class="project-image-container">
                                        <img style="object-fit:cover; width: 60px; height:60px" :src="data.path" alt="">
                                        <button type="button" style="float:right; border:0" @click="deleteBlogImage(data.id)"><i style="color: red" class="feather-x"></i></button>
                                      </div>
                                    </div>
                                  </div>                                  
                                </div>
                              </div>
                            </div>
                            <!-- <div id="my-strictly-unique-vue-upload-multiple-image" style="display: flex; justify-content: center;">
                              <vue-upload-multiple-image
                                @upload-success="uploadImage"
                                @before-remove="beforeRemove"
                                @edit-image="editImage"
                                @data-change="dataChange"
                                :data-images="images"
                                dragText="Drag and drop images"
                                browseText="Browse Images"
                                primaryText="added"
                                markIsPrimaryText="	Set default image"
                                popupText="Description default image"
                                :maxImage=3
                                ></vue-upload-multiple-image>

                            </div> -->
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
                <input type="hidden" class="form-control" name="id" v-model="currentData.id" />
              </form>

            </div>
            <div class="modal-footer">
              <div v-if="loading">
                <button type="button" class="btn btn-primary" data-dismiss="modal">Loading.....</button>
              </div>
              <div v-else>
                <button type="button" class="btn btn-secondary" @click="closeUpdateModal" data-dismiss="modal">Close</button>&nbsp;
                <button type="button" @click="updateBlog()" class="btn btn-primary">Submit</button>
              </div>
            </div>

          </div>
        </div>
      </div>




    </div>
  </layout-default>
</template>
<script>
import LayoutDefault from "@/layouts/default";
import axios from 'axios';
// import { VueEditor } from "vue2-editor";
import moment from 'moment';
import VueUploadMultipleImage from 'vue-upload-multiple-image';
import $ from "jquery";

export default {
  name: 'userblog',
  components: {
    LayoutDefault, 
    // VueEditor, 
    VueUploadMultipleImage
  },

    created() {
        this.fetchData();
        this.fetchUser();
        this.fetchCategories();
        this.fetchTotalProjects();
        this.fetchTotalFollowers();
        this.fetchCompanies();
    },

    watch: {
      $route: {
          immediate: true,
          handler(to) {
              document.title = to.meta.title || 'My Blog Posts';
          }          
      },

      'currentData.description'(value) {
        this.currentData.description = value;
        window.$('#editdescription').summernote("code", value);
      },
  }, 

    data() {
        return {
          loading: false,
          user_id: localStorage.getItem('user'),
          currentData: {
            update_images: '',
          },
          details: [],
          categories: [],
          user:{},
          total_project: 0,
          total_followers: 0,
          pagination: {
              lastPage: '',
              currentPage: '',
              total: '',
              lastPageUrl: '',
              nextPageUrl: '',
              prevPageUrl: '',
              from: '',
              to: ''
          },
          blog: {
              title: '',
              description: '',
              cat_id: '',
              images: '',
              user_type:'user',
              user_id: localStorage.getItem('user'),
          },
          images: [],
          view_images:[],
          show: false,
          htmlForEditor: "",

          customToolbar: [
              ["bold", "italic", "underline"],
              [{ list: "ordered" }, { list: "bullet" }],
              // ["image", "code-block"]
          ],
        }
    },

    methods: {
        fetchData () {
            axios(process.env.VUE_APP_API_URL + 'blog/user/'+localStorage.getItem('user')).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.show = false;
                let data = response.data;
                this.details = data.data;
                this.configPagination(data);
            }).catch({});
        },

        fetchUser () {
            axios(process.env.VUE_APP_API_URL + 'user/details/'+localStorage.getItem('user')).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.user = response.data;
            }).catch({});
        },

        fetchCompanies () {
            axios(process.env.VUE_APP_API_URL + 'user/get-companies/'+localStorage.getItem('user')).then(response =>{
                if (! response.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.companies = response.data;
            }).catch({});
        },

        fetchCategories () {
            axios(process.env.VUE_APP_API_URL + 'blog/categories').then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.categories = response.data;
            }).catch({});
        },

        fetchTotalProjects () {
            axios(process.env.VUE_APP_API_URL + 'project/total-project/'+localStorage.getItem('user')).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.total_project = response.data;
            }).catch({});
        },

        fetchTotalFollowers () {
            axios(process.env.VUE_APP_API_URL + 'user/total-following/'+localStorage.getItem('user')).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.total_followers = response.data;
            }).catch({});
        },

        createBlog() {
            this.loading = true;
            var form = $("#blog-form")[0];
            var _data = new FormData(form);
            axios.post(process.env.VUE_APP_API_URL + 'blog/create-blog', _data)
            .then((response) => {
            if (response.status === 200) {
              this.uploadBlogImage(response.data, this.blog.images)
                this.$toastr.s(response.message);
                // this.blog.title = '';
                // this.blog.description = '';
                // this.blog.cat_id = 160;
                // this.blog.images = '';
                // this.images = [];
                this.$root.$emit('action')
                this.closeCreateModal();
                // window.$('#service-modal').modal('hide');
                this.loading = false;
            } else {
                this.$toastr.e(response.data.message);
                this.loading = false;
            }
            }).catch((error) =>{
                this.$toastr.e(error.response.data.message);
                this.loading = false;
            })
        },

        uploadBlogImage(id, imgs) {
          console.log(id, imgs);
            axios.post(process.env.VUE_APP_API_URL + 'blog/upload-image', {blog_id: id, images: imgs})
            .then((response) => {
            if (response.status === 200) {
                // this.$toastr.s(response.message);
                this.blog.images = '';
                this.images = [];
                this.$root.$emit('action')
            }
            });
        },

        updateBlog() {
            this.loading = true;
            var form = $("#update-form")[0];
            var _data = new FormData (form);
            
            axios.post(process.env.VUE_APP_API_URL + 'blog/update-blog', _data)
            .then((response) => {
                this.$toastr.s(response.message);
                // this.currentData.images = '';
                // this.images = [];
                this.$root.$emit('action')
                this.closeUpdateModal();
            }).catch((error) =>{
                this.$toastr.e(error.response.data.message);
                this.loading = false;
            })
        },

        deleteBlogImage (id) {
            axios(process.env.VUE_APP_API_URL + 'blog/delete-blog-image/'+id)
            .then((response) => {
            if (response.status === 200) {
                this.$toastr.s(response.message);
                this.$root.$emit('action')
            } else {
                this.$toastr.e(response.data.message);
            }
            }).catch((error) =>{
                this.$toastr.e(error.response.data.message);
            })
        },

        openEditBlog(index){      
          this.currentData = this.details[index];
          window.$('#update-modal').modal('show');
        },

        configPagination(data) {
            this.pagination.lastPage = data.last_page;
            this.pagination.currentPage = data.current_page;
            this.pagination.total = data.total;
            this.pagination.lastPageUrl = data.last_page_url;
            this.pagination.nextPageUrl = data.next_page_url;
            this.pagination.prevPageUrl = data.prev_page_url;
            this.pagination.from = data.from;
            this.pagination.to = data.to;
        },

        uploadImage(formData, index, fileList) {
            this.blog.images = fileList;
        },

        uploadUpdateImage(formData, index, fileList) {
            this.currentData.update_images = fileList;
        },

        beforeRemove (index, done, fileList) {
          console.log('index', index, fileList)
          var r = confirm("remove image")
          if (r == true) {
            done()
          } else {
            ''
          }
        },

        editImage (formData, index, fileList) {
          console.log('edit data', formData, index, fileList)
        },

        dataChange (data) {
          console.log(data)
        },

        closeUpdateModal(){
          window.$('#update-modal').modal('hide');
        },

        closeCreateModal(){
          window.$('#service-modal').modal('hide');
        },

        formatDate(date){
            return moment(date).fromNow();
        },

        formatDate2(date) {
            return moment(date).format('MMM DD, YYYY');
        },

        viewAddedImage(e) {
            var selectedFiles = e.target.files;
            for (let i = 0; i < selectedFiles.length; i++) {
                let img = {
                    src: URL.createObjectURL(selectedFiles[i]),
                    file: selectedFiles[i],
                }
                this.view_images.push(img);
            }
        },

        removeImage(index){
          this.view_images.splice(index, 1);
          const dt = new DataTransfer()
          const input = document.getElementById('images')
          const { files } = input
          
          for (let i = 0; i < files.length; i++) {
            const file = files[i]
            if (index !== i)
              dt.items.add(file) // here you exclude the file. thus removing it.
          }
          
          input.files = dt.files
        },

    },

     computed: {
        image_url: function() {
          return process.env.VUE_APP_IMAGE_URL;
        }
    },

    mounted: function () {
      this.$root.$on('action', ()=>{
        this.fetchData()
      })
    },
}
</script>