<template>
  <layout-default>
    <div class="Blog">
      <div style="background-color: navy" class="py-5">
        <div class="container">
          <div class="row">
            <div class="col-md-12 mx-auto">
              <h1 class="text-white font-weight-light">
                <span class="font-weight-bold">Blog</span> News
              </h1>
              <p class="mb-2 text-white-50"></p>
            </div>
          </div>
        </div>
      </div>
      <div class="py-5">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <div class="row">
                <div
                  v-for="(data, index) in details"
                  :key="index"
                  class="col-lg-4 col-md-4"
                >
                  <div
                    class="box shadow-sm rounded bg-white mb-3 blog-card border-0"
                  >
                    <router-link :to="'/blog/' + data.slug">
                      <img
                        v-for="image in data.images.slice(0, 1)"
                        :key="image.id"
                        class="card-img-top"
                        :src="image.path"
                        style="
                          height: 250px;
                          max-width: 100%;
                          object-fit: cover;
                        "
                        alt=""
                      />
                      <div class="card-body">
                        <span class="badge badge-primary">{{
                          data.category ? data.category.name : ''
                        }}</span>
                        <h6 class="text-dark">
                          {{ data.title.substring(0, 32) + '....' }}
                        </h6>
                        <!-- <p v-html="data.description.substring(0,200)+'....'" class="mb-0"></p> -->
                      </div>
                      <div class="card-footer border-0">
                        <p class="mb-0">
                          Posted {{ formatDate2(data.created_at) }}
                        </p>
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>
              <pagination
                style="float: right"
                :pagination="pagination"
                @prev="fetchData(pagination.prevPageUrl)"
                @next="fetchData(pagination.nextPageUrl)"
                v-if="details.length > 0"
              >
              </pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </layout-default>
</template>
<script>
import LayoutDefault from '@/layouts/default';
import axios from 'axios';
import moment from 'moment';
export default {
  name: 'Blog',
  components: { LayoutDefault },

  created() {
    this.fetchData();
    this.fetchFeaturedBlog();
  },

  watch: {
      $route: {
          immediate: true,
          handler(to) {
            document.title = to.meta.title;
          }
      },
  }, 

  data() {
    return {
      details: [],
      featured: [],
      pagination: {
        lastPage: '',
        currentPage: '',
        total: '',
        lastPageUrl: '',
        nextPageUrl: '',
        prevPageUrl: '',
        from: '',
        to: '',
      },
      show: false,
    };
  },

  methods: {
    fetchData(url = process.env.VUE_APP_API_URL + 'blog/all') {
      axios(url)
        .then((response) => {
          if (!response.data.status == 200) {
            return (this.errorMessage = 'Could not fetch data');
          }
          this.show = false;
          let data = response.data;
          this.details = data.data;
          this.configPagination(data);
        })
        .catch({});
    },

    fetchFeaturedBlog() {
      axios(process.env.VUE_APP_API_URL + 'blog/featured')
        .then((response) => {
          if (!response.data.status == 200) {
            return (this.errorMessage = 'Could not fetch data');
          }
          this.featured = response.data;
        })
        .catch({});
    },

    formatDate2(date) {
      return moment(date).format('MMMM DD, YYYY');
    },

    configPagination(data) {
      this.pagination.lastPage = data.last_page;
      this.pagination.currentPage = data.current_page;
      this.pagination.total = data.total;
      this.pagination.lastPageUrl = data.last_page_url;
      this.pagination.nextPageUrl = data.next_page_url;
      this.pagination.prevPageUrl = data.prev_page_url;
      this.pagination.from = data.from;
      this.pagination.to = data.to;
    },
  },

  computed: {
    image_url: function () {
      return process.env.VUE_APP_IMAGE_URL;
    },
  },
};
</script>
