<template>
    <div class="Login">
        <div class="bg-white">
            <div class="container">
                <div class="row justify-content-center align-items-center d-flex vh-100">
                    <div class="col-md-4 mx-auto" style="background-color: white">
                        <div class="osahan-login py-4">
                            <div class="text-center mb-4">
                                <a href="/"><img :src="image_url+'logo/'+company.logo" alt="" style="max-height: 130px"></a>
                                <h5 class="font-weight-bold mt-3">Welcome Back</h5>
                                <p class="text-muted">Don't miss your next opportunity. Sign in to stay updated on your professional world.</p>
                            </div>
                            <form id="login-form">
                                <div class="form-group">
                                    <label class="mb-1">Email</label>
                                    <div class="position-relative icon-form-control">
                                        <i class="feather-user position-absolute"></i>
                                        <input type="email" name="email" class="form-control">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="mb-1">Password</label>
                                    <div class="position-relative icon-form-control">
                                        <i class="feather-unlock position-absolute"></i>
                                        <input type="password" name="password" class="form-control">
                                    </div>
                                </div>
                                <div class="custom-control custom-checkbox mb-3">
                                    <input type="checkbox" class="custom-control-input" id="customCheck1">
                                    <label class="custom-control-label" for="customCheck1">Remember password</label>
                                </div>
                                <button v-if="loading" class="btn btn-primary btn-block text-uppercase" type="button"> loading...... </button>
                                <button v-else class="btn btn-primary btn-block text-uppercase" type="button" @click="login()"> Sign in </button>
                                <!-- <div class="text-center mt-3 border-bottom pb-3">
                                    <p class="small text-muted">Or login with</p>
                                    <div class="row">
                                        <div class="col-4">
                                            <button type="button" @click="AuthProvider('google')" class="btn btn-sm btn-outline-google btn-block"><i class="feather-google"></i> Google</button>
                                        </div>
                                        <div class="col-4">
                                            <button type="button" @click="AuthProvider('facebook')" class="btn btn-sm btn-outline-facebook btn-block"><i class="feather-facebook"></i> Facebook</button>
                                        </div>
                                        <div class="col-4">
                                            <button type="button" @click="AuthProvider('linkedin')" class="btn btn-sm btn-outline-facebook btn-block"><i class="feather-linkedin"></i> Linkedin</button>
                                        </div>
                                    </div>
                                </div> -->
                                <div class="py-3 d-flex align-item-center">
                                    <router-link to="/forgot-password">Forgot password?</router-link>
                                    <span class="ml-auto"> New to SC-Listing? <router-link class="font-weight-bold" to="/register">Join now</router-link></span>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import $ from "jquery";

export default {
    name: 'Login',
    components: {
        
    },

    created() {
        // console.log('REF ------',this, this.$store);
        this.fectchCompany();
    },

    watch: {
      $route: {
          immediate: true,
          handler(to) {
              document.title = to.meta.title || 'Login';
          }
      },
  }, 

    methods: {
        login () {
            this.loading = true;
            var form = $("#login-form")[0];
            var _data = new FormData(form);
            axios.post(process.env.VUE_APP_API_URL + 'auth/login', _data)
            .then((response) => {
            if (response.status === 200) {
                this.$toastr.s(response.message);
                // this.fetchUser();
                localStorage.setItem('user', response.data.data.id);
                localStorage.setItem('slug', response.data.data.slug);
                this.$router.push('/feeds')
                this.$router.go();
                // this.$router.push({ name: 'Feed', query: { redirect: '/feeds' } });
                this.loading = false;
            } else {
                this.$toastr.e(response.data.message);
                this.loading = false;
            }
            }).catch((error) =>{
                this.$toastr.e(error.response.data.message);
                this.loading = false;
            })
        },

        fetchUser () {
            axios(process.env.VUE_APP_API_URL + 'user/details/'+localStorage.getItem('user')).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.user = response.data;
                try {
                    console.log(this, this.$store);
                    this.$store.dispatch('user/setUser', this.user);
                } catch (error) {
                    console.log(error);
                }
            }).catch({});
        },

        fectchCompany () {
            axios(process.env.VUE_APP_API_URL + 'company').then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.company = response.data;
            }).catch({});
        },

        AuthProvider(provider) {

            var self = this

            this.$auth.authenticate(provider).then(response =>{

            self.SocialLogin(provider,response)

            }).catch(err => {
                console.log({err:err})
            })

        },

        SocialLogin(provider,response){

            this.$http.get(process.env.VUE_APP_BASE_URL + 'social/login/'+provider,response).then(response => {

                console.log(response.data)

            }).catch(err => {
                console.log({err:err})
            })
        },
    },
    data() {
        return {
            company: {},
            loading: false
        }
    },
    computed: {
        image_url: function() {
            return process.env.VUE_APP_IMAGE_URL;
        }
    },
}
</script>