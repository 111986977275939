export default {
    namespace: true,
    state: {
        user: null,
    },

    mutations: {
        SET_USER(state, payload) {
          state.user = payload
        }
    },

    actions: {
        setUser(context, payload) {
          context.commit('SET_USER', payload)
        }
    },

    getters: {
        user: function (state) {
          return state.user
        }
    }
}