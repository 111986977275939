<template>
    <div class="ForgotPassword">
        <div class="bg-white">
            <div class="container">
                <div class="row justify-content-center align-items-center d-flex vh-100">
                    <div class="col-md-4 mx-auto">
                        <div class="osahan-login py-4">
                            <div class="text-center mb-4">
                                <a><img :src="image_url+'logo/'+company.logo" alt="" style="max-height: 130px"></a>
                                <h5 class="font-weight-bold mt-3">Forgot Account Password</h5>
                                <p class="text-muted">We will be sending a code to your email to reset your password</p>
                            </div>
                            <form id="submit-form">
                                <div class="form-group">
                                    <label class="mb-1">Registered Email</label>
                                    <div class="position-relative icon-form-control">
                                        <i class="feather-at-sign position-absolute"></i>
                                        <input type="email" name="email" class="form-control">
                                    </div>
                                </div>
                                <button v-if="loading" class="btn btn-primary btn-block text-uppercase" type="button"> loading..........</button>
                                <button v-else class="btn btn-primary btn-block text-uppercase" type="button" @click="submitForm()"> Submit</button>
                                
                                <div class="py-3 d-flex align-item-center">
                                    <span class="ml-auto"> Have remembered your details? <router-link class="font-weight-bold" to="/login">Login Here</router-link></span>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import $ from "jquery";

export default {
    name: 'ForgotPassword',
    components: {
        
    },

    created() {
        this.fectchCompany();
    },

    watch: {
      $route: {
          immediate: true,
          handler(to) {
              document.title = to.meta.title || 'Forgot Password';
          }
      },
  }, 

    methods: {
        submitForm () {
                this.loading = true;
                var form = $("#submit-form")[0];
                var _data = new FormData(form);
                axios.post(process.env.VUE_APP_API_URL + 'auth/forgot-password', _data)
            .then((response) => {
            if (response.status === 200) {
                this.$toastr.s(response.message);
                this.$router.push({ name: 'Login', query: { redirect: '/login' } });
                this.loading = false;
            } else {
                this.$toastr.e(response.data.message);
                this.loading = false;
            }
            }).catch((error) =>{
                this.$toastr.e(error.response.data.message);
                this.loading = false;
            })
        },

        fectchCompany () {
            axios(process.env.VUE_APP_API_URL + 'company').then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.company = response.data;
            }).catch({});
        },
    },
    data() {
        return {
            company: {},
            loading: false
        }
    },
    computed: {
        image_url: function() {
            return process.env.VUE_APP_IMAGE_URL;
        }
    },
}
</script>