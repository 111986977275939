<template>
  <layout-default>
    <div class="Home">
      <div class="py-4">
        <div class="">
          <div class="info d-flex flex-lg-row flex-column-reverse">
            <div class="left py-5 d-flex justify-content-center">
              <div class="left-container">
                <div class="user-info d-flex justify-content-center py-5">
                  <div class="left-container2">
                    <div class="d-flex justify-content-center align-items-center">
                      <a v-if="user.avatar" @click="() => showSingle(user.avatar)"><img class="img-fluid mt-2 rounded-circle" :src="image_url+'users/'+user.avatar" style="max-height: 150px; max-width: 150px" alt=""></a>
                      <a v-else @click="() => showSingle(image_url+'assets/avatar.png')"><img class="img-fluid mt-2 rounded-circle" src="/assets/avatar.png" style="max-height: 150px; max-width: 150px" alt=""></a>
                    </div>
                    <div class="mt-3">
                      <h2>{{user.name}}</h2>
                      <p v-if="user.currentcompany" style="text-align: center" class="mb-0 text-muted">{{user.current_position}} <br> <router-link :to="'/company/'+user.currentcompany.slug">{{user.currentcompany.name}}</router-link></p>
                      <p v-else style="text-align: center" class="mb-0 text-muted">{{user.profession}}</p>
                      <center>
                      <span v-if="user.membership" class="text-info"><span v-if="user.membership.membership">{{ user.membership.membership.code }}</span><i v-if="user.membership.status == 1" data-toggle="tooltip" data-placement="top" title="Verified" class="feather-check-circle"></i></span> &nbsp;
                      <span v-if="user.certification" class="text-info"><span v-if="user.certification.certification">{{ user.certification.certification.code }}</span><i v-if="user.certification.status == 1" data-toggle="tooltip" data-placement="top" title="Verified" class="feather-check-circle"></i></span>
                      </center>
                      <div class="d-flex text-center my-3">
                        <div style="width: 50%; border-right: 1px solid black">
                          <h3>{{total_followers}}</h3>
                          <h4>Followers</h4>
                        </div>
                        <div style="width: 50%">
                          <h3>{{total_project}}</h3>
                          <h4>Projects</h4>
                        </div>
                      </div>
                      <div class="text-center">
                        <router-link :to="'/sc/profile/'+id" class="btn btn-danger rounded-pill px-3">
                          View my profile
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="blog position-relative my-5 d-flex justify-content-center align-items-center" >
                  <h1 class="text-white">blog</h1>
                  <div class="overlay position-absolute"></div>
                </div> -->
              </div>
            </div>

            <div class="d-flex justify-content-end right" style="width: 100%; margin-top: 10px">
              
              <div class="main-content d-flex justify-content-center">
                <div class="main-container">
                  <div style="margin-bottom: 20px">
                    <button type="button" data-toggle="modal" data-target="#project-modal" class="btn btn-outline-primary btn-sm"> <i class="feather-plus mr-2"></i> Add Listing</button>
                  </div>
                  <div class="right-row">
                    <div v-for="(data, index) in feeds" :key="index" :class="data.type == 'project'?'longstyle':'shortstyle'">
                      <div v-if="data.type == 'project'" class="group">
                        <span v-if="data.project">
                        <div v-if="data.project" class="longstyleimg">
                          <router-link v-for="image in data.project.images.slice(0,1)" :key="image.id" :to="'/project/'+data.project.slug">
                          <img
                            :src="image.path"
                            alt=""
                            style="
                              width: 100%;
                              height: 100%;
                              border-radius: 30px;
                              object-fit: cover;
                            "
                          />
                        </router-link>
                        </div>
                        <span class="badge badge-success">{{data.type}}</span>
                        <p class="my-2"><router-link :to="'/project/'+data.project.slug">{{data.project.name.length > 50 ? data.project.name.substring(0,50)+'...' : data.project.name}}</router-link></p>
                        <div class="d-flex align-items-center">
                          <div class="p-2 d-flex justify-content-center align-items-center">
                            <img v-if="data.user.avatar" class="rounded-circle" :src="image_url+'users/'+data.user.avatar" style="max-height: 30px; max-width: 30px" alt="">
                            <img v-else class="rounded-circle" src="/assets/avatar.png" style="max-height: 30px; max-width: 30px" alt="">
                            <!-- <span class="text-white">VC</span> -->
                          </div>
                          <h5 v-if="data.user.company" class="ms-2"><router-link :to="'/company/'+data.user.company.slug" style="color: black">{{data.user?data.user.name:''}}</router-link></h5>
                          <h5 v-else class="ms-2"><router-link :to="'/sc/profile/'+data.user.slug" style="color: black">{{data.user?data.user.name:''}}</router-link></h5>
                        </div>
                        </span>
                      </div>

                      <div v-else-if="data.type == 'property'" class="group">
                        <span v-if="data.post">
                        <div class="shortstyleimg">
                          <router-link v-for="image in data.post.images.slice(0,1)" :key="image.id" :to="'/post/'+data.post.slug">
                          <img
                            :src="image.path"
                            alt=""
                            style="
                              width: 100%;
                              height: 100%;
                              border-radius: 30px;
                              object-fit: cover;
                            "
                          />
                        </router-link>
                        </div>
                        <span class="badge badge-info">{{data.type}}</span>
                        <p class="my-2"><router-link :to="'/post/'+data.post.slug">{{data.post.name.length > 50 ? data.post.name.substring(0,50)+'...' : data.post.name}}</router-link></p>
                        <div class="d-flex align-items-center">
                          <div class="p-2 d-flex justify-content-center align-items-center">
                            <img v-if="data.user.avatar" class="rounded-circle" :src="image_url+'users/'+data.user.avatar" style="max-height: 30px; max-width: 30px" alt="">
                            <img v-else class="rounded-circle" src="/assets/avatar.png" style="max-height: 30px; max-width: 30px" alt="">
                            <!-- <span class="text-white">VC</span> -->
                          </div>
                          <h5 v-if="data.user.company" class="ms-2"><router-link :to="'/company/'+data.user.company.slug" style="color: black">{{data.user?data.user.name:''}}</router-link></h5>
                          <h5 v-else class="ms-2"><router-link :to="'/sc/profile/'+data.user.slug" style="color: black">{{data.user?data.user.name:''}}</router-link></h5>
                        </div>
                      </span>
                      </div>

                      <div v-else-if="data.type == 'plan'" class="group">
                        <span v-if="data.plan">
                        <div class="shortstyleimg">
                          <router-link v-for="image in data.plan.images.slice(0,1)" :key="image.id" :to="'/plan/'+data.plan.slug">
                          <img
                            :src="image.path"
                            alt=""
                            style="
                              width: 100%;
                              height: 100%;
                              border-radius: 30px;
                              object-fit: cover;
                            "
                          />
                        </router-link>
                        </div>
                        <span class="badge badge-danger">Design</span>
                        <p class="my-2"><router-link :to="'/plan/'+data.plan.slug">{{data.plan.name.length > 50 ? data.plan.name.substring(0,50)+'...' : data.plan.name}}</router-link></p>
                        <div class="d-flex align-items-center">
                          <div class="p-2 d-flex justify-content-center align-items-center">
                            <img v-if="data.user.avatar" class="rounded-circle" :src="image_url+'users/'+data.user.avatar" style="max-height: 30px; max-width: 30px" alt="">
                            <img v-else class="rounded-circle" src="/assets/avatar.png" style="max-height: 30px; max-width: 30px" alt="">
                            <!-- <span class="text-white">VC</span> -->
                          </div>
                          <h5 v-if="data.user.company" class="ms-2"><router-link :to="'/company/'+data.user.company.slug" style="color: black">{{data.user?data.user.name:''}}</router-link></h5>
                          <h5 v-else class="ms-2"><router-link :to="'/sc/profile/'+data.user.slug" style="color: black">{{data.user?data.user.name:''}}</router-link></h5>
                        </div>
                        </span>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>

          <vue-easy-lightbox
            :visible="visible"
            :imgs="imgs"
            :index="index"
            @hide="handleHide"
          ></vue-easy-lightbox>

          <div v-if="feeds.length" class="box border rounded mb-3">
            <div class="infinite-wrapper">
              <infinite-loading
                force-use-infinite-wrapper=".infinite-wrapper"
                @distance="3"
                @infinite="fetchData2"
              ></infinite-loading>
            </div>
            <!-- <center><button type="button" @click="fetchData()" class="btn btn-outline-primary btn-sm">Load More</button></center> -->
          </div>
        </div>
      </div>

      <!-- Modal -->
      <div class="modal fade" id="project-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">New Listing</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div id="inprogress" class="border rounded bg-white mb-3">
                <div v-if="created == false" class="box-body p-3">
                  <div class="row">
                    <div class="col-md-12 mb-12 mb-sm-12">
                      <div class="js-form-message">
                        <div class="form-group">
                          <label id="nameLabel" class="form-label">Select Your Listing Type
                            <span class="text-danger">*</span>
                          </label>
                          <select class="form-control custom-select" v-model="type" data-msg="Please select type." data-error-class="u-has-error" data-success-class="u-has-success">
                            <option value="" disabled selected>Select Type</option>
                            <option value="project">Project</option>
                            <option value="property">Property</option>
                            <option value="plan">Design</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div v-if="type == 'project' && created == false">
                  <div class="box-title border-bottom p-3">
                    <h6 class="m-0">Supply Project Details</h6>
                    <p class="mb-0 mt-0 small">add your project details .</p>
                  </div>
                  <div class="box-body p-3">
                    <form id="create-form" class="js-validate" novalidate="novalidate">
                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Project Name
                            <span class="text-danger">*</span>
                            </label>
                            <div class="form-group">
                              <input type="text" class="form-control" name="name" v-model="project.name" required="">
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Project Description
                            <span class="text-danger">*</span>
                            </label>
                            <div class="form-group">
                              <vue-editor id="property_editor" v-model="project.description" :editorToolbar="customToolbar"></vue-editor>
                              <!-- <textarea type="text" class="form-control" name="description" rows="5" v-model="project.description" required></textarea> -->
                            </div>
                          </div>
                        </div>
                      </div>

                      <label class="form-label">Project Category
                      <span class="text-danger">*</span>
                      </label>
                      <div class="row">
                        <div class="col-md-4 mb-3 mb-sm-4">
                          <div class="js-form-message">
                            <div class="form-group">
                              <select class="form-control custom-select" name="project_interest_1" v-model="project.project_interest_1" data-msg="Please select country." data-error-class="u-has-error" data-success-class="u-has-success" @change='fectchInterestChildren($event)'>
                                <option value="" disabled selected>select Category</option>
                                <option v-for="data in interests" :key="data.id" :value="data.id">{{data.name}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 mb-3 mb-sm-4">
                          <div v-if="interest_children.length > 0" class="js-form-message">
                            <div class="form-group">
                              <select class="form-control custom-select" name="project_interest_2" v-model="project.project_interest_2" data-msg="Please select state." data-error-class="u-has-error" data-success-class="u-has-success" @change='fectchInterestChildren2($event)'>
                                <option value="" disabled selected>select category</option>
                                <option v-for="data in interest_children" :key="data.id" :value="data.id">{{data.name}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 mb-3 mb-sm-4">
                          <div v-if="interest_children2.length > 0" class="js-form-message">
                            <div class="form-group">
                              <select class="form-control custom-select" name="project_interest_3" v-model="project.project_interest_3" data-msg="Please select city." data-error-class="u-has-error" data-success-class="u-has-success">
                                <option value="" disabled selected>select category</option>
                                <option v-for="data in interest_children2" :key="data.id" :value="data.id">{{data.name}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-4 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Start Date</label>
                            <div class="form-group">
                              <input type="date" class="form-control" name="project_start_date" v-model="project.project_start_date" required="">
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-4 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Project Status
                            <span class="text-danger">*</span>
                            </label>
                            <div class="form-group">
                              <select type="date" class="form-control" name="status" v-model="project.status" required="">
                                <option value="" disabled selected>select option</option>
                                <option value="1">Completed</option>
                                <option value="2">On-going</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-4 mb-2">
                          <div v-if="project.status == 1" class="js-form-message">
                            <label id="nameLabel" class="form-label">End Date</label>
                            <div class="form-group">
                              <input type="date" class="form-control" name="project_end_date" v-model="project.project_end_date" required="">
                            </div>
                          </div>
                        </div>
                      </div>

                      <label class="form-label">Project Location
                      <span class="text-danger">*</span>
                      </label>
                      <div class="row">
                        <div class="col-md-4 mb-3 mb-sm-4">
                          <div class="js-form-message">
                            <div class="form-group">
                              <select class="form-control custom-select" name="country_id" v-model="project.country_id" data-msg="Please select country." data-error-class="u-has-error" data-success-class="u-has-success" @change='fetchStates($event)'>
                                <option value="" disabled selected>Country</option>
                                <option v-for="data in country" :key="data.id" :value="data.id">{{data.name}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 mb-3 mb-sm-4">
                          <div class="js-form-message">
                            <div class="form-group">
                              <select class="form-control custom-select" name="state_id" v-model="project.state_id" data-msg="Please select state." data-error-class="u-has-error" data-success-class="u-has-success" @change='fetchCities($event)'>
                                <option value="" disabled selected>State</option>
                                <option v-for="data in state" :key="data.id" :value="data.id">{{data.name}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 mb-3 mb-sm-4">
                          <div class="js-form-message">
                            <div class="form-group">
                              <select class="form-control custom-select" name="city_id" v-model="project.city_id" data-msg="Please select city." data-error-class="u-has-error" data-success-class="u-has-success">
                                <option value="" disabled selected>City</option>
                                <option v-for="data in city" :key="data.id" :value="data.id">{{data.name}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Address</label>
                            <div class="form-group">
                              <textarea class="form-control" name="address" v-model="project.address"></textarea>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Height</label>
                            <div class="form-group">
                              <select class="form-control" name="height" v-model="project.height">
                                <option value="" disabled selected>~select~</option>
                                <option value="Low rise (Less than 3 Storeys)">Low rise (Less than 3 Storeys)</option>
                                <option value="Mid rise (4 - 10 Storeys)">Mid rise (4 - 10 Storeys)</option>
                                <option value="High rise (11 - 30 Storeys)">High rise (11 - 30 Storeys)</option>
                                <option value="Skyscrappers (above 30 storeys)">Skyscrappers (above 30 storeys)</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Project Client/Developer</label>
                            <div class="form-group">
                              <multiselect v-model="project.client" label="name" placeholder="Select one" :options="users" :allow-empty="true"></multiselect>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Project Architect
                            <span style="font-size: 12px"><a style="cursor:pointer" @click="addArchitectTeam">Add Team Member</a></span>
                            </label>
                            <div class="form-group">
                              <multiselect v-model="project.architect" label="name" placeholder="Select one" :options="users" :allow-empty="true"></multiselect>

                              <div class="row" v-for="(input, index) in project.architect_team" :key="index">
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6" style="padding-top: 12px">
                                  <model-list-select :list="users" v-model="input.user_id" option-value="id" option-text="name" placeholder="search team"></model-list-select>
                                </div>
                                <div class="col-lg-2" style="padding-top: 12px">
                                  <button type="button" @click="deleteArchitectTeam(index)" style="color: red; border:0">X</button>
                                </div>
                                <div class="col-lg-2"></div>
                                <br>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Project Structural Engineer
                            <span style="font-size: 12px"><a style="cursor:pointer" @click="addStructuralEngineerTeam">Add Team Member</a></span>
                            </label>
                            <div class="form-group">
                              <multiselect v-model="project.structural_engineer" label="name" placeholder="Select one" :options="users" :allow-empty="true"></multiselect>

                              <div class="row" v-for="(input, index) in project.structural_engineer_team" :key="index">
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6" style="padding-top: 12px">
                                  <model-list-select :list="users" v-model="input.user_id" option-value="id" option-text="name" placeholder="search team"></model-list-select>
                                </div>
                                <div class="col-lg-2" style="padding-top: 12px">
                                  <button type="button" @click="deleteStructuralEngineerTeam(index)" style="color: red; border:0">X</button>
                                </div>
                                <div class="col-lg-2"></div>
                                <br>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Project Mep Engineer
                            <span style="font-size: 12px"><a style="cursor:pointer" @click="addMepEngineerTeam">Add Team Member</a></span>
                            </label>
                            <div class="form-group">
                              <multiselect v-model="project.mep_engineer" label="name" placeholder="Select one" :options="users" :allow-empty="true"></multiselect>

                              <div class="row" v-for="(input, index) in project.mep_engineer_team" :key="index">
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6" style="padding-top: 12px">
                                  <model-list-select :list="users" v-model="input.user_id" option-value="id" option-text="name" placeholder="search team"></model-list-select>
                                </div>
                                <div class="col-lg-2" style="padding-top: 12px">
                                  <button type="button" @click="deleteMepEngineerTeam(index)" style="color: red; border:0">X</button>
                                </div>
                                <div class="col-lg-2"></div>
                                <br>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Project Quantity Surveyor
                            <span style="font-size: 12px"><a style="cursor:pointer" @click="addSurveyorTeam">Add Team Member</a></span>
                            </label>
                            <div class="form-group">
                               <multiselect v-model="project.quantity_surveyor" label="name" placeholder="Select one" :options="users" :allow-empty="true"></multiselect>

                              <div class="row" v-for="(input, index) in project.quantity_surveyor_team" :key="index">
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6" style="padding-top: 12px">
                                  <model-list-select :list="users" v-model="input.user_id" option-value="id" option-text="name" placeholder="search team"></model-list-select>
                                </div>
                                <div class="col-lg-2" style="padding-top: 12px">
                                  <button type="button" @click="deleteSurveyorTeam(index)" style="color: red; border:0">X</button>
                                </div>
                                <div class="col-lg-2"></div>
                                <br>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Project Main Contractor <span class="text-danger">*</span>
                            <span style="font-size: 12px"><a style="cursor:pointer" @click="addMainContractorTeam">Add Team Member</a></span>
                            </label>
                            <div class="form-group">
                              <multiselect v-model="project.main_contractor" label="name" placeholder="Select one" :options="users" :allow-empty="true"></multiselect>

                              <div class="row" v-for="(input, index) in project.main_contractor_team" :key="index">
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6" style="padding-top: 12px">
                                  <model-list-select :list="users" v-model="input.user_id" option-value="id" option-text="name" placeholder="search team"></model-list-select>
                                </div>
                                <div class="col-lg-2" style="padding-top: 12px">
                                  <button type="button" @click="deleteMainContractorTeam(index)" style="color: red; border:0">X</button>
                                </div>
                                <div class="col-lg-2"></div>
                                <br>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Project Mechanical Contractor
                            <span style="font-size: 12px"><a style="cursor:pointer" @click="addMechanicalContractorTeam">Add Team Member</a></span>
                            </label>
                            <div class="form-group">
                              <multiselect v-model="project.mechanical_contractor" label="name" placeholder="Select " :options="users" :allow-empty="true"></multiselect>
                              
                              <div class="row" v-for="(input, index) in project.mechanical_contractor_team" :key="index">
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6" style="padding-top: 12px">
                                  <model-list-select :list="users" v-model="input.user_id" option-value="id" option-text="name" placeholder="search team"></model-list-select>
                                </div>
                                <div class="col-lg-2" style="padding-top: 12px">
                                  <button type="button" @click="deleteMechanicalContractorTeam(index)" style="color: red; border:0">X</button>
                                </div>
                                <div class="col-lg-2"></div>
                                <br>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Project Electrical Contractor
                            <span style="font-size: 12px"><a style="cursor:pointer" @click="addElectricalContractorTeam">Add Team Member</a></span>
                            </label>
                            <div class="form-group">
                              <multiselect v-model="project.electrical_contractor" label="name" placeholder="Select one" :options="users" :allow-empty="true"></multiselect>       													

                              <div class="row" v-for="(input, index) in project.electrical_contractor_team" :key="index">
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6" style="padding-top: 12px">
                                  <model-list-select :list="users" v-model="input.user_id" option-value="id" option-text="name" placeholder="search team"></model-list-select>
                                </div>
                                <div class="col-lg-2" style="padding-top: 12px">
                                  <button type="button" @click="deleteElectricalContractorTeam(index)" style="color: red; border:0">X</button>
                                </div>
                                <div class="col-lg-2"></div>
                                <br>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Piling Contractor
                            <span style="font-size: 12px"><a style="cursor:pointer" @click="addPilingTeam">Add Team Member</a></span>
                            </label>
                            <div class="form-group">
                              <multiselect v-model="project.piling_contractor" label="name" placeholder="Select one" :options="users" :allow-empty="true"></multiselect>

                              <div class="row" v-for="(input, index) in project.piling_contractor_team" :key="index">
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6" style="padding-top: 12px">
                                  <model-list-select :list="users" v-model="input.user_id" option-value="id" option-text="name" placeholder="search team"></model-list-select>
                                </div>
                                <div class="col-lg-2" style="padding-top: 12px">
                                  <button type="button" @click="deletePilingTeam(index)" style="color: red; border:0">X</button>
                                </div>
                                <div class="col-lg-2"></div>
                                <br>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Lift Contractor
                            <span style="font-size: 12px"><a style="cursor:pointer" @click="addLiftTeam">Add Team Member</a></span>
                            </label>
                            <div class="form-group">
                              <multiselect v-model="project.lift_contractor" label="name" placeholder="Select one" :options="users" :allow-empty="true"></multiselect>

                              <div class="row" v-for="(input, index) in project.lift_contractor_team" :key="index">
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6" style="padding-top: 12px">
                                  <model-list-select :list="users" v-model="input.user_id" option-value="id" option-text="name" placeholder="search team"></model-list-select>
                                </div>
                                <div class="col-lg-2" style="padding-top: 12px">
                                  <button type="button" @click="deleteLiftTeam(index)" style="color: red; border:0">X</button>
                                </div>
                                <div class="col-lg-2"></div>
                                <br>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Project Manager
                            <span style="font-size: 12px"><a style="cursor:pointer" @click="addPMTeam">Add Team Member</a></span>
                            </label>
                            <div class="form-group">
                              <multiselect v-model="project.project_manager" label="name" placeholder="Select one" :options="users" :allow-empty="true"></multiselect>

                              <div class="row" v-for="(input, index) in project.project_manager_team" :key="index">
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6" style="padding-top: 12px">
                                  <model-list-select :list="users" v-model="input.user_id" option-value="id" option-text="name" placeholder="search team"></model-list-select>
                                </div>
                                <div class="col-lg-2" style="padding-top: 12px">
                                  <button type="button" @click="deletePMTeam(index)" style="color: red; border:0">X</button>
                                </div>
                                <div class="col-lg-2"></div>
                                <br>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Project Vendors
                            <span style="font-size: 12px"><a style="cursor:pointer" @click="addVendor">Add Vendor</a></span>
                            </label>
                            <div class="form-group">
                              <div class="row" v-for="(input, index) in project.project_vendors" :key="index">
                                <div class="col-lg-5" style="padding-top: 12px">
                                  <model-list-select :list="vendor_category" v-model="input.vendor" option-value="id" option-text="name" placeholder="select category"></model-list-select>
                                </div>
                                <div class="col-lg-5" style="padding-top: 12px">
                                  <model-list-select :list="users" v-model="input.user_id" option-value="id" option-text="name" placeholder="search team"></model-list-select>
                                </div>
                                <div class="col-lg-2" style="padding-top: 12px">
                                  <button type="button" @click="deleteVendor(index)" style="color: red; border:0">X</button>
                                </div>
                                <br>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Add Project Images
                            <span class="text-danger">* </span>
                            </label>
                            <div id="my-strictly-unique-vue-upload-multiple-image" style="display: flex; justify-content: center;">
                              <vue-upload-multiple-image
                                @upload-success="uploadImageSuccess"
                                @before-remove="beforeRemove"
                                @edit-image="editImage"
                                @data-change="dataChange"
                                :data-images="images"
                                dragText="Drag and drop images"
                                browseText="Browse Images"
                                primaryText="added"
                                markIsPrimaryText="	Set default image"
                                popupText="Description default image"
                                :maxImage=5
                                ></vue-upload-multiple-image>

                            </div>
                          </div>
                        </div>
                      </div>

                    </form>
                  </div>
                </div>






                <div v-if="type == 'property' && created == false">
                  <div class="box-title border-bottom p-3">
                    <h6 class="m-0">Supply Property Details</h6>
                    <p class="mb-0 mt-0 small">add your property details .</p>
                  </div>
                  <div class="box-body p-3">
                    <form id="create-form" class="js-validate" novalidate="novalidate">
                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Property Name
                            <span class="text-danger">*</span>
                            </label>
                            <div class="form-group">
                              <input type="text" class="form-control" name="name" v-model="property.name" required="">
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Property Description
                            <span class="text-danger">*</span>
                            </label>
                            <div class="form-group">
                              <vue-editor id="property_editor" v-model="property.description" :editorToolbar="customToolbar"></vue-editor>
                              <!-- <textarea type="text" class="form-control summernote" name="description" rows="5" v-model="property.description" required></textarea> -->
                            </div>
                          </div>
                        </div>
                      </div>

                      <label class="form-label">Category
                      <span class="text-danger">*</span>
                      </label>
                      <div class="row">
                        <div class="col-md-4 mb-3 mb-sm-4">
                          <div class="js-form-message">
                            <div class="form-group">
                              <select class="form-control custom-select" name="project_interest_1" v-model="property.project_interest_1" @change='fectchInterestChildren($event)'>
                                <option value="" disabled selected>select Category</option>
                                <option v-for="data in property_interests" :key="data.id" :value="data.id">{{data.name}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 mb-3 mb-sm-4">
                          <div v-if="interest_children.length > 0" class="js-form-message">
                            <div class="form-group">
                              <select class="form-control custom-select" name="project_interest_2" v-model="property.project_interest_2" @change='fectchInterestChildren2($event)'>
                                <option value="" disabled selected>select category</option>
                                <option v-for="data in interest_children" :key="data.id" :value="data.id">{{data.name}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 mb-3 mb-sm-4">
                          <div v-if="interest_children2.length > 0" class="js-form-message">
                            <div class="form-group">
                              <select class="form-control custom-select" name="project_interest_3" v-model="property.project_interest_3">
                                <option value="" disabled selected>select category</option>
                                <option v-for="data in interest_children2" :key="data.id" :value="data.id">{{data.name}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Property Type
                            <span class="text-danger">*</span>
                            </label>
                            <div class="form-group">
                              <select type="date" class="form-control" name="type" v-model="property.type" required="">
                                <option value="" disabled selected>select option</option>
                                <option value="Residential">Residential</option>
                                <option value="Commercial">Commercial</option>
                                <option value="Residential | Commercial">Residential | Commercial</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <label class="form-label">Property Location
                      <span class="text-danger">*</span>
                      </label>
                      <div class="row">
                        <div class="col-md-4 mb-3 mb-sm-4">
                          <div class="js-form-message">
                            <div class="form-group">
                              <select class="form-control custom-select" name="country_id" v-model="property.country_id" data-msg="Please select country." data-error-class="u-has-error" data-success-class="u-has-success" @change='fetchStates($event)'>
                                <option value="" disabled selected>Country</option>
                                <option v-for="data in country" :key="data.id" :value="data.id">{{data.name}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 mb-3 mb-sm-4">
                          <div class="js-form-message">
                            <div class="form-group">
                              <select class="form-control custom-select" name="state_id" v-model="property.state_id" data-msg="Please select state." data-error-class="u-has-error" data-success-class="u-has-success" @change='fetchCities($event)'>
                                <option value="" disabled selected>State</option>
                                <option v-for="data in state" :key="data.id" :value="data.id">{{data.name}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 mb-3 mb-sm-4">
                          <div class="js-form-message">
                            <div class="form-group">
                              <select class="form-control custom-select" name="city_id" v-model="property.city_id" data-msg="Please select city." data-error-class="u-has-error" data-success-class="u-has-success">
                                <option value="" disabled selected>City</option>
                                <option v-for="data in city" :key="data.id" :value="data.id">{{data.name}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mb-3 mb-sm-12">
                          <div class="js-form-message">
                            <div class="form-group">
                              <label class="form-label">Address</label>
                              <input type="text" class="form-control" name="address" v-model="property.address">
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-12 mb-3 mb-sm-12">
                          <div class="js-form-message">
                            <div class="form-group">
                              <label class="form-label">Currency</label>
                              <select class="form-control custom-select" name="currency_id" v-model="property.currency_id">
                                <option v-for="data in currency" :key="data.id" :value="data.id">{{data.symbol}} - {{data.name}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="form-group mb-0">
                        <div class="custom-control custom-radio d-inline mr-3">
                          <input type="radio" v-model="price_type" value="single" class="custom-control-input" id="customCheck1" checked>
                          <label class="custom-control-label" for="customCheck1">Single Price</label>
                        </div>
                        <div class="custom-control custom-radio d-inline">
                          <input type="radio" v-model="price_type" value="multiple" class="custom-control-input" id="customCheck2">
                          <label class="custom-control-label" for="customCheck2">Different Prices</label>
                        </div>
                      </div><br>
                      
                      <div v-if="price_type == 'single'" class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Property Price
                            <span class="text-danger">*</span>
                            </label>
                            <div class="form-group">
                              <input type="number" class="form-control" name="price" v-model="property.price">
                            </div>
                          </div>
                        </div>
                      </div>

                      <div v-if="price_type == 'multiple'" class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Property Prices
                            <span class="text-danger">* </span><span style="font-size: 12px"><a class="btn-sm btn-primary" style="cursor:pointer" @click="addPrice">Add Price</a></span>
                            </label>
                            <div class="form-group">
                              <div class="row" v-for="(input, index) in property.prices" :key="index">
                                <div class="col-lg-5" style="padding-top: 12px">
                                  <label class="form-label" style="font-size: 12px">Price Amount</label>
                                   <input type="text" placeholder="price" class="form-control" v-model="input.price" onkeypress="if ( isNaN(this.value + String.fromCharCode(event.keyCode) )) return false;">
                                </div>
                                <div class="col-lg-5" style="padding-top: 12px">
                                  <label class="form-label" style="font-size: 12px">Price Title</label>
                                   <input type="text" placeholder="price title" class="form-control" v-model="input.title">
                                </div>                                
                                <div class="col-lg-2" style="padding-top: 45px">
                                  <button type="button" @click="deletePrice(index)" style="color: red; border:0; background-color:white">X</button>
                                </div>
                                <br>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="form-group mb-0">
                        <div class="custom-control custom-checkbox d-inline mr-3">
                          <input type="checkbox" v-model="more_detail" value="1" class="custom-control-input" id="customCheck3">
                          <label class="custom-control-label" for="customCheck3">More Property Details</label>
                        </div>
                      </div><br>

                      <div v-if="more_detail == '1'" class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Property Details
                            <span style="font-size: 12px"><a class="btn-sm btn-primary" style="cursor:pointer" @click="addDetail">Add Detail</a></span>
                            </label>
                            <div class="form-group">
                              <div class="row" v-for="(input, index) in property.details" :key="index">
                                <div class="col-lg-5" style="padding-top: 12px">
                                  <label class="form-label" style="font-size: 12px">Title</label>
                                   <input type="text" class="form-control" v-model="input.title">
                                </div>
                                <div class="col-lg-5" style="padding-top: 12px">
                                  <label class="form-label" style="font-size: 12px">Description</label>
                                   <input type="text" class="form-control" v-model="input.description">
                                </div>
                                <div class="col-lg-2" style="padding-top: 45px">
                                  <button type="button" @click="deleteDetail(index)" style="color: red; border:0; background-color:white">X</button>
                                </div>
                                <br>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Add Property Images
                            <span class="text-danger">* </span>
                            </label>
                            <div id="my-strictly-unique-vue-upload-multiple-image" style="display: flex; justify-content: center;">
                              <vue-upload-multiple-image
                                @upload-success="uploadImageProperty"
                                @before-remove="beforeRemove"
                                @edit-image="editImage"
                                @data-change="dataChange"
                                :data-images="images"
                                dragText="Drag and drop images"
                                browseText="Browse Images"
                                primaryText="added"
                                markIsPrimaryText="	Set default image"
                                popupText="Description default image"
                                :maxImage=5
                                ></vue-upload-multiple-image>

                            </div>
                          </div>
                        </div>
                      </div>

                    </form>
                  </div>
                </div>







                <div v-if="type == 'plan' && created == false">
                  <div class="box-title border-bottom p-3">
                    <h6 class="m-0">Supply Plan Details</h6>
                    <p class="mb-0 mt-0 small">add your plan details .</p>
                  </div>
                  <div class="box-body p-3">
                    <form id="plan-form" class="js-validate" novalidate="novalidate">
                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Title
                            <span class="text-danger">*</span>
                            </label>
                            <div class="form-group">
                              <input type="text" class="form-control" name="title" v-model="plan.title" required="">
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label"> Description
                            <span class="text-danger">*</span>
                            </label>
                            <div class="form-group">
                              <vue-editor id="plan_editor" v-model="plan.description" :editorToolbar="customToolbar"></vue-editor>
                              <!-- <textarea type="text" class="form-control summernote" name="description" rows="5" v-model="plan.description" required></textarea> -->
                            </div>
                          </div>
                        </div>
                      </div>

                      <label class="form-label">Category
                      <span class="text-danger">*</span>
                      </label>
                      <div class="row">
                        <div class="col-md-4 mb-3 mb-sm-4">
                          <div class="js-form-message">
                            <div class="form-group">
                              <select class="form-control custom-select" name="project_interest_1" v-model="plan.project_interest_1" @change='fectchInterestChildren($event)'>
                                <option value="" disabled selected>select Category</option>
                                <option v-for="data in plan_interests" :key="data.id" :value="data.id">{{data.name}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 mb-3 mb-sm-4">
                          <div v-if="interest_children.length > 0" class="js-form-message">
                            <div class="form-group">
                              <select class="form-control custom-select" name="project_interest_2" v-model="plan.project_interest_2" @change='fectchInterestChildren2($event)'>
                                <option value="" disabled selected>select category</option>
                                <option v-for="data in interest_children" :key="data.id" :value="data.id">{{data.name}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 mb-3 mb-sm-4">
                          <div v-if="interest_children2.length > 0" class="js-form-message">
                            <div class="form-group">
                              <select class="form-control custom-select" name="project_interest_3" v-model="plan.project_interest_3">
                                <option value="" disabled selected>select category</option>
                                <option v-for="data in interest_children2" :key="data.id" :value="data.id">{{data.name}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Height</label>
                            <div class="form-group">
                              <select class="form-control" name="height" v-model="plan.height">
                                <option value="" disabled selected>~select~</option>
                                <option value="Low rise (Less than 3 Storeys)">Low rise (Less than 3 Storeys)</option>
                                <option value="Mid rise (4 - 10 Storeys)">Mid rise (4 - 10 Storeys)</option>
                                <option value="High rise (11 - 30 Storeys)">High rise (11 - 30 Storeys)</option>
                                <option value="Skyscrappers (above 30 storeys)">Skyscrappers (above 30 storeys)</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Land Size
                            <span class="text-danger">*</span>
                            </label>
                            <div class="row">
                              <div class="col-sm-6">
                                <div class="form-group">
                                  <input type="text" class="form-control" name="land_size" v-model="plan.land_size" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..?)\../g, '$1');" required="">
                                </div>
                              </div>
                              <div class="col-sm-6">
                                <div class="form-group">
                                  <select class="form-control" name="land_size_unit" v-model="plan.land_size_unit" required="">
                                    <option value="" disabled selected>select unit</option>
                                    <option value="M">M&#178;</option>
                                    <option value="Plot">Plot</option>
                                    <option value="Acre">Acre</option>
                                    <option value="Hectares">Hectares</option>
                                    <option value="KM">KM&#178;</option>
                                  </select>
                                </div>
                              </div>
                            </div>                            
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Add Plan Images
                            <span class="text-danger">* </span>
                            </label>
                            <div id="my-strictly-unique-vue-upload-multiple-image" style="display: flex; justify-content: center;">
                              <vue-upload-multiple-image
                                @upload-success="uploadImagePlan"
                                @before-remove="beforeRemove"
                                @edit-image="editImage"
                                @data-change="dataChange"
                                :data-images="images"
                                dragText="Drag and drop images"
                                browseText="Browse Images"
                                primaryText="added"
                                markIsPrimaryText="	Set default image"
                                popupText="Description default image"
                                :maxImage=5
                                ></vue-upload-multiple-image>

                            </div>
                          </div>
                        </div>
                      </div>

                    </form>
                  </div>
                </div>



              </div>

               <div id="success" style="display:none">
                  <div class="form-card">
                      <h2 class="fs-title text-center">Success !</h2> <br><br>
                      <div class="row justify-content-center">
                          <div class="col-3"> <img src="https://img.icons8.com/color/96/000000/ok--v2.png" class="fit-image"> </div>
                      </div> <br><br>
                      <div class="row justify-content-center">
                          <div class="col-7 text-center">
                              <h5>You Have Successfully Added A New Listing</h5>
                          </div>
                      </div>
                  </div>
              </div>
            </div>
            <div v-if="created" class="modal-footer">
              <div>
                <button type="button" @click="closeForm()" class="btn btn-secondary" data-dismiss="modal">Close</button>&nbsp;
              </div>
            </div>
            <div v-else class="modal-footer">
              <div v-if="loading">
                <button type="button" class="btn btn-primary" data-dismiss="modal">Loading.....</button>
              </div>
              <div v-else>
                <button type="button" @click="cancelForm()" class="btn btn-secondary" data-dismiss="modal">Close</button>&nbsp;
                <button type="button" @click="createProject()" class="btn btn-primary">Submit Listing</button>
              </div>
            </div>

          </div>
        </div>
      </div>





      <!-- likes Modal -->
      <div
        class="modal fade"
        id="post-likes"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <!-- <h5 class="modal-title"></h5> -->
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div v-if="currentData.likes" class="modal-body">
              <div
                v-for="data in currentData.likes"
                :key="data.id"
                class="p-3 d-flex align-items-center border-bottom osahan-post-header"
              >
                <div class="dropdown-list-image mr-3">
                  <a :href="'/sc/profile/' + data.user.slug">
                    <img
                      v-if="data.user.avatar"
                      class="rounded-circle"
                      :src="image_url + 'users/' + data.user.avatar"
                      style="max-height: 150px; max-width: 150px"
                      alt=""
                    />
                    <img
                      v-else
                      class="rounded-circle"
                      src="/assets/avatar.png"
                      style="max-height: 150px; max-width: 150px"
                      alt=""
                    />
                  </a>
                </div>
                <div class="font-weight-bold">
                  <div class="text-truncate">
                    <router-link :to="'/sc/profile/' + data.user.slug">{{
                      data.user.name
                    }}</router-link>
                  </div>
                  <div class="small text-gray-500">
                    {{ data.user.profession }}
                  </div>
                </div>
                <span class="ml-auto"
                  ><br /><button
                    type="button"
                    @click="follow(data.user.id)"
                    class="btn btn-outline-primary btn-sm"
                  >
                    Follow
                  </button></span
                >
              </div>
            </div>
            <!-- <div class="modal-footer">
              <div>
                <button type="button" @click="closeModal()" class="btn btn-secondary" data-dismiss="modal">Close</button>&nbsp;
              </div>
            </div> -->
          </div>
        </div>
      </div>

      <!-- comments Modal -->
      <div
        class="modal fade"
        id="post-comments"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div v-if="currentData.comments" class="modal-body">
              <div
                v-for="com in currentData.comments"
                :key="com.id"
                class="p-3 d-flex align-items-top border-bottom osahan-post-comment"
              >
                <div class="dropdown-list-image mr-3">
                  <img
                    v-if="com.user.avatar"
                    class="rounded-circle"
                    :src="image_url + 'users/' + com.user.avatar"
                    style="max-height: 150px; max-width: 150px"
                    alt=""
                  />
                  <img
                    v-else
                    class="rounded-circle"
                    src="/assets/avatar.png"
                    style="max-height: 150px; max-width: 150px"
                    alt=""
                  />
                  <div class="status-indicator bg-success"></div>
                </div>
                <div class="font-weight-bold">
                  <div class="text-truncate">
                    {{ com.user.name }}
                    <span class="float-right small">{{
                      formatDate(com.created_at)
                    }}</span>
                  </div>
                  <div class="small text-gray-500">{{ com.comment }}</div>
                </div>
              </div>
            </div>
            <!-- <div class="modal-footer">
              <div>
                <button type="button" @click="closeModal()" class="btn btn-secondary" data-dismiss="modal">Close</button>&nbsp;
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </layout-default>
</template>
<script>
  import LayoutDefault from '../../layouts/default.vue';
  import axios from 'axios';import 'vue-search-select/dist/VueSearchSelect.css'
  import VueUploadMultipleImage from 'vue-upload-multiple-image';
  import $ from "jquery";
  import moment from 'moment';
  import { ModelListSelect } from 'vue-search-select';
  import Multiselect from 'vue-multiselect';
  import InfiniteLoading from 'vue-infinite-loading';
  import VueEasyLightbox from 'vue-easy-lightbox';
  import { VueEditor } from "vue2-editor";
  
  export default {
    name: 'Feed',
    components: {
      LayoutDefault,
      ModelListSelect, 
      Multiselect,
      VueUploadMultipleImage,
      InfiniteLoading,
      VueEasyLightbox,
      VueEditor,
    },
  
    watch: {
        $route: {
            immediate: true,
            handler(to) {
                document.title = to.meta.title || 'Structurecity Services';
            }
        },
    },
  
    created() {
          this.fetchCompany();
          this.fetchData();
          this.fetchUsers();
          this.fetchUser();
          this.fetchCountries();
          this.fetchVendorCategory();
          this.fectchProjectInterests();
          this.fectchPropertyInterests();
          this.fectchPlanInterests();
          this.fetchProjects();
          this.fetchFollowers();
          this.fetchTotalProjects();
          this.fetchTotalFollowers();
          this.fetchCurrency();
    },
  
    data() {
          return {
            price_type: 'single',
            more_detail: '',
            page: 1,
            currentData: {},
            readMore: {},
            type: '',
            company: {},
            user: {},
            loading: false,
            created: false,
            id: localStorage.getItem('slug'),
            images: [],
            currency:[],
            country: [],
            state: [],
            city: [],
            users: [],
            projects:[],
            interests: [],
            property_interests: [],
            plan_interests: [],
            interest_children: [],
            interest_children2: [],
            vendor_category: [],            
            project_client: {},
            feeds: [],
            total_project: 0,
            total_followers: 0,
            followers: [],
  
            comment: {
              description: [],
            },
            
            project: {
              user_id: localStorage.getItem('user'),
              name: '',
              project_interest_1: '',
              project_interest_2: '',
              project_interest_3: '',
              description: '',
              project_start_date: '',
              project_end_date: '',
              country_id: 160,
              state_id: '',
              city_id: '',
              status: '',
              height: '',
              address: '',
              architect: '',
              structural_engineer: '',
              mep_engineer: '',
              client: '',
              quantity_surveyor: '',
              main_contractor: '',
              mechanical_contractor: '',
              electrical_contractor: '',
              piling_contractor: '',
              lift_contractor: '',
              project_manager: '',
              architect_team: [],
              structural_engineer_team: [],
              mep_engineer_team: [],
              quantity_surveyor_team: [],
              main_contractor_team: [],
              mechanical_contractor_team: [],
              electrical_contractor_team: [],
              piling_contractor_team: [],
              lift_contractor_team:[],
              project_manager_team:[],
              project_vendors: [],
              images:'',            
            },
  
            property: {
              user_id: localStorage.getItem('user'),
              name: '',
              project_interest_1: '',
              project_interest_2: '',
              project_interest_3: '',
              description: '',
              currency_id: '',
              country_id: 160,
              state_id: '',
              city_id: '',
              address: '',
              price: '',
              type: '',
              prices: [],
              details: [],
              images:'',
            },

            plan: {
              user_id: localStorage.getItem('user'),
              title: '',
              project_interest_1: '',
              project_interest_2: '',
              project_interest_3: '',
              description: '',
              height: '',
              land_size: '',
              land_size_unit: '',
              images:'',
            },

            imgs: '',
            visible: false,
            index: 0,
  
            customToolbar: [
              ["bold", "italic", "underline"],
              [{ list: "ordered" }, { list: "bullet" }],
              // ["image", "code-block"]
            ],
          }
    },   
  
    methods: {
          logout () {
            localStorage.clear();
            this.$router.go();
          },
  
          fetchData () {
              axios(process.env.VUE_APP_API_URL + 'user/feeds/'+localStorage.getItem('user')).then(response =>{
                  if (! response.status == 200) {
                      return this.errorMessage = 'Could not fetch data';
                  }
                  this.feeds = response.data.data;
                  this.page = this.page + 1;
                  this.fetchNigeriaStates();
                  this.fetchLagosCities();
              }).catch({});
          },
  
          fetchData2($state) {
            axios(process.env.VUE_APP_API_URL + 'user/feeds/'+localStorage.getItem('user')+'?page='+this.page)
                .then(res => {
                    $.each(res.data.data, (key, value) => {
                        this.feeds.push(value);
                    });
                    $state.loaded();
                    this.page = this.page + 1;
                    if(res.data.data.length <= 0){
                     $state.complete();
                    }
                });
          },
  
          fetchCompany () {
              axios(process.env.VUE_APP_API_URL + 'company').then(response =>{
                  if (! response.data.status == 200) {
                      return this.errorMessage = 'Could not fetch data';
                  }
                  this.company = response.data;
              }).catch({});
          },
  
          fetchCurrency () {
              axios(process.env.VUE_APP_API_URL + 'currency').then(response =>{
                  if (! response.data.status == 200) {
                      return this.errorMessage = 'Could not fetch data';
                  }
                  this.currency = response.data;
              }).catch({});
          },
  
          fetchUser () {
              axios(process.env.VUE_APP_API_URL + 'user/details/'+localStorage.getItem('user')).then(response =>{
                  if (! response.data.status == 200) {
                      return this.errorMessage = 'Could not fetch data';
                  }
                  this.user = response.data;
              }).catch({});
          },
  
          fetchCountries () {
              axios(process.env.VUE_APP_API_URL + 'country').then(response =>{
                  if (! response.data.status == 200) {
                      return this.errorMessage = 'Could not fetch data';
                  }
                  this.country = response.data;
              }).catch({});
          },
  
          fetchStates (e) {
              axios(process.env.VUE_APP_API_URL + 'state/'+e.target.value).then(response =>{
                  if (! response.data.status == 200) {
                      return this.errorMessage = 'Could not fetch data';
                  }
                  this.state = response.data;
              }).catch({});
          },
  
          fetchCities (e) {
              axios(process.env.VUE_APP_API_URL + 'city/'+e.target.value).then(response =>{
                  if (! response.data.status == 200) {
                      return this.errorMessage = 'Could not fetch data';
                  }
                  this.city = response.data;
              }).catch({});
          },
  
          fetchNigeriaStates () {
              axios(process.env.VUE_APP_API_URL + 'state/'+160).then(response =>{
                  if (! response.status == 200) {
                      return this.errorMessage = 'Could not fetch data';
                  }
                  this.state = response.data;
              }).catch({});
          },
  
          fetchLagosCities () {
              axios(process.env.VUE_APP_API_URL + 'city/'+2671).then(response =>{
                  if (! response.status == 200) {
                      return this.errorMessage = 'Could not fetch data';
                  }
                  this.city = response.data;
              }).catch({});
          },
  
          fetchUsers () {
              axios(process.env.VUE_APP_API_URL + 'users').then(response =>{
                  if (! response.data.status == 200) {
                      return this.errorMessage = 'Could not fetch data';
                  }
                  this.users = response.data;
              }).catch({});
          },
  
          fetchVendorCategory () {
              axios(process.env.VUE_APP_API_URL + 'vendor-category').then(response =>{
                  if (! response.data.status == 200) {
                      return this.errorMessage = 'Could not fetch data';
                  }
                  this.vendor_category = response.data;
              }).catch({});
          },
  
          fectchProjectInterests () {
              axios(process.env.VUE_APP_API_URL + 'project-interest').then(response =>{
                  if (! response.data.status == 200) {
                      return this.errorMessage = 'Could not fetch data';
                  }
                  this.interests = response.data;
              }).catch({});
          },
  
          fectchPropertyInterests () {
              axios(process.env.VUE_APP_API_URL + 'property-interest').then(response =>{
                  if (! response.data.status == 200) {
                      return this.errorMessage = 'Could not fetch data';
                  }
                  this.property_interests = response.data;
              }).catch({});
          },

          fectchPlanInterests () {
              axios(process.env.VUE_APP_API_URL + 'plan-interest').then(response =>{
                  if (! response.data.status == 200) {
                      return this.errorMessage = 'Could not fetch data';
                  }
                  this.plan_interests = response.data;
              }).catch({});
          },
  
          fectchInterestChildren (e) {
              axios(process.env.VUE_APP_API_URL + 'interest/'+e.target.value).then(response =>{
                  if (! response.data.status == 200) {
                      return this.errorMessage = 'Could not fetch data';
                  }
                  this.interest_children = response.data;
              }).catch({});
          },
  
          fectchInterestChildren2 (e) {
              axios(process.env.VUE_APP_API_URL + 'interest/'+e.target.value).then(response =>{
                  if (! response.data.status == 200) {
                      return this.errorMessage = 'Could not fetch data';
                  }
                  this.interest_children2 = response.data;
              }).catch({});
          },
  
          fetchProjects () {
              axios(process.env.VUE_APP_API_URL + 'project/user/'+localStorage.getItem('user')).then(response =>{
                  if (! response.data.status == 200) {
                      return this.errorMessage = 'Could not fetch data';
                  }
                  this.projects = response.data;
              }).catch({});
          },
  
          fetchFollowers () {
              axios(process.env.VUE_APP_API_URL + 'user/following/'+localStorage.getItem('user')).then(response =>{
                  if (! response.data.status == 200) {
                      return this.errorMessage = 'Could not fetch data';
                  }
                  this.followers = response.data;
              }).catch({});
          },
  
          fetchTotalProjects () {
              axios(process.env.VUE_APP_API_URL + 'project/total-project/'+localStorage.getItem('user')).then(response =>{
                  if (! response.data.status == 200) {
                      return this.errorMessage = 'Could not fetch data';
                  }
                  this.total_project = response.data;
              }).catch({});
          },
  
          fetchTotalFollowers () {
              axios(process.env.VUE_APP_API_URL + 'user/total-followers/'+localStorage.getItem('user')).then(response =>{
                  if (! response.data.status == 200) {
                      return this.errorMessage = 'Could not fetch data';
                  }
                  this.total_followers = response.data;
              }).catch({});
          },
  
          createProject () {
              this.loading = true;
              if(this.type == 'project'){
                axios.post(process.env.VUE_APP_API_URL + 'project/create', this.project)
                .then((response) => {
                  if (response.status == 200) {
                      this.$toastr.s(response.message);
                      this.created = true;
                      $("#inprogress").hide();
                      $("#success").show();
                      this.$root.$emit('action')
                      // this.fetchData();
                      this.type = '';
                      this.images = [];
                      this.project.name = '';
                      this.project.description = '';
                      this.project.project_start_date = '';
                      this.project.project_end_date = '';
                      this.project.country_id = '';
                      this.project.state_id = '';
                      this.project.city_id = '';
                      this.project.status = '';
                      this.project.height = '';
                      this.project.address = '';
                      this.project.architect = '';
                      this.project.structural_engineer = '';
                      this.project.mep_engineer = '';
                      this.project.client = '';
                      this.project.quantity_surveyor = '';
                      this.project.main_contractor = '';
                      this.project.mechanical_contractor = '';
                      this.project.electrical_contractor = '';
                      this.project.piling_contractor = '';
                      this.project.lift_contractor = '';
                      this.project.project_manager = '';
                      this.project.architect_team = [];
                      this.project.structural_engineer_team = [];
                      this.project.mep_engineer_team = [];
                      this.project.quantity_surveyor_team = [];
                      this.project.main_contractor_team = [];
                      this.project.mechanical_contractor_team = [];
                      this.project.electrical_contractor_team = [];
                      this.project.piling_contractor_team = [];
                      this.project.lift_contractor_team = [];
                      this.project.project_manager_team = [];
                      this.project.project_vendors = [];
                      this.project.images = '';
                      this.loading = false;
                  } else {
                      this.$toastr.e(response.data.message);
                      this.loading = false;
                  }
                }).catch((error) =>{
                    this.$toastr.e(error.response.data.message);
                    this.loading = false;
                })
  
              } else if(this.type == 'property') {

               axios.post(process.env.VUE_APP_API_URL + 'property/create', this.property)
               .then((response) => {
                  if (response.status == 200) {
                      this.$toastr.s(response.message);
                      this.created = true;
                      $("#inprogress").hide();
                      $("#success").show();
                      this.$root.$emit('action')
                      // this.fetchData();
                      this.type = '';
                      this.images = [];
                      this.property.name = '';
                      this.property.description = '';
                      this.property.country_id = '';
                      this.property.state_id = '';
                      this.property.city_id = '';
                      this.property.address = '';
                      this.property.type = '';
                      this.property.prices = [];
                      this.property.details = [];
                      this.property.images = '';
                      this.loading = false;
                  } else {
                      this.$toastr.e(response.data.message);
                      this.loading = false;
                  }
                }).catch((error) =>{
                    this.$toastr.e(error.response.data.message);
                    this.loading = false;
                })

              } else if(this.type == 'plan') {

                axios.post(process.env.VUE_APP_API_URL + 'plan/create', this.plan)
                .then((response) => {
                  if (response.status == 200) {
                      this.$toastr.s(response.message);
                      this.created = true;
                      $("#inprogress").hide();
                      $("#success").show();
                      this.$root.$emit('action')
                      // this.fetchData();
                      this.type = '';
                      this.images = [];
                      this.plan.title = '';
                      this.plan.description = '';
                      this.plan.land_size = '';
                      this.plan.land_size_unit = '';
                      this.plan.height = '';
                      this.plan.images = '';
                      this.loading = false;
                  } else {
                      this.$toastr.e(response.data.message);
                      this.loading = false;
                  }
                }).catch((error) =>{
                    this.$toastr.e(error.response.data.message);
                    this.loading = false;
                })
            }           
          },
  
          cancelForm(){
            if(confirm('Are you sure you want to discard this form?')){
              // this.$router.go(0);
              $('#project-modal').modal('hide');
              this.type = '';
              this.images = [];
              this.project.name = '';
              this.project.description = '';
              this.project.project_start_date = '';
              this.project.project_end_date = '';
              this.project.country_id = '';
              this.project.state_id = '';
              this.project.city_id = '';
              this.project.status = '';
              this.project.height = '';
              this.project.address = '';
              this.project.architect = '';
              this.project.structural_engineer = '';
              this.project.mep_engineer = '';
              this.project.client = '';
              this.project.quantity_surveyor = '';
              this.project.main_contractor = '';
              this.project.mechanical_contractor = '';
              this.project.electrical_contractor = '';
              this.project.piling_contractor = '';
              this.project.lift_contractor = '';
              this.project.project_manager = '';
              this.project.architect_team = [];
              this.project.structural_engineer_team = [];
              this.project.mep_engineer_team = [];
              this.project.quantity_surveyor_team = [];
              this.project.main_contractor_team = [];
              this.project.mechanical_contractor_team = [];
              this.project.electrical_contractor_team = [];
              this.project.piling_contractor_team = [];
              this.project.lift_contractor_team = [];
              this.project.project_manager_team = [];
              this.project.project_vendors = [];
              this.project.images = '';
              this.property.name = '';
              this.property.description = '';
              this.property.country_id = '';
              this.property.state_id = '';
              this.property.city_id = '';
              this.property.address = '';
              this.property.type = '';
              this.property.prices = [];
              this.property.details = [];
              this.property.images = '';
            }
          },
  
          closeForm(){
            this.created = false;
            $("#success").hide();
            $("#inprogress").show();            
              $('#project-modal').modal('hide');   
              this.type = '';         
              this.images = [];
              this.project.name = '';
              this.project.description = '';
              this.project.project_start_date = '';
              this.project.project_end_date = '';
              this.project.country_id = '';
              this.project.state_id = '';
              this.project.city_id = '';
              this.project.status = '';
              this.project.height = '';
              this.project.address = '';
              this.project.architect = '';
              this.project.structural_engineer = '';
              this.project.mep_engineer = '';
              this.project.client = '';
              this.project.quantity_surveyor = '';
              this.project.main_contractor = '';
              this.project.mechanical_contractor = '';
              this.project.electrical_contractor = '';
              this.project.piling_contractor = '';
              this.project.lift_contractor = '';
              this.project.project_manager = '';
              this.project.architect_team = [];
              this.project.structural_engineer_team = [];
              this.project.mep_engineer_team = [];
              this.project.quantity_surveyor_team = [];
              this.project.main_contractor_team = [];
              this.project.mechanical_contractor_team = [];
              this.project.electrical_contractor_team = [];
              this.project.piling_contractor_team = [];
              this.project.lift_contractor_team = [];
              this.project.project_manager_team = [];
              this.project.project_vendors = [];
              this.project.images = '';
              this.property.name = '';
              this.property.description = '';
              this.property.country_id = '';
              this.property.state_id = '';
              this.property.city_id = '';
              this.property.address = '';
              this.property.type = '';
              this.property.prices = [];
              this.property.details = [];
              this.property.images = '';
          },
  
          submitComment(event, id) {
              this.loading = true;
              axios.post(process.env.VUE_APP_API_URL + 'timeline/comment', {"comment":event.target.value, "timeline_id":id, "user_id":localStorage.getItem('user')})
              .then((response) => {
              if (response.status === 200) {
                  this.$toastr.s(response.message);
                  this.comment.description = []
                  this.$root.$emit('action')
                  this.loading = false;
              } else {
                  this.$toastr.e(response.data.message);
                  this.loading = false;
              }
              }).catch((error) =>{
                  this.$toastr.e(error.response.data.message);
                  this.loading = false;
              })
          },
  
          likeTimeline(index, id){
            this.loading = true;
            axios.post(process.env.VUE_APP_API_URL + 'timeline/like', {"timeline_id":id, "user_id":localStorage.getItem('user')})
            .then((response) => {
            if (response.status === 200) {
                this.$toastr.s(response.message);
                this.$root.$emit('action2')
            } else {
                this.$toastr.e(response.data.message);
                this.loading = false;
            }
            }).catch((error) =>{
                this.$toastr.e(error.response.data.message);
                this.loading = false;
            })
          },
  
          follow (id) {
              this.loading = true;
              axios.post(process.env.VUE_APP_API_URL + 'user/follow', {"user_id":id, "followed_by":localStorage.getItem('user')})
              .then((response) => {
              if (response.status === 200) {
                  this.$toastr.s(response.message);
                  this.loading = false;
              } else {
                  this.$toastr.e(response.data.message);
                  this.loading = false;
              }
              }).catch((error) =>{
                  this.$toastr.e(error.response.data.message);
                  this.loading = false;
              })
          },
  
          addArchitectTeam() {
            this.project.architect_team.push({
              user_id: '',
            })
          },
  
          deleteArchitectTeam(index) {
              this.project.architect_team.splice(index,1)
          },
  
          addStructuralEngineerTeam() {
            this.project.structural_engineer_team.push({
              user_id: '',
            })
          },
  
          deleteStructuralEngineerTeam(index) {
              this.project.structural_engineer_team.splice(index,1)
          },
  
          addMepEngineerTeam() {
            this.project.mep_engineer_team.push({
              user_id: '',
            })
          },
  
          deleteMepEngineerTeam(index) {
              this.project.mep_engineer_team.splice(index,1)
          },
  
          addSurveyorTeam() {
            this.project.quantity_surveyor_team.push({
              user_id: '',
            })
          },
  
          deleteSurveyorTeam(index) {
              this.project.quantity_surveyor_team.splice(index,1)
          },
  
          addMainContractorTeam() {
            this.project.main_contractor_team.push({
              user_id: '',
            })
          },
  
          deleteMainContractorTeam(index) {
              this.project.main_contractor_team.splice(index,1)
          },
  
          addMechanicalContractorTeam() {
            this.project.mechanical_contractor_team.push({
              user_id: '',
            })
          },
  
          deleteMechanicalContractorTeam(index) {
              this.project.mechanical_contractor_team.splice(index,1)
          },
  
          addElectricalContractorTeam() {
            this.project.electrical_contractor_team.push({
              user_id: '',
            })
          },
  
          deleteElectricalContractorTeam(index) {
              this.project.electrical_contractor_team.splice(index,1)
          },
  
          addPilingTeam() {
            this.project.piling_contractor_team.push({
              user_id: '',
            })
          },
  
          deletePilingTeam(index) {
              this.project.piling_contractor_team.splice(index,1)
          },

          addLiftTeam() {
            this.project.lift_contractor_team.push({
              user_id: '',
            })
          },
  
          deleteLiftTeam(index) {
              this.project.lift_contractor_team.splice(index,1)
          },

          addPMTeam() {
            this.project.project_manager_team.push({
              user_id: '',
            })
          },
  
          deletePMTeam(index) {
              this.project.project_manager_team.splice(index,1)
          },
  
          addVendor() {
            this.project.project_vendors.push({
              vendor: '',
              user_id: '',
            })
          },
  
          deleteVendor(index) {
              this.project.project_vendors.splice(index,1)
          },
  
          addPrice() {
            this.property.prices.push({
              title: '',
              price: ''
            })
          },
  
          deletePrice(index) {
              this.property.prices.splice(index,1)
          },
  
          addDetail() {
            this.property.details.push({
              title: '',
              description: ''
            })
          },
  
          deleteDetail(index) {
              this.property.details.splice(index,1)
          },
  
          uploadImageSuccess(formData, index, fileList) {
            this.project.images = fileList;
            console.log('data', formData, index, fileList)
            // Upload image api
            // axios.post('http://your-url-upload', { data: formData }).then(response => {
            //   console.log(response)
            // })
          },
  
          uploadImageProperty(formData, index, fileList) {
            this.property.images = fileList;
          },

          uploadImagePlan(formData, index, fileList) {
            this.plan.images = fileList;
          },
  
          beforeRemove (index, done, fileList) {
            console.log('index', index, fileList)
            var r = confirm("remove image")
            if (r == true) {
              done()
            } else {
              ''
            }
          },
  
          editImage (formData, index, fileList) {
            console.log('edit data', formData, index, fileList)
          },
  
          dataChange (data) {
            console.log(data)
          },
  
          formatDate(date){
            return moment(date).fromNow();
          },
  
          newline() {
            this.comment.description = `${this.comment.description}\n`;
          },
  
          viewLikes (index) {
            this.currentData = this.feeds[index];
            window.$('#post-likes').modal('show');
          },
  
          viewComments (index) {
            this.currentData = this.feeds[index];
            window.$('#post-comments').modal('show');
          },
  
          showMore(id) {
              this.$set(this.readMore, id, true);
          },
          showLess(id) {
              this.$set(this.readMore, id, false);
          },
  
          sendSubmitComment (id) {
            var str = document.getElementById("submitCommentButton"+id).value;
            this.submitComment2(str, id);
          },
  
          submitComment2(comment, id) {
              this.loading = true;
              axios.post(process.env.VUE_APP_API_URL + 'timeline/comment', {"comment":comment, "timeline_id":id, "user_id":localStorage.getItem('user')})
              .then((response) => {
              if (response.status === 200) {
                  this.$toastr.s(response.message);
                  this.comment.description = []
                  this.$root.$emit('action')
                  this.loading = false;
              } else {
                  this.$toastr.e(response.data.message);
                  this.loading = false;
              }
              }).catch((error) =>{
                  this.$toastr.e(error.response.data.message);
                  this.loading = false;
              })
          },
  
          showSingle(data) {
            this.imgs = data
            this.show()
          },
  
          show() {
            this.visible = true
          },
  
          handleHide () {
            this.visible = false
          },
  
  
  
      },
  
      mounted: function () {
         this.$root.$on('action', ()=>{
          this.fetchData()
        })
  
        this.$root.$on('action2', ()=>{
          this.fetchData()
        })
      },
  
      computed: {
          image_url: function() {
            return process.env.VUE_APP_IMAGE_URL;
          }
      },
  }
  </script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
#my-strictly-unique-vue-upload-multiple-image {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

* {
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
}

#grad1 {
  background-color: #9c27b0;
  background-image: linear-gradient(120deg, #ff4081, #81d4fa);
}

#msform {
  text-align: center;
  position: relative;
  margin-top: 20px;
}

#msform fieldset .form-card {
  background: white;
  border: 0 none;
  border-radius: 0px;
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.2);
  padding: 20px 40px 30px 40px;
  box-sizing: border-box;
  width: 94%;
  margin: 0 3% 20px 3%;
  position: relative;
}

#msform fieldset {
  background: white;
  border: 0 none;
  border-radius: 0.5rem;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding-bottom: 20px;
  position: relative;
}

#msform fieldset:not(:first-of-type) {
  display: none;
}

#msform fieldset .form-card {
  text-align: left;
  color: #9e9e9e;
}

#msform input,
#msform textarea {
  padding: 0px 8px 4px 8px;
  border: none;
  border-bottom: 1px solid #ccc;
  border-radius: 0px;
  margin-bottom: 25px;
  margin-top: 2px;
  width: 100%;
  box-sizing: border-box;
  font-family: montserrat;
  color: #2c3e50;
  font-size: 16px;
  letter-spacing: 1px;
}

@media (max-width: 500px) {
  .right-row {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)) !important;
  }
}

#msform input:focus,
#msform textarea:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: none;
  font-weight: bold;
  border-bottom: 2px solid skyblue;
  outline-width: 0;
}

#msform .action-button {
  width: 100px;
  background: skyblue;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 0px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px;
}

#msform .action-button:hover,
#msform .action-button:focus {
  box-shadow: 0 0 0 2px white, 0 0 0 3px skyblue;
}

#msform .action-button-previous {
  width: 100px;
  background: #616161;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 0px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px;
}

#msform .action-button-previous:hover,
#msform .action-button-previous:focus {
  box-shadow: 0 0 0 2px white, 0 0 0 3px #616161;
}

select.list-dt {
  border: none;
  outline: 0;
  border-bottom: 1px solid #ccc;
  padding: 2px 5px 3px 5px;
  margin: 2px;
}

select.list-dt:focus {
  border-bottom: 2px solid skyblue;
}

.card {
  z-index: 0;
  border: none;
  border-radius: 0.5rem;
  position: relative;
}

.fs-title {
  font-size: 25px;
  color: #2c3e50;
  margin-bottom: 10px;
  font-weight: bold;
  text-align: left;
}

#progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  color: lightgrey;
}

#progressbar .active {
  color: #000000;
}

#progressbar li {
  list-style-type: none;
  font-size: 12px;
  width: 25%;
  float: left;
  position: relative;
}

#progressbar #account:before {
  font-family: FontAwesome;
  content: '\f023';
}

#progressbar #personal:before {
  font-family: FontAwesome;
  content: '\f007';
}

#progressbar #payment:before {
  font-family: FontAwesome;
  content: '\f09d';
}

#progressbar #confirm:before {
  font-family: FontAwesome;
  content: '\f00c';
}

#progressbar li:before {
  width: 50px;
  height: 50px;
  line-height: 45px;
  display: block;
  font-size: 18px;
  color: #ffffff;
  background: lightgray;
  border-radius: 50%;
  margin: 0 auto 10px auto;
  padding: 2px;
}

#progressbar li:after {
  content: '';
  width: 100%;
  height: 2px;
  background: lightgray;
  position: absolute;
  left: 0;
  top: 25px;
  z-index: -1;
}

#progressbar li.active:before,
#progressbar li.active:after {
  background: skyblue;
}

.radio-group {
  position: relative;
  margin-bottom: 25px;
}

.radio {
  display: inline-block;
  width: 204;
  height: 104;
  border-radius: 0;
  background: lightblue;
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  cursor: pointer;
  margin: 8px 2px;
}

.radio:hover {
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.3);
}

.radio.selected {
  box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.1);
}

.fit-image {
  width: 100%;
  object-fit: cover;
}

/* button inside texarea start */
.textarea-container {
  position: relative;
}
.textarea-container textarea {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
.textarea-container button {
  position: absolute;
  top: 0;
  right: 0;
}
/* button inside texarea end */

@import url('https://fonts.googleapis.com/css?family=Poppins');

body {
  font-family: Poppins !important;
  overflow-x: hidden !important;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.search-nav {
  width: 60%;
  display: flex;
}
.search-nav .search {
  width: 60%;
}
.search-nav img {
  margin-right: 10px;
}
.search-nav .search input {
  background: #f9f9f9;
  border-right: none !important;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
}
.search-nav .search span {
  background: #f9f9f9;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
}
.nav {
  background-color: white;
  width: 100%;
  z-index: 2;
}
.left {
  /* width: 413px; */
  width: 25%;
  background: #efefef !important;
  left: 0;
  top: 50px;
  height: 100vh;
  z-index: 1;
  overflow: auto;
  position: fixed;
}
.left-container {
  width: 85%;
}
.left-container2 {
  width: 75%;
}
.user-info {
  background: #ffffff;
  border-radius: 38px;
}
.user-info .name {
  background: #313e7e;
  border-radius: 50%;
  padding: 50px 0;
}

.user-info .name h1 {
  font-weight: 700;
  font-size: 128px;
}
.user-info h2 {
  font-weight: 600;
  font-size: 20px;
  line-height: 122.5%;
  text-align: center;
  color: #494949;
}

.user-info h3 {
  font-weight: 600;
  font-size: 16px;
  line-height: 122.5%;
  text-align: center;
  color: #494949;
}
.user-info h4 {
  font-weight: 300;
  font-size: 12px;
  line-height: 122.5%;
  color: #494949;
}
.user-info button {
  font-size: 11px;
  font-weight: 600;
}
.blog {
  height: 470px;
  background-image: url('/assets/SCLISTING/img2.png');
  border-radius: 38px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* margin-bottom: 100px; */
}
.blog h1 {
  font-weight: 600;
  font-size: 32px;
  z-index: 1;
}
.blog .overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.266);
  top: 0;
  border-radius: 38px;
}
.longstyle {
  /* height: 341.29px; */
  /* background-image: url('/assets/SCLISTING/img6.png');
  border-radius: 38px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; */
  grid-row: span 2;
}
.shortstyle {
  /* height: 170.65px; */
  /* background-image: url('/assets/SCLISTING/img6.png');
  border-radius: 38px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; */
  grid-row: span 2;
}
.main-content {
  width: 75%;
}
.main-container {
  width: 95%;
}
.group {
  /* margin-bottom: 10px; */
  width: 100%;
  height: 100%;
}
.right-row {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 15px;
  grid-auto-rows: 200px;
  grid-auto-flow: dense;
}
.shortstyleimg {
  height: 70%;
}
.longstyleimg {
  height: 70%;
}

.group p {
  font-weight: 600;
  font-size: 14px;
}
.logo {
  background: #c80000;
  border-radius: 50%;
  transform: scale(0.7);
}
.group h5 {
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 0;
}
.bottom-nav {
  display: none;
}

@media (max-width: 1199px) {
  .search-nav img {
    margin-right: 0;
  }
}

@media (max-width: 991px) {
  #nav-content {
    max-width: 97% !important;
  }
  .left {
    position: static;
    width: 100%;
    overflow: none !important;
    height: auto;
  }
  .main-content {
    width: 100%;
  }
  .left-container {
    width: 50%;
    display: flex;
    flex-direction: column-reverse;
  }
  .user-info .name {
    padding: 70px 0;
  }
}

@media (max-width: 869px) {
  .search-nav {
    display: none !important;
  }
  .bottom-nav {
    display: flex;
    justify-content: space-evenly;
    padding: 15px 0;
    background-color: white;
    position: fixed;
    bottom: 0;
    z-index: 2;
    width: 100%;
  }
  .bottom-nav img {
    width: 25px;
  }
}

@media (max-width: 575px) {
  .left-container {
    width: 90%;
  }
  .user-info .name {
    padding: 80px 0;
  }
  /* .navbar-brand img {
        width: 150px;
    } */
}

.group2 {
  width: 80%;
}
.group2 h2 {
  font-weight: 600;
  font-size: 20px;
}
.group2 h5 {
  font-weight: 400;
  font-size: 15px;
}
.group2 span {
  font-weight: 400;
  font-size: 11px;
}
.group2 .longstyle {
  height: 500px;
}
.group2 p {
  font-weight: 400;
  font-size: 15px;
}
.profile-img {
  height: 200px;
  background-image: url('/assets/SCLISTING/img1.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 30px;
}
.profile-abbre {
  background: #c80000;
  border: 4px solid white;
  border-radius: 60%;
  margin: -50px auto 0 auto;
  /* padding: ; */
  width: max-content;
  padding: 15px 20px;
}
.profile-abbre span {
  font-weight: 700;
  font-size: 64px;
}
.projects {
  height: 550px;
}
.projects .recent {
  height: 200px;
  background-image: url('/assets/SCLISTING/img7.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 30px;
}
.projects .popular {
  height: 200px;
  background-image: url('/assets/SCLISTING/img6.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 30px;
}
.projects h4 {
  z-index: 1;
  font-weight: 600;
  font-size: 24px;
}
</style>
