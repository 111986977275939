<template>
  <layout-default>
    <div class="Profile">
        <div class="py-4">
            <div class="container">
                <div class="row">

                  <aside class="col col-xl-3 order-xl-1 col-lg-12 order-lg-1 col-12">
                    <div class="box mb-3 shadow-sm border rounded bg-white profile-box text-center">
                      <div class="py-4 px-3 border-bottom">
                        <a v-if="user.avatar" @click="() => showSingle(image_url+'users/'+user.avatar)"><img class="img-fluid mt-2 rounded-circle" :src="image_url+'users/'+user.avatar" style="max-height: 150px; max-width: 150px" alt=""></a>
                        <a v-else @click="() => showSingle(image_url+'assets/avatar.png')"><img class="img-fluid mt-2 rounded-circle" src="/assets/avatar.png" style="max-height: 150px; max-width: 150px" alt=""></a>
                        <h5 class="font-weight-bold text-dark mb-1 mt-4">{{user.name}}</h5> 
                        <p v-if="user.currentcompany" class="mb-0 text-muted">{{user.current_position}} <br> <router-link :to="'/company/'+user.currentcompany.slug">{{user.currentcompany.name}}</router-link></p>
                        <p v-else class="mb-0 text-muted">{{user.profession}}</p>
                        <span v-if="user.membership" class="text-info"><span v-if="user.membership.membership">{{ user.membership.membership.code }}</span><i v-if="user.membership.status == 1" data-toggle="tooltip" data-placement="top" title="Verified" class="feather-check-circle"></i></span> &nbsp;
                        <span v-if="user.certification" class="text-info"><span v-if="user.certification.certification">{{ user.certification.certification.code }}</span><i v-if="user.certification.status == 1" data-toggle="tooltip" data-placement="top" title="Verified" class="feather-check-circle"></i></span>
                        <div v-if="id != user.id" class="p-3">
                          <button v-if="user_follower <= 0" type="button" @click="follow(user.id)" class="btn btn-outline-primary pl-4 pr-4"> Follow </button>
                          <button v-else type="button" @click="unfollow(user.id)" class="btn btn-outline-primary pl-4 pr-4"> Unfollow </button>
                        </div>
                         <div v-if="id != user.id" class="p-3">
                          <button type="button" data-toggle='modal' data-target='#message' class="btn btn-primary btn-sm rounded pl-4 pr-4"> Send Message </button>
                        </div>
                      </div>
                      <div class="d-flex">
                        <div class="col-6 border-right p-3">
                          <h6 class="font-weight-bold text-dark mb-1">{{total_following}}</h6>
                          <p class="mb-0 text-black-50 small">Following</p>
                        </div>
                        <div class="col-6 p-3">
                          <h6 class="font-weight-bold text-dark mb-1">{{total_project}}</h6>
                          <p class="mb-0 text-black-50 small">Projects</p>
                        </div>
                      </div>
                    </div>
                    <div v-if="companies.length" class="box shadow-sm border rounded bg-white mb-3">
                    <div class="box-title border-bottom p-3">
                    <h6 class="m-0">Company / Businesses</h6>
                    </div>
                      <div class="box-body">
                        <div v-for="data in companies.slice(0,5)" :key="data.id" class="d-flex align-items-center osahan-post-header p-3 border-bottom people-list">
                          <div class="dropdown-list-image mr-3">
                            <router-link :to="'/company/'+data.slug"><img class="rounded-circle" :src="image_url+'users/'+data.logo" alt=""></router-link>
                          </div>
                          <div class="font-weight-bold">
                            <router-link :to="'/company/'+data.slug"><div class="text-truncate">{{data.name}} </div></router-link>
                            <div class="mb-0 text-muted"><i class="feather-map-pin"></i> {{data.state?data.state.name+',':''}} {{data.country.name}}</div>
                          </div>
                        </div>
                        <div v-if="show_all_companies">
                          <div v-for="data in companies.slice(5)" :key="data.id" class="d-flex align-items-center osahan-post-header p-3 border-bottom people-list">
                            <div class="dropdown-list-image mr-3">
                              <router-link :to="'/company/'+data.slug"><img class="rounded-circle" :src="image_url+'users/'+data.logo" alt=""></router-link>
                            </div>
                            <div class="font-weight-bold">
                              <router-link :to="'/company/'+data.slug"><div class="text-truncate">{{data.name}} </div></router-link>
                              <div class="mb-0 text-muted"><i class="feather-map-pin"></i> {{data.state?data.state.name+',':''}} {{data.country.name}}</div>
                            </div>
                          </div>
                        </div>                        
                      </div>
                      <span v-if="companies.length > 5">
                        <a v-if="show_all_companies == false" class="btn btn-small btn-default" style="cursor:pointer" @click="showAllCompany()">show all <i class="feather-arrow-down"></i></a>
                        <a v-if="show_all_companies == true" class="btn btn-small btn-default" style="cursor:pointer" @click="showLessCompany()">show less <i class="feather-arrow-up"></i></a>
                      </span>
                    </div>
                    <div v-if="user.id == id" class="box shadow-sm mb-3 rounded bg-white ads-box text-center overflow-hidden">                    
                      <div class="p-3">
                        <button type="button" data-toggle="modal" data-target="#new-company" class="btn btn-outline-primary pl-4 pr-4"> Add New Company </button>
                      </div>
                    </div>
                  </aside>
                    <main class="col col-xl-6 order-xl-2 col-lg-12 order-lg-2 col-md-12 col-sm-12 col-12">
                      <div class="box shadow-sm rounded bg-white mb-3 overflow-hidden">
                        <ul class="nav border-bottom osahan-line-tab" id="myTab" role="tablist">
                          <li class="nav-item">
                              <a class="nav-link active" id="home-tab" data-toggle="tab" href="#about" role="tab" aria-controls="home" aria-selected="true">About</a>
                          </li>
                          <li v-if="projects.length > 0" class="nav-item">
                              <a class="nav-link" id="project-listing-tab" data-toggle="tab" href="#project-listing" role="tab" aria-controls="project-listing" aria-selected="false">Projects</a>
                          </li>
                          <li v-if="properties.length > 0" class="nav-item">
                              <a class="nav-link" id="property-listing-tab" data-toggle="tab" href="#property-listing" role="tab" aria-controls="property-listing" aria-selected="false">Properties</a>
                          </li>
                          <li v-if="plans.length > 0" class="nav-item">
                              <a class="nav-link" id="plan-listing-tab" data-toggle="tab" href="#plan-listing" role="tab" aria-controls="plan-listing" aria-selected="false">Plans</a>
                          </li>
                          <li v-if="user.id == id" class="nav-item">
                                <button type="button" data-toggle="modal" data-target="#project-modal" class="nav-link btn btn-light mr-2"> <i class="feather-plus-circle"></i> Add Listing</button>
                          </li>
                        </ul>
                      </div>
                      <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="about" role="tabpanel" aria-labelledby="home-tab">
                          <div class="box shadow-sm border rounded bg-white mb-3">
                            <div class="box-title border-bottom p-3">
                              <h6 class="m-0">About </h6>
                            </div>
                            <div class="box-body p-3">
                              <p><span v-html="user.about"></span></p>
                            </div>
                          </div>
                          <div class="box shadow-sm border rounded bg-white mb-3">
                            <div class="box-title border-bottom p-3">
                              <h6 class="m-0">Project Connections</h6>
                            </div>
                            <div v-for="(data, index) in connections" :key="index" class="box-body p-3 border-bottom">
                              <div v-if="data.project">
                              <div class="d-flex align-items-top job-item-header pb-2">
                                <div class="mr-2">
                                  <router-link :to="'/project/'+data.project.slug">
                                  <h6 class="font-weight-bold text-dark mb-0">{{data.project.name}}</h6>
                                  <div class="text-truncate text-primary">{{data.project.state?data.project.state.name+',':''}} {{data.project.country?data.project.country.name:''}}.</div>
                                  <div class="small text-gray-500">{{formatDate2(data.project.project_start_date)}} - {{data.project.status == 1?formatDate2(data.project.project_end_date):'Present'}} ({{formatDate(data.project.project_start_date)}}) </div>
                                  </router-link>
                                </div>
                                <img v-if="data.project.images.length" class="img-fluid ml-auto mb-auto" :src="data.project.images[0].path" alt="">
                              </div>
                              <p class="mb-0" v-if="data.project.description"> <span v-html="data.project.description.substring(0,200)+'...'"></span></p>
                              <a style="cursor:pointer" @click="viewTeam2(index)">
                              <div class="d-flex align-items-center p-3 job-item-body">
                                <div class="overlap-rounded-circle">
                                  <img v-for="connect in data.project.tags.slice(0,10)" :key="connect.id" class="rounded-circle shadow-sm" data-toggle="tooltip" data-placement="top" title="" :src="connect.user.avatar?image_url+'users/'+connect.user.avatar:'/assets/avatar.png'" alt="" :data-original-title="connect.user.name">
                                </div>
                                <span class="font-weight-bold text-muted">{{data.project.tags.length}} connections</span>
                              </div>
                              </a>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div v-if="projects.length > 0" class="tab-pane fade" id="project-listing" role="tabpanel" aria-labelledby="project-listing-tab">
                          <div v-for="(data, index) in projects" :key="data.id" class="box mb-3 shadow-sm border rounded bg-white osahan-post">
                            <div v-if="data.user.id == id" class="dropdown" style="float: right">
                              <a class="btn btn-icon" style="" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span style="font-size: 25px;">...</span>
                              </a>

                              <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                  <a class="dropdown-item" style="cursor:pointer" @click="deleteProject(data.id)">Delete</a>                                
                              </div>
                            </div>
                            <div class="p-3 d-flex align-items-center border-bottom osahan-post-header">
                              <div class="dropdown-list-image mr-3">
                                <img v-if="data.user.avatar" class="rounded-circle" :src="image_url+'users/'+data.user.avatar" style="max-height: 150px; max-width: 150px" alt="">
                                <img v-else class="rounded-circle" src="/assets/avatar.png" style="max-height: 150px; max-width: 150px" alt="">
                              <div class="status-indicator bg-success"></div>
                              </div>
                              <div class="font-weight-bold">
                              <div class="text-truncate">{{data.user?data.user.name:''}}</div>
                              <div class="small text-gray-500">{{data.user.state?data.user.state.name+',':''}} {{data.user.country?data.user.country.name:''}}..</div>
                              </div>                              
                              <span class="ml-auto small">{{formatDate(data.created_at)}}</span>
                            </div>
                            <div class="p-3 border-bottom osahan-post-body">
                              <p><router-link :to="'/project/'+data.slug"><strong>{{data.name}}</strong></router-link><br> <span class="small text-gray-500">{{data.state?data.state.name+',':''}} {{data.country?data.country.name:''}}.</span></p>                                  

                              <span v-if="data.description.length > 250">
                              <p v-if="!readMore[data.id]"><span v-html="data.description.substring(0,250)+'....'"></span> <a @click="showMore(data.id)" style="color:#0056B3; cursor:pointer">view more</a></p>
                              <p v-if="readMore[data.id]"><span v-html="data.description"></span>. <a @click="showLess(data.id)" style="color:#0056B3; cursor:pointer">view less</a></p>
                              </span>
                              <span v-else>
                                <p><span v-html="data.description"></span></p>
                              </span>
                              <div v-if="data.images.length">
                                <div class="row">
                                  <div class="col-md-12" v-for="image in data.images.slice(0,1)" :key="image.id" >
                                    <router-link :to="'/project/'+data.slug"><img :src="image.path" class="img-fluid" alt="" style="object-fit: contain;"></router-link>
                                  </div>
                                </div>
                              </div>
                              <a style="cursor:pointer" @click="viewTeam(index)">
                                <div class="d-flex align-items-center p-3 job-item-body">
                                    <div class="overlap-rounded-circle">
                                    <img v-for="connect in data.tags.slice(0,10)" :key="connect.id" class="rounded-circle shadow-sm" data-toggle="tooltip" data-placement="top" title="" :src="connect.user.avatar?image_url+'users/'+connect.user.avatar:'/assets/avatar.png'" alt="" :data-original-title="connect.user.name">
                                    </div>
                                    <span class="font-weight-bold text-muted">{{data.tags.length}} connections</span>
                                </div>
                                </a>
                            </div>
                            <div class="p-3 border-bottom osahan-post-footer">
                              <span class="mr-3 text-secondary"><a @click="likeTimeline(index, data.id)"><i class="feather-thumbs-up text-danger"></i></a> <a @click="viewLikes(index)" style="cursor:pointer">{{data.likes.length}}</a></span>
                              <a v-if="data.comments.length > 0" class="mr-3 text-secondary" @click="viewComments(index)"><i class="feather-message-square"></i> {{data.comments.length}}</a>
                              <a v-else class="mr-3 text-secondary"><i class="feather-message-square"></i> {{data.comments.length}}</a>
                            </div>

                            <div v-for="com in data.comments.slice(0,3)" :key="com.id" class="p-3 d-flex align-items-top border-bottom osahan-post-comment">
                              <div class="dropdown-list-image mr-3">
                                <img v-if="com.user.avatar" class="rounded-circle" :src="image_url+'users/'+com.user.avatar" style="max-height: 150px; max-width: 150px" alt="">
                                <img v-else class="rounded-circle" src="/assets/avatar.png" style="max-height: 150px; max-width: 150px" alt="">
                                <div class="status-indicator bg-success"></div>
                              </div>
                              <div class="font-weight-bold">
                              <div class="text-truncate"> {{com.user.name}} <span class="float-right small">{{formatDate(com.created_at)}}</span></div>
                              <div class="small text-gray-500">{{com.comment}}</div>
                              </div>
                            </div>
                            <div class="p-3">
                              <div class="textarea-container">
                                <textarea placeholder="Add Comment..." class="form-control border-0 p-0 shadow-none" rows="2" :id="'submitCommentButton'+data.id" v-model="comment.description[index]" value="" @keydown.enter.exact.prevent @keyup.enter.exact="submitComment($event, data.id)" @keydown.enter.shift.exact="newline"></textarea>
                                <button type="button" style="border:0; color:#0056B3; font-size: 20px; background-color: white; padding-top:20px" @click="sendSubmitComment(data.id)"><i class="feather-send"></i></button>
                              </div>
                            </div>           

                          </div>
                        </div>

                        <div v-if="properties.length > 0" class="tab-pane fade" id="property-listing" role="tabpanel" aria-labelledby="property-listing-tab">
                          <div v-for="(data, index) in properties" :key="data.id" class="box mb-3 shadow-sm border rounded bg-white osahan-post">
                            <div v-if="data.user.id == id" class="dropdown" style="float: right">
                                <a class="btn btn-icon" style="" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                  <span style="font-size: 25px;">...</span>
                                </a>

                                <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                    <a class="dropdown-item" style="cursor:pointer" @click="deleteProperty(data.id)">Delete</a>                                
                                </div>
                              </div>
                            <div class="p-3 d-flex align-items-center border-bottom osahan-post-header">
                              <div class="dropdown-list-image mr-3">
                                <img v-if="data.user.avatar" class="rounded-circle" :src="image_url+'users/'+data.user.avatar" style="max-height: 150px; max-width: 150px" alt="">
                                <img v-else class="rounded-circle" src="/assets/avatar.png" style="max-height: 150px; max-width: 150px" alt="">
                              <div class="status-indicator bg-success"></div>
                              </div>
                              <div class="font-weight-bold">
                              <div class="text-truncate">{{data.user?data.user.name:''}}</div>
                              <div class="small text-gray-500">{{data.user.state?data.user.state.name+',':''}} {{data.user.country?data.user.country.name:''}}..</div>
                              </div>
                              <span class="ml-auto small">{{formatDate(data.created_at)}}</span>
                            </div>
                            <div class="p-3 border-bottom osahan-post-body">
                              <p><router-link :to="'/post/'+data.slug"><strong>{{data.name}}</strong></router-link><br> <span class="small text-gray-500">{{data.state?data.state.name+',':''}} {{data.country?data.country.name:''}}.</span></p>                                  

                              <span v-if="data.description.length > 250">
                              <p v-if="!readMore[data.id]"><span v-html="data.description.substring(0,200)+'....'"></span> <a @click="showMore(data.id)" style="color:#0056B3; cursor:pointer">view more</a></p>
                              <p v-if="readMore[data.id]"><span v-html="data.description"></span>. <a @click="showLess(data.id)" style="color:#0056B3; cursor:pointer">view less</a></p>
                              </span>
                              <span v-else>
                                <p><span v-html="data.description"></span></p>
                              </span>
                              <div v-if="data.images.length">
                                <div class="row">
                                  <div class="col-md-12" v-for="image in data.images.slice(0,1)" :key="image.id" >
                                    <router-link :to="'/post/'+data.slug"><img :src="image.path" class="img-fluid" alt="" style="object-fit: contain;"></router-link>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="p-3 border-bottom osahan-post-footer">
                              <span class="mr-3 text-secondary"><a @click="likePropertyTimeline(index, data.id)"><i class="feather-thumbs-up text-danger"></i></a> <a @click="viewPropertyLikes(index)" style="cursor:pointer">{{data.likes.length}}</a></span>
                              <a v-if="data.comments.length > 0" class="mr-3 text-secondary" @click="viewPropertyComments(index)"><i class="feather-message-square"></i> {{data.comments.length}}</a>
                              <a v-else class="mr-3 text-secondary"><i class="feather-message-square"></i> {{data.comments.length}}</a>
                            </div>

                            <div v-for="com in data.comments.slice(0,3)" :key="com.id" class="p-3 d-flex align-items-top border-bottom osahan-post-comment">
                              <div class="dropdown-list-image mr-3">
                                <img v-if="com.user.avatar" class="rounded-circle" :src="image_url+'users/'+com.user.avatar" style="max-height: 150px; max-width: 150px" alt="">
                                <img v-else class="rounded-circle" src="/assets/avatar.png" style="max-height: 150px; max-width: 150px" alt="">
                                <div class="status-indicator bg-success"></div>
                              </div>
                              <div class="font-weight-bold">
                              <div class="text-truncate"> {{com.user.name}} <span class="float-right small">{{formatDate(com.created_at)}}</span></div>
                              <div class="small text-gray-500">{{com.comment}}</div>
                              </div>
                            </div>
                            <div class="p-3">
                              <div class="textarea-container">
                                <textarea placeholder="Add Comment..." class="form-control border-0 p-0 shadow-none" rows="2" :id="'submitPropertyCommentButton'+data.id" v-model="comment.description[index]" value="" @keydown.enter.exact.prevent @keyup.enter.exact="submitPropertyComment($event, data.id)" @keydown.enter.shift.exact="newline"></textarea>
                                <button type="button" style="border:0; color:#0056B3; font-size: 20px; background-color: white; padding-top:20px" @click="sendSubmitPropertyComment(data.id)"><i class="feather-send"></i></button>
                              </div>
                            </div>           

                          </div>
                        </div>


                        <div v-if="plans.length > 0" class="tab-pane fade" id="plan-listing" role="tabpanel" aria-labelledby="plan-listing-tab">
                          <div v-for="(data, index) in plans" :key="data.id" class="box mb-3 shadow-sm border rounded bg-white osahan-post">
                            <div v-if="data.user.id == id" class="dropdown" style="float: right">
                                <a class="btn btn-icon" style="" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                  <span style="font-size: 25px;">...</span>
                                </a>

                                <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                    <a class="dropdown-item" style="cursor:pointer" @click="deletePlan(data.id)">Delete</a>                                
                                </div>
                              </div>
                            <div class="p-3 d-flex align-items-center border-bottom osahan-post-header">
                              <div class="dropdown-list-image mr-3">
                                <img v-if="data.user.avatar" class="rounded-circle" :src="image_url+'users/'+data.user.avatar" style="max-height: 150px; max-width: 150px" alt="">
                                <img v-else class="rounded-circle" src="/assets/avatar.png" style="max-height: 150px; max-width: 150px" alt="">
                              <div class="status-indicator bg-success"></div>
                              </div>
                              <div class="font-weight-bold">
                              <div class="text-truncate">{{data.user?data.user.name:''}}</div>
                              <div class="small text-gray-500">{{data.user.state?data.user.state.name+',':''}} {{data.user.country?data.user.country.name:''}}..</div>
                              </div>
                              <span class="ml-auto small">{{formatDate(data.created_at)}}</span>
                            </div>
                            <div class="p-3 border-bottom osahan-post-body">
                              <p><router-link :to="'/plan/'+data.slug"><strong>{{data.name}}</strong></router-link></p>                                  

                              <span v-if="data.description.length > 250">
                              <p v-if="!readMore[data.id]"><span v-html="data.description.substring(0,200)+'....'"></span> <a @click="showMore(data.id)" style="color:#0056B3; cursor:pointer">view more</a></p>
                              <p v-if="readMore[data.id]"><span v-html="data.description"></span>. <a @click="showLess(data.id)" style="color:#0056B3; cursor:pointer">view less</a></p>
                              </span>
                              <span v-else>
                                <p><span v-html="data.description"></span></p>
                              </span>
                              <div v-if="data.images.length">
                                <div class="row">
                                  <div class="col-md-12" v-for="image in data.images.slice(0,1)" :key="image.id" >
                                    <router-link :to="'/plan/'+data.slug"><img :src="image.path" class="img-fluid" alt="" style="object-fit: contain;"></router-link>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="p-3 border-bottom osahan-post-footer">
                              <span class="mr-3 text-secondary"><a @click="likePlanTimeline(index, data.id)"><i class="feather-thumbs-up text-danger"></i></a> <a @click="viewPlanLikes(index)" style="cursor:pointer">{{data.likes.length}}</a></span>
                              <a v-if="data.comments.length > 0" class="mr-3 text-secondary" @click="viewPlanComments(index)"><i class="feather-message-square"></i> {{data.comments.length}}</a>
                              <a v-else class="mr-3 text-secondary"><i class="feather-message-square"></i> {{data.comments.length}}</a>
                            </div>

                            <div v-for="com in data.comments.slice(0,3)" :key="com.id" class="p-3 d-flex align-items-top border-bottom osahan-post-comment">
                              <div class="dropdown-list-image mr-3">
                                <img v-if="com.user.avatar" class="rounded-circle" :src="image_url+'users/'+com.user.avatar" style="max-height: 150px; max-width: 150px" alt="">
                                <img v-else class="rounded-circle" src="/assets/avatar.png" style="max-height: 150px; max-width: 150px" alt="">
                                <div class="status-indicator bg-success"></div>
                              </div>
                              <div class="font-weight-bold">
                              <div class="text-truncate"> {{com.user.name}} <span class="float-right small">{{formatDate(com.created_at)}}</span></div>
                              <div class="small text-gray-500">{{com.comment}}</div>
                              </div>
                            </div>
                            <div class="p-3">
                              <div class="textarea-container">
                                <textarea placeholder="Add Comment..." class="form-control border-0 p-0 shadow-none" rows="2" :id="'submitPlanCommentButton'+data.id" v-model="comment.description[index]" value="" @keydown.enter.exact.prevent @keyup.enter.exact="submitPlanComment($event, data.id)" @keydown.enter.shift.exact="newline"></textarea>
                                <button type="button" style="border:0; color:#0056B3; font-size: 20px; background-color: white; padding-top:20px" @click="sendSubmitPlanComment(data.id)"><i class="feather-send"></i></button>
                              </div>
                            </div>           

                          </div>
                        </div>

                      </div>
                    </main>
                    <aside class="col col-xl-3 order-xl-3 col-lg-12 order-lg-3 col-12">
                      <div v-if="user.id == id" class="box shadow-sm mb-3 rounded bg-white ads-box text-center overflow-hidden">                        
                        <div class="p-3">
                        <router-link :to="'/profile/update'" type="button" class="btn btn-outline-gold pl-4 pr-4"> Update Profile </router-link>
                        </div>
                      </div>
                      <div v-if="followers.length > 0" class="box shadow-sm border rounded bg-white mb-3">
                        <div class="box-title border-bottom p-3">
                            <h6 class="m-0">Followers - ({{total_followers}})</h6>
                        </div>
                        <div class="box-body p-3">
                          <div v-for="data in followers.slice(0,6)" :key="data.id" class="d-flex align-items-center osahan-post-header mb-3 people-list">
                            <div class="dropdown-list-image mr-3">
                                <a v-if="data.user.type == 'user'" :href="'/sc/profile/'+data.user.slug">
                                <img v-if="data.user.avatar" class="rounded-circle" :src="image_url+'users/'+data.user.avatar" alt="">
                                <img v-else class="rounded-circle" src="/assets/avatar.png" alt="">
                                </a>
                                <router-link v-else :to="'/company/'+data.user.company.slug">
                                <img v-if="data.user.avatar" class="rounded-circle" :src="image_url+'users/'+data.user.avatar" alt="">
                                <img v-else class="rounded-circle" src="/assets/avatar.png" alt="">
                                </router-link>
                            </div>
                            <div class="font-weight-bold mr-2">
                                <a v-if="data.user.type == 'user'" :href="'/sc/profile/'+data.user.slug">
                                <div class="text-truncate">{{data.user.name}}</div>
                                <div v-if="data.user.profession" class="small text-gray-500">{{data.user.profession}}</div>
                                <div v-else class="small text-gray-500"><i v-if="data.user.country" class="feather-map-pin"></i> {{data.user.city?data.user.city.name+',':''}} {{data.user.state?data.user.state.name+',':''}} {{data.user.country?data.user.country.name:''}}</div>    
                                </a>  
                                <router-link v-else :to="'/company/'+data.user.company.slug">
                                <div class="text-truncate">{{data.user.company.name}}</div>
                                <div class="small text-gray-500"><i v-if="data.user.company.country" class="feather-map-pin"></i> {{data.user.company.state?data.user.company.state.name+',':''}} {{data.user.company.country?data.user.company.country.name:''}}</div>    
                                </router-link>                                    
                            </div>
                            <!-- <span class="ml-auto"><button type="button" class="btn btn-light btn-sm text-nowrap"><i class="feather-plus"></i> Follow</button></span> -->
                          </div>

                          <div v-if="show_all_followers">
                            <div v-for="data in followers.slice(6)" :key="data.id" class="d-flex align-items-center osahan-post-header mb-3 people-list">
                              <div class="dropdown-list-image mr-3">
                                  <a v-if="data.user.type == 'user'" :href="'/sc/profile/'+data.user.slug">
                                  <img v-if="data.user.avatar" class="rounded-circle" :src="image_url+'users/'+data.user.avatar" alt="">
                                  <img v-else class="rounded-circle" src="/assets/avatar.png" alt="">
                                  </a>
                                  <router-link v-else :to="'/company/'+data.user.company.slug">
                                  <img v-if="data.user.avatar" class="rounded-circle" :src="image_url+'users/'+data.user.avatar" alt="">
                                  <img v-else class="rounded-circle" src="/assets/avatar.png" alt="">
                                  </router-link>
                              </div>
                              <div class="font-weight-bold mr-2">
                                  <a v-if="data.user.type == 'user'" :href="'/sc/profile/'+data.user.slug">
                                  <div class="text-truncate">{{data.user.name}}</div>
                                  <div v-if="data.user.profession" class="small text-gray-500">{{data.user.profession}}</div>
                                  <div v-else class="small text-gray-500"><i v-if="data.user.country" class="feather-map-pin"></i> {{data.user.city?data.user.city.name+',':''}} {{data.user.state?data.user.state.name+',':''}} {{data.user.country?data.user.country.name:''}}</div>    
                                  </a>  
                                  <router-link v-else :to="'/company/'+data.user.company.slug">
                                  <div class="text-truncate">{{data.user.company.name}}</div>
                                  <div class="small text-gray-500"><i v-if="data.user.company.country" class="feather-map-pin"></i> {{data.user.company.state?data.user.company.state.name+',':''}} {{data.user.company.country?data.user.company.country.name:''}}</div>    
                                  </router-link>                                    
                              </div>
                              <!-- <span class="ml-auto"><button type="button" class="btn btn-light btn-sm text-nowrap"><i class="feather-plus"></i> Follow</button></span> -->
                            </div>
                          </div>

                          <span v-if="followers.length > 6">
                            <a v-if="show_all_followers == false" class="btn btn-small btn-default" style="cursor:pointer" @click="showFollowersAll()">show all <i class="feather-arrow-down"></i></a>
                            <a v-if="show_all_followers == true" class="btn btn-small btn-default" style="cursor:pointer" @click="showFollowersLess()">show less <i class="feather-arrow-up"></i></a>
                          </span>
                        </div>
                      </div>


                      <div v-if="following.length > 0" class="box shadow-sm border rounded bg-white mb-3">
                        <div class="box-title border-bottom p-3">
                            <h6 class="m-0">Following - ({{total_following}})</h6>
                        </div>
                        <div class="box-body p-3">
                          <div v-for="data in following.slice(0,6)" :key="data.id" class="d-flex align-items-center osahan-post-header mb-3 people-list">
                            <div class="dropdown-list-image mr-3">
                                <a v-if="data.user.type == 'user'" :href="'/sc/profile/'+data.user.slug">
                                <img v-if="data.user.avatar" class="rounded-circle" :src="image_url+'users/'+data.user.avatar" alt="">
                                <img v-else class="rounded-circle" src="/assets/avatar.png" alt="">
                                </a>
                                <router-link v-else :to="'/company/'+data.user.company.slug">
                                <img v-if="data.user.avatar" class="rounded-circle" :src="image_url+'users/'+data.user.avatar" alt="">
                                <img v-else class="rounded-circle" src="/assets/avatar.png" alt="">
                                </router-link>
                            </div>
                            <div class="font-weight-bold mr-2">
                                <a v-if="data.user.type == 'user'" :href="'/sc/profile/'+data.user.slug">
                                <div class="text-truncate">{{data.user.name}}</div>
                                <div v-if="data.user.profession" class="small text-gray-500">{{data.user.profession}}</div>
                                <div v-else class="small text-gray-500"><i v-if="data.user.country" class="feather-map-pin"></i> {{data.user.city?data.user.city.name+',':''}} {{data.user.state?data.user.state.name+',':''}} {{data.user.country?data.user.country.name:''}}</div>    
                                </a>  
                                <router-link v-else :to="'/company/'+data.user.company.slug">
                                <div class="text-truncate">{{data.user.company.name}}</div>
                                <div class="small text-gray-500"><i v-if="data.user.company.country" class="feather-map-pin"></i> {{data.user.company.state?data.user.company.state.name+',':''}} {{data.user.company.country?data.user.company.country.name:''}}</div>    
                                </router-link>                                    
                            </div>
                            <!-- <span class="ml-auto"><button type="button" class="btn btn-light btn-sm text-nowrap"><i class="feather-plus"></i> Follow</button></span> -->
                          </div>

                          <div v-if="show_all_following">
                            <div v-for="data in following.slice(6)" :key="data.id" class="d-flex align-items-center osahan-post-header mb-3 people-list">
                              <div class="dropdown-list-image mr-3">
                                  <a v-if="data.user.type == 'user'" :href="'/sc/profile/'+data.user.slug">
                                  <img v-if="data.user.avatar" class="rounded-circle" :src="image_url+'users/'+data.user.avatar" alt="">
                                  <img v-else class="rounded-circle" src="/assets/avatar.png" alt="">
                                  </a>
                                  <router-link v-else :to="'/company/'+data.user.company.slug">
                                  <img v-if="data.user.avatar" class="rounded-circle" :src="image_url+'users/'+data.user.avatar" alt="">
                                  <img v-else class="rounded-circle" src="/assets/avatar.png" alt="">
                                  </router-link>
                              </div>
                              <div class="font-weight-bold mr-2">
                                  <a v-if="data.user.type == 'user'" :href="'/sc/profile/'+data.user.slug">
                                  <div class="text-truncate">{{data.user.name}}</div>
                                  <div v-if="data.user.profession" class="small text-gray-500">{{data.user.profession}}</div>
                                  <div v-else class="small text-gray-500"><i v-if="data.user.country" class="feather-map-pin"></i> {{data.user.city?data.user.city.name+',':''}} {{data.user.state?data.user.state.name+',':''}} {{data.user.country?data.user.country.name:''}}</div>    
                                  </a>  
                                  <router-link v-else :to="'/company/'+data.user.company.slug">
                                  <div class="text-truncate">{{data.user.company.name}}</div>
                                  <div class="small text-gray-500"><i v-if="data.user.company.country" class="feather-map-pin"></i> {{data.user.company.state?data.user.company.state.name+',':''}} {{data.user.company.country?data.user.company.country.name:''}}</div>    
                                  </router-link>                                    
                              </div>
                              <!-- <span class="ml-auto"><button type="button" class="btn btn-light btn-sm text-nowrap"><i class="feather-plus"></i> Follow</button></span> -->
                            </div>
                          </div>

                          <span v-if="following.length > 6">
                            <a v-if="show_all_following == false" class="btn btn-small btn-default" style="cursor:pointer" @click="showFollowingAll()">show all <i class="feather-arrow-down"></i></a>
                            <a v-if="show_all_following == true" class="btn btn-small btn-default" style="cursor:pointer" @click="showFollowingLess()">show less <i class="feather-arrow-up"></i></a>
                          </span>

                        </div>
                      </div>
                      
                      <!-- <a href="job-profile.html">
                      <div class="shadow-sm border rounded bg-white job-item mb-3">
                      <div class="d-flex align-items-center p-3 job-item-header">
                      <div class="overflow-hidden mr-2">
                      <h6 class="font-weight-bold text-dark mb-0 text-truncate">Product Director</h6>
                      <div class="text-truncate text-primary">Spotify Inc.</div>
                      <div class="small text-gray-500"><i class="feather-map-pin"></i> India, Punjab</div>
                      </div>
                      <img class="img-fluid ml-auto" src="img/l3.png" alt="">
                      </div>
                      <div class="d-flex align-items-center p-3 border-top border-bottom job-item-body">
                      <div class="overlap-rounded-circle">
                      <img class="rounded-circle shadow-sm" data-toggle="tooltip" data-placement="top" title="" src="img/p9.png" alt="" data-original-title="Sophia Lee">
                      <img class="rounded-circle shadow-sm" data-toggle="tooltip" data-placement="top" title="" src="img/p10.png" alt="" data-original-title="John Doe">
                      <img class="rounded-circle shadow-sm" data-toggle="tooltip" data-placement="top" title="" src="img/p11.png" alt="" data-original-title="Julia Cox">
                      <img class="rounded-circle shadow-sm" data-toggle="tooltip" data-placement="top" title="" src="img/p10.png" alt="" data-original-title="John Doe">
                      <img class="rounded-circle shadow-sm" data-toggle="tooltip" data-placement="top" title="" src="img/p11.png" alt="" data-original-title="Julia Cox">
                      <img class="rounded-circle shadow-sm" data-toggle="tooltip" data-placement="top" title="" src="img/p12.png" alt="" data-original-title="Robert Cook">
                      </div>
                      <span class="font-weight-bold text-muted">18 connections</span>
                      </div>
                      <div class="p-3 job-item-footer">
                      <small class="text-gray-500"><i class="feather-clock"></i> Posted 3 Days ago</small>
                      </div>
                      </div>
                      </a> -->
                    </aside>
                </div>
            </div>
        </div>

        <vue-easy-lightbox
            :visible="visible"
            :imgs="imgs"
            :index="index"
            @hide="handleHide"
          ></vue-easy-lightbox>




        <!-- connections Modal -->
      <div v-if="currentData" class="modal fade" id="team-view" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">{{currentData.name}}</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div v-for="data in currentData.tags" :key="data.id" class="p-3 d-flex align-items-center border-bottom osahan-post-header">
                <div v-if="data.user.type == 'user'" class="dropdown-list-image mr-3">
                  <router-link :to="{name:'profile', params:{id:data.user.slug}}">
                  <img v-if="data.user.avatar" class="rounded-circle" :src="image_url+'users/'+data.user.avatar" style="max-height: 150px; max-width: 150px" alt="">
                  <img v-else class="rounded-circle" src="/assets/avatar.png" style="max-height: 150px; max-width: 150px" alt="">
                  </router-link>
                </div>
                <div v-else class="dropdown-list-image mr-3">
                  <router-link :to="{name:'CompanyDetail', params:{id:data.user.slug}}">
                  <img v-if="data.user.avatar" class="rounded-circle" :src="image_url+'users/'+data.user.avatar" style="max-height: 150px; max-width: 150px" alt="">
                  <img v-else class="rounded-circle" src="/assets/avatar.png" style="max-height: 150px; max-width: 150px" alt="">
                  </router-link>
                </div>
                <div v-if="data.user.type == 'user'" class="font-weight-bold">
                  <div class="text-truncate"><router-link :to="{name:'profile', params:{id:data.user.slug}}">{{data.user.name}}</router-link></div>
                  <div class="small text-gray-500">{{data.user.profession}} </div>
                </div>
                <div v-else class="font-weight-bold">
                  <div class="text-truncate"><router-link :to="{name:'CompanyDetail', params:{id:data.user.slug}}">{{data.user.name}}</router-link></div>
                  <div class="small text-gray-500">{{data.user.profession}} </div>
                </div>
                <span class="ml-auto"><br><button type="button" @click="follow(data.user.id)" class="btn btn-outline-primary btn-sm">Follow</button></span> 
              </div>
            </div>
            <div class="modal-footer">
              <div>
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>&nbsp;
              </div>
            </div>

          </div>
        </div>
      </div>





      <!-- company Modal -->
      <div class="modal fade" id="new-company" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Add New Company</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div id="inprogress" class="border rounded bg-white mb-3">
                <div class="box-title border-bottom p-3">
                  <h6 class="m-0">Register New Business</h6>
                  <p class="mb-0 mt-0 small">add your business details .</p>
                </div>
                <div class="box-body p-3">
                  <form id="company-form" class="js-validate" novalidate="novalidate">
                    <div class="row">
                      <div class="col-sm-12 mb-2">
                        <div class="js-form-message">
                          <label id="nameLabel" class="form-label">Company Name
                          <span class="text-danger">*</span>
                          </label>
                          <div class="form-group">
                            <input type="text" class="form-control" name="name" required="">
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 mb-2">
                        <div class="js-form-message">
                          <label id="nameLabel" class="form-label">Industry <span class="text-danger">*</span></label>
                          <div class="form-group">
                            <select class="form-control custom-select" name="industry_id" data-msg="Please select industry.">
                              <option value="" disabled selected>-- select option --</option>
                              <option v-for="data in industries" :key="data.id" :value="data.id">{{data.name}}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      
                      <div class="col-sm-12 mb-2">
                        <div class="js-form-message">
                          <label id="nameLabel" class="form-label">Company Contact Email <span class="text-danger">*</span></label>
                          <div class="form-group">
                            <input type="email" class="form-control" name="email">
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 mb-2">
                        <div class="js-form-message">
                          <label id="nameLabel" class="form-label">Company Contact Tel 1 <span class="text-danger">*</span></label>
                          <div class="form-group">
                            <input type="tel" class="form-control" name="phone_number">
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 mb-2">
                        <div class="js-form-message">
                          <label id="nameLabel" class="form-label">Company Contact Tel 2</label>
                          <div class="form-group">
                            <input type="tel" class="form-control" name="phone_number2">
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 mb-2">
                        <div class="js-form-message">
                          <label id="nameLabel" class="form-label">Company Description</label>
                          <div class="form-group">
                            <textarea type="text" class="form-control" name="short_description" required></textarea>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 mb-2">
                        <div class="js-form-message">
                          <label id="nameLabel" class="form-label">Company Website</label>
                          <div class="form-group">
                            <input type="url" class="form-control" name="website" value="https://">
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 mb-2">
                        <div class="js-form-message">
                          <label id="nameLabel" class="form-label">Company Size</label>
                          <div class="form-group">
                            <select class="form-control" name="company_size">
                              <option value="1-10">1-10</option>
                              <option value="11-25">11-25</option>
                              <option value="25-50">25-50</option>
                              <option value="50-100">50-100</option>
                              <option value="100-500">100-500</option>
                              <option value="500-1000">500-1000</option>
                              <option value="1000-10000">1000-10000</option>
                              <option value="10000-50000">10000-50000</option>
                              <option value="50000-100000">50000-100000</option>
                            </select>
                          </div>
                        </div>
                      </div>                      
                    </div>
                    <label class="form-label">Company Location</label>
                    <div class="row">
                      <div class="col-sm-4 mb-2">
                        <div class="js-form-message">
                          <div class="form-group">
                            <select class="form-control custom-select" name="country_id" v-model="company.country_id" data-msg="Please select country." data-error-class="u-has-error" data-success-class="u-has-success" @change='fetchStates($event)'>
                              <option value="" disabled selected>Country</option>
                              <option v-for="data in country" :key="data.id" :value="data.id">{{data.name}}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-4 mb-2">
                        <div class="js-form-message">
                          <div class="form-group">
                            <select class="form-control custom-select" name="state_id" v-model="company.state_id" data-msg="Please select state." data-error-class="u-has-error" data-success-class="u-has-success" @change='fetchCities($event)'>
                              <option value="" disabled selected>State</option>
                              <option v-for="data in state" :key="data.id" :value="data.id">{{data.name}}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-4 mb-2">
                        <div class="js-form-message">
                          <div class="form-group">
                            <select class="form-control custom-select" name="city_id" v-model="company.city_id" data-msg="Please select city." data-error-class="u-has-error" data-success-class="u-has-success">
                              <option value="" disabled selected>City</option>
                              <option v-for="data in city" :key="data.id" :value="data.id">{{data.name}}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 mb-2">
                        <div class="js-form-message">
                          <label id="nameLabel" class="form-label">Address </label>
                          <div class="form-group">
                            <input type="text" class="form-control" name="address">
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 mb-2">
                        <div class="js-form-message">
                          <label id="nameLabel" class="form-label">Company Logo</label>
                          <div class="form-group">
                            <input type="file" class="form-control" name="logo" accept="image/*">
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <div v-if="loading">
                <button type="button" class="btn btn-primary" data-dismiss="modal">Loading.....</button>
              </div>
              <div v-else>
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>&nbsp;
                <button type="button" @click="createCompany()" class="btn btn-primary">Submit </button>
              </div>
            </div>

          </div>
        </div>
      </div>





      <!-- message Modal -->
      <div class="modal fade" id="message" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Message {{user.name}}</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form id="message-form" class="js-validate" novalidate="novalidate">
                <div class="row">

                    <div class="col-sm-12 mb-2">
                        <div class="js-form-message">
                            <label id="nameLabel" class="form-label">
                            Your Message
                            <span class="text-danger">*</span>
                            </label>
                            <div class="form-group">
                                <textarea class="form-control" name="message" required></textarea>
                            </div>
                        </div>
                    </div>
                </div>              
                <div class="mb-3 text-right">
                  <input type="hidden" class="form-control" name="user_id" :value="user.id" required>
                  <input type="hidden" class="form-control" name="sender_id" :value="id" required>
                  <a class="font-weight-bold btn btn-primary rounded p-3" @click="sendMessage()"> &nbsp;&nbsp;&nbsp;&nbsp; Send Message &nbsp;&nbsp;&nbsp;&nbsp; </a>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <div>
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>&nbsp;
              </div>
            </div>

          </div>
        </div>
      </div>












      <!-- Modal -->
      <div class="modal fade" id="project-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">New Listing</h5>
              <button type="button" class="close" @click="cancelForm()" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div id="inprogress" class="border rounded bg-white mb-3">
                <div v-if="created == false" class="box-body p-3">
                  <div class="row">
                    <div class="col-md-12 mb-12 mb-sm-12">
                      <div class="js-form-message">
                        <div class="form-group">
                          <label id="nameLabel" class="form-label">Select Your Listing Type
                            <span class="text-danger">*</span>
                          </label>
                          <select class="form-control custom-select" v-model="type" data-msg="Please select type." data-error-class="u-has-error" data-success-class="u-has-success">
                            <option value="" disabled selected>Select Type</option>
                            <option value="project">Project</option>
                            <option value="property">Property</option>
                            <option value="plan">Design</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="type == 'project' && created == false">
                  <div class="box-title border-bottom p-3">
                    <h6 class="m-0">Supply Project Details</h6>
                    <p class="mb-0 mt-0 small">add your project details .</p>
                  </div>
                  <div class="box-body p-3">
                    <form id="create-form" class="js-validate" novalidate="novalidate">
                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Project Name
                            <span class="text-danger">*</span>
                            </label>
                            <div class="form-group">
                              <input type="text" class="form-control" name="name" v-model="project.name" required="">
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Project Description
                            <span class="text-danger">*</span>
                            </label>
                            <div class="form-group">
                              <vue-editor id="project_editor" v-model="project.description" :editorToolbar="customToolbar"></vue-editor>
                              <!-- <textarea type="text" class="form-control" name="description" rows="5" v-model="project.description" required></textarea> -->
                            </div>
                          </div>
                        </div>
                      </div>

                      <label class="form-label">Project Category
                      <span class="text-danger">*</span>
                      </label>
                      <div class="row">
                        <div class="col-md-4 mb-3 mb-sm-4">
                          <div class="js-form-message">
                            <div class="form-group">
                              <select class="form-control custom-select" name="project_interest_1" v-model="project.project_interest_1" data-msg="Please select country." data-error-class="u-has-error" data-success-class="u-has-success" @change='fectchInterestChildren($event)'>
                                <option value="" disabled selected>select Category</option>
                                <option v-for="data in interests" :key="data.id" :value="data.id">{{data.name}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 mb-3 mb-sm-4">
                          <div v-if="interest_children.length > 0" class="js-form-message">
                            <div class="form-group">
                              <select class="form-control custom-select" name="project_interest_2" v-model="project.project_interest_2" data-msg="Please select state." data-error-class="u-has-error" data-success-class="u-has-success" @change='fectchInterestChildren2($event)'>
                                <option value="" disabled selected>select category</option>
                                <option v-for="data in interest_children" :key="data.id" :value="data.id">{{data.name}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 mb-3 mb-sm-4">
                          <div v-if="interest_children2.length > 0" class="js-form-message">
                            <div class="form-group">
                              <select class="form-control custom-select" name="project_interest_3" v-model="project.project_interest_3" data-msg="Please select city." data-error-class="u-has-error" data-success-class="u-has-success">
                                <option value="" disabled selected>select category</option>
                                <option v-for="data in interest_children2" :key="data.id" :value="data.id">{{data.name}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-4 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Start Date
                            <span class="text-danger">*</span>
                            </label>
                            <div class="form-group">
                              <input type="date" class="form-control" name="project_start_date" v-model="project.project_start_date" required="">
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-4 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Project Status
                            <span class="text-danger">*</span>
                            </label>
                            <div class="form-group">
                              <select type="date" class="form-control" name="status" v-model="project.status" required="">
                                <option value="" disabled selected>select option</option>
                                <option value="1">Completed</option>
                                <option value="2">On-going</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-4 mb-2">
                          <div v-if="project.status == 1" class="js-form-message">
                            <label id="nameLabel" class="form-label">End Date
                            <span class="text-danger">*</span>
                            </label>
                            <div class="form-group">
                              <input type="date" class="form-control" name="project_end_date" v-model="project.project_end_date" required="">
                            </div>
                          </div>
                        </div>
                      </div>

                      <label class="form-label">Project Location
                      <span class="text-danger">*</span>
                      </label>
                      <div class="row">
                        <div class="col-md-4 mb-3 mb-sm-4">
                          <div class="js-form-message">
                            <div class="form-group">
                              <select class="form-control custom-select" name="country_id" v-model="project.country_id" data-msg="Please select country." data-error-class="u-has-error" data-success-class="u-has-success" @change='fetchStates($event)'>
                                <option value="" disabled selected>Country</option>
                                <option v-for="data in country" :key="data.id" :value="data.id">{{data.name}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 mb-3 mb-sm-4">
                          <div class="js-form-message">
                            <div class="form-group">
                              <select class="form-control custom-select" name="state_id" v-model="project.state_id" data-msg="Please select state." data-error-class="u-has-error" data-success-class="u-has-success" @change='fetchCities($event)'>
                                <option value="" disabled selected>State</option>
                                <option v-for="data in state" :key="data.id" :value="data.id">{{data.name}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 mb-3 mb-sm-4">
                          <div class="js-form-message">
                            <div class="form-group">
                              <select class="form-control custom-select" name="city_id" v-model="project.city_id" data-msg="Please select city." data-error-class="u-has-error" data-success-class="u-has-success">
                                <option value="" disabled selected>City</option>
                                <option v-for="data in city" :key="data.id" :value="data.id">{{data.name}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Address</label>
                            <div class="form-group">
                              <textarea class="form-control" name="address" v-model="project.address"></textarea>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Height</label>
                            <div class="form-group">
                              <select class="form-control" name="height" v-model="project.height">
                                <option value="" disabled selected>~select~</option>
                                <option value="Low rise (Less than 3 Storeys)">Low rise (Less than 3 Storeys)</option>
                                <option value="Mid rise (4 - 10 Storeys)">Mid rise (4 - 10 Storeys)</option>
                                <option value="High rise (11 - 30 Storeys)">High rise (11 - 30 Storeys)</option>
                                <option value="Skyscrappers (above 30 storeys)">Skyscrappers (above 30 storeys)</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Project Client/Developer
                            <span class="text-danger">*</span>
                            </label>
                            <div class="form-group">
                              <multiselect v-model="project.client" label="name" placeholder="Select one" :options="users" :allow-empty="true"></multiselect>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Project Architect
                            <span class="text-danger">* </span><span style="font-size: 12px"><a style="cursor:pointer" @click="addArchitectTeam">Add Team Member</a></span>
                            </label>
                            <div class="form-group">
                              <multiselect v-model="project.architect" label="name" placeholder="Select one" :options="users" :allow-empty="true"></multiselect>

                              <div class="row" v-for="(input, index) in project.architect_team" :key="index">
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6" style="padding-top: 12px">
                                  <model-list-select :list="users" v-model="input.user_id" option-value="id" option-text="name" placeholder="search team"></model-list-select>
                                </div>
                                <div class="col-lg-2" style="padding-top: 12px">
                                  <button type="button" @click="deleteArchitectTeam(index)" style="color: red; border:0">X</button>
                                </div>
                                <div class="col-lg-2"></div>
                                <br>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Project Structural Engineer
                            <span class="text-danger">* </span><span style="font-size: 12px"><a style="cursor:pointer" @click="addStructuralEngineerTeam">Add Team Member</a></span>
                            </label>
                            <div class="form-group">
                              <multiselect v-model="project.structural_engineer" label="name" placeholder="Select one" :options="users" :allow-empty="true"></multiselect>

                              <div class="row" v-for="(input, index) in project.structural_engineer_team" :key="index">
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6" style="padding-top: 12px">
                                  <model-list-select :list="users" v-model="input.user_id" option-value="id" option-text="name" placeholder="search team"></model-list-select>
                                </div>
                                <div class="col-lg-2" style="padding-top: 12px">
                                  <button type="button" @click="deleteStructuralEngineerTeam(index)" style="color: red; border:0">X</button>
                                </div>
                                <div class="col-lg-2"></div>
                                <br>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Project Mep Engineer
                            <span class="text-danger">* </span><span style="font-size: 12px"><a style="cursor:pointer" @click="addMepEngineerTeam">Add Team Member</a></span>
                            </label>
                            <div class="form-group">
                              <multiselect v-model="project.mep_engineer" label="name" placeholder="Select one" :options="users" :allow-empty="true"></multiselect>

                              <div class="row" v-for="(input, index) in project.mep_engineer_team" :key="index">
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6" style="padding-top: 12px">
                                  <model-list-select :list="users" v-model="input.user_id" option-value="id" option-text="name" placeholder="search team"></model-list-select>
                                </div>
                                <div class="col-lg-2" style="padding-top: 12px">
                                  <button type="button" @click="deleteMepEngineerTeam(index)" style="color: red; border:0">X</button>
                                </div>
                                <div class="col-lg-2"></div>
                                <br>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Project Quantity Surveyor
                            <span class="text-danger">* </span><span style="font-size: 12px"><a style="cursor:pointer" @click="addSurveyorTeam">Add Team Member</a></span>
                            </label>
                            <div class="form-group">
                               <multiselect v-model="project.quantity_surveyor" label="name" placeholder="Select one" :options="users" :allow-empty="true"></multiselect>

                              <div class="row" v-for="(input, index) in project.quantity_surveyor_team" :key="index">
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6" style="padding-top: 12px">
                                  <model-list-select :list="users" v-model="input.user_id" option-value="id" option-text="name" placeholder="search team"></model-list-select>
                                </div>
                                <div class="col-lg-2" style="padding-top: 12px">
                                  <button type="button" @click="deleteSurveyorTeam(index)" style="color: red; border:0">X</button>
                                </div>
                                <div class="col-lg-2"></div>
                                <br>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Project Main Contractor
                            <span class="text-danger">* </span><span style="font-size: 12px"><a style="cursor:pointer" @click="addMainContractorTeam">Add Team Member</a></span>
                            </label>
                            <div class="form-group">
                              <multiselect v-model="project.main_contractor" label="name" placeholder="Select one" :options="users" :allow-empty="true"></multiselect>

                              <div class="row" v-for="(input, index) in project.main_contractor_team" :key="index">
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6" style="padding-top: 12px">
                                  <model-list-select :list="users" v-model="input.user_id" option-value="id" option-text="name" placeholder="search team"></model-list-select>
                                </div>
                                <div class="col-lg-2" style="padding-top: 12px">
                                  <button type="button" @click="deleteMainContractorTeam(index)" style="color: red; border:0">X</button>
                                </div>
                                <div class="col-lg-2"></div>
                                <br>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Project Mechanical Contractor
                            <span class="text-danger">* </span><span style="font-size: 12px"><a style="cursor:pointer" @click="addMechanicalContractorTeam">Add Team Member</a></span>
                            </label>
                            <div class="form-group">
                              <multiselect v-model="project.mechanical_contractor" label="name" placeholder="Select " :options="users" :allow-empty="true"></multiselect>
                              
                              <div class="row" v-for="(input, index) in project.mechanical_contractor_team" :key="index">
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6" style="padding-top: 12px">
                                  <model-list-select :list="users" v-model="input.user_id" option-value="id" option-text="name" placeholder="search team"></model-list-select>
                                </div>
                                <div class="col-lg-2" style="padding-top: 12px">
                                  <button type="button" @click="deleteMechanicalContractorTeam(index)" style="color: red; border:0">X</button>
                                </div>
                                <div class="col-lg-2"></div>
                                <br>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Project Electrical Contractor
                            <span class="text-danger">* </span><span style="font-size: 12px"><a style="cursor:pointer" @click="addElectricalContractorTeam">Add Team Member</a></span>
                            </label>
                            <div class="form-group">
                              <multiselect v-model="project.electrical_contractor" label="name" placeholder="Select one" :options="users" :allow-empty="true"></multiselect>       													

                              <div class="row" v-for="(input, index) in project.electrical_contractor_team" :key="index">
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6" style="padding-top: 12px">
                                  <model-list-select :list="users" v-model="input.user_id" option-value="id" option-text="name" placeholder="search team"></model-list-select>
                                </div>
                                <div class="col-lg-2" style="padding-top: 12px">
                                  <button type="button" @click="deleteElectricalContractorTeam(index)" style="color: red; border:0">X</button>
                                </div>
                                <div class="col-lg-2"></div>
                                <br>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Piling Contractor
                            <span style="font-size: 12px"><a style="cursor:pointer" @click="addPilingTeam">Add Team Member</a></span>
                            </label>
                            <div class="form-group">
                              <multiselect v-model="project.piling_contractor" label="name" placeholder="Select one" :options="users" :allow-empty="true"></multiselect>

                              <div class="row" v-for="(input, index) in project.piling_contractor_team" :key="index">
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6" style="padding-top: 12px">
                                  <model-list-select :list="users" v-model="input.user_id" option-value="id" option-text="name" placeholder="search team"></model-list-select>
                                </div>
                                <div class="col-lg-2" style="padding-top: 12px">
                                  <button type="button" @click="deletePilingTeam(index)" style="color: red; border:0">X</button>
                                </div>
                                <div class="col-lg-2"></div>
                                <br>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Lift Contractor
                            <span style="font-size: 12px"><a style="cursor:pointer" @click="addLiftTeam">Add Team Member</a></span>
                            </label>
                            <div class="form-group">
                              <multiselect v-model="project.lift_contractor" label="name" placeholder="Select one" :options="users" :allow-empty="true"></multiselect>

                              <div class="row" v-for="(input, index) in project.lift_contractor_team" :key="index">
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6" style="padding-top: 12px">
                                  <model-list-select :list="users" v-model="input.user_id" option-value="id" option-text="name" placeholder="search team"></model-list-select>
                                </div>
                                <div class="col-lg-2" style="padding-top: 12px">
                                  <button type="button" @click="deleteLiftTeam(index)" style="color: red; border:0">X</button>
                                </div>
                                <div class="col-lg-2"></div>
                                <br>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Project Manager
                            <span style="font-size: 12px"><a style="cursor:pointer" @click="addPMTeam">Add Team Member</a></span>
                            </label>
                            <div class="form-group">
                              <multiselect v-model="project.project_manager" label="name" placeholder="Select one" :options="users" :allow-empty="true"></multiselect>

                              <div class="row" v-for="(input, index) in project.project_manager_team" :key="index">
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6" style="padding-top: 12px">
                                  <model-list-select :list="users" v-model="input.user_id" option-value="id" option-text="name" placeholder="search team"></model-list-select>
                                </div>
                                <div class="col-lg-2" style="padding-top: 12px">
                                  <button type="button" @click="deletePMTeam(index)" style="color: red; border:0">X</button>
                                </div>
                                <div class="col-lg-2"></div>
                                <br>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Project Vendors
                            <span class="text-danger">* </span><span style="font-size: 12px"><a style="cursor:pointer" @click="addVendor">Add Vendor</a></span>
                            </label>
                            <div class="form-group">
                              <div class="row" v-for="(input, index) in project.project_vendors" :key="index">
                                <div class="col-lg-5" style="padding-top: 12px">
                                  <model-list-select :list="vendor_category" v-model="input.vendor" option-value="id" option-text="name" placeholder="select category"></model-list-select>
                                </div>
                                <div class="col-lg-5" style="padding-top: 12px">
                                  <model-list-select :list="users" v-model="input.user_id" option-value="id" option-text="name" placeholder="search team"></model-list-select>
                                </div>
                                <div class="col-lg-2" style="padding-top: 12px">
                                  <button type="button" @click="deleteVendor(index)" style="color: red; border:0">X</button>
                                </div>
                                <br>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Add Project Images
                            <span class="text-danger">* </span>
                            </label>
                            <div id="my-strictly-unique-vue-upload-multiple-image" style="display: flex; justify-content: center;">
                              <vue-upload-multiple-image
                                @upload-success="uploadImageSuccess"
                                @before-remove="beforeRemove"
                                @edit-image="editImage"
                                @data-change="dataChange"
                                :data-images="images"
                                dragText="Drag and drop images"
                                browseText="Browse Images"
                                primaryText="added"
                                markIsPrimaryText="	Set default image"
                                popupText="Description default image"
                                :maxImage=5
                                ></vue-upload-multiple-image>

                            </div>
                          </div>
                        </div>
                      </div>

                    </form>
                  </div>
                </div>


                <div v-if="type == 'property' && created == false">
                  <div class="box-title border-bottom p-3">
                    <h6 class="m-0">Supply Property Details</h6>
                    <p class="mb-0 mt-0 small">add your property details .</p>
                  </div>
                  <div class="box-body p-3">
                    <form id="create-form" class="js-validate" novalidate="novalidate">
                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Property Name
                            <span class="text-danger">*</span>
                            </label>
                            <div class="form-group">
                              <input type="text" class="form-control" name="name" v-model="property.name" required="">
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Property Description
                            <span class="text-danger">*</span>
                            </label>
                            <div class="form-group">
                              <vue-editor id="property_editor" v-model="property.description" :editorToolbar="customToolbar"></vue-editor>
                              <!-- <textarea type="text" class="form-control" name="description" rows="5" v-model="property.description" required></textarea> -->
                            </div>
                          </div>
                        </div>
                      </div>

                      <label class="form-label">Category
                      <span class="text-danger">*</span>
                      </label>
                      <div class="row">
                        <div class="col-md-4 mb-3 mb-sm-4">
                          <div class="js-form-message">
                            <div class="form-group">
                              <select class="form-control custom-select" name="project_interest_1" v-model="property.project_interest_1" @change='fectchInterestChildren($event)'>
                                <option value="" disabled selected>select Category</option>
                                <option v-for="data in property_interests" :key="data.id" :value="data.id">{{data.name}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 mb-3 mb-sm-4">
                          <div v-if="interest_children.length > 0" class="js-form-message">
                            <div class="form-group">
                              <select class="form-control custom-select" name="project_interest_2" v-model="property.project_interest_2" @change='fectchInterestChildren2($event)'>
                                <option value="" disabled selected>select category</option>
                                <option v-for="data in interest_children" :key="data.id" :value="data.id">{{data.name}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 mb-3 mb-sm-4">
                          <div v-if="interest_children2.length > 0" class="js-form-message">
                            <div class="form-group">
                              <select class="form-control custom-select" name="project_interest_3" v-model="property.project_interest_3">
                                <option value="" disabled selected>select category</option>
                                <option v-for="data in interest_children2" :key="data.id" :value="data.id">{{data.name}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Property Type
                            <span class="text-danger">*</span>
                            </label>
                            <div class="form-group">
                              <select type="date" class="form-control" name="type" v-model="property.type" required="">
                                <option value="" disabled selected>select option</option>
                                <option value="Residential">Residential</option>
                                <option value="Commercial">Commercial</option>
                                <option value="Residential | Commercial">Residential | Commercial</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <label class="form-label">Property Location
                      <span class="text-danger">*</span>
                      </label>
                      <div class="row">
                        <div class="col-md-4 mb-3 mb-sm-4">
                          <div class="js-form-message">
                            <div class="form-group">
                              <select class="form-control custom-select" name="country_id" v-model="property.country_id" data-msg="Please select country." data-error-class="u-has-error" data-success-class="u-has-success" @change='fetchStates($event)'>
                                <option value="" disabled selected>Country</option>
                                <option v-for="data in country" :key="data.id" :value="data.id">{{data.name}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 mb-3 mb-sm-4">
                          <div class="js-form-message">
                            <div class="form-group">
                              <select class="form-control custom-select" name="state_id" v-model="property.state_id" data-msg="Please select state." data-error-class="u-has-error" data-success-class="u-has-success" @change='fetchCities($event)'>
                                <option value="" disabled selected>State</option>
                                <option v-for="data in state" :key="data.id" :value="data.id">{{data.name}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 mb-3 mb-sm-4">
                          <div class="js-form-message">
                            <div class="form-group">
                              <select class="form-control custom-select" name="city_id" v-model="property.city_id" data-msg="Please select city." data-error-class="u-has-error" data-success-class="u-has-success">
                                <option value="" disabled selected>City</option>
                                <option v-for="data in city" :key="data.id" :value="data.id">{{data.name}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mb-3 mb-sm-12">
                          <div class="js-form-message">
                            <div class="form-group">
                              <label class="form-label">Address</label>
                              <input type="text" class="form-control" name="address" v-model="property.address">
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-12 mb-3 mb-sm-12">
                          <div class="js-form-message">
                            <div class="form-group">
                              <label class="form-label">Currency</label>
                              <select class="form-control custom-select" name="currency_id" v-model="property.currency_id">
                                <option v-for="data in currency" :key="data.id" :value="data.id">{{data.symbol}} - {{data.name}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="form-group mb-0">
                        <div class="custom-control custom-radio d-inline mr-3">
                          <input type="radio" v-model="price_type" value="single" class="custom-control-input" id="customCheck1" checked>
                          <label class="custom-control-label" for="customCheck1">Single Price</label>
                        </div>
                        <div class="custom-control custom-radio d-inline">
                          <input type="radio" v-model="price_type" value="multiple" class="custom-control-input" id="customCheck2">
                          <label class="custom-control-label" for="customCheck2">Different Prices</label>
                        </div>
                      </div><br>
                      
                      <div v-if="price_type == 'single'" class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Property Price
                            <span class="text-danger">*</span>
                            </label>
                            <div class="form-group">
                              <input type="number" class="form-control" name="price" v-model="property.price">
                            </div>
                          </div>
                        </div>
                      </div>

                      <div v-if="price_type == 'multiple'" class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Property Prices
                            <span class="text-danger">* </span><span style="font-size: 12px"><a class="btn-sm btn-primary" style="cursor:pointer" @click="addPrice">Add Price</a></span>
                            </label>
                            <div class="form-group">
                              <div class="row" v-for="(input, index) in property.prices" :key="index">
                                <div class="col-lg-5" style="padding-top: 12px">
                                  <label class="form-label" style="font-size: 12px">Price Amount</label>
                                   <input type="text" placeholder="price" class="form-control" v-model="input.price" onkeypress="if ( isNaN(this.value + String.fromCharCode(event.keyCode) )) return false;">
                                </div>
                                <div class="col-lg-5" style="padding-top: 12px">
                                  <label class="form-label" style="font-size: 12px">Price Title</label>
                                   <input type="text" placeholder="price title" class="form-control" v-model="input.title">
                                </div> 
                                <div class="col-lg-2" style="padding-top: 45px">
                                  <button type="button" @click="deletePrice(index)" style="color: red; border:0; background-color:white">X</button>
                                </div>
                                <br>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="form-group mb-0">
                        <div class="custom-control custom-checkbox d-inline mr-3">
                          <input type="checkbox" v-model="more_detail" value="1" class="custom-control-input" id="customCheck3">
                          <label class="custom-control-label" for="customCheck3">More Property Details</label>
                        </div>
                      </div><br>

                      <div v-if="more_detail == '1'" class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Property Details
                            <span style="font-size: 12px"><a class="btn-sm btn-primary" style="cursor:pointer" @click="addDetail">Add Detail</a></span>
                            </label>
                            <div class="form-group">
                              <div class="row" v-for="(input, index) in property.details" :key="index">
                                <div class="col-lg-5" style="padding-top: 12px">
                                  <label class="form-label" style="font-size: 12px">Title</label>
                                   <input type="text" class="form-control" v-model="input.title">
                                </div>
                                <div class="col-lg-5" style="padding-top: 12px">
                                  <label class="form-label" style="font-size: 12px">Description</label>
                                   <input type="text" class="form-control" v-model="input.description">
                                </div>
                                <div class="col-lg-2" style="padding-top: 45px">
                                  <button type="button" @click="deleteDetail(index)" style="color: red; border:0; background-color:white">X</button>
                                </div>
                                <br>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Add Property Images
                            <span class="text-danger">* </span>
                            </label>
                            <div id="my-strictly-unique-vue-upload-multiple-image" style="display: flex; justify-content: center;">
                              <vue-upload-multiple-image
                                @upload-success="uploadImageProperty"
                                @before-remove="beforeRemove"
                                @edit-image="editImage"
                                @data-change="dataChange"
                                :data-images="images"
                                dragText="Drag and drop images"
                                browseText="Browse Images"
                                primaryText="added"
                                markIsPrimaryText="	Set default image"
                                popupText="Description default image"
                                :maxImage=5
                                ></vue-upload-multiple-image>

                            </div>
                          </div>
                        </div>
                      </div>

                    </form>
                  </div>
                </div>








                <div v-if="type == 'plan' && created == false">
                  <div class="box-title border-bottom p-3">
                    <h6 class="m-0">Supply Plan Details</h6>
                    <p class="mb-0 mt-0 small">add your plan details .</p>
                  </div>
                  <div class="box-body p-3">
                    <form id="plan-form" class="js-validate" novalidate="novalidate">
                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Title
                            <span class="text-danger">*</span>
                            </label>
                            <div class="form-group">
                              <input type="text" class="form-control" name="title" v-model="plan.title" required="">
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label"> Description
                            <span class="text-danger">*</span>
                            </label>
                            <div class="form-group">
                              <vue-editor id="plan_editor" v-model="plan.description" :editorToolbar="customToolbar"></vue-editor>
                              <!-- <textarea type="text" class="form-control summernote" name="description" rows="5" v-model="plan.description" required></textarea> -->
                            </div>
                          </div>
                        </div>
                      </div>

                      <label class="form-label">Category
                      <span class="text-danger">*</span>
                      </label>
                      <div class="row">
                        <div class="col-md-4 mb-3 mb-sm-4">
                          <div class="js-form-message">
                            <div class="form-group">
                              <select class="form-control custom-select" name="project_interest_1" v-model="plan.project_interest_1" @change='fectchInterestChildren($event)'>
                                <option value="" disabled selected>select Category</option>
                                <option v-for="data in plan_interests" :key="data.id" :value="data.id">{{data.name}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 mb-3 mb-sm-4">
                          <div v-if="interest_children.length > 0" class="js-form-message">
                            <div class="form-group">
                              <select class="form-control custom-select" name="project_interest_2" v-model="plan.project_interest_2" @change='fectchInterestChildren2($event)'>
                                <option value="" disabled selected>select category</option>
                                <option v-for="data in interest_children" :key="data.id" :value="data.id">{{data.name}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 mb-3 mb-sm-4">
                          <div v-if="interest_children2.length > 0" class="js-form-message">
                            <div class="form-group">
                              <select class="form-control custom-select" name="project_interest_3" v-model="plan.project_interest_3">
                                <option value="" disabled selected>select category</option>
                                <option v-for="data in interest_children2" :key="data.id" :value="data.id">{{data.name}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Height</label>
                            <div class="form-group">
                              <select class="form-control" name="height" v-model="plan.height">
                                <option value="" disabled selected>~select~</option>
                                <option value="Low rise (Less than 3 Storeys)">Low rise (Less than 3 Storeys)</option>
                                <option value="Mid rise (4 - 10 Storeys)">Mid rise (4 - 10 Storeys)</option>
                                <option value="High rise (11 - 30 Storeys)">High rise (11 - 30 Storeys)</option>
                                <option value="Skyscrappers (above 30 storeys)">Skyscrappers (above 30 storeys)</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Land Size
                            <span class="text-danger">*</span>
                            </label>
                            <div class="row">
                              <div class="col-sm-6">
                                <div class="form-group">
                                  <input type="text" class="form-control" name="land_size" v-model="plan.land_size" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..?)\../g, '$1');" required="">
                                </div>
                              </div>
                              <div class="col-sm-6">
                                <div class="form-group">
                                  <select class="form-control" name="land_size_unit" v-model="plan.land_size_unit" required="">
                                    <option value="" disabled selected>select unit</option>
                                    <option value="M">M&#178;</option>
                                    <option value="Plot">Plot</option>
                                    <option value="Acre">Acre</option>
                                    <option value="Hectares">Hectares</option>
                                    <option value="KM">KM&#178;</option>
                                  </select>
                                </div>
                              </div>
                            </div>                            
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Add Plan Images
                            <span class="text-danger">* </span>
                            </label>
                            <div id="my-strictly-unique-vue-upload-multiple-image" style="display: flex; justify-content: center;">
                              <vue-upload-multiple-image
                                @upload-success="uploadImagePlan"
                                @before-remove="beforeRemove"
                                @edit-image="editImage"
                                @data-change="dataChange"
                                :data-images="images"
                                dragText="Drag and drop images"
                                browseText="Browse Images"
                                primaryText="added"
                                markIsPrimaryText="	Set default image"
                                popupText="Description default image"
                                :maxImage=5
                                ></vue-upload-multiple-image>

                            </div>
                          </div>
                        </div>
                      </div>

                    </form>
                  </div>
                </div>


              </div>

               <div id="success" style="display:none">
                  <div class="form-card">
                      <h2 class="fs-title text-center">Success !</h2> <br><br>
                      <div class="row justify-content-center">
                          <div class="col-3"> <img src="https://img.icons8.com/color/96/000000/ok--v2.png" class="fit-image"> </div>
                      </div> <br><br>
                      <div class="row justify-content-center">
                          <div class="col-7 text-center">
                              <h5>You Have Successfully Added A New Listing</h5>
                          </div>
                      </div>
                  </div>
              </div>
            </div>
            <div v-if="created" class="modal-footer">
              <div>
                <button type="button" @click="closeForm()" class="btn btn-secondary" data-dismiss="modal">Close</button>&nbsp;
              </div>
            </div>
            <div v-else class="modal-footer">
              <div v-if="loading">
                <button type="button" class="btn btn-primary" data-dismiss="modal">Loading.....</button>
              </div>
              <div v-else>
                <button type="button" @click="cancelForm()" class="btn btn-secondary" data-dismiss="modal">Close</button>&nbsp;
                <button type="button" @click="createProject()" class="btn btn-primary">Submit Listing</button>
              </div>
            </div>

          </div>
        </div>
      </div>





      <!-- likes Modal -->
      <div class="modal fade" id="project-likes" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <!-- <h5 class="modal-title"></h5> -->
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div v-if="currentData.project">
              <div v-if="currentData.project.likes" class="modal-body">
                <div v-for="data in currentData.project.likes" :key="data.id" class="p-3 d-flex align-items-center border-bottom osahan-post-header">
                  <div class="dropdown-list-image mr-3">
                    <a :href="'/sc/profile/'+data.user.slug">
                    <img v-if="data.user.avatar" class="rounded-circle" :src="image_url+'users/'+data.user.avatar" style="max-height: 150px; max-width: 150px" alt="">
                    <img v-else class="rounded-circle" src="/assets/avatar.png" style="max-height: 150px; max-width: 150px" alt="">
                    </a>
                  </div>
                  <div class="font-weight-bold">
                    <div class="text-truncate"><router-link :to="'/sc/profile/'+data.user.slug">{{data.user.name}}</router-link></div>
                    <div class="small text-gray-500">{{data.user.profession}} </div>
                  </div>
                  <span class="ml-auto"><br><button type="button" @click="follow(data.user.id)" class="btn btn-outline-primary btn-sm">Follow</button></span> 
                </div>
              </div>
            </div>
            <!-- <div class="modal-footer">
              <div>
                <button type="button" @click="closeModal()" class="btn btn-secondary" data-dismiss="modal">Close</button>&nbsp;
              </div>
            </div> -->

          </div>
        </div>
      </div>




      <!-- property likes view Modal -->
      <div class="modal fade" id="post-likes" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <!-- <h5 class="modal-title"></h5> -->
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div v-if="currentData">
              <div v-if="currentData.likes" class="modal-body">
                <div v-for="data in currentData.likes" :key="data.id" class="p-3 d-flex align-items-center border-bottom osahan-post-header">
                  <div class="dropdown-list-image mr-3">
                    <a :href="'/sc/profile/'+data.user.slug">
                    <img v-if="data.user.avatar" class="rounded-circle" :src="image_url+'users/'+data.user.avatar" style="max-height: 150px; max-width: 150px" alt="">
                    <img v-else class="rounded-circle" src="/assets/avatar.png" style="max-height: 150px; max-width: 150px" alt="">
                    </a>
                  </div>
                  <div class="font-weight-bold">
                    <div class="text-truncate"><router-link :to="'/sc/profile/'+data.user.slug">{{data.user.name}}</router-link></div>
                    <div class="small text-gray-500">{{data.user.profession}} </div>
                  </div>
                  <span class="ml-auto"><br><button type="button" @click="follow(data.user.id)" class="btn btn-outline-primary btn-sm">Follow</button></span> 
                </div>
              </div>
            </div>
            <!-- <div class="modal-footer">
              <div>
                <button type="button" @click="closeModal()" class="btn btn-secondary" data-dismiss="modal">Close</button>&nbsp;
              </div>
            </div> -->

          </div>
        </div>
      </div>




      <!-- plan likes view Modal -->
      <div class="modal fade" id="plan-likes" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <!-- <h5 class="modal-title"></h5> -->
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div v-if="currentData">
              <div v-if="currentData.likes" class="modal-body">
                <div v-for="data in currentData.likes" :key="data.id" class="p-3 d-flex align-items-center border-bottom osahan-post-header">
                  <div class="dropdown-list-image mr-3">
                    <a :href="'/sc/profile/'+data.user.slug">
                    <img v-if="data.user.avatar" class="rounded-circle" :src="image_url+'users/'+data.user.avatar" style="max-height: 150px; max-width: 150px" alt="">
                    <img v-else class="rounded-circle" src="/assets/avatar.png" style="max-height: 150px; max-width: 150px" alt="">
                    </a>
                  </div>
                  <div class="font-weight-bold">
                    <div class="text-truncate"><router-link :to="'/sc/profile/'+data.user.slug">{{data.user.name}}</router-link></div>
                    <div class="small text-gray-500">{{data.user.profession}} </div>
                  </div>
                  <span class="ml-auto"><br><button type="button" @click="follow(data.user.id)" class="btn btn-outline-primary btn-sm">Follow</button></span> 
                </div>
              </div>
            </div>
            <!-- <div class="modal-footer">
              <div>
                <button type="button" @click="closeModal()" class="btn btn-secondary" data-dismiss="modal">Close</button>&nbsp;
              </div>
            </div> -->

          </div>
        </div>
      </div>





      <!-- comments Modal -->
      <div class="modal fade" id="project-comments" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div v-if="currentData.project">
              <div v-if="currentData.project.comments" class="modal-body">
                <div v-for="com in currentData.project.comments" :key="com.id" class="p-3 d-flex align-items-top border-bottom osahan-post-comment">
                  <div class="dropdown-list-image mr-3">
                    <img v-if="com.user.avatar" class="rounded-circle" :src="image_url+'users/'+com.user.avatar" style="max-height: 150px; max-width: 150px" alt="">
                    <img v-else class="rounded-circle" src="/assets/avatar.png" style="max-height: 150px; max-width: 150px" alt="">
                    <div class="status-indicator bg-success"></div>
                  </div>
                  <div class="font-weight-bold">
                  <div class="text-truncate"> {{com.user.name}} <span class="float-right small">{{formatDate(com.created_at)}}</span></div>
                  <div class="small text-gray-500">{{com.comment}}</div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="modal-footer">
              <div>
                <button type="button" @click="closeModal()" class="btn btn-secondary" data-dismiss="modal">Close</button>&nbsp;
              </div>
            </div> -->

          </div>
        </div>
      </div>



      <!-- property comments view Modal -->
      <div class="modal fade" id="post-comments" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div v-if="currentData">
              <div v-if="currentData.comments" class="modal-body">
                <div v-for="com in currentData.comments" :key="com.id" class="p-3 d-flex align-items-top border-bottom osahan-post-comment">
                  <div class="dropdown-list-image mr-3">
                    <img v-if="com.user.avatar" class="rounded-circle" :src="image_url+'users/'+com.user.avatar" style="max-height: 150px; max-width: 150px" alt="">
                    <img v-else class="rounded-circle" src="/assets/avatar.png" style="max-height: 150px; max-width: 150px" alt="">
                    <div class="status-indicator bg-success"></div>
                  </div>
                  <div class="font-weight-bold">
                  <div class="text-truncate"> {{com.user.name}} <span class="float-right small">{{formatDate(com.created_at)}}</span></div>
                  <div class="small text-gray-500">{{com.comment}}</div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="modal-footer">
              <div>
                <button type="button" @click="closeModal()" class="btn btn-secondary" data-dismiss="modal">Close</button>&nbsp;
              </div>
            </div> -->

          </div>
        </div>
      </div>





      <!-- plan comments view Modal -->
      <div class="modal fade" id="plan-comments" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div v-if="currentData">
              <div v-if="currentData.comments" class="modal-body">
                <div v-for="com in currentData.comments" :key="com.id" class="p-3 d-flex align-items-top border-bottom osahan-post-comment">
                  <div class="dropdown-list-image mr-3">
                    <img v-if="com.user.avatar" class="rounded-circle" :src="image_url+'users/'+com.user.avatar" style="max-height: 150px; max-width: 150px" alt="">
                    <img v-else class="rounded-circle" src="/assets/avatar.png" style="max-height: 150px; max-width: 150px" alt="">
                    <div class="status-indicator bg-success"></div>
                  </div>
                  <div class="font-weight-bold">
                  <div class="text-truncate"> {{com.user.name}} <span class="float-right small">{{formatDate(com.created_at)}}</span></div>
                  <div class="small text-gray-500">{{com.comment}}</div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="modal-footer">
              <div>
                <button type="button" @click="closeModal()" class="btn btn-secondary" data-dismiss="modal">Close</button>&nbsp;
              </div>
            </div> -->

          </div>
        </div>
      </div>









    </div>
  </layout-default>
</template>
<script>
import LayoutDefault from '../../layouts/default.vue';
import axios from 'axios';
import moment from 'moment';
import $ from "jquery";
import VueEasyLightbox from 'vue-easy-lightbox';
import { ModelListSelect } from 'vue-search-select';
import 'vue-search-select/dist/VueSearchSelect.css'
import Multiselect from 'vue-multiselect';
import VueUploadMultipleImage from 'vue-upload-multiple-image';
import { VueEditor } from "vue2-editor";

export default {
  name: 'Profile',
  components: {
    LayoutDefault,
    VueEasyLightbox,
    ModelListSelect,
    Multiselect,
    VueUploadMultipleImage,
    VueEditor
  },

  created() {
        this.fetchUser();
        this.fetchCountries();
        this.fetchCompanies();
        this.fetchIndustries();
        this.fetchVendorCategory();
        this.fectchProjectInterests();
        this.fectchPropertyInterests();
        this.fectchPlanInterests();
        this.fetchUsers();
  },

  watch: {
      $route: {
          immediate: true,
          handler(to) {
            let subject = to.params.id.substring(0, to.params.id.length - 10);
            document.title = to.meta.title || subject.replace(/-/g, " ")+' - Structurecity';
          }
      },
  },  

  data() {
        return {
          company: {
            country_id: 160,
            state_id: '',
            city_id: '',
          },
          price_type: 'single',
          images:[],
          readMore: {},
          more_detail: '',
          type: '',
          created:false,
          industries: [],
          companies: [],
          projects: [],
          plans: [],
          properties: [],
          user: {},
          loading: false,
          id: localStorage.getItem('user'),
          total_project: 0,
          total_followers: 0,
          total_following: 0,
          connections: [],
          currentData: {},
          country: [],
          state: [],
          city: [],
          followers: [],
          following: [],
          show_all_following: false,
          show_all_followers: false,
          user_follower: 0,
          show_all_companies: false,
          imgs: '',
          visible: false,
          index: 0,
          currency:[],
          vendor_category:[],
          interests: [],
          property_interests: [],
          plan_interests: [],
          interest_children: [],
          interest_children2: [],
          users: [],
          project: {
            user_id: '',
            name: '',
            project_interest_1: '',
            project_interest_2: '',
            project_interest_3: '',
            description: '',
            project_start_date: '',
            project_end_date: '',
            country_id: 160,
            state_id: '',
            city_id: '',
            status: '',
            height: '',
            address: '',
            architect: '',
            structural_engineer: '',
            mep_engineer: '',
            client: '',
            quantity_surveyor: '',
            main_contractor: '',
            mechanical_contractor: '',
            electrical_contractor: '',
            piling_contractor: '',
            lift_contractor: '',
            project_manager: '',
            architect_team: [],
            structural_engineer_team: [],
            mep_engineer_team: [],
            quantity_surveyor_team: [],
            main_contractor_team: [],
            mechanical_contractor_team: [],
            electrical_contractor_team: [],
            piling_contractor_team: [],
            lift_contractor_team:[],
            project_manager_team:[],
            project_vendors: [],
            images:'',
          },
          comment: {
            description: [],
          },

          property: {
            user_id: '',
            name: '',
            project_interest_1: '',
            project_interest_2: '',
            project_interest_3: '',
            description: '',
            currency_id: '',
            country_id: 160,
            state_id: '',
            city_id: '',
            address: '',
            price: '',
            type: '',
            prices: [],
            details: [],
            images:'',
          },

          plan: {
            user_id: localStorage.getItem('user'),
            title: '',
            project_interest_1: '',
            project_interest_2: '',
            project_interest_3: '',
            description: '',
            height: '',
            land_size: '',
            land_size_unit: '',
            images:'',
          },

          customToolbar: [
            ["bold", "italic", "underline"],
            [{ list: "ordered" }, { list: "bullet" }],
            // ["image", "code-block"]
          ],
      }
  },   

  methods: {
      logout () {
          localStorage.clear();
          this.$router.go();
        },

        fetchUser () {
            axios(process.env.VUE_APP_API_URL + 'user/details/'+this.$route.params.id).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.user = response.data;
                this.fetchPlans(this.user.id);
                this.fetchProperties(this.user.id);
                this.fetchProjects(this.user.id);
                this.fetchTotalProjects(this.user.id);
                this.fetchTotalFollowers(this.user.id);
                this.fetchTotalFollowing(this.user.id);
                this.checkUserFollower(this.user.id);
                this.fetchConnections(this.user.id);
                this.fetchFollowers(this.user.id);
                this.fetchFollowing(this.user.id);
                this.fetchNigeriaStates();
                this.fetchLagosCities();                
            }).catch({});
        },

        fetchCurrency () {
            axios(process.env.VUE_APP_API_URL + 'currency').then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.currency = response.data;
            }).catch({});
        },

        fetchUsers () {
            axios(process.env.VUE_APP_API_URL + 'users').then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.users = response.data;
            }).catch({});
        },

        fetchIndustries () {
            axios(process.env.VUE_APP_API_URL + 'fetch-industries').then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.industries = response.data;
            }).catch({});
        },

        fetchCompanies () {
            axios(process.env.VUE_APP_API_URL + 'user/companies/'+this.$route.params.id).then(response =>{
                if (! response.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.companies = response.data;
            }).catch({});
        },

        fetchVendorCategory () {
            axios(process.env.VUE_APP_API_URL + 'vendor-category').then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.vendor_category = response.data;
            }).catch({});
        },

        fectchProjectInterests () {
            axios(process.env.VUE_APP_API_URL + 'project-interest').then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.interests = response.data;
            }).catch({});
        },

        fectchPropertyInterests () {
            axios(process.env.VUE_APP_API_URL + 'property-interest').then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.property_interests = response.data;
            }).catch({});
        },

        fectchPlanInterests () {
            axios(process.env.VUE_APP_API_URL + 'plan-interest').then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.plan_interests = response.data;
            }).catch({});
          },

        fectchInterestChildren (e) {
            axios(process.env.VUE_APP_API_URL + 'interest/'+e.target.value).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.interest_children = response.data;
            }).catch({});
        },

        fectchInterestChildren2 (e) {
            axios(process.env.VUE_APP_API_URL + 'interest/'+e.target.value).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.interest_children2 = response.data;
            }).catch({});
        },

        fetchTotalProjects (id) {
            axios(process.env.VUE_APP_API_URL + 'project/total-project/'+id).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.total_project = response.data;
            }).catch({});
        },

        fetchTotalFollowers (id) {
            axios(process.env.VUE_APP_API_URL + 'user/total-following/'+id).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.total_following = response.data;
            }).catch({});
        },

        fetchTotalFollowing (id) {
            axios(process.env.VUE_APP_API_URL + 'user/total-followers/'+id).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.total_followers = response.data;
            }).catch({});
        },

        fetchConnections (id) {
            axios(process.env.VUE_APP_API_URL + 'user/projects/'+id).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.connections = response.data;
            }).catch({});
        },

        fetchProjects (id) {
            axios(process.env.VUE_APP_API_URL + 'user/fetch-projects/'+id).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.projects = response.data;
            }).catch({});
        },

        fetchProperties (id) {
            axios(process.env.VUE_APP_API_URL + 'user/fetch-properties/'+id).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.properties = response.data;
            }).catch({});
        },

        fetchPlans (id) {
            axios(process.env.VUE_APP_API_URL + 'user/fetch-plans/'+id).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.plans = response.data; 
            }).catch({});
        },

        fetchFollowing(user_id) {
            axios(process.env.VUE_APP_API_URL + 'user/following/'+user_id).then(response =>{
                if (! response.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.following = response.data;
            }).catch({});
        },

        fetchFollowers(user_id) {
            axios(process.env.VUE_APP_API_URL + 'user/followers/'+user_id).then(response =>{
                if (! response.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.followers = response.data;
            }).catch({});
        },

        checkUserFollower (user_id) {
            axios(process.env.VUE_APP_API_URL + 'user/check-user-follow/'+localStorage.getItem('user')+'/'+user_id).then(response =>{
                if (! response.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.user_follower = response.data;
            }).catch({});
        },

        follow (id) {
            this.loading = true;
            axios.post(process.env.VUE_APP_API_URL + 'user/follow', {"user_id":id, "followed_by":localStorage.getItem('user')})
            .then((response) => {
            if (response.status === 200) {
                this.$root.$emit('action')
                this.$toastr.s(response.message);
                this.loading = false;
            } else {
                this.$toastr.e(response.data.message);
                this.loading = false;
            }
            }).catch((error) =>{
                this.$toastr.e(error.response.data.message);
                this.loading = false;
            })
        },

        unfollow (id) {
            this.loading = true;
            axios.post(process.env.VUE_APP_API_URL + 'user/unfollow', {"user_id":id, "followed_by":localStorage.getItem('user')})
            .then((response) => {
            if (response.status === 200) {
                this.$root.$emit('action')
                this.$toastr.s(response.message);
                this.loading = false;
            } else {
                this.$toastr.e(response.data.message);
                this.loading = false;
            }
            }).catch((error) =>{
                this.$toastr.e(error.response.data.message);
                this.loading = false;
            })
        },

        createCompany () {
          this.loading = true;				
          var form = $("#company-form")[0];
          var _data = new FormData(form);
					_data.append('user_id',localStorage.getItem('user'));
          axios.post(process.env.VUE_APP_API_URL + 'user/create-company', _data)
          .then((response) => {
          if (response.status == 200) {
            this.fetchCompanies();
            this.$toastr.s(response.message);
            window.$('#new-company').modal('hide');
            this.loading = false;
          } else {
            this.$toastr.e(response.data.message);
            this.loading = false;
          }
            }).catch((error) =>{
              this.$toastr.e(error.response.data.message);
              this.loading = false;
            })
        },

        fetchCountries () {
            axios(process.env.VUE_APP_API_URL + 'country').then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.country = response.data;
            }).catch({});
        },

        fetchStates (e) {
            axios(process.env.VUE_APP_API_URL + 'state/'+e.target.value).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.state = response.data;
            }).catch({});
        },

        fetchCities (e) {
            axios(process.env.VUE_APP_API_URL + 'city/'+e.target.value).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.city = response.data;
            }).catch({});
        },

        fetchNigeriaStates () {
            axios(process.env.VUE_APP_API_URL + 'state/'+160).then(response =>{
                if (! response.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.state = response.data;
            }).catch({});
        },

        fetchLagosCities () {
            axios(process.env.VUE_APP_API_URL + 'city/'+2671).then(response =>{
                if (! response.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.city = response.data;
            }).catch({});
        },

        sendMessage () {
            this.loading = true;
            var form = $("#message-form")[0];
            var _data = new FormData(form);
            // _data.append('user_id', localStorage.getItem('user'));
            axios.post(process.env.VUE_APP_API_URL + 'messenger/send', _data)
            .then((response) => {
            if (response.status === 200) {
                this.$toastr.s(response.message);
                window.$('#message').modal('hide');
                this.loading = false;
            } else {
                this.$toastr.e(response.data.message);
                this.loading = false;
            }
            }).catch((error) =>{
                this.$toastr.e(error.response.data.message);
                this.loading = false;
            })
        },

        showAllCompany(){
          this.show_all_companies = true;
        },

        showLessCompany(){
          this.show_all_companies = false;
        },

        showFollowingAll(){
          this.show_all_following = true
        },

        showFollowingLess(){
          this.show_all_following = false
        },

        showFollowersAll(){
          this.show_all_followers = true
        },

        showFollowersLess(){
          this.show_all_followers = false
        },

        showSingle(data) {
          this.imgs = data
          this.show()
        },

        show() {
          this.visible = true
        },

        handleHide () {
          this.visible = false
        },

      createProject () {
            this.loading = true;
            if(this.type == 'project'){
              axios.post(process.env.VUE_APP_API_URL + 'project/create', this.project)
              .then((response) => {
            if (response.status == 200) {
                this.$toastr.s(response.message);
                this.created = true;
                $("#inprogress").hide();
                $("#success").show();
                this.$root.$emit('refresh-project')
                this.type = '';
                this.images = [];
                this.project.name = '';
                this.project.description = '';
                this.project.project_start_date = '';
                this.project.project_end_date = '';
                this.project.country_id = '';
                this.project.state_id = '';
                this.project.city_id = '';
                this.project.status = '';
                this.project.height = '';
                this.project.address = '';
                this.project.architect = '';
                this.project.structural_engineer = '';
                this.project.mep_engineer = '';
                this.project.client = '';
                this.project.quantity_surveyor = '';
                this.project.main_contractor = '';
                this.project.mechanical_contractor = '';
                this.project.electrical_contractor = '';
                this.project.piling_contractor = '';
                this.project.lift_contractor = '';
                this.project.project_manager = '';
                this.project.architect_team = [];
                this.project.structural_engineer_team = [];
                this.project.mep_engineer_team = [];
                this.project.quantity_surveyor_team = [];
                this.project.main_contractor_team = [];
                this.project.mechanical_contractor_team = [];
                this.project.electrical_contractor_team = [];
                this.project.piling_contractor_team = [];
                this.project.lift_contractor_team = [];
                this.project.project_manager_team = [];
                this.project.project_vendors = [];
                this.project.images = '';
                this.loading = false;
            } else {
                this.$toastr.e(response.data.message);
                this.loading = false;
            }
            }).catch((error) =>{
                this.$toastr.e(error.response.data.message);
                this.loading = false;
            })

          } else if(this.type == 'property') {
             axios.post(process.env.VUE_APP_API_URL + 'property/create', this.property)
             .then((response) => {
            if (response.status == 200) {
                this.$toastr.s(response.message);
                this.created = true;
                $("#inprogress").hide();
                $("#success").show();
                this.$root.$emit('refresh-property')
                this.type = '';
                this.images = [];
                this.property.name = '';
                this.property.description = '';
                this.property.country_id = '';
                this.property.state_id = '';
                this.property.city_id = '';
                this.property.address = '';
                this.property.type = '';
                this.property.prices = [];
                this.property.details = [];
                this.property.images = '';
                this.loading = false;
            } else {
                this.$toastr.e(response.data.message);
                this.loading = false;
            }
            }).catch((error) =>{
                this.$toastr.e(error.response.data.message);
                this.loading = false;
            })
          } else if(this.type == 'plan') {

            axios.post(process.env.VUE_APP_API_URL + 'plan/create', this.plan)
            .then((response) => {
              if (response.status == 200) {
                  this.$toastr.s(response.message);
                  this.created = true;
                  $("#inprogress").hide();
                  $("#success").show();
                  this.$root.$emit('refresh-plans')
                  // this.fetchData();
                  this.type = '';
                  this.images = [];
                  this.plan.title = '';
                  this.plan.description = '';
                  this.plan.land_size = '';
                  this.plan.land_size_unit = '';
                  this.plan.height = '';
                  this.plan.images = '';
                  this.loading = false;
              } else {
                  this.$toastr.e(response.data.message);
                  this.loading = false;
              }
            }).catch((error) =>{
                this.$toastr.e(error.response.data.message);
                this.loading = false;
            })
          }            
        },

        cancelForm(){
          if(confirm('Are you sure you want to discard this form?')){
            // this.$router.go(0);
            $('#project-modal').modal('hide');
            this.type = '';
            this.images = [];
            this.project.name = '';
            this.project.description = '';
            this.project.project_start_date = '';
            this.project.project_end_date = '';
            this.project.country_id = '';
            this.project.state_id = '';
            this.project.city_id = '';
            this.project.status = '';
            this.project.height = '';
            this.project.address = '';
            this.project.architect = '';
            this.project.structural_engineer = '';
            this.project.mep_engineer = '';
            this.project.client = '';
            this.project.quantity_surveyor = '';
            this.project.main_contractor = '';
            this.project.mechanical_contractor = '';
            this.project.electrical_contractor = '';
            this.project.piling_contractor = '';
            this.project.lift_contractor = '';
            this.project.project_manager = '';
            this.project.architect_team = [];
            this.project.structural_engineer_team = [];
            this.project.mep_engineer_team = [];
            this.project.quantity_surveyor_team = [];
            this.project.main_contractor_team = [];
            this.project.mechanical_contractor_team = [];
            this.project.electrical_contractor_team = [];
            this.project.piling_contractor_team = [];
            this.project.lift_contractor_team = [];
            this.project.project_manager_team = [];
            this.project.project_vendors = [];
            this.project.images = '';
            this.property.name = '';
            this.property.description = '';
            this.property.country_id = '';
            this.property.state_id = '';
            this.property.city_id = '';
            this.property.address = '';
            this.property.type = '';
            this.property.prices = [];
            this.property.details = [];
            this.property.images = '';
            this.plan.title = '';
            this.plan.description = '';
            this.plan.land_size = '';
            this.plan.land_size_unit = '';
            this.plan.height = '';
            this.plan.images = '';
          }
        },

        closeForm(){
          this.created = false;
          $("#success").hide();
          $("#inprogress").show();            
            $('#project-modal').modal('hide'); 
            this.type = '';           
            this.images = [];
            this.project.name = '';
            this.project.description = '';
            this.project.project_start_date = '';
            this.project.project_end_date = '';
            this.project.country_id = '';
            this.project.state_id = '';
            this.project.city_id = '';
            this.project.status = '';
            this.project.height = '';
            this.project.address = '';
            this.project.architect = '';
            this.project.structural_engineer = '';
            this.project.mep_engineer = '';
            this.project.client = '';
            this.project.quantity_surveyor = '';
            this.project.main_contractor = '';
            this.project.mechanical_contractor = '';
            this.project.electrical_contractor = '';
            this.project.piling_contractor = '';
            this.project.lift_contractor = '';
            this.project.project_manager = '';
            this.project.architect_team = [];
            this.project.structural_engineer_team = [];
            this.project.mep_engineer_team = [];
            this.project.quantity_surveyor_team = [];
            this.project.main_contractor_team = [];
            this.project.mechanical_contractor_team = [];
            this.project.electrical_contractor_team = [];
            this.project.piling_contractor_team = [];
            this.project.lift_contractor_team = [];
            this.project.project_manager_team = [];
            this.project.project_vendors = [];
            this.project.images = '';
            this.property.name = '';
            this.property.description = '';
            this.property.country_id = '';
            this.property.state_id = '';
            this.property.city_id = '';
            this.property.address = '';
            this.property.type = '';
            this.property.prices = [];
            this.property.details = [];
            this.property.images = '';
            this.plan.title = '';
            this.plan.description = '';
            this.plan.land_size = '';
            this.plan.land_size_unit = '';
            this.plan.height = '';
            this.plan.images = '';
        },

        submitComment(event, id) {
            this.loading = true;
            axios.post(process.env.VUE_APP_API_URL + 'project/comment', {"comment":event.target.value, "project_id":id, "user_id":localStorage.getItem('user')})
            .then((response) => {
            if (response.status === 200) {
                this.$toastr.s(response.message);
                this.comment.description = []
                this.$root.$emit('action')
                this.loading = false;
            } else {
                this.$toastr.e(response.data.message);
                this.loading = false;
            }
            }).catch((error) =>{
                this.$toastr.e(error.response.data.message);
                this.loading = false;
            })
        },

        submitPropertyComment(event, id) {
            this.loading = true;
            axios.post(process.env.VUE_APP_API_URL + 'property/comment', {"comment":event.target.value, "post_id":id, "user_id":localStorage.getItem('user')})
            .then((response) => {
            if (response.status === 200) {
                this.$toastr.s(response.message);
                this.comment.description = []
                this.$root.$emit('action')
                this.loading = false;
            } else {
                this.$toastr.e(response.data.message);
                this.loading = false;
            }
            }).catch((error) =>{
                this.$toastr.e(error.response.data.message);
                this.loading = false;
            })
        },

        likeTimeline(index, id){
          this.loading = true;
          axios.post(process.env.VUE_APP_API_URL + 'project/like', {"project_id":id, "user_id":localStorage.getItem('user')})
          .then((response) => {
          if (response.status === 200) {
              this.$toastr.s(response.message);
              this.$root.$emit('action')
          } else {
              this.$toastr.e(response.data.message);
              this.loading = false;
          }
          }).catch((error) =>{
              this.$toastr.e(error.response.data.message);
              this.loading = false;
          })
        },

        likePropertyTimeline(index, id){
          this.loading = true;
          axios.post(process.env.VUE_APP_API_URL + 'property/like', {"post_id":id, "user_id":localStorage.getItem('user')})
          .then((response) => {
          if (response.status === 200) {
              this.$toastr.s(response.message);
              this.$root.$emit('action')
          } else {
              this.$toastr.e(response.data.message);
              this.loading = false;
          }
          }).catch((error) =>{
              this.$toastr.e(error.response.data.message);
              this.loading = false;
          })
        },

        likePlanTimeline(index, id){
          this.loading = true;
          axios.post(process.env.VUE_APP_API_URL + 'plan/like', {"plan_id":id, "user_id":localStorage.getItem('user')})
          .then((response) => {
          if (response.status === 200) {
              this.$toastr.s(response.message);
              this.$root.$emit('action')
          } else {
              this.$toastr.e(response.data.message);
              this.loading = false;
          }
          }).catch((error) =>{
              this.$toastr.e(error.response.data.message);
              this.loading = false;
          })
        },

        setImage: function (e) {
            const file = e.target.files[0]
            if (!file.type.includes("image/")) {
              alert("Please select an image file")
              return
            }
            if (typeof FileReader === "function") {
              const reader = new FileReader()
              reader.onload = event => {
                this.imageSrc = event.target.result

                // Rebuild cropperjs with the updated source
                this.$refs.cropper.replace(event.target.result)
              }
              reader.readAsDataURL(file)
            } else {
              alert("Sorry, FileReader API not supported")
            }
          },

          cropImage() {
            // Get image data for post processing, e.g. upload or setting image src
            this.croppedImageSrc = this.$refs.cropper.getCroppedCanvas().toDataURL()
          },

          uploadImage (id) {
            var self = this;
            self.loading = true;
            this.$refs.cropper.getCroppedCanvas().toBlob(function (blob) {
              let formData = new FormData()
              // Add name for our image
              formData.append("name", "image-name-" + new Date().getTime())
              // Append image file
              formData.append("file", blob)
              formData.append("id", id)
            axios.post(process.env.VUE_APP_API_URL + 'business/banner-update', formData)
              .then((response) => {
              if (response.status === 200) {
                  self.$toastr.s(response.message);
                  self.loading = false;
                  self.$root.$emit('refresh-action');
                  window.$('#update-banner').modal('hide');
              } else {
                  self.$toastr.e(response.message);
                  self.loading = false;
              }
              }).catch((error) =>{
                  self.$toastr.e(error.response.message);
                  self.loading = false;
              })
          })
        },

        addArchitectTeam() {
          this.project.architect_team.push({
            user_id: '',
          })
        },

        deleteArchitectTeam(index) {
            this.project.architect_team.splice(index,1)
        },

        addStructuralEngineerTeam() {
          this.project.structural_engineer_team.push({
            user_id: '',
          })
        },

        deleteStructuralEngineerTeam(index) {
            this.project.structural_engineer_team.splice(index,1)
        },

        addMepEngineerTeam() {
          this.project.mep_engineer_team.push({
            user_id: '',
          })
        },

        deleteMepEngineerTeam(index) {
            this.project.mep_engineer_team.splice(index,1)
        },

        addSurveyorTeam() {
          this.project.quantity_surveyor_team.push({
            user_id: '',
          })
        },

        deleteSurveyorTeam(index) {
            this.project.quantity_surveyor_team.splice(index,1)
        },

        addMainContractorTeam() {
          this.project.main_contractor_team.push({
            user_id: '',
          })
        },

        deleteMainContractorTeam(index) {
            this.project.main_contractor_team.splice(index,1)
        },

        addMechanicalContractorTeam() {
          this.project.mechanical_contractor_team.push({
            user_id: '',
          })
        },

        deleteMechanicalContractorTeam(index) {
            this.project.mechanical_contractor_team.splice(index,1)
        },

        addElectricalContractorTeam() {
          this.project.electrical_contractor_team.push({
            user_id: '',
          })
        },

        deleteElectricalContractorTeam(index) {
            this.project.electrical_contractor_team.splice(index,1)
        },

        addPilingTeam() {
          this.project.piling_contractor_team.push({
            user_id: '',
          })
        },

        deletePilingTeam(index) {
            this.project.piling_contractor_team.splice(index,1)
        },

        addLiftTeam() {
          this.project.lift_contractor_team.push({
            user_id: '',
          })
        },

        deleteLiftTeam(index) {
            this.project.lift_contractor_team.splice(index,1)
        },

        addPMTeam() {
          this.project.project_manager_team.push({
            user_id: '',
          })
        },

        deletePMTeam(index) {
            this.project.project_manager_team.splice(index,1)
        },

        addVendor() {
          this.project.project_vendors.push({
            vendor: '',
            user_id: '',
          })
        },

        deleteVendor(index) {
            this.project.project_vendors.splice(index,1)
        },

        uploadImageSuccess(formData, index, fileList) {
          this.project.images = fileList;
          console.log('data', formData, index, fileList)
          // Upload image api
          // axios.post('http://your-url-upload', { data: formData }).then(response => {
          //   console.log(response)
          // })
        },

        uploadImageProperty(formData, index, fileList) {
          this.property.images = fileList;
        },

        uploadImagePlan(formData, index, fileList) {
            this.plan.images = fileList;
          },

        addPrice() {
          this.property.prices.push({
            title: '',
            price: ''
          })
        },

        deletePrice(index) {
            this.property.prices.splice(index,1)
        },

        addDetail() {
          this.property.details.push({
            title: '',
            description: ''
          })
        },

        deleteDetail(index) {
            this.property.details.splice(index,1)
        },

        beforeRemove (index, done, fileList) {
          console.log('index', index, fileList)
          var r = confirm("remove image")
          if (r == true) {
            done()
          } else {
            ''
          }
        },

        editImage (formData, index, fileList) {
          console.log('edit data', formData, index, fileList)
        },

        dataChange (data) {
          console.log(data)
        },

        viewTeam2 (index) {
          this.currentData = this.connections[index].project;
          window.$('#team-view').modal('show');
        },

        viewTeam (index) {
          this.currentData = this.projects[index];
          window.$('#team-view').modal('show');
        },

        formatDate(date){
          return moment(date).fromNow();
        },

        formatDate2(date) {
            return moment(date).format('DD MMMM, YYYY');
        },

        newline() {
          this.comment.description = `${this.comment.description}\n`;
        },

        viewLikes (index) {
          this.currentData = this.projects[index];
          window.$('#project-likes').modal('show');
        },

        viewComments (index) {
          this.currentData = this.projects[index];
          window.$('#project-comments').modal('show');
        },

        viewPropertyLikes (index) {
          this.currentData = this.properties[index];
          window.$('#post-likes').modal('show');
        },

        viewPropertyComments (index) {
          this.currentData = this.properties[index];
          window.$('#post-comments').modal('show');
        },

        viewPlanLikes (index) {
          this.currentData = this.plans[index];
          window.$('#plan-likes').modal('show');
        },

        viewPlanComments (index) {
          this.currentData = this.plans[index];
          window.$('#plan-comments').modal('show');
        },

        showMore(id) {
            this.$set(this.readMore, id, true);
        },
        showLess(id) {
            this.$set(this.readMore, id, false);
        },

        sendSubmitComment (id) {
          var str = document.getElementById("submitCommentButton"+id).value;
          this.submitComment2(str, id);
        },

        sendSubmitPropertyComment (id) {
          var str = document.getElementById("submitPropertyCommentButton"+id).value;
          this.submitPropertyComment2(str, id);
        },

        submitComment2(comment, id) {
            this.loading = true;
            axios.post(process.env.VUE_APP_API_URL + 'project/comment', {"comment":comment, "project_id":id, "user_id":localStorage.getItem('user')})
            .then((response) => {
            if (response.status === 200) {
                this.$toastr.s(response.message);
                this.comment.description = []
                this.$root.$emit('action')
                this.loading = false;
            } else {
                this.$toastr.e(response.data.message);
                this.loading = false;
            }
            }).catch((error) =>{
                this.$toastr.e(error.response.data.message);
                this.loading = false;
            })
        },

        submitPropertyComment2(comment, id) {
            this.loading = true;
            axios.post(process.env.VUE_APP_API_URL + 'property/comment', {"comment":comment, "post_id":id, "user_id":localStorage.getItem('user')})
            .then((response) => {
            if (response.status === 200) {
                this.$toastr.s(response.message);
                this.comment.description = []
                this.$root.$emit('action')
                this.loading = false;
            } else {
                this.$toastr.e(response.data.message);
                this.loading = false;
            }
            }).catch((error) =>{
                this.$toastr.e(error.response.data.message);
                this.loading = false;
            })
        },

      sendSubmitPlanComment (id) {
        var str = document.getElementById("submitPlanCommentButton"+id).value;
        this.submitPlanComment(str, id);
      },

      submitPlanComment(comment, id) {
          this.loading = true;
          axios.post(process.env.VUE_APP_API_URL + 'plan/comment', {"comment":comment, "plan_id":id, "user_id":localStorage.getItem('user')})
          .then((response) => {
          if (response.status === 200) {
              this.$toastr.s(response.message);
              this.comment.description = []
              this.$root.$emit('action')
              this.loading = false;
          } else {
              this.$toastr.e(response.data.message);
              this.loading = false;
          }
          }).catch((error) =>{
              this.$toastr.e(error.response.data.message);
              this.loading = false;
          })
      },

      deleteProject (id) {
        axios.delete(process.env.VUE_APP_API_URL + 'project/delete/'+id)
        .then((response) => {
        if (response.status === 200) {
            this.$toastr.s(response.message);
            this.$root.$emit('refresh-project')
        } else {
            this.$toastr.e(response.data.message);
        }
        }).catch((error) =>{
            this.$toastr.e(error.response.data.message);
        })
      },

      deleteProperty (id) {
        axios.delete(process.env.VUE_APP_API_URL + 'property/delete/'+id)
        .then((response) => {
        if (response.status === 200) {
            this.$toastr.s(response.message);
            this.$root.$emit('refresh-property')
        } else {
            this.$toastr.e(response.data.message);
        }
        }).catch((error) =>{
            this.$toastr.e(error.response.data.message);
        })
      },

      deletePlan (id) {
        axios.delete(process.env.VUE_APP_API_URL + 'plan/delete/'+id)
        .then((response) => {
        if (response.status === 200) {
            this.$toastr.s(response.message);
            this.$root.$emit('refresh-plans')
        } else {
            this.$toastr.e(response.data.message);
        }
        }).catch((error) =>{
            this.$toastr.e(error.response.data.message);
        })
      },

    },

    mounted: function () {
      this.$root.$on('refresh-action', ()=>{
        this.fetchCompany()
      })

      this.$root.$on('refresh-project', ()=>{
        this.fetchProjects()
      })

      this.$root.$on('refresh-property', ()=>{
        this.fetchProperties()
      })

      this.$root.$on('refresh-plans', ()=>{
        this.fetchPlans()
      })

      this.$root.$on('action', ()=>{
        this.fetchUser()
        this.fetchProjects()
        this.fetchProperties()
        this.fetchPlans()
      })
    },

    computed: {
        image_url: function() {
          return process.env.VUE_APP_IMAGE_URL;
        }
    },
}
</script>