<template>
  <div class="LayoutDefault">
    <!-- <nav class="LayoutDefault__nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link> |
      <router-link to="/contact">Contact</router-link>
    </nav> -->
    <nav class="navbar navbar-expand navbar-light bg-white osahan-nav-top p-0" style="z-index: 2; position: sticky; top: 0; width: 100%;">
          <div class="container">
            <router-link class="navbar-brand mr-2" to="/"><img :src="image_url+'logo/'+company.logo" alt=""></router-link>
            <form action="/search" class="d-none d-sm-inline-block form-inline mr-auto my-2 my-md-0 mw-100 navbar-search">
              <div class="input-group">
                <input name="search" type="text" class="form-control shadow-none border-0" placeholder="Search people, companies and more..." aria-label="Search" aria-describedby="basic-addon2">
                <div class="input-group-append">
                  <button class="btn" type="button"><i class="feather-search"></i></button>
                </div>
              </div>
            </form>
            <ul v-if="user_id !== null" class="navbar-nav ml-auto d-flex align-items-center">

            <li class="nav-item dropdown no-arrow d-sm-none">
            <a class="nav-link dropdown-toggle" href="#" id="searchDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="feather-search mr-2"></i>
            </a>

            <div class="dropdown-menu dropdown-menu-right p-3 shadow-sm animated--grow-in" aria-labelledby="searchDropdown">
            <form action="/search" class="form-inline mr-auto w-100 navbar-search">
            <div class="input-group">
            <input type="text" name="search" class="form-control border-0 shadow-none" placeholder="Search people, companies and more..." aria-label="Search" aria-describedby="basic-addon2">
            <div class="input-group-append">
            <button class="btn" type="button">
            <i class="feather-search"></i>
            </button>
            </div>
            </div>
            </form>
            </div>
            </li>
            <li class="nav-item">
            <a class="nav-link" href="https://store.structurecity.com" target="_blank"><img src="/assets/icons/shopping-cart.png" style="max-height:22px" />&nbsp; <span class="d-none d-lg-inline">Stores</span></a>
            </li>
            <li class="nav-item">
            <router-link class="nav-link" to="/blog"><i class="feather-book mr-2"></i><span class="d-none d-lg-inline">Blog</span></router-link>
            </li>
            <li class="nav-item dropdown mr-2">
            <a class="nav-link dropdown-toggle pr-0" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="feather-file-text mr-2"></i><span class="d-none d-lg-inline">Pages</span>
            </a>

            <div class="dropdown-menu dropdown-menu-right shadow-sm">
            <!-- <router-link class="dropdown-item" to="jobs.html"><i class="feather-briefcase mr-1"></i> Company</router-link>
            <router-link class="dropdown-item" to="profile.html"><i class="feather-user mr-1"></i> My Projects</router-link>
            <router-link class="dropdown-item" to="/my-followers"><i class="feather-users mr-1"></i> Followers</router-link>
            <router-link class="dropdown-item" :to="'/sc/profile/'+id"><i class="feather-user-plus mr-1"></i> Profile</router-link> -->
            <router-link class="dropdown-item" to="/blog"><i class="feather-globe mr-1"></i> Blog News</router-link>
            <router-link class="dropdown-item" to="/services"><i class="feather-briefcase mr-1"></i> Services</router-link>
            </div>
            </li>
            <li v-if="pending_follow.length + awaiting_stakeholder.length + awaiting_vendors.length != 0" class="nav-item dropdown no-arrow mx-1 osahan-list-dropdown">
            <a class="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="feather-users"></i>

            <span v-if="pending_follow.length + awaiting_stakeholder.length + awaiting_vendors.length != 0" class="badge badge-danger badge-counter">{{pending_follow.length + awaiting_stakeholder.length + awaiting_vendors.length}}</span>
            </a>

            <div class="dropdown-list dropdown-menu dropdown-menu-right shadow-sm">
            <h6 v-if="pending_follow.length > 0" class="dropdown-header"> Pending Follow-back </h6>
            <router-link v-for="data in pending_follow.slice(0,5)" :key="data.id" :to="'/sc/profile/'+data.user.slug" class="dropdown-item d-flex align-items-center">
            <div class="dropdown-list-image mr-3">
              <img v-if="data.user.avatar" class="rounded-circle" :src="image_url+'users/'+data.user.avatar" alt="">
              <img v-else class="rounded-circle" src="/assets/avatar.png" alt="">
            <div class="status-indicator bg-success"></div>
            </div>
            <div class="font-weight-bold overflow-hidden">
            <div class="text-truncate">{{data.user.name}}</div>
            <div class="small text-gray-500">{{data.user.profession}} · {{formatDate(data.created_at)}}</div>
            </div>
            <span class="ml-auto"><button type="button" @click="follow(data.id)" class="btn btn-outline-primary btn-sm">Follow</button></span>
            </router-link>
            <div v-if="view_all == false && pending_follow.length > 5">
              <a class="dropdown-item text-center small text-gray-500" @click="viewAllpending" >Show All</a>
            </div>

            <div v-if="view_all && pending_follow.length > 5">
              <router-link v-for="data in pending_follow.slice(5)" :key="data.id" :to="'/sc/profile/'+data.user.slug" class="dropdown-item d-flex align-items-center">
              <div class="dropdown-list-image mr-3">
                <img v-if="data.user.avatar" class="rounded-circle" :src="image_url+'users/'+data.user.avatar" alt="">
                <img v-else class="rounded-circle" src="/assets/avatar.png" alt="">
              <div class="status-indicator bg-success"></div>
              </div>
              <div class="font-weight-bold overflow-hidden">
              <div class="text-truncate">{{data.user.name}}</div>
              <div class="small text-gray-500">{{data.user.profession}} · {{formatDate(data.created_at)}}</div>
              </div>
              <span class="ml-auto"><button type="button" @click="follow(data.id)" class="btn btn-outline-primary btn-sm">Follow</button></span>
              </router-link>
              <a class="dropdown-item text-center small text-gray-500" @click="hideAllpending" >Show Little</a>
            </div>

            <h6 v-if="awaiting_stakeholder.length > 0" class="dropdown-header"> Awaiting Project Stakeholders </h6>
            <router-link v-for="data in awaiting_stakeholder.slice(0,5)" :key="data.id" :to="'/sc/profile/'+data.user.slug" class="dropdown-item d-flex align-items-center">
              <!-- <div class="dropdown-list-image mr-3">
                <img v-if="data.user.avatar" class="rounded-circle" :src="image_url+'users/'+data.user.avatar" alt="">
                <img v-else class="rounded-circle" src="/assets/avatar.png" alt="">
                <div class="status-indicator bg-success"></div>
              </div> -->
              <div class="font-weight-bold overflow-hidden">
              <div class="text-truncate">{{data.user?data.user.name:''}} <br> <span class="small text-gray-500">{{data.stakeholder}}</span></div>
              <div class="small text-gray-500">{{data.project?data.project.name:''}} <br> {{formatDate(data.created_at)}}</div>
              </div>
              <span v-if="data.status == 0" class="ml-auto">
                <button type="button" @click="accept(data.id, 'team')" class="btn btn-outline-success btn-sm">✓</button>&nbsp;
                <button type="button" @click="decline(data.id, 'team')" class="btn btn-outline-danger btn-sm">X</button>
              </span>
              <!-- <span v-if="data.status == 2" class="ml-auto">
                <button type="button" @click="accept(data.id, 'team')" class="btn btn-outline-success btn-sm">✓</button>
              </span> -->
            </router-link>
            <div v-if="view_all_team == false && awaiting_stakeholder.length > 5">
              <a style="cursor:pointer" class="dropdown-item text-center small text-gray-500" @click="viewAllpendingTeam" >Show All</a>
            </div>

            <div v-if="view_all_team && awaiting_stakeholder.length > 5">
              <router-link v-for="data in awaiting_stakeholder.slice(5)" :key="data.id" :to="'/sc/profile/'+data.user.slug" class="dropdown-item d-flex align-items-center">
              <!-- <div class="dropdown-list-image mr-3">
                <img v-if="data.user.avatar" class="rounded-circle" :src="image_url+'users/'+data.user.avatar" alt="">
                <img v-else class="rounded-circle" src="/assets/avatar.png" alt="">
                <div class="status-indicator bg-success"></div>
              </div> -->
              <div class="font-weight-bold overflow-hidden">
              <div class="text-truncate">{{data.user?data.user.name:''}} <br> <span class="small text-gray-500">{{data.stakeholder}}</span></div>
              <div class="small text-gray-500">{{data.project?data.project.name:''}} <br> {{formatDate(data.created_at)}}</div>
              </div>
              <span v-if="data.status == 0" class="ml-auto">
                <button type="button" @click="accept(data.id, 'team')" class="btn btn-outline-success btn-sm">✓</button>&nbsp;
                <button type="button" @click="decline(data.id, 'team')" class="btn btn-outline-danger btn-sm">X</button>
              </span>
              <span v-if="data.status == 2" class="ml-auto">
                <button type="button" @click="accept(data.id, 'team')" class="btn btn-outline-success btn-sm">✓</button>
              </span>
              </router-link>
             <a style="cursor:pointer" class="dropdown-item text-center small text-gray-500" @click="hideAllpendingTeam" >Show Less</a>
            </div>

            <!--- vendor start --->
            <router-link v-for="data in awaiting_vendors.slice(0,5)" :key="data.id" :to="'/sc/profile/'+data.user.slug" class="dropdown-item d-flex align-items-center">
              <!-- <div class="dropdown-list-image mr-3">
                <img v-if="data.user.avatar" class="rounded-circle" :src="image_url+'users/'+data.user.avatar" alt="">
                <img v-else class="rounded-circle" src="/assets/avatar.png" alt="">
                <div class="status-indicator bg-success"></div>
              </div> -->
              <div class="font-weight-bold overflow-hidden">
              <div class="text-truncate">{{data.user?data.user.name:''}} <br> <span class="small text-gray-500">{{data.category?data.category.name:''}}</span></div>
              <div class="small text-gray-500">{{data.project?data.project.name:''}} <br> {{formatDate(data.created_at)}}</div>
              </div>
              <span v-if="data.status == 0" class="ml-auto">
                <button type="button" @click="accept(data.id, 'vendor')" class="btn btn-outline-success btn-sm">✓</button>&nbsp;
                <button type="button" @click="decline(data.id, 'vendor')" class="btn btn-outline-danger btn-sm">X</button>
              </span>
              <span v-if="data.status == 2" class="ml-auto">
                <button type="button" @click="accept(data.id, 'vendor')" class="btn btn-outline-success btn-sm">✓</button>
              </span>
            </router-link>
            <div v-if="view_all_vendor == false && awaiting_vendors.length > 5">
              <a style="cursor:pointer" class="dropdown-item text-center small text-gray-500" @click="viewAllpendingVendor" >Show All</a>
            </div>

            <div v-if="view_all_vendor && awaiting_vendors.length > 5">
              <router-link v-for="data in awaiting_vendors.slice(5)" :key="data.id" :to="'/sc/profile/'+data.user.slug" class="dropdown-item d-flex align-items-center">
              <!-- <div class="dropdown-list-image mr-3">
                <img v-if="data.user.avatar" class="rounded-circle" :src="image_url+'users/'+data.user.avatar" alt="">
                <img v-else class="rounded-circle" src="/assets/avatar.png" alt="">
                <div class="status-indicator bg-success"></div>
              </div> -->
              <div class="font-weight-bold overflow-hidden">
              <div class="text-truncate">{{data.user?data.user.name:''}} <br> <span class="small text-gray-500">{{data.category?data.category.name:''}}</span></div>
              <div class="small text-gray-500">{{data.project?data.project.name:''}} <br> {{formatDate(data.created_at)}}</div>
              </div>
              <span v-if="data.status == 0" class="ml-auto">
                <button type="button" @click="accept(data.id, 'vendor')" class="btn btn-outline-success btn-sm">✓</button>&nbsp;
                <button type="button" @click="decline(data.id, 'vendor')" class="btn btn-outline-danger btn-sm">X</button>
              </span>
              <span v-if="data.status == 2" class="ml-auto">
                <button type="button" @click="accept(data.id, 'vendor')" class="btn btn-outline-success btn-sm">✓</button>
              </span>
              </router-link>
             <a style="cursor:pointer" class="dropdown-item text-center small text-gray-500" @click="hideAllpendingVendor" >Show Less</a>
            </div>
            <!--- vendor end --->

            </div>
            </li>

            <li class="nav-item dropdown no-arrow mx-1 osahan-list-dropdown">
            <a class="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="feather-bell"></i>

            <span v-if="total_unread_notification != 0" class="badge badge-info badge-counter">{{total_unread_notification}}</span>
            </a>

            <div style="height: 450px; overflow: auto" class="dropdown-list dropdown-menu dropdown-menu-right shadow-sm">
            <h6 class="dropdown-header"> Notifications </h6>
            <router-link v-for="data in notifications.slice(0,5)" :key="data.id" :to="data.link" class="dropdown-item d-flex align-items-center">
              <div v-if="data.status == 0" class="font-weight-bold overflow-hidden">
                <div @mouseover="updateNotification(data.id)" class="small text-gray-100">{{data.description}} <br> {{formatDate(data.created_at)}}</div>
              </div>
              <div v-else class="font-weight-bold overflow-hidden">
                <div @mouseover="updateNotification(data.id)" class="small text-gray-500">{{data.description}} <br> {{formatDate(data.created_at)}}</div>
              </div>
            </router-link>
            <div v-if="view_all_notice == false && notifications.length > 5">
              <a style="cursor:pointer" class="dropdown-item text-center small text-gray-500" @mouseover="viewAllpendingNotice" >Show All</a>
            </div>

            <div v-if="view_all_notice && notifications.length > 5">
              <router-link v-for="data in notifications.slice(5)" :key="data.id" :to="data.link" @mouseover="updateNotification(data.id)" class="dropdown-item d-flex align-items-center">
              <div v-if="data.status == 0" class="font-weight-bold overflow-hidden">
                <div @mouseover="updateNotification(data.id)" class="small text-gray-100">{{data.description}} <br> {{formatDate(data.created_at)}}</div>
              </div>
              <div v-else class="font-weight-bold overflow-hidden">
                <div @mouseover="updateNotification(data.id)" class="small text-gray-500">{{data.description}} <br> {{formatDate(data.created_at)}}</div>
              </div>
              </router-link>
             <a style="cursor:pointer" class="dropdown-item text-center small text-gray-500" @mouseover="hideAllpendingNotice" >Show Less</a>
            </div>

            </div>
            </li>

            <li class="nav-item dropdown no-arrow ml-1 osahan-profile-dropdown">
            <a class="nav-link dropdown-toggle pr-0" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <img v-if="user.avatar" class="img-profile rounded-circle" :src="image_url+'users/'+user.avatar" alt="">
            <img v-else class="img-profile rounded-circle" src="/assets/avatar.png" alt="">
            </a>

            <div class="dropdown-menu dropdown-menu-right shadow-sm">
            <div class="p-3 d-flex align-items-center">
            <div class="dropdown-list-image mr-3">
            <img v-if="user.avatar" class="rounded-circle" :src="image_url+'users/'+user.avatar" alt="">
            <img v-else class="rounded-circle" src="/assets/avatar.png" alt="">
            <div class="status-indicator bg-success"></div>
            </div>
            <div class="font-weight-bold">
            <div class="text-truncate">{{user.name}}</div>
            <div class="small text-gray-500">{{user.profession}}</div>
            </div>
            </div>
            <div class="dropdown-divider"></div>
            <router-link class="dropdown-item" :to="'/sc/profile/'+id"><i class="feather-edit mr-1"></i> My Account</router-link>
            <router-link class="dropdown-item" :to="'/profile/update'"><i class="feather-user mr-1"></i> Update Profile</router-link>
            <router-link class="dropdown-item" :to="'/profile/change-password'"><i class="feather-lock mr-1"></i> Change Password</router-link>
            <router-link class="dropdown-item" :to="'/messages'"><i class="feather-message-circle mr-1"></i> Messages</router-link>
            <router-link class="dropdown-item" :to="'/my-followers'"><i class="feather-users mr-1"></i> Followers</router-link>
            <router-link class="dropdown-item" :to="'/my-service-requests'"><i class="feather-briefcase mr-1"></i> My Service Requests</router-link>
            <router-link class="dropdown-item" :to="'/my-blog-history'"><i class="feather-globe mr-1"></i> My Blog History</router-link>
            <router-link class="dropdown-item" :to="'/notification-settings'"><i class="feather-list mr-1"></i> Notification Settings</router-link>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" @click="logout()"><i class="feather-log-out mr-1"></i> Logout</a>
            </div>
            </li>
            </ul>

            <ul v-else class="navbar-nav ml-auto d-flex align-items-center">
              <li class="nav-item">
                <router-link class="nav-link" to="/"><i class="feather-folder mr-2"></i><span class="d-none d-lg-inline">Listings</span></router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/blog"><i class="feather-book mr-2"></i><span class="d-none d-lg-inline">Blog</span></router-link>
              </li>
              <li class="nav-item">
                <a style="cursor:pointer" class="nav-link" data-toggle="modal" data-target="#login2-modal"><i class="feather-user mr-2"></i><span class="d-none d-lg-inline">Register | Login</span></a>
              </li>
            </ul>
          </div>
        </nav>
        
    <main class="LayoutDefault__main">
      <slot/>
    </main>

    <footer class="bg-light py-4">
<div class="container">
<div class="d-flex justify-content-between align-items-center">

<p class="small text-muted mb-0">© {{company.short_name}}. 2022, Developed By <a href="https://www.structurecity.com/company/structurecity-associates-limited-1666183265" target="_blank">Structurecity</a> in partnership with <a href="https://www.essyp.com" target="_blank">Essyp</a>.<br>
  <!-- <span style="font-size: 10px"> Developed by <a href="https://www.essyp.com" target="_blank">Essyp</a></span> -->
</p>


<ul class="list-inline mb-0">
<li class="list-inline-item">
<a class="btn btn-sm btn-light" :href="company.facebook" target="_blank">
<span class="feather-facebook"></span>
</a>
</li>
<li class="list-inline-item">
<a class="btn btn-sm btn-light" :href="company.instagram" target="_blank">
<span class="feather-instagram"></span>
</a>
</li>
<li class="list-inline-item">
<a class="btn btn-sm btn-light" :href="company.twitter" target="_blank">
<span class="feather-twitter"></span>
</a>
</li>
<li class="list-inline-item">
<a class="btn btn-sm btn-light" :href="company.linkedin" target="_blank">
<span class="feather-linkedin"></span>
</a>
</li>
 </ul>

</div>
</div>
</footer>
    <!-- <footer class="LayoutDefault__footer">
      &copy; {{company.short_name}} 2022, Powered By <a href="https://www.structurecity.com" target="_blank">Structurecity</a> <br>
     <span style="font-size: 12px"> developed by <a href="https://www.essyp.com" target="_blank">Essyp</a></span>
    </footer>     -->






    <!-- project connect Modal -->
    <div class="modal fade" id="login2-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Login to Continue</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
               <form id="login-form">
                  <div class="form-group">
                      <label class="mb-1">Email</label>
                      <div class="position-relative icon-form-control">
                          <i class="feather-user position-absolute"></i>
                          <input type="email" name="email" class="form-control">
                      </div>
                  </div>
                  <div class="form-group">
                      <label class="mb-1">Password</label>
                      <div class="position-relative icon-form-control">
                          <i class="feather-unlock position-absolute"></i>
                          <input type="password" name="password" class="form-control">
                      </div>
                  </div>
                  <button v-if="loading" class="btn btn-primary btn-block text-uppercase" type="button"> loading...... </button>
                  <button v-else class="btn btn-primary btn-block text-uppercase" type="button" @click="loginNow()"> Sign in </button>
                  <div class="py-3 d-flex align-item-center">
                      <a @click="forgotPassword2()" style="cursor:pointer" to="/forgot-password">Forgot password?</a>
                      <span class="ml-auto"> New to SC-Listing? <a class="font-weight-bold" @click="register2()" style="cursor:pointer" to="/register">Join now</a></span>
                  </div>
              </form>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>&nbsp;
            </div>

          </div>
        </div>

      </div>




    <Chat v-if="user_id !== null"/>
  </div>
</template>

<script>
import Chat from '../components/Chat.vue';
import axios from 'axios';
import moment from 'moment';
import $ from "jquery";

export default  {
  name: 'LayoutDefault',

  components: {
    Chat,
  },

  created() {
        this.fetchCompany();
        this.fetchUser();
        this.fetchPendingFollowBack();
        this.fetchAwaitingStakeholder();
        this.fetchAwaitingVendors();
        this.fetchNotifications();
        this.fetchTotalUnreadNotifications();
  },

  data() {
        return {
            company: {},
            user: {},
            loading: false,
            id: localStorage.getItem('slug'),
            user_id: localStorage.getItem('user'),
            pending_follow: [],
            view_all: false,
            view_all_team: false,
            view_all_vendor: false,
            view_all_notice: false,
            awaiting_stakeholder: [],
            awaiting_vendors:[],
            notifications:[],
            total_unread_notification: 0,
        }
  },   

  methods: {
        logout () {
          localStorage.clear();
          this.$router.go();
        },

        fetchCompany () {
            axios(process.env.VUE_APP_API_URL + 'company').then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.company = response.data;
            }).catch({});
        },

        fetchUser () {
            axios(process.env.VUE_APP_API_URL + 'user/details/'+localStorage.getItem('user')).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.user = response.data;
            }).catch({});
        },

        fetchPendingFollowBack () {
            axios(process.env.VUE_APP_API_URL + 'user/pending-followback/'+localStorage.getItem('user')).then(response =>{
                if (! response.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.pending_follow = response.data;
            }).catch({});
        },

        fetchAwaitingStakeholder () {
            axios(process.env.VUE_APP_API_URL + 'project/awaiting-stakeholders/'+localStorage.getItem('user')).then(response =>{
                if (! response.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.awaiting_stakeholder = response.data;
            }).catch({});
        },

        fetchAwaitingVendors () {
            axios(process.env.VUE_APP_API_URL + 'project/awaiting-vendors/'+localStorage.getItem('user')).then(response =>{
                if (! response.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.awaiting_vendors = response.data;
            }).catch({});
        },

        fetchNotifications () {
            axios(process.env.VUE_APP_API_URL + 'user/notifications/'+localStorage.getItem('user')).then(response =>{
                if (! response.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.notifications = response.data;
            }).catch({});
        },

        fetchTotalUnreadNotifications () {
            axios(process.env.VUE_APP_API_URL + 'user/unread-notification/'+localStorage.getItem('user')).then(response =>{
                if (! response.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.total_unread_notification = response.data;
            }).catch({});
        },

        updateNotification(id){
           axios(process.env.VUE_APP_API_URL + 'user/update-notifications/'+id).then(response =>{
                if (! response.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.$root.$emit('refresh_notify')
            }).catch({});
        },

        follow (id) {
            this.loading = true;
            axios(process.env.VUE_APP_API_URL + 'user/follow-back/'+id)
            .then((response) => {
            if (response.status === 200) {
                this.$toastr.s(response.message);
                this.$root.$emit('refresh_follow')
                this.loading = false;
            } else {
                this.$toastr.e(response.data.message);
                this.loading = false;
            }
            }).catch((error) =>{
                this.$toastr.e(error.response.data.message);
                this.loading = false;
            })
        },

        accept (id, type) {
            this.loading = true;
            axios.post(process.env.VUE_APP_API_URL + 'project/approve-connection', {'id':id, 'type':type})
            .then((response) => {
            if (response.status === 200) {
                this.$toastr.s(response.message);
                this.$root.$emit('refresh_team')
                this.loading = false;
            } else {
                this.$toastr.e(response.data.message);
                this.loading = false;
            }
            }).catch((error) =>{
                this.$toastr.e(error.response.data.message);
                this.loading = false;
            })
        },

        decline (id, type) {
            this.loading = true;
            axios.post(process.env.VUE_APP_API_URL + 'project/decline-stakeholder', {'id':id, 'type':type})
            .then((response) => {
            if (response.status === 200) {
                this.$toastr.s(response.message);
                this.$root.$emit('refresh_team')
                this.loading = false;
            } else {
                this.$toastr.e(response.data.message);
                this.loading = false;
            }
            }).catch((error) =>{
                this.$toastr.e(error.response.data.message);
                this.loading = false;
            })
        },

        viewAllpending(){
          this.view_all = true
        },

        hideAllpending(){
          this.view_all = false
        },

        viewAllpendingNotice(){
          this.view_all_notice = true
        },

        hideAllpendingNotice(){
          this.view_all_notice = false
        },

        viewAllpendingTeam(){
          this.view_all_team = true
        },

        hideAllpendingTeam(){
          this.view_all_team = false
        },

        viewAllpendingVendor(){
          this.view_all_vendor = true
        },

        hideAllpendingVendor(){
          this.view_all_vendor = false
        },

        forgotPassword2 () {
          window.$('#login2-modal').modal('hide');
          this.$router.push('/forgot-password')
          this.$router.go();
        },

        register2 () {
          window.$('#login2-modal').modal('hide');
          this.$router.push('/register')
          this.$router.go();
        },

        loginNow () {
          this.loading = true;
          var form = $("#login-form")[0];
          var _data = new FormData(form);
          axios.post(process.env.VUE_APP_API_URL + 'auth/login', _data)
          .then((response) => {
          if (response.status === 200) {
              window.$('#login2-modal').modal('hide');
              this.$toastr.s(response.message);
              localStorage.setItem('user', response.data.data.id);
              localStorage.setItem('slug', response.data.data.slug);
              // this.$router.push('/feeds')
              this.$router.go();
              this.loading = false;
          } else {
              this.$toastr.e(response.data.message);
              this.loading = false;
          }
          }).catch((error) =>{
              this.$toastr.e(error.response.data.message);
              this.loading = false;
          })
      },

        formatDate(date){
          return moment(date).fromNow();
        },
    },

    mounted: function () {
       this.$root.$on('refresh_follow', ()=>{
        this.fetchPendingFollowBack()
      })

      this.$root.$on('refresh_team', ()=>{
        this.fetchAwaitingStakeholder()
        this.fetchAwaitingVendors()
        this.fetchNotifications()
      })

      this.$root.$on('refresh_notify', ()=>{
        this.fetchNotifications()
        this.fetchTotalUnreadNotifications()
      })
    },

    computed: {
        image_url: function() {
          return process.env.VUE_APP_IMAGE_URL;
        }
    },
}
</script>