<template>
  <layout-default>
      <div class="projectdetail">
        <div class="profile-cover text-center banner-container">
          <img v-if="details.banner" class="img-fluid" :src="details.banner" alt="">
          <img v-else class="img-fluid" :src="image_url+'logo/'+settings.profile_banner" alt="">
         <span v-if="details.user"><button v-if="details.user.id == id || details.user.owner_id == id" type="button" style="float:right; border:0; padding-top: 30px" data-toggle="modal" data-target="#update-banner"><i style="font-size: 25px" class="feather-edit"></i></button></span>
        </div>
        <div class="bg-white shadow-sm border-bottom">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="d-flex align-items-center py-3">
                  <div class="profile-left">
                    <h5 class="font-weight-bold text-dark mb-1 mt-0">{{details.name}}</h5>
                    <p class="mb-0 text-muted"><i class="feather-map-pin"></i> 
                      {{details.city?details.city.name+',':''}} {{details.state?details.state.name+',':''}} {{details.country?details.country.name:''}} -- DatePosted {{formatDate(details.created_at)}} <br> 
                      <span v-if="details.interests">
                        <span v-if="details.interests.length > 0">
                          <router-link v-for="data in details.interests" :key="data.id" class="mr-2 font-weight-bold" :to="'/project/category/'+data.interest.slug">{{data.interest?data.interest.name:''}},&nbsp;</router-link>
                        </span>
                      </span>
                    </p>
                  </div>
                  <div v-if="details.user" class="profile-right ml-auto">                    
                    <span v-if="details.user_id != id && details.user.owner_id != id">Did you participate in this project? <button type="button" data-toggle="modal" data-target="#team-connect-modal" class="btn btn-primary"> Add your info </button></span>
                    <button v-if="details.user.id == id || details.user.owner_id == id" type="button" class="btn btn-light mr-1" @click="openUpdateProject()">  <i class="feather-edit"></i> Edit </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="py-4">
          <div class="container">
            <div class="row">

              <main class="col col-xl-6 order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12">
                <div class="box shadow-sm rounded bg-white mb-3 overflow-hidden">
                    <ul class="nav border-bottom osahan-line-tab" id="myTab" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Overview</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="project-comment-tab" data-toggle="tab" href="#project-comments" role="tab" aria-controls="project-listing" aria-selected="false">Comments</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="project-team-tab" data-toggle="tab" href="#project-teams" role="tab" aria-controls="project-listing" aria-selected="false">Teams</a>
                        </li>
                    </ul>
                </div>
                <div class="tab-content" id="myTabContent">
                  <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                    <div class="box shadow-sm border rounded bg-white mb-3">
                      <div class="box-title border-bottom p-3">
                        <h6 class="m-0">Overview</h6>
                      </div>

                      <carousel v-if="details.images" :per-page="1" navigation-click="forward" :mouse-drag="true" :navigationEnabled="true" navigationNextLabel="<i class='navigation-icon-size'>&#8250;</>" navigationPrevLabel="<i class='navigation-icon-size'>&#8249;</>">
                        <slide v-for="(data, index) in details.images" :key="index">
                          <a v-if="data.path" style="cursor:pointer" @click="() => showSingle(data.path)">
                            <img :src="data.path" style="width: 100%; height: 400px; object-fit: contain">
                          </a>
                        </slide>
                      </carousel>

                      <vue-easy-lightbox
                        :visible="visible"
                        :imgs="imgs"
                        :index="index"
                        @hide="handleHide"
                      ></vue-easy-lightbox>

                      <div class="box-body p-3">
                        <p v-html="details.description"></p>
                      </div>
                    </div>
                    <div class="box shadow-sm border rounded bg-white mb-3">
                      <div class="box-title border-bottom p-3">
                        <h6 class="m-0">Project Details</h6>
                      </div>
                      <div class="box-body">
                        <table class="table table-borderless mb-0">
                          <tbody>
                            <tr class="border-bottom">
                              <th class="p-3">Location</th>
                              <td class="p-3">{{details.city?details.city.name+',':''}} {{details.state?details.state.name+',':''}} {{details.country?details.country.name:''}}</td>
                            </tr>
                            <tr class="border-bottom">
                              <th class="p-3">Address</th>
                              <td class="p-3">{{details.address?details.address: '--'}}</td>
                            </tr>
                            <tr class="border-bottom">
                              <th class="p-3">Date Commenced</th>
                              <td class="p-3">{{details.project_start_date?formatDate2(details.project_start_date):'--'}}</td>
                            </tr>
                            <tr v-if="details.status == 1" class="border-bottom">
                              <th class="p-3">Date Completed</th>
                              <td class="p-3">{{details.project_end_date?formatDate2(details.project_end_date):'--'}}</td>
                            </tr>
                            <tr class="border-bottom">
                              <th class="p-3">Height</th>
                              <td class="p-3">{{details.height?details.height: '--'}}</td>
                            </tr>
                            <tr class="border-bottom">
                              <th class="p-3">Status</th>
                              <td class="p-3"><span v-if="details.status == 1" class="badge badge-success">completed</span><span v-else class="badge badge-warning">on-going</span></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                   <div class="tab-pane fade" id="project-comments" role="tabpanel" aria-labelledby="home-tab">
                    <div class="box shadow-sm border rounded bg-white mb-3">
                      <div class="box-title border-bottom p-3">
                        <h6 class="m-0">Comments</h6>
                      </div>
                      <div v-if="details.comments">
                        <div v-for="com in details.comments" :key="com.id" class="p-3 align-items-top border-bottom osahan-post-comment">
                          <div v-if="com.user" class="dropdown-list-image mr-3">
                            <router-link class="nav-link" :to="'/sc/profile/'+com.user.slug">
                              <img v-if="com.user" class="rounded-circle" :src="image_url+'users/'+com.user.avatar" style="max-height: 150px; max-width: 150px" alt="">
                              <img v-else class="rounded-circle" src="/assets/avatar.png" style="max-height: 150px; max-width: 150px" alt="">
                              <div class="status-indicator bg-success"></div>
                            </router-link>
                          </div>
                          <div class="font-weight-bold">
                            <div v-if="com.user.id == id" class="dropdown" style="float: right">
                              <a class="btn btn-icon" style="" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span style="font-size: 25px;">...</span>
                              </a>

                              <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                  <a class="dropdown-item" @click="editProjectComment(com.id, com.comment)" style="cursor:pointer">Edit</a>
                                  <a class="dropdown-item" style="cursor:pointer" @click="deleteComment(com.id)">Delete</a>                                
                              </div>
                            </div>
                            <div v-if="com.user" class="text-truncate"> 
                              <router-link class="nav-link" :to="'/sc/profile/'+com.user.slug"> {{com.user.name}} </router-link> 
                              <span class="float-right small">{{formatDate(com.created_at)}}</span>
                            </div>
                            <div class="small text-gray-500">{{com.comment}}</div>
                            <div class="osahan-post-footer">
                              <a style="font-size:12px" class="mr-3 text-secondary"><i class="feather-thumbs-up text-danger"></i> {{com.likes.length}}</a>
                              <a v-if="checkIfUser(com.likes)" style="font-size:12px; cursor: pointer" @click="unlikeProjectComment(com.id)" class="mr-3 text-secondary">Unlike</a>
                              <a v-else style="font-size:12px; cursor: pointer" @click="likeProjectComment(com.id)" class="mr-3 text-secondary">Like</a>
                              <a style="font-size:12px; cursor: pointer" @click="replyProjectCommentModal(com.id)" class="mr-3 text-secondary">Reply</a>
                            </div>
                          </div>

                          <div v-if="com.sub">
                            <div v-for="sub in com.sub" :key="sub.id" class="p-3 align-items-top osahan-post-comment">
                              <div v-if="sub.user" class="dropdown-list-image mr-3">
                                <router-link class="nav-link" :to="'/sc/profile/'+sub.user.slug">
                                  <img v-if="sub.user" class="rounded-circle" :src="image_url+'users/'+sub.user.avatar" style="max-height: 150px; max-width: 150px" alt="">
                                  <img v-else class="rounded-circle" src="/assets/avatar.png" style="max-height: 150px; max-width: 150px" alt="">
                                  <div class="status-indicator bg-success"></div>
                                </router-link>
                              </div>
                              <div class="font-weight-bold">
                                <div v-if="sub.user.id == id" class="dropdown" style="float: right">
                                  <a class="btn btn-icon" style="" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span style="font-size: 25px;">...</span>
                                  </a>

                                  <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                      <a class="dropdown-item" @click="editProjectComment(sub.id, sub.comment)" style="cursor:pointer">Edit</a>
                                      <a class="dropdown-item" style="cursor:pointer" @click="deleteComment(sub.id)">Delete</a>                                
                                  </div>
                                </div>
                                <div v-if="sub.user" class="text-truncate"> 
                                  <router-link class="nav-link" :to="'/sc/profile/'+sub.user.slug"> {{sub.user.name}} </router-link> 
                                  <span class="float-right small">{{formatDate(sub.created_at)}}</span>
                                </div>
                                <div class="small text-gray-500">{{sub.comment}}</div>
                                <div class="osahan-post-footer">
                                  <a style="font-size:12px" class="mr-3 text-secondary"><i class="feather-thumbs-up text-danger"></i> {{sub.likes.length}}</a>
                                  <a v-if="checkIfUser(sub.likes)" style="font-size:12px; cursor: pointer" @click="unlikeProjectComment(sub.id)" class="mr-3 text-secondary">Unlike</a>
                                  <a v-else style="font-size:12px; cursor: pointer" @click="likeProjectComment(sub.id)" class="mr-3 text-secondary">Like</a>
                                  <a style="font-size:12px; cursor: pointer" @click="replyProjectCommentModal(sub.id)" class="mr-3 text-secondary">Reply</a>
                                </div>
                              </div>

                              <div v-if="sub.sub">
                                <div v-for="sb in sub.sub" :key="sb.id" class="p-3 align-items-top osahan-post-comment">
                                  <div v-if="sb.user" class="dropdown-list-image mr-3">
                                    <router-link class="nav-link" :to="'/sc/profile/'+sb.user.slug">
                                      <img v-if="sb.user" class="rounded-circle" :src="image_url+'users/'+sb.user.avatar" style="max-height: 150px; max-width: 150px" alt="">
                                      <img v-else class="rounded-circle" src="/assets/avatar.png" style="max-height: 150px; max-width: 150px" alt="">
                                      <div class="status-indicator bg-success"></div>
                                    </router-link>
                                  </div>
                                  <div class="font-weight-bold">
                                    <div v-if="sb.user.id == id" class="dropdown" style="float: right">
                                      <a class="btn btn-icon" style="" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <span style="font-size: 25px;">...</span>
                                      </a>

                                      <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                          <a class="dropdown-item" @click="editProjectComment(sb.id, sub.comment)" style="cursor:pointer">Edit</a>
                                          <a class="dropdown-item" style="cursor:pointer" @click="deleteComment(sb.id)">Delete</a>                                
                                      </div>
                                    </div>
                                    <div v-if="sb.user" class="text-truncate"> 
                                      <router-link class="nav-link" :to="'/sc/profile/'+sb.user.slug"> {{sb.user.name}} </router-link> 
                                      <span class="float-right small">{{formatDate(sb.created_at)}}</span>
                                    </div>
                                    <div class="small text-gray-500">{{sb.comment}}</div>
                                    <div class="osahan-post-footer">
                                      <a style="font-size:12px" class="mr-3 text-secondary"><i class="feather-thumbs-up text-danger"></i> {{sb.likes.length}}</a>
                                      <a v-if="checkIfUser(sb.likes)" style="font-size:12px; cursor: pointer" @click="unlikeProjectComment(sb.id)" class="mr-3 text-secondary">Unlike</a>
                                      <a v-else style="font-size:12px; cursor: pointer" @click="likeProjectComment(sb.id)" class="mr-3 text-secondary">Like</a>
                                      <!-- <a style="font-size:12px; cursor: pointer" @click="replyProjectCommentModal(sb.id)" class="mr-3 text-secondary">Reply</a> -->
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </div>
                          </div>


                        </div>
                      </div>
                      <div class="p-3">
                        <div class="textarea-container">
                          <textarea placeholder="Add Comment..." class="form-control border-0 p-0 shadow-none" rows="2" :id="'submitCommentButton'+details.id" v-model="comment.description[index]" value="" @keydown.enter.exact.prevent @keyup.enter.exact="submitComment($event, details.id)" @keydown.enter.shift.exact="newline"></textarea>
                          <button v-if="loading" type="button" style="border:0; color:#0056B3; font-size: 20px; background-color: white; padding-top:20px"><i class="feather-send"></i></button>
                          <button v-else type="button" style="border:0; color:#0056B3; font-size: 20px; background-color: white; padding-top:20px" @click="sendSubmitComment(details.id)"><i class="feather-send"></i></button>
                        </div>
                      </div>
                    </div>
                  </div>
                

                  <div class="tab-pane fade" id="project-teams" role="tabpanel" aria-labelledby="home-tab">
                    <div class="box shadow-sm border rounded bg-white mb-3">
                      <div class="box-title border-bottom p-3">
                        <h6 class="m-0">Project Teams</h6>
                      </div>
                      <div class="box-body">
                        <table class="table table-borderless mb-0">
                          <tbody>
                            <tr v-if="details.getclient" class="border-bottom">
                              <td class="p-3">
                                <div class="p-3 d-flex align-items-center border-bottom osahan-post-header">                              
                                  <div v-if="details.getclient.type == 'user'" class="dropdown-list-image mr-3">
                                    <router-link :to="'/sc/profile/'+details.getclient.slug">
                                    <img v-if="details.getclient.avatar" class="rounded-circle" :src="image_url+'users/'+details.getclient.avatar" style="max-height: 150px; max-width: 150px" alt="">
                                    <img v-else class="rounded-circle" src="/assets/avatar.png" style="max-height: 150px; max-width: 150px" alt="">
                                    </router-link>
                                  </div>
                                  <div v-else class="dropdown-list-image mr-3">
                                    <router-link :to="'/company/'+details.getclient.slug">
                                    <img v-if="details.getclient.avatar" class="rounded-circle" :src="image_url+'users/'+details.getclient.avatar" style="max-height: 150px; max-width: 150px" alt="">
                                    <img v-else class="rounded-circle" src="/assets/avatar.png" style="max-height: 150px; max-width: 150px" alt="">
                                    </router-link>
                                  </div>
                                  <div class="font-weight-bold">
                                    <h6 class="font-weight-bold text-dark mb-0 text-truncate">Client/Developer</h6>
                                    <div v-if="details.getclient.type == 'user'" class="text-truncate"><router-link :to="'/sc/profile/'+details.getclient.slug">{{details.getclient.name}}</router-link></div>
                                    <div v-else class="text-truncate"><router-link :to="'/company/'+details.getclient.slug">{{details.getclient.name}}</router-link></div>
                                    <div class="small text-gray-500">{{details.getclient.profession}} | {{details.getclient.followers.length}} followers</div>
                                  </div>
                                  <span class="ml-auto"><br><button type="button" @click="follow(details.getclient.id)" class="btn btn-outline-primary btn-sm">Follow</button></span> 
                                </div>
                              </td>
                            </tr>

                            <tr v-if="details.getarchitect" class="border-bottom">
                              <td class="p-3">
                                <div class="p-3 d-flex align-items-center border-bottom osahan-post-header">
                                  <div v-if="details.getarchitect.type == 'user'" class="dropdown-list-image mr-3">
                                    <router-link :to="'/sc/profile/'+details.getarchitect.slug">
                                    <img v-if="details.getarchitect.avatar" class="rounded-circle" :src="image_url+'users/'+details.getarchitect.avatar" style="max-height: 150px; max-width: 150px" alt="">
                                    <img v-else class="rounded-circle" src="/assets/avatar.png" style="max-height: 150px; max-width: 150px" alt="">
                                    </router-link>
                                  </div>
                                  <div v-else class="dropdown-list-image mr-3">
                                    <router-link :to="'/company/'+details.getarchitect.slug">
                                    <img v-if="details.getarchitect.avatar" class="rounded-circle" :src="image_url+'users/'+details.getarchitect.avatar" style="max-height: 150px; max-width: 150px" alt="">
                                    <img v-else class="rounded-circle" src="/assets/avatar.png" style="max-height: 150px; max-width: 150px" alt="">
                                    </router-link>
                                  </div>
                                  <div class="font-weight-bold">
                                    <h6 class="font-weight-bold text-dark mb-0 text-truncate">Project Architect</h6>
                                    <div v-if="details.getarchitect.type == 'user'" class="text-truncate"><router-link :to="'/sc/profile/'+details.getarchitect.slug">{{details.getarchitect.name}}</router-link></div>
                                    <div v-else class="text-truncate"><router-link :to="'/company/'+details.getarchitect.slug">{{details.getarchitect.name}}</router-link></div>
                                    <div class="small text-gray-500">{{details.getarchitect.profession}} | {{details.getarchitect.followers.length}} followers</div>
                                  </div>
                                  <span class="ml-auto"><br><button type="button" @click="follow(details.getarchitect.id)" class="btn btn-outline-primary btn-sm">Follow</button></span> 
                                </div>
                                <a style="cursor:pointer" @click="viewTeam(details.architectteam, 'Architects Team Members')">
                                <div v-if="details.architectteam" class="d-flex align-items-center p-3 border-top border-bottom job-item-body">
                                  <div class="overlap-rounded-circle">
                                    <img v-for="data in details.architectteam" :key="data.id" class="rounded-circle shadow-sm" data-toggle="tooltip" data-placement="top" title="" :src="data.user.avatar?image_url+'users/'+data.user.avatar:'/assets/avatar.png'" alt="" :data-original-title="data.user.name">
                                  </div>
                                  <span class="font-weight-bold text-muted">{{details.architectteam.length}} connections</span>
                                </div>
                                </a>   
                              </td>
                            </tr>

                            <tr v-if="details.structuralengineer" class="border-bottom">
                              <td class="p-3">
                                <div class="p-3 d-flex align-items-center border-bottom osahan-post-header">
                                  <div v-if="details.structuralengineer.type == 'user'" class="dropdown-list-image mr-3">
                                    <router-link :to="'/sc/profile/'+details.structuralengineer.slug">
                                    <img v-if="details.structuralengineer.avatar" class="rounded-circle" :src="image_url+'users/'+details.structuralengineer.avatar" style="max-height: 150px; max-width: 150px" alt="">
                                    <img v-else class="rounded-circle" src="/assets/avatar.png" style="max-height: 150px; max-width: 150px" alt="">
                                    </router-link>
                                  </div>
                                  <div v-else class="dropdown-list-image mr-3">
                                    <router-link :to="'/company/'+details.structuralengineer.slug">
                                    <img v-if="details.structuralengineer.avatar" class="rounded-circle" :src="image_url+'users/'+details.structuralengineer.avatar" style="max-height: 150px; max-width: 150px" alt="">
                                    <img v-else class="rounded-circle" src="/assets/avatar.png" style="max-height: 150px; max-width: 150px" alt="">
                                    </router-link>
                                  </div>
                                  <div class="font-weight-bold">
                                    <h6 class="font-weight-bold text-dark mb-0 text-truncate">Structural Engineer</h6>
                                    <div v-if="details.structuralengineer.type == 'user'" class="text-truncate"><router-link :to="'/sc/profile/'+details.structuralengineer.slug">{{details.structuralengineer.name}}</router-link></div>
                                    <div v-else class="text-truncate"><router-link :to="'/company/'+details.structuralengineer.slug">{{details.structuralengineer.name}}</router-link></div>
                                    <div class="small text-gray-500">{{details.structuralengineer.profession}} | {{details.structuralengineer.followers.length}} followers</div>
                                  </div>
                                  <span class="ml-auto"><br><button type="button" @click="follow(details.structuralengineer.id)" class="btn btn-outline-primary btn-sm">Follow</button></span> 
                                </div>
                                <a style="cursor:pointer" @click="viewTeam(details.structuralengineerteam, 'Structural Engineer Team Members')">
                                <div v-if="details.structuralengineerteam" class="d-flex align-items-center p-3 border-top border-bottom job-item-body">
                                  <div class="overlap-rounded-circle">
                                    <img v-for="data in details.structuralengineerteam" :key="data.id" class="rounded-circle shadow-sm" data-toggle="tooltip" data-placement="top" title="" :src="data.user.avatar?image_url+'users/'+data.user.avatar:'/assets/avatar.png'" alt="" :data-original-title="data.user.name">
                                  </div>
                                  <span class="font-weight-bold text-muted">{{details.structuralengineerteam.length}} connections</span>
                                </div>
                                </a>
                              </td>
                            </tr>

                            <tr v-if="details.mepengineer" class="border-bottom">
                              <td class="p-3">
                                <div class="p-3 d-flex align-items-center border-bottom osahan-post-header">
                                  <div v-if="details.mepengineer.type == 'user'" class="dropdown-list-image mr-3">
                                    <router-link :to="'/sc/profile/'+details.mepengineer.slug">
                                    <img v-if="details.mepengineer.avatar" class="rounded-circle" :src="image_url+'users/'+details.mepengineer.avatar" style="max-height: 150px; max-width: 150px" alt="">
                                    <img v-else class="rounded-circle" src="/assets/avatar.png" style="max-height: 150px; max-width: 150px" alt="">
                                    </router-link>
                                  </div>
                                  <div v-else class="dropdown-list-image mr-3">
                                    <router-link :to="'/company/'+details.mepengineer.slug">
                                    <img v-if="details.mepengineer.avatar" class="rounded-circle" :src="image_url+'users/'+details.mepengineer.avatar" style="max-height: 150px; max-width: 150px" alt="">
                                    <img v-else class="rounded-circle" src="/assets/avatar.png" style="max-height: 150px; max-width: 150px" alt="">
                                    </router-link>
                                  </div>
                                  <div class="font-weight-bold">
                                    <h6 class="font-weight-bold text-dark mb-0 text-truncate">MEP Engineer</h6>
                                    <div v-if="details.mepengineer.type == 'user'" class="text-truncate"><router-link :to="'/sc/profile/'+details.mepengineer.slug">{{details.mepengineer.name}}</router-link></div>
                                    <div v-else class="text-truncate"><router-link :to="'/company/'+details.mepengineer.slug">{{details.mepengineer.name}}</router-link></div>
                                    <div class="small text-gray-500">{{details.mepengineer.profession}} | {{details.mepengineer.followers.length}} followers</div>
                                  </div>
                                  <span class="ml-auto"><br><button type="button" @click="follow(details.mepengineer.id)" class="btn btn-outline-primary btn-sm">Follow</button></span> 
                                </div>
                                <a style="cursor:pointer" @click="viewTeam(details.mepengineerteam, 'MEP Engineer Team Members')">
                                <div v-if="details.mepengineerteam" class="d-flex align-items-center p-3 border-top border-bottom job-item-body">
                                  <div class="overlap-rounded-circle">
                                    <img v-for="data in details.mepengineerteam" :key="data.id" class="rounded-circle shadow-sm" data-toggle="tooltip" data-placement="top" title="" :src="data.user.avatar?image_url+'users/'+data.user.avatar:'/assets/avatar.png'" alt="" :data-original-title="data.user.name">
                                  </div>
                                  <span class="font-weight-bold text-muted">{{details.mepengineerteam.length}} connections</span>
                                </div>
                                </a>
                              </td>
                            </tr>

                            <tr v-if="details.maincontractor" class="border-bottom">
                              <td class="p-3">
                                <div class="p-3 d-flex align-items-center border-bottom osahan-post-header">
                                  <div v-if="details.maincontractor.type == 'user'" class="dropdown-list-image mr-3">
                                    <router-link :to="'/sc/profile/'+details.maincontractor.slug">
                                    <img v-if="details.maincontractor.avatar" class="rounded-circle" :src="image_url+'users/'+details.maincontractor.avatar" style="max-height: 150px; max-width: 150px" alt="">
                                    <img v-else class="rounded-circle" src="/assets/avatar.png" style="max-height: 150px; max-width: 150px" alt="">
                                    </router-link>
                                  </div>
                                  <div v-else class="dropdown-list-image mr-3">
                                    <router-link :to="'/company/'+details.maincontractor.slug">
                                    <img v-if="details.maincontractor.avatar" class="rounded-circle" :src="image_url+'users/'+details.maincontractor.avatar" style="max-height: 150px; max-width: 150px" alt="">
                                    <img v-else class="rounded-circle" src="/assets/avatar.png" style="max-height: 150px; max-width: 150px" alt="">
                                    </router-link>
                                  </div>
                                  <div class="font-weight-bold">
                                    <h6 class="font-weight-bold text-dark mb-0 text-truncate">Main Contractor</h6>
                                    <div v-if="details.maincontractor.type == 'user'" class="text-truncate"><router-link :to="'/sc/profile/'+details.maincontractor.slug">{{details.maincontractor.name}}</router-link></div>
                                    <div v-else class="text-truncate"><router-link :to="'/company/'+details.maincontractor.slug">{{details.maincontractor.name}}</router-link></div>
                                    <div class="small text-gray-500">{{details.maincontractor.profession}} | {{details.maincontractor.followers.length}} followers</div>
                                  </div>
                                  <span class="ml-auto"><br><button type="button" @click="follow(details.maincontractor.id)" class="btn btn-outline-primary btn-sm">Follow</button></span> 
                                </div>
                                <a style="cursor:pointer" @click="viewTeam(details.maincontractorteam, 'Main Contractor Team Members')">
                                <div v-if="details.maincontractorteam" class="d-flex align-items-center p-3 border-top border-bottom job-item-body">
                                  <div class="overlap-rounded-circle">
                                    <img v-for="data in details.maincontractorteam" :key="data.id" class="rounded-circle shadow-sm" data-toggle="tooltip" data-placement="top" title="" :src="data.user.avatar?image_url+'users/'+data.user.avatar:'/assets/avatar.png'" alt="" :data-original-title="data.user.name">
                                  </div>
                                  <span class="font-weight-bold text-muted">{{details.maincontractorteam.length}} connections</span>
                                </div>
                                </a>
                              </td>
                            </tr>

                            <tr v-if="details.pilingcontractor" class="border-bottom">
                              <td class="p-3">
                                <div class="p-3 d-flex align-items-center border-bottom osahan-post-header">
                                  <div v-if="details.pilingcontractor.type == 'user'" class="dropdown-list-image mr-3">
                                    <router-link :to="'/sc/profile/'+details.pilingcontractor.slug">
                                    <img v-if="details.pilingcontractor.avatar" class="rounded-circle" :src="image_url+'users/'+details.pilingcontractor.avatar" style="max-height: 150px; max-width: 150px" alt="">
                                    <img v-else class="rounded-circle" src="/assets/avatar.png" style="max-height: 150px; max-width: 150px" alt="">
                                    </router-link>
                                  </div>
                                  <div v-else class="dropdown-list-image mr-3">
                                    <router-link :to="'/company/'+details.pilingcontractor.slug">
                                    <img v-if="details.pilingcontractor.avatar" class="rounded-circle" :src="image_url+'users/'+details.pilingcontractor.avatar" style="max-height: 150px; max-width: 150px" alt="">
                                    <img v-else class="rounded-circle" src="/assets/avatar.png" style="max-height: 150px; max-width: 150px" alt="">
                                    </router-link>
                                  </div>
                                  <div class="font-weight-bold">
                                    <h6 class="font-weight-bold text-dark mb-0 text-truncate">Piling Contractor</h6>
                                    <div v-if="details.pilingcontractor.type == 'user'" class="text-truncate"><router-link :to="'/sc/profile/'+details.pilingcontractor.slug">{{details.pilingcontractor.name}}</router-link></div>
                                    <div v-else class="text-truncate"><router-link :to="'/company/'+details.pilingcontractor.slug">{{details.pilingcontractor.name}}</router-link></div>
                                    <div class="small text-gray-500">{{details.pilingcontractor.profession}} | {{details.pilingcontractor.followers.length}} followers</div>
                                  </div>
                                  <span class="ml-auto"><br><button type="button" @click="follow(details.pilingcontractor.id)" class="btn btn-outline-primary btn-sm">Follow</button></span> 
                                </div>
                                <a style="cursor:pointer" @click="viewTeam(details.pilingcontractorteam, 'Main Contractor Team Members')">
                                <div v-if="details.pilingcontractorteam" class="d-flex align-items-center p-3 border-top border-bottom job-item-body">
                                  <div class="overlap-rounded-circle">
                                    <img v-for="data in details.pilingcontractorteam" :key="data.id" class="rounded-circle shadow-sm" data-toggle="tooltip" data-placement="top" title="" :src="data.user.avatar?image_url+'users/'+data.user.avatar:'/assets/avatar.png'" alt="" :data-original-title="data.user.name">
                                  </div>
                                  <span class="font-weight-bold text-muted">{{details.pilingcontractorteam.length}} connections</span>
                                </div>
                                </a>
                              </td>
                            </tr>

                            <tr v-if="details.liftcontractor" class="border-bottom">
                              <td class="p-3">
                                <div class="p-3 d-flex align-items-center border-bottom osahan-post-header">
                                  <div v-if="details.liftcontractor.type == 'user'" class="dropdown-list-image mr-3">
                                    <router-link :to="'/sc/profile/'+details.liftcontractor.slug">
                                    <img v-if="details.liftcontractor.avatar" class="rounded-circle" :src="image_url+'users/'+details.liftcontractor.avatar" style="max-height: 150px; max-width: 150px" alt="">
                                    <img v-else class="rounded-circle" src="/assets/avatar.png" style="max-height: 150px; max-width: 150px" alt="">
                                    </router-link>
                                  </div>
                                  <div v-else class="dropdown-list-image mr-3">
                                    <router-link :to="'/company/'+details.liftcontractor.slug">
                                    <img v-if="details.liftcontractor.avatar" class="rounded-circle" :src="image_url+'users/'+details.liftcontractor.avatar" style="max-height: 150px; max-width: 150px" alt="">
                                    <img v-else class="rounded-circle" src="/assets/avatar.png" style="max-height: 150px; max-width: 150px" alt="">
                                    </router-link>
                                  </div>
                                  <div class="font-weight-bold">
                                    <h6 class="font-weight-bold text-dark mb-0 text-truncate">Lift Contractor</h6>
                                    <div v-if="details.liftcontractor.type == 'user'" class="text-truncate"><router-link :to="'/sc/profile/'+details.liftcontractor.slug">{{details.liftcontractor.name}}</router-link></div>
                                    <div v-else class="text-truncate"><router-link :to="'/company/'+details.liftcontractor.slug">{{details.liftcontractor.name}}</router-link></div>
                                    <div class="small text-gray-500">{{details.liftcontractor.profession}} | {{details.liftcontractor.followers.length}} followers</div>
                                  </div>
                                  <span class="ml-auto"><br><button type="button" @click="follow(details.liftcontractor.id)" class="btn btn-outline-primary btn-sm">Follow</button></span> 
                                </div>
                                <a style="cursor:pointer" @click="viewTeam(details.liftcontractorteam, 'Main Contractor Team Members')">
                                <div v-if="details.liftcontractorteam" class="d-flex align-items-center p-3 border-top border-bottom job-item-body">
                                  <div class="overlap-rounded-circle">
                                    <img v-for="data in details.liftcontractorteam" :key="data.id" class="rounded-circle shadow-sm" data-toggle="tooltip" data-placement="top" title="" :src="data.user.avatar?image_url+'users/'+data.user.avatar:'/assets/avatar.png'" alt="" :data-original-title="data.user.name">
                                  </div>
                                  <span class="font-weight-bold text-muted">{{details.liftcontractorteam.length}} connections</span>
                                </div>
                                </a>
                              </td>
                            </tr>

                            <tr v-if="details.projectmanager" class="border-bottom">
                              <td class="p-3">
                                <div class="p-3 d-flex align-items-center border-bottom osahan-post-header">
                                  <div v-if="details.projectmanager.type == 'user'" class="dropdown-list-image mr-3">
                                    <router-link :to="'/sc/profile/'+details.projectmanager.slug">
                                    <img v-if="details.projectmanager.avatar" class="rounded-circle" :src="image_url+'users/'+details.projectmanager.avatar" style="max-height: 150px; max-width: 150px" alt="">
                                    <img v-else class="rounded-circle" src="/assets/avatar.png" style="max-height: 150px; max-width: 150px" alt="">
                                    </router-link>
                                  </div>
                                  <div v-else class="dropdown-list-image mr-3">
                                    <router-link :to="'/company/'+details.projectmanager.slug">
                                    <img v-if="details.projectmanager.avatar" class="rounded-circle" :src="image_url+'users/'+details.projectmanager.avatar" style="max-height: 150px; max-width: 150px" alt="">
                                    <img v-else class="rounded-circle" src="/assets/avatar.png" style="max-height: 150px; max-width: 150px" alt="">
                                    </router-link>
                                  </div>
                                  <div class="font-weight-bold">
                                    <h6 class="font-weight-bold text-dark mb-0 text-truncate">Project Manager</h6>
                                    <div v-if="details.projectmanager.type == 'user'" class="text-truncate"><router-link :to="'/sc/profile/'+details.projectmanager.slug">{{details.projectmanager.name}}</router-link></div>
                                    <div v-else class="text-truncate"><router-link :to="'/company/'+details.projectmanager.slug">{{details.projectmanager.name}}</router-link></div>
                                    <div class="small text-gray-500">{{details.projectmanager.profession}} | {{details.projectmanager.followers.length}} followers</div>
                                  </div>
                                  <span class="ml-auto"><br><button type="button" @click="follow(details.projectmanager.id)" class="btn btn-outline-primary btn-sm">Follow</button></span> 
                                </div>
                                <a style="cursor:pointer" @click="viewTeam(details.projectmanagerteam, 'Main Contractor Team Members')">
                                <div v-if="details.projectmanagerteam" class="d-flex align-items-center p-3 border-top border-bottom job-item-body">
                                  <div class="overlap-rounded-circle">
                                    <img v-for="data in details.projectmanagerteam" :key="data.id" class="rounded-circle shadow-sm" data-toggle="tooltip" data-placement="top" title="" :src="data.user.avatar?image_url+'users/'+data.user.avatar:'/assets/avatar.png'" alt="" :data-original-title="data.user.name">
                                  </div>
                                  <span class="font-weight-bold text-muted">{{details.projectmanagerteam.length}} connections</span>
                                </div>
                                </a>
                              </td>
                            </tr>

                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </main>

              <aside class="col col-xl-3 order-xl-1 col-lg-6 order-lg-2 col-md-6 col-sm-6 col-12">                
                <div v-if="details.vendors" class="box shadow-sm border rounded bg-white mb-3">
                  <div v-if="details.vendors.length">
                    <div class="box-title border-bottom p-3">
                      <h6 class="m-0">Vendors</h6>
                    </div>
                    <div class="box-body p-3">
                      <div v-for="data in details.vendors" :key="data.id" class="d-flex align-items-center osahan-post-header mb-3 people-list">                      
                        <div v-if="data.user.type == 'user'" class="dropdown-list-image mr-3">
                          <router-link :to="'/sc/profile/'+data.user.slug">
                            <img v-if="data.user.avatar" class="rounded-circle" :src="image_url+'users/'+data.user.avatar" alt="">
                            <img v-else class="rounded-circle" src="/assets/avatar.png" alt="">
                            <div class="status-indicator bg-success"></div>
                          </router-link>
                        </div>
                        <div v-else class="dropdown-list-image mr-3">
                          <router-link :to="'/company/'+data.user.slug">
                            <img v-if="data.user.avatar" class="rounded-circle" :src="image_url+'users/'+data.user.avatar" alt="">
                            <img v-else class="rounded-circle" src="/assets/avatar.png" alt="">
                            <div class="status-indicator bg-success"></div>
                          </router-link>
                        </div>
                        <div v-if="data.user.type == 'user'" class="font-weight-bold mr-2">
                          <router-link :to="'/sc/profile/'+data.user.slug">
                            <div class="text-truncate">{{data.user.name}}</div>
                            <div class="small text-gray-500">{{data.category.name}}</div>
                          </router-link>
                        </div>
                        <div v-else class="font-weight-bold mr-2">
                          <router-link :to="'/company/'+data.user.slug">
                            <div class="text-truncate">{{data.user.name}}</div>
                            <div class="small text-gray-500">{{data.category.name}}</div>
                          </router-link>
                        </div>
                        <span class="ml-auto"><button type="button" @click="follow(data.user.id)" class="btn btn-outline-primary btn-sm">Follow</button></span>
                      </div>          
                    </div>
                  </div>
                </div>

                <div class="card-body">
                  <h5 class="card-title mb-4"></h5>
                  <div id="featured-properties" class="carousel slide" data-ride="carousel">
                    <ol class="carousel-indicators">
                      <li data-target="#featured-properties" data-slide-to="0" class=""></li>
                      <li data-target="#featured-properties" data-slide-to="1" class="active"></li>
                    </ol>
                    <div class="carousel-inner">        
                      <div v-for="data in adverts.slice(1)" :key="data.id" class="carousel-item">
                        <div class="border rounded bg-white job-item">
                          <a :href="data.link" target="_blank"><img class="img-fluid ml-auto" :src="image_url+'advert/'+data.image" alt=""></a>
                        </div>
                      </div>
                      <div v-for="data in adverts.slice(0,1)" :key="data.id" class="carousel-item active">
                        <div class="border rounded bg-white job-item">
                          <a :href="data.link" target="_blank"><img class="img-fluid ml-auto" :src="image_url+'advert/'+data.image" alt=""></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </aside>
              <aside class="col col-xl-3 order-xl-3 col-lg-6 order-lg-3 col-md-6 col-sm-6 col-12">
                <div v-if="details.user" class="box mb-3 shadow-sm border rounded bg-white profile-box text-center">
                  <div v-if="details.user" class="py-4 px-3 border-bottom">
                    <a v-if="details.user.type == 'company'" :href="'/company/'+details.user.company.slug" target="_blank">
                      <img v-if="details.user.avatar" class="img-fluid mt-2 rounded-circle" :src="image_url+'users/'+details.user.avatar" style="max-height: 150px; max-width: 150px" alt="">
                      <img v-else class="img-fluid mt-2 rounded-circle" src="/assets/avatar.png" style="max-height: 150px; max-width: 150px" alt="">
                      <h5 class="font-weight-bold text-dark mb-1 mt-4">{{details.user?details.user.name:''}}</h5>
                      <p class="mb-0 text-muted">{{details.user.state?details.user.state.name+',':''}} {{details.user.country?details.user.country.name:''}}</p>
                    </a>
                    <a v-else :href="'/sc/profile/'+details.user.slug" target="_blank">
                      <img v-if="details.user.avatar" class="img-fluid mt-2 rounded-circle" :src="image_url+'users/'+details.user.avatar" style="max-height: 150px; max-width: 150px" alt="">
                      <img v-else class="img-fluid mt-2 rounded-circle" src="/assets/avatar.png" style="max-height: 150px; max-width: 150px" alt="">
                      <h5 class="font-weight-bold text-dark mb-1 mt-4">{{details.user?details.user.name:''}}</h5>
                      <p v-if="details.user.currentcompany" class="mb-0 text-muted">{{details.user.current_position}} <br> <router-link :to="'/company/'+details.user.currentcompany.slug">{{details.user.currentcompany.name}}</router-link></p>
                      <p v-else class="mb-0 text-muted">{{details.user.profession}}</p>
                      <span v-if="details.user.membership" class="text-info"><span v-if="details.user.membership.membership">{{ details.user.membership.membership.code }}</span><i v-if="details.user.membership.status == 1" data-toggle="tooltip" data-placement="top" title="Verified" class="feather-check-circle"></i></span> &nbsp;
                      <span v-if="details.user.certification" class="text-info"><span v-if="details.user.certification.certification">{{ details.user.certification.certification.code }}</span><i v-if="details.user.certification.status == 1" data-toggle="tooltip" data-placement="top" title="Verified" class="feather-check-circle"></i></span>
                      <p class="mb-0 text-muted">{{details.user.state?details.user.state.name+',':''}} {{details.user.country?details.user.country.name:''}}</p>
                    </a>
                  </div>
                </div>
                <div v-if="details.quantitysurveyor || details.mechanicalcontractor || details.electricalcontractor" class="box shadow-sm border rounded bg-white mb-3">
                  <div class="box-title border-bottom p-3">
                    <h6 class="m-0">Other Project Teams </h6>
                  </div>
                  <div class="box-body p-3">
                    <div v-if="details.quantitysurveyor" class="shadow-sm border rounded bg-white job-item mb-3">
                      <div class="d-flex align-items-center p-3 job-item-header">
                        <div class="overflow-hidden mr-2">
                          <h6 class="font-weight-bold text-dark mb-0 text-truncate">Quantity Surveyor</h6>
                          <div v-if="details.quantitysurveyor.type == 'user'" class="text-truncate text-primary"><router-link :to="'/sc/profile/'+details.quantitysurveyor.slug">{{details.quantitysurveyor.name}}.</router-link></div>
                          <div v-else class="text-truncate text-primary"><router-link :to="'/company/'+details.quantitysurveyor.slug">{{details.quantitysurveyor.name}}.</router-link></div>
                          <div v-if="details.quantitysurveyor.country" class="small text-gray-500"><i class="feather-map-pin"></i> {{details.quantitysurveyor.country?details.quantitysurveyor.country.name+',':''}} {{details.quantitysurveyor.state?details.quantitysurveyor.state.name+',':''}}</div>
                          <span class="ml-auto"><br><button type="button" @click="follow(details.quantitysurveyor.id)" class="btn btn-outline-primary btn-sm">Follow</button></span> 
                        </div>
                        <router-link v-if="details.quantitysurveyor.type == 'user'" :to="'/sc/profile/'+details.quantitysurveyor.slug">
                          <img v-if="details.quantitysurveyor.avatar" class="img-fluid ml-auto" :src="image_url+'users/'+details.quantitysurveyor.avatar" alt="">
                          <img v-else class="img-fluid ml-auto" src="/assets/avatar.png" alt="">
                        </router-link>
                        <router-link v-else :to="'/company/'+details.quantitysurveyor.slug">
                          <img v-if="details.quantitysurveyor.avatar" class="img-fluid ml-auto" :src="image_url+'users/'+details.quantitysurveyor.avatar" alt="">
                          <img v-else class="img-fluid ml-auto" src="/assets/avatar.png" alt="">
                        </router-link>
                      </div>
                      <a style="cursor:pointer" @click="viewTeam(details.quantitysurveyorteam, 'Quantity Surveyor Team Members')">
                      <div v-if="details.quantitysurveyorteam" class="d-flex align-items-center p-3 border-top border-bottom job-item-body">
                        <div class="overlap-rounded-circle">
                          <img v-for="data in details.quantitysurveyorteam" :key="data.id" class="rounded-circle shadow-sm" data-toggle="tooltip" data-placement="top" title="" :src="data.user.avatar?image_url+'users/'+data.user.avatar:'/assets/avatar.png'" alt="" :data-original-title="data.user.name">
                        </div>
                        <span class="font-weight-bold text-muted">{{details.quantitysurveyorteam.length}} connections</span>
                      </div>
                      </a>
                    </div>

                    <div v-if="details.mechanicalcontractor" class="shadow-sm border rounded bg-white job-item mb-3">
                      <div class="d-flex align-items-center p-3 job-item-header">
                        <div class="overflow-hidden mr-2">
                          <h6 class="font-weight-bold text-dark mb-0 text-truncate">Mechanical Contractor</h6>
                          <div v-if="details.mechanicalcontractor.type == 'user'" class="text-truncate text-primary"><router-link :to="'/sc/profile/'+details.mechanicalcontractor.slug">{{details.mechanicalcontractor.name}}.</router-link></div>
                          <div v-else class="text-truncate text-primary"><router-link :to="'/company/'+details.mechanicalcontractor.slug">{{details.mechanicalcontractor.name}}.</router-link></div>
                          <div v-if="details.mechanicalcontractor.country" class="small text-gray-500"><i class="feather-map-pin"></i> {{details.mechanicalcontractor.country?details.mechanicalcontractor.country.name+',':''}} {{details.mechanicalcontractor.state?details.mechanicalcontractor.state.name+',':''}}</div>
                          <span class="ml-auto"><br><button type="button" @click="follow(details.mechanicalcontractor.id)" class="btn btn-outline-primary btn-sm">Follow</button></span> 
                        </div>
                        <router-link v-if="details.mechanicalcontractor.type == 'user'" :to="'/sc/profile/'+details.mechanicalcontractor.slug">
                          <img v-if="details.mechanicalcontractor.avatar" class="img-fluid ml-auto" :src="image_url+'users/'+details.mechanicalcontractor.avatar" alt="">
                          <img v-else class="img-fluid ml-auto" src="/assets/avatar.png" alt="">
                        </router-link>
                        <router-link v-else :to="'/company/'+details.mechanicalcontractor.slug">
                          <img v-if="details.mechanicalcontractor.avatar" class="img-fluid ml-auto" :src="image_url+'users/'+details.mechanicalcontractor.avatar" alt="">
                          <img v-else class="img-fluid ml-auto" src="/assets/avatar.png" alt="">
                        </router-link>
                      </div>
                      <a style="cursor:pointer" @click="viewTeam(details.mechanicalcontractorteam, 'Mechanical Contractor Team Members')">
                      <div v-if="details.mechanicalcontractorteam" class="d-flex align-items-center p-3 border-top border-bottom job-item-body">
                        <div class="overlap-rounded-circle">
                          <img v-for="data in details.mechanicalcontractorteam" :key="data.id" class="rounded-circle shadow-sm" data-toggle="tooltip" data-placement="top" title="" :src="data.user.avatar?image_url+'users/'+data.user.avatar:'/assets/avatar.png'" alt="" :data-original-title="data.user.name">
                        </div>
                        <span class="font-weight-bold text-muted">{{details.mechanicalcontractorteam.length}} connections</span>
                      </div>
                      </a>
                    </div>

                    <div v-if="details.electricalcontractor" class="shadow-sm border rounded bg-white job-item mb-3">
                      <div class="d-flex align-items-center p-3 job-item-header">
                        <div class="overflow-hidden mr-2">
                          <h6 class="font-weight-bold text-dark mb-0 text-truncate">Electrical Contractor</h6>
                          <div v-if="details.electricalcontractor.type == 'user'" class="text-truncate text-primary"><router-link :to="'/sc/profile/'+details.electricalcontractor.slug">{{details.electricalcontractor.name}}.</router-link></div>
                          <div v-else class="text-truncate text-primary"><router-link :to="'/company/'+details.electricalcontractor.slug">{{details.electricalcontractor.name}}.</router-link></div>
                          <div v-if="details.electricalcontractor.country" class="small text-gray-500"><i class="feather-map-pin"></i> {{details.electricalcontractor.country?details.electricalcontractor.country.name+',':''}} {{details.electricalcontractor.state?details.electricalcontractor.state.name+',':''}}</div>
                          <span class="ml-auto"><br><button type="button" @click="follow(details.electricalcontractor.id)" class="btn btn-outline-primary btn-sm">Follow</button></span> 
                        </div>
                        <router-link v-if="details.electricalcontractor.type == 'user'" :to="'/sc/profile/'+details.electricalcontractor.slug">
                          <img v-if="details.electricalcontractor.avatar" class="img-fluid ml-auto" :src="image_url+'users/'+details.electricalcontractor.avatar" alt="">
                          <img v-else class="img-fluid ml-auto" src="/assets/avatar.png" alt="">
                        </router-link>
                        <router-link v-else :to="'/company/'+details.electricalcontractor.slug">
                          <img v-if="details.electricalcontractor.avatar" class="img-fluid ml-auto" :src="image_url+'users/'+details.electricalcontractor.avatar" alt="">
                          <img v-else class="img-fluid ml-auto" src="/assets/avatar.png" alt="">
                        </router-link>
                      </div>
                      <a style="cursor:pointer" @click="viewTeam(details.electricalcontractorteam, 'Electrical Contractor Team Members')">
                      <div v-if="details.electricalcontractorteam" class="d-flex align-items-center p-3 border-top border-bottom job-item-body">
                        <div class="overlap-rounded-circle">
                          <img v-for="data in details.electricalcontractorteam" :key="data.id" class="rounded-circle shadow-sm" data-toggle="tooltip" data-placement="top" title="" :src="data.user.avatar?image_url+'users/'+data.user.avatar:'/assets/avatar.png'" alt="" :data-original-title="data.user.name">
                        </div>
                        <span class="font-weight-bold text-muted">{{details.electricalcontractorteam.length}} connections</span>
                      </div>
                      </a>
                    </div>

                  </div>
                </div>
              </aside>
            </div>


            <div v-if="related_project.length > 0">
              <div class="d-flex align-items-center py-3">
                <div class="profile-left">
                  <h5 class="font-weight-bold text-dark mb-1 mt-0">Related Projects</h5>
                </div>
              </div>

              
              <div class="row">
                <div class="col-lg-12 col-md-12">
                  <div class="row">
                    <div v-for="(data, index) in related_project" :key="index" class="col-lg-4 col-md-4">
                      <div v-if="data.project" class="box shadow-sm rounded bg-white mb-3 blog-card border-0">
                        <a :href="'/project/'+data.project.slug">
                          <span v-if="data.project.images">
                            <img v-if="data.project.images.length > 0" class="card-img-top" :src="data.project.images[0].path" style="height:300px; width:100%; object-fit:cover; border-radius: 20px" alt="">
                          </span>
                        </a>
                        <div class="card-body">
                          <span class="badge badge-success">{{data.interest.name}}</span>
                          <a :href="'/project/'+data.project.slug"><h6 class="text-dark">{{data.project.name}}</h6></a>
                          <!-- <p class="mb-0"><span v-html="data.project.description.substring(0,150)+'....'"></span></p> -->
                        </div>
                        <div class="card-footer border-0">
                          <p v-if="data.project.user" class="mb-0">
                            <router-link v-if="data.project.user.type == 'user'" :to="'/sc/profile/'+data.project.user.slug" target="_blank">
                              <img v-if="data.project.user.avatar" class="rounded-circle" :src="image_url+'users/'+data.project.user.avatar" alt="" />
                              <img v-else class="rounded-circle" :src="image_url+'avatar.png'" alt="" />
                              <strong>{{data.project.user.name}}</strong> &nbsp;
                              <span style="font-size: 10px">{{formatDate(data.project.created_at)}}</span>
                            </router-link>
                            <router-link v-if="data.project.user.type == 'company'" :to="'/company/'+data.project.user.company.slug" target="_blank">
                              <img v-if="data.project.user.avatar" class="rounded-circle" :src="image_url+'users/'+data.project.user.avatar" alt="" />
                              <img v-else class="rounded-circle" :src="image_url+'avatar.png'" alt="" />  
                              <strong>{{data.project.user.name}}</strong> &nbsp;
                              <span style="font-size: 10px">{{formatDate(data.project.created_at)}}</span>
                            </router-link>
                          </p>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <center>
                <!-- <button class="btn btn-default" type="button" @click="handleLoadMore">Load More</button> -->
                <div class="infinite-wrapper">
                <infinite-loading force-use-infinite-wrapper=".infinite-wrapper" @distance="3" @infinite="handleLoadMore"></infinite-loading>
                </div>
              </center>        
            </div>

          </div>   

        </div>




        <!-- connections Modal -->
      <div class="modal fade" id="team-view" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">{{titleTeam}}</h5>
              <button type="button" class="close" @click="closeTeamModal()" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div v-for="data in currentData" :key="data.id" class="p-3 d-flex align-items-center border-bottom osahan-post-header">
                <div v-if="data.user.type == 'user'" class="dropdown-list-image mr-3">
                  <router-link :to="'/sc/profile/'+data.user.slug">
                  <img v-if="data.user.avatar" class="rounded-circle" :src="image_url+'users/'+data.user.avatar" style="max-height: 150px; max-width: 150px" alt="">
                  <img v-else class="rounded-circle" src="/assets/avatar.png" style="max-height: 150px; max-width: 150px" alt="">
                  </router-link>
                </div>
                <div v-else class="dropdown-list-image mr-3">
                  <router-link :to="'/company/'+data.user.slug">
                  <img v-if="data.user.avatar" class="rounded-circle" :src="image_url+'users/'+data.user.avatar" style="max-height: 150px; max-width: 150px" alt="">
                  <img v-else class="rounded-circle" src="/assets/avatar.png" style="max-height: 150px; max-width: 150px" alt="">
                  </router-link>
                </div>
                <div v-if="data.user.type == 'user'" class="font-weight-bold">
                  <div class="text-truncate"><router-link :to="'/sc/profile/'+data.user.slug">{{data.user.name}}</router-link></div>
                  <div class="small text-gray-500">{{data.user.profession}} </div>
                </div>
                <div v-else class="font-weight-bold">
                  <div class="text-truncate"><router-link :to="'/company/'+data.user.slug">{{data.user.name}}</router-link></div>
                  <div class="small text-gray-500">{{data.user.profession}} </div>
                </div>
                <span class="ml-auto"><br><button type="button" @click="follow(data.user.id)" class="btn btn-outline-primary btn-sm">Follow</button></span> 
              </div>
            </div>
            <div class="modal-footer">
              <div>
                <button type="button" @click="closeTeamModal()" class="btn btn-secondary" data-dismiss="modal">Close</button>&nbsp;
              </div>
            </div>

          </div>
        </div>
      </div>


      <!-- project connect Modal -->
      <div class="modal fade" id="team-connect-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div v-if="id" class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Connect to this Project</h5>
              <button type="button" class="close" @click="closeTeamConnectModal()" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-md-12 mb-12 mb-sm-12">
                  <div class="js-form-message">
                    <div class="form-group">
                      <select class="form-control custom-select" name="connection_type" v-model="connection.connection_type" data-msg="select role played" data-error-class="u-has-error" data-success-class="u-has-success" required>
                        <option value="" disabled selected>select role played</option>
                        <option value="architect">Architect</option>
                        <option value="structural_engineer">Structural Engineer</option>
                        <option value="mep_engineer">Mep Engineer</option>
                        <option value="quantity_surveyor">Quantity Surveyor</option>
                        <option value="main_contractor">Main Contractor</option>
                        <option value="mechanical_contractor">Mechanical Contractor</option>
                        <option value="electrical_contractor">Electrical Contractor</option>
                        <option value="piling_contractor">Piling Contractor</option>
                        <option value="lift_contractor">Lift Contractor</option>
                        <option value="project_manager">Project Manager</option>
                        <option value="vendor">Vendor</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div v-if="connection.connection_type == 'vendor'" class="col-md-12 mb-12 mb-sm-12">
                  <div class="js-form-message">
                    <div class="form-group">
                      <select class="form-control custom-select" name="vendor_category" v-model="connection.vendor_category" data-msg="select role played" data-error-class="u-has-error" data-success-class="u-has-success">
                        <option value="" disabled selected>select vendor category</option>
                        <option v-for="data in vendor_category" :key="data.id" :value="data.id">{{data.name}}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <div v-if="loading">
                <button type="button" class="btn btn-primary" data-dismiss="modal">Loading.....</button>
              </div>
              <div v-else>
                <button type="button" @click="closeTeamConnectModal()" class="btn btn-secondary" data-dismiss="modal">Close</button>&nbsp;
                <button type="button" @click="connectProject()" class="btn btn-primary">Send Connection</button>
              </div>
            </div>

          </div>
        </div>



        <div v-else class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Login to Connect</h5>
              <button type="button" @click="closeTeamConnectModal()" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
               <form id="login-form">
                  <div class="form-group">
                      <label class="mb-1">Email</label>
                      <div class="position-relative icon-form-control">
                          <i class="feather-user position-absolute"></i>
                          <input type="email" name="email" class="form-control">
                      </div>
                  </div>
                  <div class="form-group">
                      <label class="mb-1">Password</label>
                      <div class="position-relative icon-form-control">
                          <i class="feather-unlock position-absolute"></i>
                          <input type="password" name="password" class="form-control">
                      </div>
                  </div>
                  <div class="custom-control custom-checkbox mb-3">
                      <input type="checkbox" class="custom-control-input" id="customCheck1">
                      <label class="custom-control-label" for="customCheck1">Remember password</label>
                  </div>
                  <button v-if="loading" class="btn btn-primary btn-block text-uppercase" type="button"> loading...... </button>
                  <button v-else class="btn btn-primary btn-block text-uppercase" type="button" @click="login()"> Sign in </button>
                  <div class="py-3 d-flex align-item-center">
                      <a @click="forgotPassword()" style="cursor:pointer" to="/forgot-password">Forgot password?</a>
                      <span class="ml-auto"> New to SC-Listing? <a class="font-weight-bold" @click="register()" style="cursor:pointer" to="/register">Join now</a></span>
                  </div>
              </form>
            </div>
            <div class="modal-footer">
              <button type="button" @click="closeTeamConnectModal()" class="btn btn-secondary" data-dismiss="modal">Close</button>&nbsp;
            </div>

          </div>
        </div>

      </div>



      <!-- update banner Modal -->
      <div class="modal fade" id="update-banner" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Update Project Banner </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div id="banner-form">
                <div class="row">                  
                  <div class="col-md-8 mb-8 mb-sm-12">
                    <div class="js-form-message">
                      <label id="nameLabel" class="form-label">Select Image
                        <span class="text-danger" style="font-size: 10px">* (1500px X 300px)</span>
                      </label>
                      <div class="form-group">
                        <input class="form-control" type="file" name="banner" accept="image/*"  @change="setImage" required/>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 mb-4 mb-sm-12">
                    <div class="js-form-message">
                     <div class="form-group">
                        <img :src="imageSrc" style="width: 100px;" />
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="this.imageSrc" class="row">                  
                  <div class="col-md-12 mb-12 mb-sm-12">
                    <div class="my-3 d-flex align-items-center justify-content-center mx-auto">
                    <vue-cropper
                      class="mr-2 w-50"
                      ref="cropper"
                      :guides="true"
                      :aspect-ratio="1500 / 400"
                      :src="imageSrc"
                    ></vue-cropper>
                    </div>
                  </div>
                  <div class="col-md-12 mb-12 mb-sm-12">
                    <!-- Cropped image previewer -->
                    <img class="ml-2 w-50 bg-light my-3 d-flex align-items-center justify-content-center mx-auto" :src="croppedImageSrc" />
                  </div>
                </div>

                <div v-if="modal" class="md-overlay" style="visibility:visible; opacity:1; background: rgba(27, 24, 24, 0.21);"></div>
                <div v-if="editmodal" class="md-overlay" style="visibility:visible; opacity:1; background: rgba(27, 24, 24, 0.21);"></div>
              </div>
            </div>
            <div class="modal-footer">
              <div v-if="loading">
                <button type="button" class="btn btn-primary" data-dismiss="modal">Loading.....</button>
              </div>
              <div v-else>
                <button v-if="this.imageSrc" @click="cropImage" type="button" class="btn btn-primary">Crop</button>&nbsp; &nbsp; 
                <button v-if="this.croppedImageSrc" @click="uploadImage(details.id)" type="button" class="btn btn-primary">Upload Image</button>
              </div>
              <!-- <div v-if="loading">
                <button v-if="this.imageSrc" @click="cropImage" type="button" class="btn btn-primary" data-dismiss="modal">Loading.....</button>
              </div>
              <div v-else>
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>&nbsp;
                <button type="button" @click="updateBanner()" class="btn btn-primary">Update</button>
              </div> -->
            </div>

          </div>
        </div>
      </div>




      <!-- update project Modal -->
      <div class="modal fade" id="project-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Update Project Details</h5>
              <button type="button" class="close" @click="cancelForm()" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="border rounded bg-white mb-3">
                <div>
                  <div class="box-title border-bottom p-3">
                    <h6 class="m-0">Supply Project Details</h6>
                    <p class="mb-0 mt-0 small">update your project details .</p>
                  </div>
                  <div class="box-body p-3">
                    <form id="create-form" class="js-validate" novalidate="novalidate">
                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Project Name
                            <span class="text-danger">*</span>
                            </label>
                            <div class="form-group">
                              <input type="text" class="form-control" name="name" v-model="project.name" required="">
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Project Description
                            <span class="text-danger">*</span>
                            </label>
                            <div class="form-group">
                              <vue-editor v-model="project.description" :editorToolbar="customToolbar"></vue-editor>
                              <!-- <textarea type="text" class="form-control" name="description" rows="5" v-model="project.description" required></textarea> -->
                            </div>
                          </div>
                        </div>
                      </div>

                      <label class="form-label">Project Category
                      <span class="text-danger">*</span>
                      </label>
                      <div class="row">
                        <div class="col-md-4 mb-3 mb-sm-4">
                          <div class="js-form-message">
                            <div class="form-group">
                              <select class="form-control custom-select" name="project_interest_1" v-model="project.project_interest_1" @change='fectchInterestChildren($event)'>
                                <option value="" disabled selected>select Category</option>
                                <option v-for="(data, index) in interests" :key="index" :value="data.id">{{data.name}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 mb-3 mb-sm-4">
                          <div v-if="interest_children.length > 0" class="js-form-message">
                            <div class="form-group">
                              <select class="form-control custom-select" name="project_interest_2" v-model="project.project_interest_2" data-msg="Please select state." data-error-class="u-has-error" data-success-class="u-has-success" @change='fectchInterestChildren2($event)'>
                                <option value="" disabled selected>select category</option>
                                <option v-for="data in interest_children" :key="data.id" :value="data.id">{{data.name}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 mb-3 mb-sm-4">
                          <div v-if="interest_children2.length > 0" class="js-form-message">
                            <div class="form-group">
                              <select class="form-control custom-select" name="project_interest_3" v-model="project.project_interest_3" data-msg="Please select city." data-error-class="u-has-error" data-success-class="u-has-success">
                                <option value="" disabled selected>select category</option>
                                <option v-for="data in interest_children2" :key="data.id" :value="data.id">{{data.name}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-4 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Start Date
                            <span class="text-danger">*</span>
                            </label>
                            <div class="form-group">
                              <input type="date" class="form-control" name="project_start_date" v-model="project.project_start_date" required="">
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-4 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Project Status
                            <span class="text-danger">*</span>
                            </label>
                            <div class="form-group">
                              <select type="date" class="form-control" name="status" v-model="project.status" required="">
                                <option value="" disabled selected>select option</option>
                                <option value="1">Completed</option>
                                <option value="2">On-going</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-4 mb-2">
                          <div v-if="project.status == 1" class="js-form-message">
                            <label id="nameLabel" class="form-label">End Date
                            <span class="text-danger">*</span>
                            </label>
                            <div class="form-group">
                              <input type="date" class="form-control" name="project_end_date" v-model="project.project_end_date" required="">
                            </div>
                          </div>
                        </div>
                      </div>

                      <label class="form-label">Project Location
                      <span class="text-danger">*</span>
                      </label>
                      <div class="row">
                        <div class="col-md-4 mb-3 mb-sm-4">
                          <div class="js-form-message">
                            <div class="form-group">
                              <select class="form-control custom-select" name="country_id" v-model="project.country_id" data-msg="Please select country." data-error-class="u-has-error" data-success-class="u-has-success" @change='fetchStates($event)'>
                                <option value="" disabled selected>Country</option>
                                <option v-for="data in country" :key="data.id" :value="data.id">{{data.name}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 mb-3 mb-sm-4">
                          <div class="js-form-message">
                            <div class="form-group">
                              <select class="form-control custom-select" name="state_id" v-model="project.state_id" data-msg="Please select state." data-error-class="u-has-error" data-success-class="u-has-success" @change='fetchCities($event)'>
                                <option value="" disabled selected>State</option>
                                <option v-for="data in state" :key="data.id" :value="data.id">{{data.name}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 mb-3 mb-sm-4">
                          <div class="js-form-message">
                            <div class="form-group">
                              <select class="form-control custom-select" name="city_id" v-model="project.city_id" data-msg="Please select city." data-error-class="u-has-error" data-success-class="u-has-success">
                                <option value="" disabled selected>City</option>
                                <option v-for="data in city" :key="data.id" :value="data.id">{{data.name}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Address</label>
                            <div class="form-group">
                              <textarea class="form-control" name="address" v-model="project.address"></textarea>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Height</label>
                            <div class="form-group">
                              <select class="form-control" name="height" v-model="project.height">
                                <option value="" disabled selected>~select~</option>
                                <option value="Low rise (Less than 3 Storeys)">Low rise (Less than 3 Storeys)</option>
                                <option value="Mid rise (4 - 10 Storeys)">Mid rise (4 - 10 Storeys)</option>
                                <option value="High rise (11 - 30 Storeys)">High rise (11 - 30 Storeys)</option>
                                <option value="Skyscrappers (above 30 storeys)">Skyscrappers (above 30 storeys)</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Project Client/Developer
                            <span class="text-danger">*</span>
                            </label>
                            <div class="form-group">
                              <multiselect v-model="project.client" deselect-label="Can't remove this value" label="name" placeholder="Select one" :options="users" :allow-empty="true"></multiselect>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Project Architect
                            <span style="font-size: 12px"><a style="cursor:pointer" @click="addArchitectTeam">Add Team Member</a></span>
                            </label>
                            <div class="form-group">
                              <multiselect v-model="project.architect" deselect-label="Can't remove this value" label="name" placeholder="Select one" :options="users" :allow-empty="true"></multiselect>

                              <div class="row" v-for="(input, index) in details.architectteam" :key="index">
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6" style="padding-top: 12px">
                                  <input type="text" class="form-control" v-model="input.user.name" readonly>
                                </div>
                                <div class="col-lg-2" style="padding-top: 12px">
                                  <button type="button" @click="deleteTeamMember(input.id)" style="color: red; border:0; background-color: white">X</button>
                                </div>
                                <div class="col-lg-2"></div>
                                <br>
                              </div>
                              
                              <div class="row" v-for="(input, index) in project.architect_team" :key="index">
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6" style="padding-top: 12px">
                                  <model-list-select :list="users" v-model="input.user_id" option-value="id" option-text="name" placeholder="search team"></model-list-select>
                                  <!-- <v-select :options="users" label="name" name="architect_team[]" v-model="input.user_id"></v-select> -->
                                </div>
                                <div class="col-lg-2" style="padding-top: 12px">
                                  <button type="button" @click="deleteArchitectTeam(index)" style="color: red; border:0; background-color: white">X</button>
                                </div>
                                <div class="col-lg-2"></div>
                                <br>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Project Structural Engineer
                            <span style="font-size: 12px"><a style="cursor:pointer" @click="addStructuralEngineerTeam">Add Team Member</a></span>
                            </label>
                            <div class="form-group">
                              <multiselect v-model="project.structural_engineer" deselect-label="Can't remove this value" label="name" placeholder="Select one" :options="users" :allow-empty="true"></multiselect>

                              <div class="row" v-for="(input, index) in details.structuralengineerteam" :key="index">
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6" style="padding-top: 12px">
                                  <input type="text" class="form-control" v-model="input.user.name" readonly>
                                </div>
                                <div class="col-lg-2" style="padding-top: 12px">
                                  <button type="button" @click="deleteTeamMember(input.id)" style="color: red; border:0; background-color: white">X</button>
                                </div>
                                <div class="col-lg-2"></div>
                                <br>
                              </div>

                              <div class="row" v-for="(input, index) in project.structural_engineer_team" :key="index">
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6" style="padding-top: 12px">
                                  <model-list-select :list="users" v-model="input.user_id" option-value="id" option-text="name" placeholder="search team"></model-list-select>
                                  <!-- <v-select :options="users" label="name" name="structural_engineer_team[]" v-model="input.user_id"></v-select> -->
                                </div>
                                <div class="col-lg-2" style="padding-top: 12px">
                                  <button type="button" @click="deleteStructuralEngineerTeam(index)" style="color: red; border:0">X</button>
                                </div>
                                <div class="col-lg-2"></div>
                                <br>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Project Mep Engineer
                            <span style="font-size: 12px"><a style="cursor:pointer" @click="addMepEngineerTeam">Add Team Member</a></span>
                            </label>
                            <div class="form-group">
                              <multiselect v-model="project.mep_engineer" deselect-label="Can't remove this value" label="name" placeholder="Select one" :options="users" :allow-empty="true"></multiselect>

                               <div class="row" v-for="(input, index) in details.mepengineerteam" :key="index">
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6" style="padding-top: 12px">
                                  <input type="text" class="form-control" v-model="input.user.name" readonly>
                                </div>
                                <div class="col-lg-2" style="padding-top: 12px">
                                  <button type="button" @click="deleteTeamMember(input.id)" style="color: red; border:0; background-color: white">X</button>
                                </div>
                                <div class="col-lg-2"></div>
                                <br>
                              </div>

                              <div class="row" v-for="(input, index) in project.mep_engineer_team" :key="index">
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6" style="padding-top: 12px">
                                  <model-list-select :list="users" v-model="input.user_id" option-value="id" option-text="name" placeholder="search team"></model-list-select>
                                  <!-- <v-select :options="users" label="name" name="mep_engineer_team[]" v-model="input.user_id"></v-select> -->
                                </div>
                                <div class="col-lg-2" style="padding-top: 12px">
                                  <button type="button" @click="deleteMepEngineerTeam(index)" style="color: red; border:0">X</button>
                                </div>
                                <div class="col-lg-2"></div>
                                <br>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Project Quantity Surveyor
                            <span style="font-size: 12px"><a style="cursor:pointer" @click="addSurveyorTeam">Add Team Member</a></span>
                            </label>
                            <div class="form-group">
                              <multiselect v-model="project.quantity_surveyor" deselect-label="Can't remove this value" label="name" placeholder="Select one" :options="users" :allow-empty="true"></multiselect>

                              <div class="row" v-for="(input, index) in details.quantitysurveyorteam" :key="index">
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6" style="padding-top: 12px">
                                  <input type="text" class="form-control" v-model="input.user.name" readonly>
                                </div>
                                <div class="col-lg-2" style="padding-top: 12px">
                                  <button type="button" @click="deleteTeamMember(input.id)" style="color: red; border:0; background-color: white">X</button>
                                </div>
                                <div class="col-lg-2"></div>
                                <br>
                              </div>

                              <div class="row" v-for="(input, index) in project.quantity_surveyor_team" :key="index">
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6" style="padding-top: 12px">
                                  <model-list-select :list="users" v-model="input.user_id" option-value="id" option-text="name" placeholder="search team"></model-list-select>
                                  <!-- <v-select :options="users" label="name" name="quantity_surveyor_team[]" v-model="input.user_id"></v-select> -->
                                </div>
                                <div class="col-lg-2" style="padding-top: 12px">
                                  <button type="button" @click="deleteSurveyorTeam(index)" style="color: red; border:0">X</button>
                                </div>
                                <div class="col-lg-2"></div>
                                <br>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Project Main Contractor
                            <span style="font-size: 12px"><a style="cursor:pointer" @click="addMainContractorTeam">Add Team Member</a></span>
                            </label>
                            <div class="form-group">
                              <multiselect v-model="project.main_contractor" deselect-label="Can't remove this value" label="name" placeholder="Select one" :options="users" :allow-empty="true"></multiselect>

                              <div class="row" v-for="(input, index) in details.maincontractorteam" :key="index">
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6" style="padding-top: 12px">
                                  <input type="text" class="form-control" v-model="input.user.name" readonly>
                                </div>
                                <div class="col-lg-2" style="padding-top: 12px">
                                  <button type="button" @click="deleteTeamMember(input.id)" style="color: red; border:0; background-color: white">X</button>
                                </div>
                                <div class="col-lg-2"></div>
                                <br>
                              </div>

                              <div class="row" v-for="(input, index) in project.main_contractor_team" :key="index">
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6" style="padding-top: 12px">
                                  <model-list-select :list="users" v-model="input.user_id" option-value="id" option-text="name" placeholder="search team"></model-list-select>
                                  <!-- <v-select :options="users" label="name" name="main_contractor_team[]" v-model="input.user_id"></v-select> -->
                                </div>
                                <div class="col-lg-2" style="padding-top: 12px">
                                  <button type="button" @click="deleteMainContractorTeam(index)" style="color: red; border:0">X</button>
                                </div>
                                <div class="col-lg-2"></div>
                                <br>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Project Mechanical Contractor
                            <span style="font-size: 12px"><a style="cursor:pointer" @click="addMechanicalContractorTeam">Add Team Member</a></span>
                            </label>
                            <div class="form-group">
                              <multiselect v-model="project.mechanical_contractor" deselect-label="Can't remove this value" label="name" placeholder="Select one" :options="users" :allow-empty="true"></multiselect>

                              <div class="row" v-for="(input, index) in details.mechanicalcontractorteam" :key="index">
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6" style="padding-top: 12px">
                                  <input type="text" class="form-control" v-model="input.user.name" readonly>
                                </div>
                                <div class="col-lg-2" style="padding-top: 12px">
                                  <button type="button" @click="deleteTeamMember(input.id)" style="color: red; border:0; background-color: white">X</button>
                                </div>
                                <div class="col-lg-2"></div>
                                <br>
                              </div>

                              <div class="row" v-for="(input, index) in project.mechanical_contractor_team" :key="index">
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6" style="padding-top: 12px">
                                  <model-list-select :list="users" v-model="input.user_id" option-value="id" option-text="name" placeholder="search team"></model-list-select>
                                  <!-- <v-select :options="users" label="name" name="mechanical_contractor_team[]" v-model="input.user_id"></v-select> -->
                                </div>
                                <div class="col-lg-2" style="padding-top: 12px">
                                  <button type="button" @click="deleteMechanicalContractorTeam(index)" style="color: red; border:0">X</button>
                                </div>
                                <div class="col-lg-2"></div>
                                <br>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Project Electrical Contractor
                            <span style="font-size: 12px"><a style="cursor:pointer" @click="addElectricalContractorTeam">Add Team Member</a></span>
                            </label>
                            <div class="form-group">
                              <multiselect v-model="project.electrical_contractor" deselect-label="Can't remove this value" label="name" placeholder="Select one" :options="users" :allow-empty="true"></multiselect>

                              <div class="row" v-for="(input, index) in details.electricalcontractorteam" :key="index">
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6" style="padding-top: 12px">
                                  <input type="text" class="form-control" v-model="input.user.name" readonly>
                                </div>
                                <div class="col-lg-2" style="padding-top: 12px">
                                  <button type="button" @click="deleteTeamMember(input.id)" style="color: red; border:0; background-color: white">X</button>
                                </div>
                                <div class="col-lg-2"></div>
                                <br>
                              </div>

                              <div class="row" v-for="(input, index) in project.electrical_contractor_team" :key="index">
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6" style="padding-top: 12px">
                                  <model-list-select :list="users" v-model="input.user_id" option-value="id" option-text="name" placeholder="search team"></model-list-select>
                                  <!-- <v-select :options="users" label="name" name="electrical_contractor_team[]" v-model="input.user_id"></v-select> -->
                                </div>
                                <div class="col-lg-2" style="padding-top: 12px">
                                  <button type="button" @click="deleteElectricalContractorTeam(index)" style="color: red; border:0">X</button>
                                </div>
                                <div class="col-lg-2"></div>
                                <br>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Project Piling Contractor
                            <span style="font-size: 12px"><a style="cursor:pointer" @click="addPilingTeam">Add Team Member</a></span>
                            </label>
                            <div class="form-group">
                              <multiselect v-model="project.piling_contractor" deselect-label="Can't remove this value" label="name" placeholder="Select one" :options="users" :allow-empty="true"></multiselect>

                              <div class="row" v-for="(input, index) in details.pilingcontractorteam" :key="index">
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6" style="padding-top: 12px">
                                  <input type="text" class="form-control" v-model="input.user.name" readonly>
                                </div>
                                <div class="col-lg-2" style="padding-top: 12px">
                                  <button type="button" @click="deleteTeamMember(input.id)" style="color: red; border:0; background-color: white">X</button>
                                </div>
                                <div class="col-lg-2"></div>
                                <br>
                              </div>

                              <div class="row" v-for="(input, index) in project.piling_contractor_team" :key="index">
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6" style="padding-top: 12px">
                                  <model-list-select :list="users" v-model="input.user_id" option-value="id" option-text="name" placeholder="search team"></model-list-select>
                                  <!-- <v-select :options="users" label="name" name="electrical_contractor_team[]" v-model="input.user_id"></v-select> -->
                                </div>
                                <div class="col-lg-2" style="padding-top: 12px">
                                  <button type="button" @click="deletePilingTeam(index)" style="color: red; border:0">X</button>
                                </div>
                                <div class="col-lg-2"></div>
                                <br>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Project Lift Contractor
                            <span style="font-size: 12px"><a style="cursor:pointer" @click="addLiftTeam">Add Team Member</a></span>
                            </label>
                            <div class="form-group">
                              <multiselect v-model="project.lift_contractor" deselect-label="Can't remove this value" label="name" placeholder="Select one" :options="users" :allow-empty="true"></multiselect>

                              <div class="row" v-for="(input, index) in details.liftcontractorteam" :key="index">
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6" style="padding-top: 12px">
                                  <input type="text" class="form-control" v-model="input.user.name" readonly>
                                </div>
                                <div class="col-lg-2" style="padding-top: 12px">
                                  <button type="button" @click="deleteTeamMember(input.id)" style="color: red; border:0; background-color: white">X</button>
                                </div>
                                <div class="col-lg-2"></div>
                                <br>
                              </div>

                              <div class="row" v-for="(input, index) in project.lift_contractor_team" :key="index">
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6" style="padding-top: 12px">
                                  <model-list-select :list="users" v-model="input.user_id" option-value="id" option-text="name" placeholder="search team"></model-list-select>
                                  <!-- <v-select :options="users" label="name" name="electrical_contractor_team[]" v-model="input.user_id"></v-select> -->
                                </div>
                                <div class="col-lg-2" style="padding-top: 12px">
                                  <button type="button" @click="deleteLiftTeam(index)" style="color: red; border:0">X</button>
                                </div>
                                <div class="col-lg-2"></div>
                                <br>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Project Manager
                            <span style="font-size: 12px"><a style="cursor:pointer" @click="addPMTeam">Add Team Member</a></span>
                            </label>
                            <div class="form-group">
                              <multiselect v-model="project.project_manager" deselect-label="Can't remove this value" label="name" placeholder="Select one" :options="users" :allow-empty="true"></multiselect>

                              <div class="row" v-for="(input, index) in details.projectmanagerteam" :key="index">
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6" style="padding-top: 12px">
                                  <input type="text" class="form-control" v-model="input.user.name" readonly>
                                </div>
                                <div class="col-lg-2" style="padding-top: 12px">
                                  <button type="button" @click="deleteTeamMember(input.id)" style="color: red; border:0; background-color: white">X</button>
                                </div>
                                <div class="col-lg-2"></div>
                                <br>
                              </div>

                              <div class="row" v-for="(input, index) in project.project_manager_team" :key="index">
                                <div class="col-lg-2"></div>
                                <div class="col-lg-6" style="padding-top: 12px">
                                  <model-list-select :list="users" v-model="input.user_id" option-value="id" option-text="name" placeholder="search team"></model-list-select>
                                  <!-- <v-select :options="users" label="name" name="electrical_contractor_team[]" v-model="input.user_id"></v-select> -->
                                </div>
                                <div class="col-lg-2" style="padding-top: 12px">
                                  <button type="button" @click="deletePMTeam(index)" style="color: red; border:0">X</button>
                                </div>
                                <div class="col-lg-2"></div>
                                <br>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Project Vendors
                            <span style="font-size: 12px"><a style="cursor:pointer" @click="addVendor">Add Vendor</a></span>
                            </label>
                            <div class="form-group">
                              <div class="row" v-for="(input, index) in details.vendors" :key="index">
                                <div class="col-lg-5" style="padding-top: 12px">
                                  <input type="text" class="form-control" v-model="input.category.name" readonly>
                                </div>
                                <div class="col-lg-5" style="padding-top: 12px">
                                  <input type="text" class="form-control" v-model="input.user.name" readonly>
                                </div>
                                <div class="col-lg-2" style="padding-top: 12px">
                                  <button type="button" @click="deleteVendorTeam(input.id)" style="color: red; border:0; background-color: white">X</button>
                                </div>
                                <br>
                              </div>

                              <div class="row" v-for="(input, index) in project.project_vendors" :key="index">
                                <div class="col-lg-5" style="padding-top: 12px">
                                  <model-list-select :list="vendor_category" v-model="input.vendor" option-value="id" option-text="name" placeholder="search category"></model-list-select>
                                  <!-- <v-select :options="vendor_category" label="name" name="vendors[]" v-model="input.vendor"></v-select> -->
                                </div>
                                <div class="col-lg-5" style="padding-top: 12px">
                                  <model-list-select :list="users" v-model="input.user_id" option-value="id" option-text="name" placeholder="search team"></model-list-select>
                                  <!-- <v-select :options="users" label="name" name="vendor_user[]" v-model="input.user_id"></v-select> -->
                                </div>
                                <div class="col-lg-2" style="padding-top: 12px">
                                  <button type="button" @click="deleteVendor(index)" style="color: red; border:0; background-color: white">X</button>
                                </div>
                                <br>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Project Images
                            <span class="text-danger">* </span>
                            </label>

                            <div class="box-body p-3">
                              <div class="gallery-box-main">
                                <div v-if="details.images" class="">
                                  <div v-if="details.images.length > 0" class="row">
                                    <div v-for="(data, index) in details.images" :key="index" class="col-md-3 col-sm-4 col-xs-4" style="margin-bottom: 10px">
                                      <div class="project-image-container">
                                        <img style="object-fit:cover; width: 60px; height:60px" :src="data.path" alt="">
                                        <button type="button" style="float:right; border:0" @click="deleteProjectImage(data.id)"><i style="color: red" class="feather-x"></i></button>
                                      </div>
                                    </div>
                                  </div>                                  
                                </div>
                              </div>
                            </div>

                            <div id="my-strictly-unique-vue-upload-multiple-image" style="display: flex; justify-content: center;">
                              <vue-upload-multiple-image
                                @upload-success="uploadImageSuccess"
                                @before-remove="beforeRemove"
                                @edit-image="editImage"
                                @data-change="dataChange"
                                :data-images="images"
                                dragText="Drag and drop images"
                                browseText="Browse Images"
                                primaryText="added"
                                markIsPrimaryText="	Set default image"
                                popupText="Description default image"
                                :maxImage=5
                                ></vue-upload-multiple-image>

                            </div>
                          </div>
                        </div>
                      </div>

                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <div v-if="loading">
                <button type="button" class="btn btn-primary" data-dismiss="modal">Loading.....</button>
              </div>
              <div v-else>
                <button type="button" @click="cancelForm()" class="btn btn-secondary" data-dismiss="modal">Close</button>&nbsp;
                <button type="button" @click="updateProject()" class="btn btn-primary">Update Listing</button>
              </div>
            </div>

          </div>
        </div>
      </div>






      <!-- edit comment Modal -->
      <div class="modal fade" id="edit_comment" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Edit Comment </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form>
                <div class="row">
                  <div class="col-md-12 mb-12 mb-sm-12">
                    <div class="js-form-message">
                      <label class="form-label">Comment</label>
                      <div class="form-group">
                        <textarea class="form-control" rows="6" name="comment" v-model="edit_comment" required></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <input class="form-control" type="hidden" name="id" v-model="edit_comment_id" required/>

              </form>

            </div>
            <div class="modal-footer">
              <div v-if="loading">
                <button type="button" class="btn btn-primary" data-dismiss="modal">Loading.....</button>
              </div>
              <div v-else>
                <button type="button" class="btn btn-secondary" @click="closeEditPostCommentModal" data-dismiss="modal">Close</button>&nbsp;
                <button type="button" @click="editComment()" class="btn btn-primary">Update</button>
              </div>
            </div>

          </div>
        </div>
      </div>






      <!-- reply comment Modal -->
      <div class="modal fade" id="reply_comment" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <form>
                <div class="row">
                  <div class="col-md-12 mb-12 mb-sm-12">
                    <div class="js-form-message">
                      <div class="form-group">
                        <textarea class="form-control" placeholder="write your reply..." name="comment" v-model="edit_comment" required></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <input class="form-control" type="hidden" name="id" v-model="edit_comment_id" required/>

              </form>

            </div>
            <div class="modal-footer">
              <div v-if="loading">
                <button type="button" class="btn btn-primary" data-dismiss="modal">Loading.....</button>
              </div>
              <div v-else>
                <button type="button" class="btn btn-secondary" @click="closeReplyPostCommentModal" data-dismiss="modal">Close</button>&nbsp;
                <button type="button" @click="replyProjectComment()" class="btn btn-primary">Update</button>
              </div>
            </div>

          </div>
        </div>
      </div>



      </div>
  </layout-default>
</template>
<script>
import LayoutDefault from '../../layouts/default.vue';
import axios from 'axios';
import moment from 'moment';
import VueEasyLightbox from 'vue-easy-lightbox';
import 'vue-search-select/dist/VueSearchSelect.css'
import VueUploadMultipleImage from 'vue-upload-multiple-image';
import { ModelListSelect } from 'vue-search-select';
import Multiselect from 'vue-multiselect';
import $ from "jquery";
import { Carousel, Slide } from 'vue-carousel';
// import VueGallery from 'vue-gallery';
// import vueAnkaCropper from 'vue-anka-cropper';
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import { VueEditor } from "vue2-editor";
import InfiniteLoading from 'vue-infinite-loading';

export default {
  name: 'projectdetail',
  components: {
    LayoutDefault,
    VueEasyLightbox,
    VueUploadMultipleImage,
    ModelListSelect, 
    Carousel,
    Slide,
    // 'gallery': VueGallery,
    // ModelSelect,
    Multiselect,
    // vueAnkaCropper,
    VueCropper,
    VueEditor,
    InfiniteLoading,
  },

  created() {
        this.fetchDetails();
        this.fetchCountries();
        this.fetchVendorCategory();
        this.fectchInterests();
        this.fetchUsers();
        this.fetchCompanySetting();
        this.fetchAdverts();
  },

  watch: {
      $route: {
          immediate: true,
          handler(to) {
            let subject = to.params.id.substring(0, to.params.id.length - 10);
            document.title = to.meta.title || 'Structurecity | Project - '+subject.replace(/-/g, " ");
          }
      },
  }, 

  data() {
        return {
            details: {},
            settings: {},
            related_project: [],
            user: {},
            images: [],
            country: [],
            state: [],
            city: [],
            users: [],
            vendor_category: [], 
            interests: [],
            interest_children: [],
            interest_children2: [],
            connection: {
              vendor_category: '',
              connection_type: '',
              user_id: localStorage.getItem('user'),
              project_id: this.$route.params.id,
            },
            currentData:{},
            titleTeam:{},
            loading: false,
            id: localStorage.getItem('user'),  
            project: {
              user_id: localStorage.getItem('user'),
              name: '',
              project_id: '',
              project_interest_1: '',
              project_interest_2: '',
              project_interest_3: '',
              description: '',
              project_start_date: '',
              project_end_date: '',
              country_id: 160,
              state_id: '',
              city_id: '',
              status: '',
              height: '',
              address: '',
              architect: '',
              structural_engineer: '',
              mep_engineer: '',
              client: '',
              quantity_surveyor: '',
              main_contractor: '',
              mechanical_contractor: '',
              electrical_contractor: '',
              piling_contractor: '',
              lift_contractor: '',
              project_manager: '',
              architect_team: [],
              structural_engineer_team: [],
              mep_engineer_team: [],
              quantity_surveyor_team: [],
              main_contractor_team: [],
              mechanical_contractor_team: [],
              electrical_contractor_team: [],
              piling_contractor_team: [],
              lift_contractor_team:[],
              project_manager_team:[],
              project_vendors: [],
              images:'',
            },  
            visible: false,
            index: 0,
            imgSrc:'',
            cropImg: '', 
            image: "", 
            modal:false,
            submitting:false,
            editmodal:false, 
            imgs: '',
            imageSrc: "",
            croppedImageSrc: "",
            comment: {
              description: [],
            },
            adverts: [],
            customToolbar: [
              ["bold", "italic", "underline"],
              [{ list: "ordered" }, { list: "bullet" }],
              // ["image", "code-block"]
            ],
            page: 1,
            edit_comment: "",
            edit_comment_id: "",
        }
  },   

  methods: {
      logout () {
          localStorage.clear();
          this.$router.go();
        },
        
        setImage: function (e) {
            const file = e.target.files[0]
            if (!file.type.includes("image/")) {
              alert("Please select an image file")
              return
            }
            if (typeof FileReader === "function") {
              const reader = new FileReader()
              reader.onload = event => {
                this.imageSrc = event.target.result

                // Rebuild cropperjs with the updated source
                this.$refs.cropper?this.$refs.cropper.replace(event.target.result):''
              }
              reader.readAsDataURL(file)
            } else {
              alert("Sorry, FileReader API not supported")
            }
          },

          cropImage() {
            // Get image data for post processing, e.g. upload or setting image src
            this.croppedImageSrc = this.$refs.cropper.getCroppedCanvas().toDataURL()
          },

          uploadImage (id) {
            var self = this;
            self.loading = true;
            this.$refs.cropper.getCroppedCanvas().toBlob(function (blob) {
              let formData = new FormData()
              // Add name for our image
              formData.append("name", "image-name-" + new Date().getTime())
              // Append image file
              formData.append("file", blob)
              formData.append("id", id)
            axios.post(process.env.VUE_APP_API_URL + 'project/banner-update', formData)
              .then((response) => {
              if (response.status === 200) {
                  self.$toastr.s(response.message);
                  self.loading = false;
                  self.$root.$emit('banner-action')
                  window.$('#update-banner').modal('hide');
              } else {
                  self.$toastr.e(response.message);
                  self.loading = false;
              }
              }).catch((error) =>{
                  self.$toastr.e(error.response.message);
                  self.loading = false;
              })
          })
        },

        fetchDetails () {
            axios(process.env.VUE_APP_API_URL + 'project/detail/'+this.$route.params.id).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.details = response.data;
                this.fetchCurrentStates(response.data.country_id);
                this.fetchCurrentCities(response.data.state_id);
                this.fetchRelatedProject(response.data.id);
                this.interestChildren(response.data.interests[0].interest_id);
                this.interestChildren2(response.data.interests[1].interest_id);
                
                this.project.project_id = response.data.id;
                this.project.name = response.data.name;
                this.project.description = response.data.description;
                this.project.project_start_date = response.data.project_start_date;
                this.project.project_end_date = response.data.project_end_date;
                this.project.country_id = response.data.country_id;
                this.project.state_id = response.data.state_id;
                this.project.city_id = response.data.city_id;
                this.project.status = response.data.status;
                this.project.height = response.data.height;
                this.project.address = response.data.address;
                this.project.architect = response.data.getarchitect;
                this.project.structural_engineer = response.data.structuralengineer;
                this.project.mep_engineer = response.data.mepengineer;
                this.project.piling_contractor = response.data.pilingcontractor;
                this.project.lift_contractor = response.data.liftcontractor;
                this.project.project_manager = response.data.projectmanager;
                this.project.client = response.data.getclient;
                this.project.quantity_surveyor = response.data.quantitysurveyor;
                this.project.main_contractor = response.data.maincontractor;
                this.project.mechanical_contractor = response.data.mechanicalcontractor;
                this.project.electrical_contractor = response.data.electricalcontractor;
                this.project.project_interest_1 = response.data.interests[0].interest_id;
                this.project.project_interest_2 = response.data.interests[1].interest_id;
                this.project.project_interest_3 = response.data.interests[2]?response.data.interests[2].interest_id:'';
                
            }).catch({});
        },

        fetchUsers () {
            axios(process.env.VUE_APP_API_URL + 'users').then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.users = response.data;
            }).catch({});
        },

        fetchRelatedProject (project_id) {
            axios(process.env.VUE_APP_API_URL + 'project/related/'+project_id).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.related_project = response.data.data;
                this.page = this.page + 1;
            }).catch({});
        },

        handleLoadMore($state) {
          axios(process.env.VUE_APP_API_URL + 'project/related/'+this.details.id+'?page=' + this.page)
              .then(res => {
                  $.each(res.data.data, (key, value) => {
                      this.related_project.push(value);
                  });
                  $state.loaded();
                  this.page = this.page + 1;
                  if(res.data.data.length <= 0){
                   $state.complete();
                  }
              });
        },

        fetchCompanySetting () {
            axios(process.env.VUE_APP_API_URL + 'company').then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.settings = response.data;
            }).catch({});
        },


        fetchCountries () {
            axios(process.env.VUE_APP_API_URL + 'country').then(response =>{
                if (! response.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.country = response.data;
            }).catch({});
        },

        fetchStates (event) {
            axios(process.env.VUE_APP_API_URL + 'state/'+event.target.value).then(response =>{
                if (! response.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.state = response.data;
            }).catch({});
        },

        fetchCities (event) {
            axios(process.env.VUE_APP_API_URL + 'city/'+event.target.value).then(response =>{
                if (! response.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.city = response.data;
            }).catch({});
        },

        fetchCurrentStates(id) {
            axios(process.env.VUE_APP_API_URL + 'state/'+id).then(response =>{
                if (! response.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.state = response.data;
            }).catch({});
        },

        fetchCurrentCities(id) {
            axios(process.env.VUE_APP_API_URL + 'city/'+id).then(response =>{
                if (!response.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.city = response.data;
            }).catch({});
        },
        
        fetchVendorCategory () {
            axios(process.env.VUE_APP_API_URL + 'vendor-category').then(response =>{
                if (! response.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.vendor_category = response.data;
            }).catch({});
        },  
        
        fectchInterests () {
            axios(process.env.VUE_APP_API_URL + 'interest').then(response =>{
                if (! response.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.interests = response.data;
            }).catch({});
        },

        fectchInterestChildren (e) {
            axios(process.env.VUE_APP_API_URL + 'interest/'+e.target.value).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.interest_children = response.data;
            }).catch({});
        },

        fectchInterestChildren2 (e) {
            axios(process.env.VUE_APP_API_URL + 'interest/'+e.target.value).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.interest_children2 = response.data;
            }).catch({});
        },

        interestChildren (id) {
            axios(process.env.VUE_APP_API_URL + 'interest/'+id).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.interest_children = response.data;
            }).catch({});
        },

        interestChildren2 (id) {
            axios(process.env.VUE_APP_API_URL + 'interest/'+id).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.interest_children2 = response.data;
            }).catch({});
        },

        fetchAdverts () {
          axios(process.env.VUE_APP_API_URL + 'adverts').then(response =>{
              if (! response.data.status == 200) {
                  return this.errorMessage = 'Could not fetch data';
              }
              this.adverts = response.data;
          }).catch({});
        },

        updateProject () {
            this.loading = true;
            axios.post(process.env.VUE_APP_API_URL + 'project/update', this.project)
            .then((response) => {
            if (response.status == 200) {
                this.$toastr.s(response.message);
                this.$root.$emit('delete-team-action')
                window.$('#project-modal').modal('hide');
                this.images = [];
                this.project.name = '';
                this.project.description = '';
                this.project.project_start_date = '';
                this.project.project_end_date = '';
                this.project.country_id = '';
                this.project.state_id = '';
                this.project.city_id = '';
                this.project.status = '';
                this.project.height = '';
                this.project.address = '';
                this.project.architect = '';
                this.project.structural_engineer = '';
                this.project.mep_engineer = '';
                this.project.client = '';
                this.project.quantity_surveyor = '';
                this.project.main_contractor = '';
                this.project.mechanical_contractor = '';
                this.project.electrical_contractor = '';
                this.project.piling_contractor = '';
                this.project.lift_contractor = '';
                this.project.project_manager = '';
                this.project.architect_team = [];
                this.project.structural_engineer_team = [];
                this.project.mep_engineer_team = [];
                this.project.quantity_surveyor_team = [];
                this.project.main_contractor_team = [];
                this.project.mechanical_contractor_team = [];
                this.project.electrical_contractor_team = [];
                this.project.piling_contractor_team = [];
                this.project.lift_contractor_team = [];
                this.project.project_manager_team = [];
                this.project.project_vendors = [];
                this.project.images = '';
                this.loading = false;
            } else {
                this.$toastr.e(response.data.message);
                this.loading = false;
            }
            }).catch((error) =>{
                this.$toastr.e(error.response.data.message);
                this.loading = false;
            })
        },

        cancelForm(){
          if(confirm('Are you sure you want to discard this form?')){
            // this.$router.go(0);
            window.$('#project-modal').modal('hide');            
            this.images = [];
            this.project.name = '';
            this.project.description = '';
            this.project.project_start_date = '';
            this.project.project_end_date = '';
            this.project.country_id = '';
            this.project.state_id = '';
            this.project.city_id = '';
            this.project.status = '';
            this.project.height = '';
            this.project.address = '';
            this.project.architect = '';
            this.project.structural_engineer = '';
            this.project.mep_engineer = '';
            this.project.client = '';
            this.project.quantity_surveyor = '';
            this.project.main_contractor = '';
            this.project.mechanical_contractor = '';
            this.project.electrical_contractor = '';
            this.project.piling_contractor = '';
            this.project.lift_contractor = '';
            this.project.project_manager = '';
            this.project.architect_team = [];
            this.project.structural_engineer_team = [];
            this.project.mep_engineer_team = [];
            this.project.quantity_surveyor_team = [];
            this.project.main_contractor_team = [];
            this.project.mechanical_contractor_team = [];
            this.project.electrical_contractor_team = [];
            this.project.piling_contractor_team = [];
            this.project.lift_contractor_team = [];
            this.project.project_manager_team = [];
            this.project.project_vendors = [];
            this.project.images = '';
          }
        },

        follow (id) {
            this.loading = true;
            axios.post(process.env.VUE_APP_API_URL + 'user/follow', {"user_id":id, "followed_by":localStorage.getItem('user')})
            .then((response) => {
            if (response.status === 200) {
                this.$toastr.s(response.message);
                this.loading = false;
            } else {
                this.$toastr.e(response.data.message);
                this.loading = false;
            }
            }).catch((error) =>{
                this.$toastr.e(error.response.data.message);
                this.loading = false;
            })
        },

        connectProject () {
            this.loading = true;
            axios.post(process.env.VUE_APP_API_URL + 'project/connect', this.connection)
            .then((response) => {
            if (response.status === 200) {
                this.$toastr.s(response.message);
                this.closeTeamConnectModal();
                // window.$('#team-connect-modal').modal('hide');
                this.loading = false;
                this.connection.vendor_category = '';
                this.connection.connection_type = '';
            } else {
                this.$toastr.e(response.data.message);
                this.loading = false;
            }
            }).catch((error) =>{
                this.$toastr.e(error.response.data.message);
                this.loading = false;
            })
        },

        updateBanner () {
            this.loading = true;
            var form = $("#banner-form")[0];
            var _data = new FormData(form);
            axios.post(process.env.VUE_APP_API_URL + 'project/banner-update', _data)
            .then((response) => {
            if (response.status === 200) {
                this.$toastr.s(response.message);
                this.loading = false;
                this.$root.$emit('banner-action')
                window.$('#update-banner').modal('hide');
            } else {
                this.$toastr.e(response.data.message);
                this.loading = false;
            }
            }).catch((error) =>{
                this.$toastr.e(error.response.data.message);
                this.loading = false;
            })
        },

        deleteTeamMember (id) {
            axios(process.env.VUE_APP_API_URL + 'project/delete-team-member/'+id)
            .then((response) => {
            if (response.status === 200) {
                this.$toastr.s(response.message);
                this.$root.$emit('delete-team-action')
            } else {
                this.$toastr.e(response.data.message);
            }
            }).catch((error) =>{
                this.$toastr.e(error.response.data.message);
            })
        },

        deleteVendorTeam (id) {
            axios(process.env.VUE_APP_API_URL + 'project/delete-vendor-team/'+id)
            .then((response) => {
            if (response.status === 200) {
                this.$toastr.s(response.message);
                this.$root.$emit('delete-team-action')
            } else {
                this.$toastr.e(response.data.message);
            }
            }).catch((error) =>{
                this.$toastr.e(error.response.data.message);
            })
        },

        deleteProjectImage (id) {
            axios(process.env.VUE_APP_API_URL + 'project/delete-project-image/'+id)
            .then((response) => {
            if (response.status === 200) {
                this.$toastr.s(response.message);
                this.$root.$emit('delete-team-action')
            } else {
                this.$toastr.e(response.data.message);
            }
            }).catch((error) =>{
                this.$toastr.e(error.response.data.message);
            })
        },

        viewTeam (event, title) {
          this.currentData = event;
          this.titleTeam = title;
          window.$('#team-view').modal('show');
        },

        openUpdateProject(){
          window.$('#project-modal').modal('show');
        },

        addArchitectTeam() {
          this.project.architect_team.push({
            user_id: '',
          })
        },

        deleteArchitectTeam(index) {
            this.project.architect_team.splice(index,1)
        },

        addStructuralEngineerTeam() {
          this.project.structural_engineer_team.push({
            user_id: '',
          })
        },

        deleteStructuralEngineerTeam(index) {
            this.project.structural_engineer_team.splice(index,1)
        },

        addMepEngineerTeam() {
          this.project.mep_engineer_team.push({
            user_id: '',
          })
        },

        deleteMepEngineerTeam(index) {
            this.project.mep_engineer_team.splice(index,1)
        },

        addSurveyorTeam() {
          this.project.quantity_surveyor_team.push({
            user_id: '',
          })
        },

        deleteSurveyorTeam(index) {
            this.project.quantity_surveyor_team.splice(index,1)
        },

        addMainContractorTeam() {
          this.project.main_contractor_team.push({
            user_id: '',
          })
        },

        deleteMainContractorTeam(index) {
            this.project.main_contractor_team.splice(index,1)
        },

        addMechanicalContractorTeam() {
          this.project.mechanical_contractor_team.push({
            user_id: '',
          })
        },

        deleteMechanicalContractorTeam(index) {
            this.project.mechanical_contractor_team.splice(index,1)
        },

        addElectricalContractorTeam() {
          this.project.electrical_contractor_team.push({
            user_id: '',
          })
        },

        deleteElectricalContractorTeam(index) {
            this.project.electrical_contractor_team.splice(index,1)
        },

        addPilingTeam() {
          this.project.piling_contractor_team.push({
            user_id: '',
          })
        },

        deletePilingTeam(index) {
            this.project.piling_contractor_team.splice(index,1)
        },

        addLiftTeam() {
            this.project.lift_contractor_team.push({
              user_id: '',
            })
          },
  
          deleteLiftTeam(index) {
              this.project.lift_contractor_team.splice(index,1)
          },

          addPMTeam() {
            this.project.project_manager_team.push({
              user_id: '',
            })
          },
  
          deletePMTeam(index) {
              this.project.project_manager_team.splice(index,1)
          },

        addVendor() {
          this.project.project_vendors.push({
            vendor: '',
            user_id: '',
          })
        },

        deleteVendor(index) {
            this.project.project_vendors.splice(index,1)
        },

        uploadImageSuccess(formData, index, fileList) {
          this.project.images = fileList;
          // console.log('data', formData, index, fileList)
          // Upload image api
          // axios.post('http://your-url-upload', { data: formData }).then(response => {
          //   console.log(response)
          // })
        },

        beforeRemove (index, done, fileList) {
          console.log('index', index, fileList)
          var r = confirm("remove image")
          if (r == true) {
            done()
          } else {
            ''
          }
        },

        editImage (formData, index, fileList) {
          console.log('edit data', formData, index, fileList)
        },

        dataChange (data) {
          console.log(data)
        },

        formatDate(date){
          return moment(date).fromNow();
        },

        formatDate2(date) {
				return moment(date).format('DD MMMM, YYYY');
			},

      showImg (index) {
        this.index = index
        this.visible = true
      },

      showSingle(data) {
        this.imgs = data
        this.show()
      },

      show() {
        this.visible = true
      },

      handleHide () {
        this.visible = false
      },

      forgotPassword () {
        window.$('#team-connect-modal').modal('hide');
        window.location.href = '/forgot-password';
        // this.$router.push({ name: 'ForgotPassword', query: { redirect: '/forgot-password' } });
      },

      register () {
        window.$('#team-connect-modal').modal('hide');
        window.location.href = '/register';
        // this.$router.push({ name: 'Register', query: { redirect: '/register' } });
      },

      login () {
          this.loading = true;
          var form = $("#login-form")[0];
          var _data = new FormData(form);
          axios.post(process.env.VUE_APP_API_URL + 'auth/login', _data)
          .then((response) => {
          if (response.status === 200) {
              window.$('#team-connect-modal').modal('hide');
              this.$toastr.s(response.message);
              localStorage.setItem('user', response.data.data.id);
              localStorage.setItem('slug', response.data.data.slug);
              this.$router.go();
              this.loading = false;
          } else {
              this.$toastr.e(response.data.message);
              this.loading = false;
          }
          }).catch((error) =>{
              this.$toastr.e(error.response.data.message);
              this.loading = false;
          })
      },

      submitComment(event, id) {
          this.loading = true;
          axios.post(process.env.VUE_APP_API_URL + 'project/comment', {"comment":event.target.value, "project_id":id, "user_id":localStorage.getItem('user')})
          .then((response) => {
          if (response.status === 200) {
              this.$toastr.s(response.message);
              this.comment.description = []
              this.$root.$emit('banner-action')
              this.loading = false;
          } else {
              this.$toastr.e(response.data.message);
              this.loading = false;
          }
          }).catch((error) =>{
              this.$toastr.e(error.response.data.message);
              this.loading = false;
          })
      },

      submitComment2(comment, id) {
          this.loading = true;
          axios.post(process.env.VUE_APP_API_URL + 'project/comment', {"comment":comment, "project_id":id, "user_id":localStorage.getItem('user')})
          .then((response) => {
          if (response.status === 200) {
              this.$toastr.s(response.message);
              this.comment.description = []
              this.$root.$emit('banner-action')
              this.loading = false;
          } else {
              this.$toastr.e(response.data.message);
              this.loading = false;
          }
          }).catch((error) =>{
              this.$toastr.e(error.response.data.message);
              this.loading = false;
          })
      },

      sendSubmitComment (id) {
        var comment = document.getElementById("submitCommentButton"+id).value;
        this.submitComment2(comment, id);
      },

      editProjectComment(comment_id, comment){
        this.edit_comment = comment;
        this.edit_comment_id = comment_id;
        window.$('#edit_comment').modal('show');
      },

      closeReplyPostCommentModal(){
        this.edit_comment_id = '';
        window.$('#reply_comment').modal('hide');
      },

      closeEditPostCommentModal(){
        this.edit_comment = '';
        this.edit_comment_id = '';
        window.$('#edit_comment').modal('hide');
        // $("#edit_comment").modal('show');
      },

      editComment() {
          this.loading = true;
          axios.post(process.env.VUE_APP_API_URL + 'project/comment/edit', {"project_id":this.details.id, "comment":this.edit_comment, "comment_id":this.edit_comment_id, "user_id":localStorage.getItem('user')})
          .then((response) => {
          if (response.status === 200) {
              this.$toastr.s(response.message);
              this.edit_comment = ""
              this.edit_comment_id = ""
              window.$('#edit_comment').modal('hide');
              this.$root.$emit('banner-action')
              this.loading = false;
          } else {
              this.$toastr.e(response.data.message);
              this.loading = false;
          }
          }).catch((error) =>{
              this.$toastr.e(error.response.data.message);
              this.loading = false;
          })
      },

      deleteComment(comment_id) {
          this.loading = true;
          axios.post(process.env.VUE_APP_API_URL + 'project/comment/delete', {"project_id":this.details.id, "comment_id":comment_id, "user_id":localStorage.getItem('user')})
          .then((response) => {
          if (response.status === 200) {
              this.$toastr.s(response.message);
              this.edit_comment = ""
              this.edit_comment_id = ""
              this.$root.$emit('banner-action')
              this.loading = false;
          } else {
              this.$toastr.e(response.data.message);
              this.loading = false;
          }
          }).catch((error) =>{
              this.$toastr.e(error.response.data.message);
              this.loading = false;
          })
      },

      likeProjectComment(comment_id){
        this.loading = true;
          axios.post(process.env.VUE_APP_API_URL + 'project/comment/like', {"comment_id":comment_id, "user_id":localStorage.getItem('user')})
          .then((response) => {
          if (response.status === 200) {
              // this.$toastr.s(response.message);
              this.$root.$emit('banner-action')
              this.loading = false;
          } else {
              this.$toastr.e(response.message);
              this.loading = false;
          }
          }).catch((error) =>{
              this.$toastr.e(error.response.message);
              this.loading = false;
          })
      },

      unlikeProjectComment(comment_id){
        this.loading = true;
          axios.post(process.env.VUE_APP_API_URL + 'project/comment/unlike', {"comment_id":comment_id, "user_id":localStorage.getItem('user')})
          .then((response) => {
          if (response.status === 200) {
              // this.$toastr.s(response.message);
              this.$root.$emit('banner-action')
              this.loading = false;
          } else {
              this.$toastr.e(response.message);
              this.loading = false;
          }
          }).catch((error) =>{
              this.$toastr.e(error.response.message);
              this.loading = false;
          })
      },

      checkIfUser(source) {
        if(source.length > 0){
          for (var i = 0; i < source.length; i++) {
            if (source[i].user_id == this.id) {
              return true;
            }
          }
          return false;
        }
      },

      replyProjectCommentModal(comment_id){
        this.edit_comment_id = comment_id;
        window.$('#reply_comment').modal('show');
      },

      replyProjectComment() {
          this.loading = true;
          axios.post(process.env.VUE_APP_API_URL + 'project/comment/reply', {"project_id":this.details.id, "comment":this.edit_comment, "parent_id":this.edit_comment_id, "user_id":localStorage.getItem('user')})
          .then((response) => {
          if (response.status === 200) {
              this.$toastr.s(response.message);
              this.edit_comment = ""
              this.edit_comment_id = ""
              window.$('#reply_comment').modal('hide');
              this.$root.$emit('banner-action')
              this.loading = false;
          } else {
              this.$toastr.e(response.data.message);
              this.loading = false;
          }
          }).catch((error) =>{
              this.$toastr.e(error.response.data.message);
              this.loading = false;
          })
      },

      closeTeamModal(){
        window.$('#team-view').modal('hide');
      },

      closeTeamConnectModal(){
        window.$('#team-connect-modal').modal('hide');
      },

    },

    mounted: function () {
       this.$root.$on('banner-action', ()=>{
        this.fetchDetails()
      })

      this.$root.$on('delete-team-action', ()=>{
        this.fetchDetails()
      })
    },

    computed: {
        image_url: function() {
           return process.env.VUE_APP_IMAGE_URL;
        }
    },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
@import '../../../node_modules/vue-anka-cropper/dist/VueAnkaCropper.css';
</style>

<style>
  .banner-container { 
    position: relative; 
  } 
  .banner-container img { 
    width: 100%; 
    height: 100%; 
    box-sizing: border-box; 
  } 
  .banner-container button { 
    position: absolute; 
    top: 0; 
    right: 0; 
  }

  .project-image-container { 
    position: relative; 
  } 
  .project-image-container img { 
    width: 100%; 
    height: 100%; 
    box-sizing: border-box; 
  } 
  .project-image-container button { 
    position: absolute; 
    top: 0; 
    right: 0; 
  }

  .cropped-image img {
  max-width: 100%;
}
/* input[type="file"] {
  display: none;
} */

.ankaCropper.dark {
    background: #0f1114;
    color: #eee
}
 
.ankaCropper.dark .ankaCropper__droparea {
    border: 2px dashed #3e424b
}
 
.ankaCropper.dark .ankaCropper__saveButton,
.ankaCropper.dark .ankaCropper__selectButton {
    background: #334f90;
    color: #fff
}
 
.ankaCropper.dark .ankaCropper__saveButton:hover,
.ankaCropper.dark .ankaCropper__selectButton:hover {
    background: #335dbe
}
 
.ankaCropper.dark .ankaCropper__navigation {
    background: #1d2227
}
 
.ankaCropper.dark .ankaCropper__navButton {
    background: #272c31
}
 
.ankaCropper.dark .ankaCropper__navButton svg {
    stroke: #eee
}
 
.ankaCropper.dark .ankaCropper__navButton:hover {
    background: #000
}

.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}

.navigation-icon-size{
    font-size: 70px;
    color: brown
  }
</style>