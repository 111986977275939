<template>
  <layout-default>
    <div class="Followers">
        <div class="py-4">
            <div class="container">
                <div class="row">
                    <aside class="col col-xl-3 order-xl-2 col-lg-12 order-lg-2 col-12">
                        <div class="box mb-3 shadow-sm border rounded bg-white profile-box text-center">
                            <div class="py-4 px-3 border-bottom">
                                <img v-if="user.avatar" class="img-fluid mt-2 rounded-circle" :src="image_url+'users/'+user.avatar" style="max-height: 150px; max-width: 150px" alt="">
                                <img v-else class="img-fluid mt-2 rounded-circle" src="/assets/avatar.png" style="max-height: 150px; max-width: 150px" alt="">
                                <h5 class="font-weight-bold text-dark mb-1 mt-4">{{user.name}}</h5>
                                <p v-if="user.currentcompany" class="mb-0 text-muted">{{user.current_position}} <br> <router-link :to="'/company/'+user.currentcompany.slug">{{user.currentcompany.name}}</router-link></p>
                                <p v-else class="mb-0 text-muted">{{user.profession}}</p>
                                <span v-if="user.membership" class="text-info"><span v-if="user.membership.membership">{{ user.membership.membership.code }}</span><i v-if="user.membership.status == 1" data-toggle="tooltip" data-placement="top" title="Verified" class="feather-check-circle"></i></span> &nbsp;
                                <span v-if="user.certification" class="text-info"><span v-if="user.certification.certification">{{ user.certification.certification.code }}</span><i v-if="user.certification.status == 1" data-toggle="tooltip" data-placement="top" title="Verified" class="feather-check-circle"></i></span>
                            </div>
                            <div class="d-flex">
                                <div class="col-6 border-right p-3">
                                    <h6 class="font-weight-bold text-dark mb-1">{{total_followers}}</h6>
                                    <p class="mb-0 text-black-50 small">Followers</p>
                                </div>
                                <div class="col-6 p-3">
                                    <h6 class="font-weight-bold text-dark mb-1">{{total_project}}</h6>
                                    <p class="mb-0 text-black-50 small">Projects</p>
                                </div>
                            </div>
                            <div class="overflow-hidden border-top">
                                <router-link class="font-weight-bold p-3 d-block" :to="'/sc/profile/'+user.slug"> View my profile </router-link>
                            </div>
                        </div>
                        <!-- <div class="box shadow-sm mb-3 rounded bg-white ads-box text-center">
                            <img src="img/job1.png" class="img-fluid" alt="Responsive image">
                            <div class="p-3 border-bottom">
                            <h6 class="font-weight-bold text-dark">Osahan Solutions</h6>
                            <p class="mb-0 text-muted">Looking for talent?</p>
                            </div>
                            <div class="p-3">
                            <button type="button" class="btn btn-outline-primary pl-4 pr-4"> POST A JOB </button>
                            </div>
                        </div> -->
                    </aside>
                    <main class="col col-xl-9 order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12">
                        <div class="row" style="float: right; padding-right:20px">
                            <button type="button" data-toggle="modal" data-target="#service-modal" class="btn btn-outline-primary btn-sm"> <i class="feather-plus mr-2"></i> Request Service</button>
                        </div>
                        <div class="box shadow-sm border rounded bg-white mb-3 osahan-share-post">
                            <h5 class="pl-3 pt-3 pr-3 border-bottom mb-0 pb-3">Service Requests</h5>
                            
                            <div class="p-3">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12">
                                        <div class="row">
                                            <div v-for="(data, index) in details" :key="index" class="col-lg-4 col-md-4">
                                                <div class="box shadow-sm rounded bg-white mb-3 blog-card border-0">
                                                    <router-link :to="'/service/'+data.slug">
                                                    <div class="card-body">
                                                        <span class="badge badge-primary">{{data.service}}</span>
                                                        <h6 class="text-dark">{{data.service}}</h6>
                                                        <p style="font-size: 12px" v-html="data.description.substring(0,200)+'....'" class="mb-0"></p>
                                                    </div>
                                                    <div class="card-footer border-0">
                                                        <p class="mb-0"> Posted: {{formatDate2(data.created_at)}}</p>
                                                    </div>
                                                    </router-link>
                                                </div>
                                            </div>
                                        </div>
                                        <pagination style="float:right;" :pagination="pagination"
                                                @prev="fetchData(pagination.prevPageUrl)"
                                                @next="fetchData(pagination.nextPageUrl)" v-if="details.length > 0">
                                        </pagination>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    
                </div>
            </div>
        </div>



        <!-- create service Modal -->
      <div class="modal fade" id="service-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Request a Service </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form id="message-form">
                <div class="row">
                  <div class="col-md-12 mb-12 mb-sm-12">
                    <div class="form-group">
                        <label class="form-label">Service Provider Needed <span class="text-danger">*</span></label>
                        <select class="form-control custom-select" name="service" v-model="service.service">
                        <option value="" disabled selected>Select Service</option>
                        <option v-for="(data, index) in professions" :key="index" :value="data.name">{{data.name}}</option>
                        </select>
                    </div>
                    <div class="js-form-message">
                        <label id="nameLabel" class="form-label">Job Description
                        <span class="text-danger">*</span>
                        </label>
                        <div class="form-group">
                            <vue-editor id="job_editor" v-model="service.description" :editorToolbar="customToolbar"></vue-editor>
                        </div>
                    </div>

                    <label class="form-label">Project Location
                      <span class="text-danger">*</span>
                      </label>
                      <div class="row">
                        <div class="col-md-4 mb-3 mb-sm-4">
                          <div class="js-form-message">
                            <div class="form-group">
                              <select class="form-control custom-select" name="country_id" v-model="service.country_id" data-msg="Please select country." data-error-class="u-has-error" data-success-class="u-has-success" @change='fetchStates($event)'>
                                <option value="" disabled selected>Country</option>
                                <option v-for="data in country" :key="data.id" :value="data.id">{{data.name}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 mb-3 mb-sm-4">
                          <div class="js-form-message">
                            <div class="form-group">
                              <select class="form-control custom-select" name="state_id" v-model="service.state_id" data-msg="Please select state." data-error-class="u-has-error" data-success-class="u-has-success" @change='fetchCities($event)'>
                                <option value="" disabled selected>State</option>
                                <option v-for="data in state" :key="data.id" :value="data.id">{{data.name}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 mb-3 mb-sm-4">
                          <div class="js-form-message">
                            <div class="form-group">
                              <select class="form-control custom-select" name="city_id" v-model="service.city_id" data-msg="Please select city." data-error-class="u-has-error" data-success-class="u-has-success">
                                <option value="" disabled selected>City</option>
                                <option v-for="data in city" :key="data.id" :value="data.id">{{data.name}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Address</label>
                            <div class="form-group">
                              <textarea class="form-control" name="address" v-model="service.address"></textarea>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 mb-2">
                          <div class="js-form-message">
                            <label id="nameLabel" class="form-label">Deadline for Job Application</label>
                            <div class="form-group">
                              <input type="date" class="form-control" name="end_date" v-model="service.end_date" />
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
              </form>

            </div>
            <div class="modal-footer">
              <div v-if="loading">
                <button type="button" class="btn btn-primary" data-dismiss="modal">Loading.....</button>
              </div>
              <div v-else>
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>&nbsp;
                <button type="button" @click="createRequest()" class="btn btn-primary">Submit Request</button>
              </div>
            </div>

          </div>
        </div>
      </div>




    </div>
  </layout-default>
</template>
<script>
import LayoutDefault from "@/layouts/default";
import axios from 'axios';
import { VueEditor } from "vue2-editor";
import moment from 'moment';
// import $ from "jquery";
export default {
  name: 'Followers',
  components: {LayoutDefault, VueEditor},

    created() {
        this.fetchData();
        this.fetchUser();
        this.fetchProfessions();
        this.fetchTotalProjects();
        this.fetchTotalFollowers();
        this.fetchCountries();
        this.fetchNigeriaStates();
        this.fetchLagosCities();
    },

    watch: {
      $route: {
          immediate: true,
          handler(to) {
              document.title = to.meta.title || 'My Service Requests';
          }
      },
  }, 

    data() {
        return {
            loading: false,
            details: [],
            user:{},
            total_project: 0,
            total_followers: 0,
            professions: [],
            country: [],
            state: [],
            city: [],
            pagination: {
                lastPage: '',
                currentPage: '',
                total: '',
                lastPageUrl: '',
                nextPageUrl: '',
                prevPageUrl: '',
                from: '',
                to: ''
            },
            show: false,
            service: {
                service: '',
                description: '',
                country_id: 160,
                state_id: '',
                city_id: '',
                address: '',
                end_date: '',
                user_id: localStorage.getItem('user'),
            },

            customToolbar: [
                ["bold", "italic", "underline"],
                [{ list: "ordered" }, { list: "bullet" }],
                // ["image", "code-block"]
            ],
        }
    },

    methods: {
        fetchData () {
            axios(process.env.VUE_APP_API_URL + 'service/'+localStorage.getItem('user')).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.show = false;
                let data = response.data;
                this.details = data.data;
                this.configPagination(data);
            }).catch({});
        },

        fetchUser () {
            axios(process.env.VUE_APP_API_URL + 'user/details/'+localStorage.getItem('user')).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.user = response.data;
            }).catch({});
        },

        fetchProfessions () {
            axios(process.env.VUE_APP_API_URL + 'professions').then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.professions = response.data;
            }).catch({});
        },

        fetchCountries () {
            axios(process.env.VUE_APP_API_URL + 'country').then(response =>{
                if (! response.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.country = response.data;
            }).catch({});
        },

        fetchStates (e) {
            axios(process.env.VUE_APP_API_URL + 'state/'+e.target.value).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.state = response.data;
            }).catch({});
        },

        fetchCities (e) {
            axios(process.env.VUE_APP_API_URL + 'city/'+e.target.value).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.city = response.data;
            }).catch({});
        },

        fetchNigeriaStates () {
            axios(process.env.VUE_APP_API_URL + 'state/'+160).then(response =>{
                if (! response.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.state = response.data;
            }).catch({});
        },

        fetchLagosCities () {
            axios(process.env.VUE_APP_API_URL + 'city/'+2671).then(response =>{
                if (! response.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.city = response.data;
            }).catch({});
        },

        fetchTotalProjects () {
            axios(process.env.VUE_APP_API_URL + 'project/total-project/'+localStorage.getItem('user')).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.total_project = response.data;
            }).catch({});
        },

        fetchTotalFollowers () {
            axios(process.env.VUE_APP_API_URL + 'user/total-following/'+localStorage.getItem('user')).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.total_followers = response.data;
            }).catch({});
        },

        createRequest() {
            this.loading = true;
            axios.post(process.env.VUE_APP_API_URL + 'service/create', this.service)
            .then((response) => {
            if (response.status === 200) {
                this.$toastr.s(response.message);
                this.service.service = '';
                this.service.description = '';
                this.service.country_id = 160;
                this.service.state_id = '';
                this.service.city_id = '';
                this.service.address = '';
                this.service.end_date = '';
                this.$root.$emit('action')
                window.$('#service-modal').modal('hide');
                this.loading = false;
            } else {
                this.$toastr.e(response.data.message);
                this.loading = false;
            }
            }).catch((error) =>{
                this.$toastr.e(error.response.data.message);
                this.loading = false;
            })
        },

        configPagination(data) {
            this.pagination.lastPage = data.last_page;
            this.pagination.currentPage = data.current_page;
            this.pagination.total = data.total;
            this.pagination.lastPageUrl = data.last_page_url;
            this.pagination.nextPageUrl = data.next_page_url;
            this.pagination.prevPageUrl = data.prev_page_url;
            this.pagination.from = data.from;
            this.pagination.to = data.to;
        },

        formatDate(date){
            return moment(date).fromNow();
        },

        formatDate2(date) {
            return moment(date).format('MMM DD, YYYY');
        },

    },

     computed: {
        image_url: function() {
          return process.env.VUE_APP_IMAGE_URL;
        }
    },

    mounted: function () {
      this.$root.$on('action', ()=>{
        this.fetchData()
        this.fetchCountries()
        this.fetchNigeriaStates()
        this.fetchLagosCities()
      })
    },
}
</script>