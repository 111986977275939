import Home from '@/views/User/Home';
import Profile from '@/views/User/Profile';
import UpdateProfile from '@/views/User/UpdateProfile';
import ChangePassword from '@/views/User/ChangePassword';
import Experience from '@/views/User/Experience';
import ProjectDetail from '@/views/User/ProjectDetail';
import Followers from '@/views/User/Followers';
import Projects from '@/views/User/Projects';
import PostDetail from '@/views/User/PostDetail';
import Message from '@/views/User/Message';
import PlanDetail from '@/views/User/PlanDetail';
import ServiceRequests from '@/views/User/ServiceRequests';
import ServiceDetail from '@/views/User/ServiceDetail';
import UserBlog from '@/views/User/UserBlog';
import NotificationSetting from '@/views/User/NotificationSetting';
import PlanCategoryDetail from '@/views/User/PlanCategoryDetail';
import ProjectCategoryDetail from '@/views/User/ProjectCategoryDetail';
import PostCategoryDetail from '@/views/User/PostCategoryDetail';

import CompanyDetail from '@/views/CompanyDetail';
import Search from '@/views/User/Search';
import Blog from '@/views/Blog';
import BlogDetail from '@/views/BlogDetail';
import BlogCategory from '@/views/BlogCategory';
import BlogSearch from '@/views/BlogSearch';
import Terms from '@/views/Terms';
import PrivacyPolicy from '@/views/PrivacyPolicy';
import Services from '@/views/Services';
import not_found from '@/views/404';

import Login from '@/views/Auth/Login';
import Register from '@/views/Auth/Register';
import ForgotPassword from '@/views/Auth/ForgotPassword';
import ResetPassword from '@/views/Auth/ResetPassword';
import VerifyAccount from '@/views/Auth/VerifyAccount';
import Index from '@/views/Index';
import Contact from '@/views/Contact';

import MarketPlace from '@/views/Shop/Products';

const routes =
  localStorage.getItem('user') !== null
    ? [
        {
          path: '/',
          name: 'Home',
          component: Home,
          meta: { title: 'Structurecity | Listings' },
        },

        {
          path: '/listing',
          name: 'Listing',
          component: Home,
          meta: { title: 'Structurecity | Listings' },
        },

        {
          path: '/feeds',
          name: 'Feed',
          component: Home,
        },

        {
          path: '/sc/profile/:id',
          name: 'Profile',
          component: Profile,
        },

        {
          path: '/profile/update',
          name: 'UpdateProfile',
          component: UpdateProfile,
          meta: { title: 'Structurecity | Update Account' },
        },

        {
          path: '/notification-settings',
          name: 'NotificationSetting',
          component: NotificationSetting,
          meta: { title: 'Structurecity | Notification Setting' },
        },

        {
          path: '/profile/change-password',
          name: 'ChangePassword',
          component: ChangePassword,
          meta: { title: 'Structurecity | Change Password' },
        },

        {
          path: '/profile/experience',
          name: 'Experience',
          component: Experience,
        },

        {
          path: '/messages',
          name: 'messages',
          component: Message,
          meta: { title: 'Structurecity | Messages' },
        },

        {
          path: '/my-followers',
          name: 'Followers',
          component: Followers,
          meta: { title: 'Structurecity | Followers' },
        },

        {
          path: '/project/:id',
          name: 'ProjectDetail',
          component: ProjectDetail,
        },

        {
          path: '/post/:id',
          name: 'PostDetail',
          component: PostDetail,
        },

        {
          path: '/plan/:id',
          name: 'PlanDetail',
          component: PlanDetail,
        },

        {
          path: '/project/category/:id',
          name: 'ProjectCategoryDetail',
          component: ProjectCategoryDetail,
        },

        {
          path: '/post/category/:id',
          name: 'PostCategoryDetail',
          component: PostCategoryDetail,
        },

        {
          path: '/plan/category/:id',
          name: 'PlanCategoryDetail',
          component: PlanCategoryDetail,
        },

        {
          path: '/my-Projects',
          name: 'Projects',
          component: Projects,
          meta: { title: 'Structurecity | My Projects' },
        },

        {
          path: '/company/:id',
          name: 'CompanyDetail',
          component: CompanyDetail,
        },

        {
          path: '/search',
          name: 'Search',
          component: Search,
          meta: { title: 'Structurecity | Search' },
        },

        {
          path: '/blog',
          name: 'Blog',
          component: Blog,
          meta: { title: 'Structurecity | Blog' },
        },

        {
          path: '/blog/:id',
          name: 'BlogDetail',
          component: BlogDetail,
        },

        {
          path: '/blog/category/:id',
          name: 'BlogCategory',
          component: BlogCategory,
        },

        {
          path: '/blog-search',
          name: 'BlogSearch',
          component: BlogSearch,
          meta: { title: 'Structurecity | Search Blog' },
        },

        {
          path: '/terms-and-conditions',
          name: 'Terms',
          component: Terms,
          meta: { title: 'Structurecity | Terms and Conditions' },
        },

        {
          path: '/privacy-policy',
          name: 'PrivacyPolicy',
          component: PrivacyPolicy,
          meta: { title: 'Structurecity | Privacy Policy' },
        },

        {
          path: '/services',
          name: 'Services',
          component: Services,
          meta: { title: 'Structurecity | Services' },
        },

        {
          path: '/my-blog-history',
          name: 'UserBlog',
          component: UserBlog,
          meta: { title: 'Structurecity | My Blog History' },
        },

        {
          path: '/my-service-requests',
          name: 'ServiceRequests',
          component: ServiceRequests,
          meta: { title: 'Structurecity | My Service Requests' },
        },

        {
          path: '/service/:id',
          name: 'ServiceDetail',
          component: ServiceDetail,
        },

        {
          path: '/404',
          name: '404',
          component: not_found,
          meta: { title: 'Structurecity | Page not found' },
        },

        {
          path: '*',
          name: '404',
          component: not_found,
          meta: { title: 'Structurecity | Page not found' },
        },

        {
          path: '/shop',
          name: 'shop',
          component: MarketPlace,
          meta: { title: 'Structurecity | Marketplace' },
        },
      ]
    : [
        {
          path: '*',
          name: 'Index',
          component: Index,
          meta: {
            title:
              'Welcome to Structurecity - Listings, services, marketplace, news',
          },
        },

        {
          path: '/',
          name: 'Index',
          component: Index,
          meta: {
            title:
              'Welcome to Structurecity - Listings, services, marketplace, news',
          },
        },

        {
          path: '/login',
          name: 'Login',
          component: Login,
          meta: { title: 'Structurecity | Login' },
        },

        {
          path: '/project/:id',
          name: 'ProjectDetail',
          component: ProjectDetail,
        },

        {
          path: '/post/:id',
          name: 'PostDetail',
          component: PostDetail,
        },

        {
          path: '/plan/:id',
          name: 'PlanDetail',
          component: PlanDetail,
        },

        {
          path: '/my-Projects',
          name: 'Projects',
          component: Projects,
          meta: { title: 'Structurecity | My Projects' },
        },

        {
          path: '/company/:id',
          name: 'CompanyDetail',
          component: CompanyDetail,
        },

        {
          path: '/search',
          name: 'Search',
          component: Search,
          meta: { title: 'Structurecity | Search' },
        },

        {
          path: '/blog',
          name: 'Blog',
          component: Blog,
          meta: { title: 'Structurecity | Blog' },
        },

        {
          path: '/blog/:id',
          name: 'BlogDetail',
          component: BlogDetail,
        },

        {
          path: '/blog/category/:id',
          name: 'BlogCategory',
          component: BlogCategory,
        },

        {
          path: '/blog-search',
          name: 'BlogSearch',
          component: BlogSearch,
          meta: { title: 'Structurecity | Search Blog' },
        },

        {
          path: '/register',
          name: 'Register',
          component: Register,
          meta: { title: 'Structurecity | Register' },
        },

        {
          path: '/forgot-password',
          name: 'ForgotPassword',
          component: ForgotPassword,
          meta: { title: 'Structurecity | Forgot Password' },
        },

        {
          path: '/reset-password/:id',
          name: 'ResetPassword',
          component: ResetPassword,
          meta: { title: 'Structurecity | Reset Password' },
        },

        {
          path: '/verify/account/:id',
          name: 'VerifyAccount',
          component: VerifyAccount,
          meta: { title: 'Structurecity | Verify Account' },
        },

        {
          path: '/terms-and-conditions',
          name: 'Terms',
          component: Terms,
          meta: { title: 'Structurecity | Terms and Conditions' },
        },

        {
          path: '/privacy-policy',
          name: 'PrivacyPolicy',
          component: PrivacyPolicy,
          meta: { title: 'Structurecity | Privacy Policy' },
        },

        // { path: '/product/:id', component: Product },
        {
          path: '/about',
          name: 'About',
          component: () => import('@/views/About.vue'),
          meta: { title: 'Structurecity | About' },
        },

        {
          path: '/contact',
          name: 'Contact Us',
          component: Contact,
          meta: { title: 'Structurecity | Contact Us' },
        },

        {
          path: '/shop',
          name: 'shop',
          component: MarketPlace,
          meta: { title: 'Structurecity | Marketplace' },
        },

        {
          path: '/auth/:provider/callback',
          component: {
            template: '<div class="auth-component"></div>',
          },
        },
      ];

// const router = createRouter({
//     mode:'history',
//     // history: createWebHistory(process.env.BASE_URL),
//     routes
// })

// router.beforeEach((to, from, next) => {
//     const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
//     const isAuthenticated = firebase.auth().currentUser;
//     console.log("isauthenticated", isAuthenticated);
//     if (requiresAuth && !isAuthenticated) {
//         next("/login");
//     } else {
//         next();
//     }
// });

export default routes;
