<template>
  <layout-default>
      <div class="Profile">
          <div class="py-4">
              <div class="container">
              <div class="row">

              <aside class="col-md-4">
                <div class="box mb-3 shadow-sm border rounded bg-white profile-box text-center">
                  <div class="py-4 px-3 border-bottom">
                    <i style="float: right; font-size: 20px; cursor: pointer" data-toggle="modal" data-target="#update-avatar" title="update profile image" class="feather-edit"></i>
                    <img v-if="user.avatar" class="img-fluid mt-2 rounded-circle" :src="image_url+'users/'+user.avatar" style="max-height: 150px; max-width: 150px" alt="">
                    <img v-else class="img-fluid mt-2 rounded-circle" src="/assets/avatar.png" style="max-height: 150px; max-width: 150px" alt="">
                    <h5 class="font-weight-bold text-dark mb-1 mt-4">{{user.name}}</h5>
                    <p v-if="user.currentcompany" class="mb-0 text-muted">{{user.current_position}} <br> <router-link :to="'/company/'+user.currentcompany.slug">{{user.currentcompany.name}}</router-link></p>
                    <p v-else class="mb-0 text-muted">{{user.profession}}</p>
                    <span v-if="user.membership" class="text-info"><span v-if="user.membership.membership">{{ user.membership.membership.code }}</span><i v-if="user.membership.status == 1" data-toggle="tooltip" data-placement="top" title="Verified" class="feather-check-circle"></i></span> &nbsp;
                    <span v-if="user.certification" class="text-info"><span v-if="user.certification.certification">{{ user.certification.certification.code }}</span><i v-if="user.certification.status == 1" data-toggle="tooltip" data-placement="top" title="Verified" class="feather-check-circle"></i></span>
                  </div>
                  <div class="d-flex">
                    <div class="col-6 border-right p-3">
                      <h6 class="font-weight-bold text-dark mb-1">{{total_following}}</h6>
                      <p class="mb-0 text-black-50 small">Following</p>
                    </div>
                    <div class="col-6 p-3">
                      <h6 class="font-weight-bold text-dark mb-1">{{total_project}}</h6>
                      <p class="mb-0 text-black-50 small">Projects</p>
                    </div>
                  </div>
                  <div class="overflow-hidden border-top">
                    <router-link class="font-weight-bold p-3 d-block" :to="'/sc/profile/'+id"> View my profile </router-link>
                  </div>
                </div>
              </aside>
              <main class="col-md-8">
              <div class="border rounded bg-white mb-3">
                <div class="box-title border-bottom p-3">
                  <h6 class="m-0">Edit Basic Info</h6>
                  <p class="mb-0 mt-0 small">update your basic account details .</p>
                </div>
                <div class="box-body p-3">
                  <form id="update-form" class="js-validate" novalidate="novalidate">
                    <div class="row">
                      <div class="col-sm-6 mb-2">
                        <div class="js-form-message">
                          <label id="nameLabel" class="form-label">First Name
                          <span class="text-danger">*</span>
                          </label>
                          <div class="form-group">
                            <input type="text" class="form-control" name="first_name" :value="user.first_name" aria-label="Enter your first name" required="" aria-describedby="nameLabel" data-msg="Please enter your name." data-error-class="u-has-error" data-success-class="u-has-success">
                            <small class="form-text text-muted">Displayed on your public profile, notifications and other places.</small>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6 mb-2">
                        <div class="js-form-message">
                          <label id="usernameLabel" class="form-label">Last Name
                          <span class="text-danger">*</span>
                          </label>
                          <div class="form-group">
                            <input type="text" class="form-control" name="last_name" :value="user.last_name" aria-label="Enter your last name" required="" aria-describedby="usernameLabel" data-msg="Please enter your username." data-error-class="u-has-error" data-success-class="u-has-success">
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-sm-6 mb-2">
                        <div class="js-form-message">
                          <label id="nameLabel" class="form-label">Email Address
                          <span class="text-danger">*</span>
                          </label>
                          <div class="form-group">
                            <input type="text" class="form-control" name="email" :value="user.email" required="" aria-describedby="nameLabel" data-msg="Please enter your email." data-error-class="u-has-error" data-success-class="u-has-success" readonly>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6 mb-2">
                        <div class="js-form-message">
                          <label id="usernameLabel" class="form-label">Phone Number
                          <span class="text-danger">*</span>
                          </label>
                          <div class="form-group">
                            <input type="text" class="form-control" name="phone_number" :value="user.phone_number" required="" aria-describedby="usernameLabel" data-msg="Please enter your phone number." data-error-class="u-has-error" data-success-class="u-has-success" readonly>
                          </div>
                        </div>
                      </div>
                    </div>


                    <!-- <label class="form-label">Birth date
                    <span class="text-danger">*</span>
                    </label> -->
                    <div class="row">

                    <div class="col-md-6 mb-3 mb-sm-6">
                      <label class="form-label">Birth Month <span class="text-danger">*</span></label>
                      <div class="js-form-message">
                        <div class="form-group">
                          <select class="form-control custom-select" name="birth_month" v-model="user.birth_month" data-msg="Please select month." data-error-class="u-has-error" data-success-class="u-has-success">
                            <option value="" disabled selected>Month</option>
                            <option value="January">January</option>
                            <option value="February">February</option>
                            <option value="March">March</option>
                            <option value="April">April</option>
                            <option value="May">May</option>
                            <option value="June">June</option>
                            <option value="July">July</option>
                            <option value="August">August</option>
                            <option value="September">September</option>
                            <option value="October">October</option>
                            <option value="November">November</option>
                            <option value="December">December</option>
                          </select>
                        </div>
                      </div>
                    </div>


                    <div class="col-sm-4 col-md-2 mb-3 mb-sm-6">
                      <label class="form-label">Birth Day <span class="text-danger">*</span></label>
                      <div class="js-form-message">
                        <div class="form-group">
                          <select class="form-control custom-select" name="birth_day" v-model="user.birth_day" data-msg="Please select date." data-error-class="u-has-error" data-success-class="u-has-success">
                            <option value="" disabled selected>Day</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                            <option value="13">13</option>
                            <option value="14">14</option>
                            <option value="15">15</option>
                            <option value="16">16</option>
                            <option value="17">17</option>
                            <option value="18">18</option>
                            <option value="19">19</option>
                            <option value="20">20</option>
                            <option value="21">21</option>
                            <option value="22">22</option>
                            <option value="23">23</option>
                            <option value="24">24</option>
                            <option value="25">25</option>
                            <option value="26">26</option>
                            <option value="27">27</option>
                            <option value="28">28</option>
                            <option value="29">29</option>
                            <option value="30">30</option>
                            <option value="31">31</option>
                          </select>
                        </div>
                      </div>
                    </div>


                    <div class="col-sm-4 col-md-2 mb-3 mb-sm-6">
                      <label class="form-label">Birth Year <span class="text-danger">*</span></label>
                      <div class="js-form-message">
                        <div class="form-group">
                          <select class="form-control custom-select" name="birth_year" v-model="user.birth_year" data-msg="Please select year." data-error-class="u-has-error" data-success-class="u-has-success">
                          <option value="" disabled selected>Year</option>
                            <option value="1900">1900</option>
                            <option value="1901">1901</option>
                            <option value="1902">1902</option>
                            <option value="1903">1903</option>
                            <option value="1904">1904</option>
                            <option value="1905">1905</option>
                            <option value="1906">1906</option>
                            <option value="1907">1907</option>
                            <option value="1908">1908</option>
                            <option value="1909">1909</option>
                            <option value="1910">1910</option>
                            <option value="1911">1911</option>
                            <option value="1912">1912</option>
                            <option value="1913">1913</option>
                            <option value="1914">1914</option>
                            <option value="1915">1915</option>
                            <option value="1916">1916</option>
                            <option value="1917">1917</option>
                            <option value="1918">1918</option>
                            <option value="1919">1919</option>
                            <option value="1920">1920</option>
                            <option value="1921">1921</option>
                            <option value="1922">1922</option>
                            <option value="1923">1923</option>
                            <option value="1924">1924</option>
                            <option value="1925">1925</option>
                            <option value="1926">1926</option>
                            <option value="1927">1927</option>
                            <option value="1928">1928</option>
                            <option value="1929">1929</option>
                            <option value="1930">1930</option>
                            <option value="1931">1931</option>
                            <option value="1932">1932</option>
                            <option value="1933">1933</option>
                            <option value="1934">1934</option>
                            <option value="1935">1935</option>
                            <option value="1936">1936</option>
                            <option value="1937">1937</option>
                            <option value="1938">1938</option>
                            <option value="1939">1939</option>
                            <option value="1940">1940</option>
                            <option value="1941">1941</option>
                            <option value="1942">1942</option>
                            <option value="1943">1943</option>
                            <option value="1944">1944</option>
                            <option value="1945">1945</option>
                            <option value="1946">1946</option>
                            <option value="1947">1947</option>
                            <option value="1948">1948</option>
                            <option value="1949">1949</option>
                            <option value="1950">1950</option>
                            <option value="1951">1951</option>
                            <option value="1952">1952</option>
                            <option value="1953">1953</option>
                            <option value="1954">1954</option>
                            <option value="1955">1955</option>
                            <option value="1956">1956</option>
                            <option value="1957">1957</option>
                            <option value="1958">1958</option>
                            <option value="1959">1959</option>
                            <option value="1960">1960</option>
                            <option value="1961">1961</option>
                            <option value="1962">1962</option>
                            <option value="1963">1963</option>
                            <option value="1964">1964</option>
                            <option value="1965">1965</option>
                            <option value="1966">1966</option>
                            <option value="1967">1967</option>
                            <option value="1968">1968</option>
                            <option value="1969">1969</option>
                            <option value="1970">1970</option>
                            <option value="1971">1971</option>
                            <option value="1972">1972</option>
                            <option value="1973">1973</option>
                            <option value="1974">1974</option>
                            <option value="1975">1975</option>
                            <option value="1976">1976</option>
                            <option value="1977">1977</option>
                            <option value="1978">1978</option>
                            <option value="1979">1979</option>
                            <option value="1980">1980</option>
                            <option value="1981">1981</option>
                            <option value="1982">1982</option>
                            <option value="1983">1983</option>
                            <option value="1984">1984</option>
                            <option value="1985">1985</option>
                            <option value="1986">1986</option>
                            <option value="1987">1987</option>
                            <option value="1988">1988</option>
                            <option value="1989">1989</option>
                            <option value="1990">1990</option>
                            <option value="1991">1991</option>
                            <option value="1992">1992</option>
                            <option value="1993">1993</option>
                            <option value="1994">1994</option>
                            <option value="1995">1995</option>
                            <option value="1996">1996</option>
                            <option value="1997">1997</option>
                            <option value="1998">1998</option>
                            <option value="1999">1999</option>
                            <option value="2000">2000</option>
                            <option value="2001">2001</option>
                            <option value="2002">2002</option>
                            <option value="2003">2003</option>
                            <option value="2004">2004</option>
                            <option value="2005">2005</option>
                            <option value="2006">2006</option>
                            <option value="2007">2007</option>
                            <option value="2008">2008</option>
                            <option value="2009">2009</option>
                            <option value="2010">2010</option>
                            <option value="2011">2011</option>
                            <option value="2012">2012</option>
                            <option value="2013">2013</option>
                            <option value="2014">2014</option>
                            <option value="2015">2015</option>
                            <option value="2016">2016</option>
                            <option value="2017">2017</option>
                          </select>
                        </div>
                      </div>
                    </div>


                    <div class="col-sm-4 col-md-2 mb-2">
                      <label class="form-label">Gender <span class="text-danger">*</span></label>
                      <div class="js-form-message">
                        <div class="form-group">
                          <select class="form-control custom-select" name="gender" v-model="user.gender" data-msg="Please select your gender." data-error-class="u-has-error" data-success-class="u-has-success">
                            <option value="" disabled selected>Gender</option>
                            <option value="Female">Female</option>
                            <option value="Male">Male</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    </div>

                    <!-- <label class="form-label">Location
                    <span class="text-danger">*</span>
                    </label> -->
                    <div class="row">
                      <div class="col-md-4 mb-3 mb-sm-4">
                        <label class="form-label">Country <span class="text-danger">*</span></label>
                        <div class="js-form-message">
                          <div class="form-group">
                            <select class="form-control custom-select" name="country_id" v-model="user.country_id" data-msg="Please select country." data-error-class="u-has-error" data-success-class="u-has-success" @change='fetchStates2($event)'>
                              <option value="" disabled selected>Country</option>
                              <option v-for="data in country" :key="data.id" :value="data.id">{{data.name}}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 mb-3 mb-sm-4">
                        <label class="form-label">State <span class="text-danger">*</span></label>
                        <div class="js-form-message">
                          <div class="form-group">
                            <select class="form-control custom-select" name="state_id" v-model="user.state_id" data-msg="Please select state." data-error-class="u-has-error" data-success-class="u-has-success" @change='fetchCities2($event)'>
                              <option value="" disabled selected>State</option>
                              <option v-for="data in state" :key="data.id" :value="data.id">{{data.name}}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 mb-3 mb-sm-4">
                        <label class="form-label">City <span class="text-danger">*</span></label>
                        <div class="js-form-message">
                          <div class="form-group">
                            <select class="form-control custom-select" name="city_id" v-model="user.city_id" data-msg="Please select city." data-error-class="u-has-error" data-success-class="u-has-success">
                              <option value="" disabled selected>City</option>
                              <option v-for="data in city" :key="data.id" :value="data.id">{{data.name}}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-sm-6 mb-2">
                        <div class="js-form-message">
                          <label id="nameLabel" class="form-label">Career Title / Profession
                          <span class="text-danger">*</span>
                          </label>
                          <div class="form-group">
                            <select class="form-control custom-select" name="profession" v-model="user.profession">
                              <option value="">-- Select --</option>
                              <option v-for="(data, index) in professions" :key="index" :value="data.name">{{data.name}}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6 mb-2">
                        <div class="js-form-message">
                          <label id="usernameLabel" class="form-label">Personal Website
                          <span class="text-danger"></span>
                          </label>
                          <div class="form-group">
                            <input type="url" class="form-control" name="website" :value="user.website" required="" aria-describedby="usernameLabel" data-error-class="u-has-error" data-success-class="u-has-success">
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="border rounded bg-white mb-3">
                      <div class="box-title border-bottom p-3">
                        <h6 class="m-0">Current Position</h6>
                        <p class="mb-0 mt-0 small">Tell us about your current position.</p>
                      </div><br>
                      <div class="col-sm-12 mb-2">
                        <label id="nameLabel" class="form-label">Your Current Position</label>
                        <input type="url" class="form-control" name="current_position" :value="user.current_position" required="" aria-describedby="usernameLabel" data-error-class="u-has-error" data-success-class="u-has-success">
                      </div>
                      <div class="col-sm-12 mb-2">
                        <label id="nameLabel" class="form-label">Company</label>
                        <multiselect v-model="user.current_position_company_id" label="name" placeholder="Select one" :options="companies" :allow-empty="true"></multiselect>
                      </div>
                    </div>

<!-- 
                    <div class="form-row">
                        <div class="form-group mb-0">
                            <label class="mb-1 w-100">Interests</label>
                            <div style="padding-left: 30px" v-for="(data) in interests" :key="data.id" class="col-md-3 custom-control custom-checkbox d-inline mr-3">
                               <input type="checkbox" class="custom-control-input" name="interests[]" :value="data.id" :id="'customCheck'+data.id">
                                <label class="custom-control-label" :for="'customCheck'+data.id">{{data.name}}</label>
                            </div>
                            <div style="padding-left: 30px" v-for="(data) in user.interests" :key="data.id" class="col-md-3 custom-control custom-checkbox d-inline mr-3">
                               <input type="checkbox" class="custom-control-input" name="interests[]" :value="data.interest_id" :id="'customCheck'+data.id" checked>
                                <label class="custom-control-label" :for="'customCheck'+data.id">{{data.interest.name}}</label>
                            </div>
                        </div>
                    </div><br/> -->

                    <div class="form-row">
                        <div class="form-group mb-0">
                            <label class="mb-1 w-100">Interests</label>
                            <div style="padding-left: 30px" v-for="(data) in interests" :key="data.id" class="col-md-3 custom-control custom-checkbox">
                               <input type="checkbox" class="custom-control-input" name="interests[]" :value="data.id" :id="'customCheck'+data.id">
                                <label class="custom-control-label" :for="'customCheck'+data.id">{{data.name}}</label>
                            </div>
                            <div style="padding-left: 30px" v-for="(data) in user.interests" :key="data.id" class="col-md-3 custom-control custom-checkbox">
                               <input type="checkbox" class="custom-control-input" name="interests[]" :value="data.interest_id" :id="'customCheck'+data.id" checked>
                                <label class="custom-control-label" :for="'customCheck'+data.id">{{data.interest.name}}</label>
                            </div>
                        </div>
                    </div><br/>

                    <div class="border rounded bg-white mb-3">
                      <div class="box-title border-bottom p-3">
                        <h6 class="m-0">About</h6>
                        <p class="mb-0 mt-0 small">Tell about yourself in two sentences.</p>
                      </div><br>
                      <div class="col-sm-12 mb-2">
                        <!-- <vue-editor id="profile_editor" name="about" v-model="user.about" :editorToolbar="customToolbar"></vue-editor> -->
                        <textarea class="form-control" rows="4" name="about" v-model="user.about" placeholder="Enter Bio"></textarea>
                      </div>
                    </div>

                    <div v-if="membership != null || certification != null " class="border rounded bg-white mb-3">
                      <div class="box-title border-bottom p-3">
                        <h6 class="m-0">Membership & Certification</h6>
                        <p class="mb-0 mt-0 small">Verify your profession membership and certification.</p>
                      </div><br>
                      <div class="col-sm-12 mb-2">
                        <div class="js-form-message">
                          <label id="nameLabel" class="form-label">Membership Number</label>
                          <p v-if="membership" class="mb-0 mt-0 small">{{membership.name}} - ({{membership.code}}). 
                            <span v-if="user.membership">
                              <span v-if="user.membership.status == 0" class="badge badge-warning">awaiting approval</span> 
                              <span v-if="user.membership.status == 1" class="badge badge-success">approved</span> 
                              <span v-if="user.membership.status == 2" class="badge badge-danger">rejected</span>
                            </span>
                          </p>
                          <div class="form-group">
                            <input type="text" class="form-control" name="membership_number" :value="user.membership?user.membership.membership_number:''" aria-describedby="usernameLabel" data-error-class="u-has-error" data-success-class="u-has-success" :readonly="approved_membership == true">
                            <input type="hidden" class="form-control" name="membership_id" :value="membership?membership.id:''">
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 mb-2">
                        <div class="js-form-message">
                          <label id="usernameLabel" class="form-label">Certificate Number </label>
                          <p v-if="certification" class="mb-0 mt-0 small">{{certification.name}} - ({{certification.code}}).
                            <span v-if="user.certification">
                              <span v-if="user.certification.status == 0" class="badge badge-warning">awaiting approval</span> 
                              <span v-if="user.certification.status == 1" class="badge badge-success">approved</span> 
                              <span v-if="user.certification.status == 2" class="badge badge-danger">rejected</span>
                            </span>
                          </p>
                          <div class="form-group">
                            <input type="text" class="form-control" name="certificate_number" :value="user.certification?user.certification.certificate_number:''" required="" aria-describedby="usernameLabel" data-error-class="u-has-error" data-success-class="u-has-success" :readonly="approved_certification == true">
                            <input type="hidden" class="form-control" name="certification_id" :value="certification?certification.id:''">
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="border rounded bg-white mb-3">
                      <div class="box-title border-bottom p-3">
                        <h6 class="m-0">Avatar</h6>
                        <p class="mb-0 mt-0 small">Update your profile picture.</p>
                      </div><br>
                      <div class="col-sm-12 mb-2">
                        <div class="mb-3 border rounded bg-white profile-box text-center w-10">
                          <div class="p-4 d-flex align-items-center">
                            <img v-if="url" class="img-fluid rounded-circle" :src="url" style="max-height: 120px; max-width: 120px" alt="">
                            <img v-else-if="user.avatar" class="img-fluid rounded-circle" :src="image_url+'users/'+user.avatar" style="max-height: 120px; max-width: 120px" alt="">                            
                            <img v-else class="img-fluid rounded-circle" src="/assets/avatar.png" style="max-height: 120px; max-width: 120px" alt="">
                            <div class="p-4">
                              <!-- <label data-toggle="tooltip" data-placement="top" data-original-title="Upload New Picture" class="btn btn-info m-0" for="fileAttachmentBtn">
                                <i class="feather-image"></i>
                                <input id="fileAttachmentBtn" name="avatar" type="file" class="d-none" accept="image/*" @change="onFileChange">
                              </label> -->
                              <a class="btn btn-info m-0" data-toggle="modal" data-target="#update-avatar"><i style="color:white" class="feather-edit"></i></a>
                              <span style="padding-left: 10px">
                                <button data-toggle="tooltip" data-placement="top" data-original-title="Delete" type="button" @click="removeAvatar()" class="btn btn-danger"><i class="feather-trash-2"></i></button>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </form>
                </div>
              </div>
              
              <div class="mb-3 text-right">
              <router-link class="font-weight-bold btn btn-link rounded p-3" to="/"> &nbsp;&nbsp;&nbsp;&nbsp; Cancel &nbsp;&nbsp;&nbsp;&nbsp; </router-link>
              <a v-if="loading" class="font-weight-bold btn btn-primary rounded p-3"> &nbsp;&nbsp;&nbsp;&nbsp; loading......... &nbsp;&nbsp;&nbsp;&nbsp; </a>
              <a v-else class="font-weight-bold btn btn-primary rounded p-3" @click="update()"> &nbsp;&nbsp;&nbsp;&nbsp; Save Changes &nbsp;&nbsp;&nbsp;&nbsp; </a>
              </div>
              </main>
              </div>
              </div>
          </div>


          <!-- update avatar Modal -->
      <div class="modal fade" id="update-avatar" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Change Profile Picture </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div id="banner-form">
                <div class="row">                  
                  <div class="col-md-8 mb-8 mb-sm-12">
                    <div class="js-form-message">
                      <label id="nameLabel" class="form-label">Select Image
                        <span class="text-danger" style="font-size: 10px">* (1500px X 300px)</span>
                      </label>
                      <div class="form-group">
                        <input class="form-control" type="file" name="banner" accept="image/*"  @change="setImage" required/>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 mb-4 mb-sm-12">
                    <div class="js-form-message">
                     <div class="form-group">
                        <img :src="imageSrc" style="width: 100px;" />
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="this.imageSrc" class="row">                  
                  <div class="col-md-12 mb-12 mb-sm-12">
                    <div class="my-3 d-flex align-items-center justify-content-center mx-auto">
                    <vue-cropper
                      class="mr-2 w-50"
                      ref="cropper"
                      :guides="true"
                      :aspect-ratio="500 / 500"
                      :src="imageSrc"
                    ></vue-cropper>
                    </div>
                  </div>
                  <div class="col-md-12 mb-12 mb-sm-12">
                    <!-- Cropped image previewer -->
                    <img class="ml-2 w-50 bg-light my-3 d-flex align-items-center justify-content-center mx-auto" :src="croppedImageSrc" />
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
               <div v-if="loading">
                <button type="button" class="btn btn-primary" data-dismiss="modal">Loading.....</button>
              </div>
              <div v-else>
                <button v-if="this.imageSrc" @click="cropImage" type="button" class="btn btn-primary">Crop</button>&nbsp; &nbsp; 
                <button v-if="this.croppedImageSrc" @click="uploadImage()" type="button" class="btn btn-primary">Upload Image</button>
              </div>
            </div>

          </div>
        </div>
      </div>


       </div>
  </layout-default>
</template>
<script>
import LayoutDefault from '../../layouts/default.vue';
import axios from 'axios';
import $ from "jquery";
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
// import { VueEditor } from "vue2-editor";
import Multiselect from 'vue-multiselect';

export default {
  name: 'Profile',
  components: {
    LayoutDefault,
    VueCropper,
    // VueEditor,
    Multiselect,
  },

  created() {
    this.fetchCompany();
    this.fetchUser();
    this.fetchCountries();
    this.fectchInterests();
    this.fetchProfessions();
    this.fetchCompanies();
  },

  watch: {
      $route: {
          immediate: true,
          handler(to) {
              document.title = to.meta.title || 'Update Profile';
          }
      },
  }, 

  data() {
        return {
          company: {},
          user: {},
          country: [],
          state: [],
          city: [],
          companies:[],
          total_project: 0,
          total_followers: 0,
          total_following: 0,
          loading: false,
          id: localStorage.getItem('slug'),
          interests: [],
          professions:[],
          url: null,
          certification:null,
          membership:null,
          approved_membership: false,
          approved_certification: false,
          // customToolbar: [
          //   ["bold", "italic", "underline"],
          //   [{ list: "ordered" }, { list: "bullet" }],
          //   // ["image", "code-block"]
          // ],
          imageSrc: "",
          croppedImageSrc: "",
        }
  },   

  methods: {
      logout () {
          localStorage.clear();
          this.$router.go();
        },

        fetchCompany () {
            axios(process.env.VUE_APP_API_URL + 'company').then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.company = response.data;
            }).catch({});
        },

        fetchUser () {
            axios(process.env.VUE_APP_API_URL + 'user/details/'+localStorage.getItem('user')).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.user = response.data;
                this.user.current_position_company_id = response.data.currentcompany;
                this.fetchTotalProjects(this.user.id);
                this.fetchTotalFollowers(this.user.id);
                this.fetchTotalFollowing(this.user.id);
                this.fetchStates();
                this.fetchCities();
                this.fetchCertification(this.user.profession);
                this.fetchMembership(this.user.profession); 
                if(this.user.membership){
                  if(this.user.membership.status == 1){
                    this.approved_membership = true;
                  }
                }  
                if(this.user.certification){
                  if(this.user.certification.status == 1){
                    this.approved_certification = true;
                  }
                }               
            }).catch({});
        },

        fetchCountries () {
            axios(process.env.VUE_APP_API_URL + 'country').then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.country = response.data;
            }).catch({});
        },

        fetchStates () {
            axios(process.env.VUE_APP_API_URL + 'state/'+this.user.country_id).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.state = response.data;
            }).catch({});
        },

        fetchCities () {
            axios(process.env.VUE_APP_API_URL + 'city/'+this.user.state_id).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.city = response.data;
            }).catch({});
        },

        fetchStates2 (event) {
            axios(process.env.VUE_APP_API_URL + 'state/'+event.target.value).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.state = response.data;
            }).catch({});
        },

        fetchCities2 (event) {
            axios(process.env.VUE_APP_API_URL + 'city/'+event.target.value).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.city = response.data;
            }).catch({});
        },

        fectchInterests () {
            axios(process.env.VUE_APP_API_URL + 'interest/user/'+localStorage.getItem('user')).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.interests = response.data;
            }).catch({});
        },

        fetchProfessions () {
            axios(process.env.VUE_APP_API_URL + 'professions').then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.professions = response.data;
            }).catch({});
        },

        fetchTotalProjects (id) {
            axios(process.env.VUE_APP_API_URL + 'project/total-project/'+id).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.total_project = response.data;
            }).catch({});
        },

        fetchTotalFollowers (id) {
            axios(process.env.VUE_APP_API_URL + 'user/total-following/'+id).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.total_following = response.data;
            }).catch({});
        },

        fetchTotalFollowing (id) {
            axios(process.env.VUE_APP_API_URL + 'user/total-followers/'+id).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.total_followers = response.data;
            }).catch({});
        },

        fetchCertification (id) {
            axios(process.env.VUE_APP_API_URL + 'certification/'+id).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.certification = response.data;
            }).catch({});
        },

        fetchMembership (id) {
            axios(process.env.VUE_APP_API_URL + 'membership/'+id).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.membership = response.data;
            }).catch({});
        },

        fetchCompanies () {
            axios(process.env.VUE_APP_API_URL + 'companies').then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.companies = response.data;
            }).catch({});
        },

        update () {
            this.loading = true;
            var form = $("#update-form")[0];
            var _data = new FormData(form);
            _data.append('current_position_company_id', JSON.stringify(this.user.current_position_company_id));
            axios.post(process.env.VUE_APP_API_URL + 'user/update-profile/'+localStorage.getItem('user'), _data)
            .then((response) => {
            if (response.status === 200) {
                this.$toastr.s(response.message);
                this.$root.$emit('refresh')
                // this.$router.push({ name: 'Profile', query: { redirect: '/sc/profile/'+localStorage.getItem('slug') } });
                this.loading = false;
            } else {
                this.$toastr.e(response.data.message);
                this.loading = false;
            }
            }).catch((error) =>{
                this.$toastr.e(error.response.data.message);
                this.loading = false;
            })
        },

        removeAvatar () {
            axios(process.env.VUE_APP_API_URL + 'user/remove-avatar/'+localStorage.getItem('user')).then(response =>{
                if (! response.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.$toastr.s(response.message);
                this.$router.go();
            }).catch({});
        },

        onFileChange(e) {
          const file = e.target.files[0];
          this.url = URL.createObjectURL(file);
        },

        setImage: function (e) {
            const file = e.target.files[0]
            if (!file.type.includes("image/")) {
              alert("Please select an image file")
              return
            }
            if (typeof FileReader === "function") {
              const reader = new FileReader()
              reader.onload = event => {
                this.imageSrc = event.target.result

                // Rebuild cropperjs with the updated source
                this.$refs.cropper.replace(event.target.result)
              }
              reader.readAsDataURL(file)
            } else {
              alert("Sorry, FileReader API not supported")
            }
          },

          cropImage() {
            // Get image data for post processing, e.g. upload or setting image src
            this.croppedImageSrc = this.$refs.cropper.getCroppedCanvas().toDataURL()
          },

          uploadImage () {
            var self = this;
            self.loading = true;
            this.$refs.cropper.getCroppedCanvas().toBlob(function (blob) {
              let formData = new FormData()
              // Add name for our image
              formData.append("name", "avatar-" + new Date().getTime())
              // Append image file
              formData.append("file", blob)
              formData.append("id", localStorage.getItem('user'))
            axios.post(process.env.VUE_APP_API_URL + 'user/update-profile-image', formData)
              .then((response) => {
              if (response.status === 200) {
                  self.$toastr.s(response.message);
                  self.loading = false;
                  self.$root.$emit('refresh');
                  window.$('#update-avatar').modal('hide');
              } else {
                  self.$toastr.e(response.message);
                  self.loading = false;
              }
              }).catch((error) =>{
                  self.$toastr.e(error.response.message);
                  self.loading = false;
              })
          })
        },
    },

    mounted: function () {
       this.$root.$on('refresh', ()=>{
        this.fetchUser()
        this.fectchInterests()
      })
    },

    computed: {
        image_url: function() {
          return process.env.VUE_APP_IMAGE_URL;
        }
    },
}
</script>