<template>
  <layout-default>
      <div class="Profile">
          <div class="py-4">
              <div class="container">
              <div class="row">
              
                <main class="col col-xl-9 order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12">
                    <button style="float:right" type="button" class="btn btn-primary mr-1" data-toggle="modal" data-target="#new-message">  <i class="feather-plus"></i> New Message </button>
                    <div class="box shadow-sm rounded bg-white mb-3 osahan-chat">
                    <h5 class="pl-3 pt-3 pr-3 border-bottom mb-0 pb-3">Messaging</h5>                    
                    <div class="row m-0">
                    <div class="border-right col-lg-5 col-xl-4 px-0">
                    <div class="osahan-chat-left">
                    <div class="position-relative icon-form-control p-3 border-bottom">
                    <i class="feather-search position-absolute"></i>
                    <input placeholder="Search messages" v-model="tableData.search" @input="fetchUsers()" type="text" class="form-control">
                    </div>
                    <div class="osahan-chat-list">
                    <a v-for="(data, index) in messenger" :key="index" style="cursor:pointer" @click="viewDetail(index, data.id)" class="p-3 d-flex align-items-center border-bottom osahan-post-header overflow-hidden">
                        <div v-if="id == data.user_id" class="dropdown-list-image mr-3">
                            <span v-if="data.sender">
                                <img v-if="data.sender.avatar" class="rounded-circle" :src="image_url+'users/'+data.sender.avatar" alt="">
                                <div v-else class="dropdown-list-image mr-3 d-flex align-items-center bg-primary justify-content-center rounded-circle text-white">{{data.sender.name.charAt(0)}}</div>
                            </span>
                        </div>
                        <div v-else class="dropdown-list-image mr-3">
                            <span v-if="data.user">
                                <img v-if="data.user.avatar" class="rounded-circle" :src="image_url+'users/'+data.user.avatar" alt="">
                                <div v-else class="dropdown-list-image mr-3 d-flex align-items-center bg-primary justify-content-center rounded-circle text-white">{{data.user.name.charAt(0)}}</div>
                            </span>
                        </div>
                        <div v-if="data.status == 0" class="font-weight-bold mr-1 overflow-hidden">
                            <div v-if="id == data.user_id" class="text-truncate">{{data.sender?data.sender.name:''}}</div> <div v-else class="text-truncate">{{data.user?data.user.name:''}}</div>
                            <div v-if="data.details.length" class="small text-truncate overflow-hidden text-black-50"><i class="feather-check text-primary"></i>{{data.details?data.details[0].message:''}}</div>
                        </div>
                        <div v-else class="mr-1 overflow-hidden">
                            <div v-if="id == data.user_id" class="text-truncate">{{data.sender?data.sender.name:''}}</div> <div v-else class="text-truncate">{{data.user?data.user.name:''}}</div>
                            <div v-if="data.details.length" class="small text-truncate overflow-hidden text-black-50"><i class="feather-check text-primary"></i>{{data.details?data.details[0].message:''}}</div>
                        </div>
                        <span class="ml-auto mb-auto">
                        <div v-if="data.details.length" class="text-right text-muted pt-1 small">{{data.details?formatDate(data.lastdetails[0].created_at):''}}</div>
                        </span>
                    </a>

                    <a v-for="data in search_result" :key="data.id" style="cursor:pointer" @click="messageUser(data.id)" class="p-3 d-flex align-items-center border-bottom osahan-post-header overflow-hidden">
                        <div class="dropdown-list-image mr-3">
                            <img v-if="data.avatar" class="rounded-circle" :src="image_url+'users/'+data.avatar" alt="">
                            <div v-else class="dropdown-list-image mr-3 d-flex align-items-center bg-primary justify-content-center rounded-circle text-white">{{data.name.charAt(0)}}</div>
                        </div>
                        <div class="font-weight-bold mr-1 overflow-hidden">
                        <div class="text-truncate">{{data.name}}</div>
                        </div>
                    </a>

                    </div>
                    </div>
                    </div>
                    <div class="col-lg-7 col-xl-8 px-0">
                    <div class="p-3 d-flex align-items-center  border-bottom osahan-post-header">
                    <div v-if="id == currentData.user_id" class="font-weight-bold mr-1 overflow-hidden">
                        <div class="text-truncate">{{currentData.sender?currentData.sender.name:''}} </div>
                        <div class="small text-truncate overflow-hidden text-black-50">{{currentData.sender?currentData.sender.profession:''}} </div>
                    </div>
                    <div v-else class="font-weight-bold mr-1 overflow-hidden">
                        <div class="text-truncate">{{currentData.user?currentData.user.name:''}} </div>
                        <div class="small text-truncate overflow-hidden text-black-50">{{currentData.user?currentData.user.profession:''}} </div>
                    </div>
                    <span v-if="currentData" class="ml-auto">
                    <a v-if="id == currentData.user_id" :href="'tel:'+currentData.sender.phone_number" type="button" class="btn btn-light btn-sm rounded">
                    <i class="feather-phone"></i></a>
                    <a v-else :href="'tel:'+currentData.user.phone_number" type="button" class="btn btn-light btn-sm rounded">
                    <i class="feather-phone"></i></a>
                    <div class="btn-group">
                    <button type="button" class="btn btn-light btn-sm rounded" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="feather-more-vertical"></i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right">
                    <button class="dropdown-item" type="button" @click="deleteMessage(currentData.id)"><i class="feather-trash"></i> Delete </button>
                    <button class="dropdown-item" type="button" @click="clearMessengerChat(currentData.id)"><i class="feather-x-circle"></i> Clear Chat</button>
                    </div>
                    </div>
                    </span>
                    </div>
                    <div class="osahan-chat-box p-3 border-top border-bottom bg-light">
                    <!-- <div class="text-center my-3">
                    <span class="px-3 py-2 small bg-white shadow-sm  rounded">DEC 21, 2020</span>
                    </div> -->
                    <div v-for="(data, index) in currentData.details" :key="index" class="d-flex align-items-center osahan-post-header">
                    <div class="dropdown-list-image mr-3 mb-auto">
                        <span v-if="data.sender">
                            <img v-if="data.sender.avatar" class="rounded-circle" :src="image_url+'users/'+data.sender.avatar" alt="">
                            <div v-else class="dropdown-list-image mr-3 d-flex align-items-center bg-primary justify-content-center rounded-circle text-white">{{data.sender.name.charAt(0)}}</div>
                        </span>
                    </div>
                    <div class="mr-1">
                    <div class="text-truncate h6 mb-3">{{data.sender?data.sender.name:''}} </div>
                    <p>{{data.message}}</p>
                    </div>
                    <span class="ml-auto mb-auto">
                    <div class="text-right text-muted pt-1 small">{{formatDate(data.created_at)}}</div>
                    </span>
                    </div> 
                    </div>
                    <div v-if="currentData" class="w-100 border-top border-bottom">
                        <form id="message-form">
                            <input type="hidden" name="sender_id" :value="id"/>
                            <input v-if="id == currentData.user_id" type="hidden" name="user_id" :value="currentData.sender_id"/>
                            <input v-else type="hidden" name="user_id" :value="currentData.user_id"/>
                            <textarea placeholder="Write a message…" name="message" class="form-control border-0 p-3 shadow-none" rows="2"></textarea>
                        </form>
                    </div>
                    <div v-if="currentData" class="p-3 d-flex align-items-center">
                    <!-- <div class="overflow-hidden">
                    <button type="button" class="btn btn-light btn-sm rounded">
                    <i class="feather-image"></i>
                    </button>
                    <button type="button" class="btn btn-light btn-sm rounded">
                    <i class="feather-paperclip"></i>
                    </button>
                    <button type="button" class="btn btn-light btn-sm rounded">
                    <i class="feather-camera"></i>
                    </button>
                    </div> -->
                    <span class="ml-auto">
                    <button v-if="loading" type="button" class="btn btn-primary btn-sm rounded">
                    <i class="feather-send"></i> sending.... </button>
                    <button v-else type="button" @click="sendMessage()" class="btn btn-primary btn-sm rounded">
                    <i class="feather-send"></i> Send </button>
                    </span>
                    </div>
                    </div>
                    </div>
                    </div>
                </main>

                <aside class="col col-xl-3 order-xl-2 col-lg-12 order-lg-2 col-12">
                <div class="box mb-3 shadow-sm border rounded bg-white profile-box text-center">
                  <div class="py-4 px-3 border-bottom">
                    <img v-if="user.avatar" class="img-fluid mt-2 rounded-circle" :src="image_url+'users/'+user.avatar" style="max-height: 150px; max-width: 150px" alt="">
                    <img v-else class="img-fluid mt-2 rounded-circle" src="/assets/avatar.png" style="max-height: 150px; max-width: 150px" alt="">
                    <h5 class="font-weight-bold text-dark mb-1 mt-4">{{user.name}}</h5>
                    <p v-if="user.currentcompany" class="mb-0 text-muted">{{user.current_position}} <br> <router-link :to="'/company/'+user.currentcompany.slug">{{user.currentcompany.name}}</router-link></p>
                    <p v-else class="mb-0 text-muted">{{user.profession}}</p>
                    <span v-if="user.membership" class="text-info"><span v-if="user.membership.membership">{{ user.membership.membership.code }}</span><i v-if="user.membership.status == 1" data-toggle="tooltip" data-placement="top" title="Verified" class="feather-check-circle"></i></span> &nbsp;
                    <span v-if="user.certification" class="text-info"><span v-if="user.certification.certification">{{ user.certification.certification.code }}</span><i v-if="user.certification.status == 1" data-toggle="tooltip" data-placement="top" title="Verified" class="feather-check-circle"></i></span>
                  </div>
                  <div class="overflow-hidden border-top">
                    <router-link class="font-weight-bold p-3 d-block" :to="'/sc/profile/'+id"> View my profile </router-link>
                  </div>
                </div>
              </aside>
              </div>
              </div>
          </div>
       </div>





       <!-- new message Modal -->
      <div class="modal fade" id="new-message" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Compose New Message </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form id="compose-form">
                <div class="row">
                    <div class="col-md-12 mb-12 mb-sm-12">
                        <div class="js-form-message">
                            <label class="form-label">Search User</label>
                            <div class="form-group">
                                <multiselect v-model="message.user_id" label="name" placeholder="Select one" :options="users" :allow-empty="true"></multiselect>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mb-12 mb-sm-12">
                        <div class="js-form-message">
                        <label class="form-label">Message</label>
                        <div class="form-group">
                            <textarea class="form-control" rows="6" name="message" v-model="message.message" placeholder="your message here...." required></textarea>
                        </div>
                        </div>
                    </div>
                </div>
              </form>

            </div>
            <div class="modal-footer">
              <div v-if="loading">
                <button type="button" class="btn btn-primary" data-dismiss="modal">Loading.....</button>
              </div>
              <div v-else>
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>&nbsp;
                <button type="button" @click="composeMessage()" class="btn btn-primary">Send</button>
              </div>
            </div>

          </div>
        </div>
      </div>

  </layout-default>
</template>
<script>
import LayoutDefault from '../../layouts/default.vue';
import axios from 'axios';
import $ from "jquery";
import moment from 'moment';
import Multiselect from 'vue-multiselect';

export default {
  name: 'Profile',
  components: {
    LayoutDefault,
    Multiselect,
  },
 created() {
     this.fetchData();
        this.fetchCompany();
        this.fetchUser();
        this.fetchAllUsers();
  },

  watch: {
      $route: {
          immediate: true,
          handler(to) {
              document.title = to.meta.title || 'Change Password';
          }
      },
  }, 

  data() {
        return {
            company: {},
            currentData:'',
            current_index: '',
            user: {},
            messenger:[],
            loading: false,
            id: localStorage.getItem('user'),
            search_result: [],
            tableData: {
                draw: 0,
                search: '',
            },
            show: false,
            users: [],
            message: {
                user_id: '',
                message: '',
                sender_id: localStorage.getItem('user'),
            }
        }
  },   

  methods: {
      logout () {
          localStorage.clear();
          this.$router.go();
        },

        fetchData () {
            axios(process.env.VUE_APP_API_URL + 'messenger/fetch/'+localStorage.getItem('user')).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.messenger = response.data;
            }).catch({});
        },

        fetchUsers (url = process.env.VUE_APP_API_URL + 'messenger/fetch-users') {
            this.show = true;
            this.tableData.draw++;
            axios(url, {params: this.tableData}).then(response =>{
                if (! response.data.status == 200) {
                    this.show = false;
                    return this.errorMessage = 'Could not fetch data';
                }
                this.show = false;
                this.search_result = response.data;
            }).catch({});
        },

        fetchAllUsers () {
              axios(process.env.VUE_APP_API_URL + 'users').then(response =>{
                  if (! response.data.status == 200) {
                      return this.errorMessage = 'Could not fetch data';
                  }
                  this.users = response.data;
              }).catch({});
          },

        fetchCompany () {
            axios(process.env.VUE_APP_API_URL + 'company').then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.company = response.data;
            }).catch({});
        },

        fetchUser () {
            axios(process.env.VUE_APP_API_URL + 'user/details/'+localStorage.getItem('user')).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.user = response.data;
            }).catch({});
        },

        sendMessage () {
            this.loading = true;
            var form = $("#message-form")[0];
            var _data = new FormData(form);
            // _data.append('user_id', localStorage.getItem('user'));
            axios.post(process.env.VUE_APP_API_URL + 'messenger/send', _data)
            .then((response) => {
            if (response.status === 200) {
                this.$toastr.s(response.message);
                this.$root.$emit('action')
                $("#message-form")[0].reset();
                this.loading = false;
            } else {
                this.$toastr.e(response.data.message);
                this.loading = false;
            }
            }).catch((error) =>{
                this.$toastr.e(error.response.data.message);
                this.loading = false;
            })
        },

        composeMessage () {
            this.loading = true;
            axios.post(process.env.VUE_APP_API_URL + 'messenger/compose-new', this.message)
            .then((response) => {
            if (response.status === 200) {                
                this.$root.$emit('action')
                $("#compose-form")[0].reset();
                window.$('#new-message').modal('hide');
                this.$toastr.s(response.message);
                this.loading = false;
            } else {
                this.$toastr.e(response.data.message);
                this.loading = false;
            }
            }).catch((error) =>{
                this.$toastr.e(error.response.data.message);
                this.loading = false;
            })
        },

        updateMessage (id, detail_id) {
            axios(process.env.VUE_APP_API_URL + 'messenger/update/'+id+'/'+detail_id).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.user = response.data;
            }).catch({});
        },

        messageUser (id) {
            this.current_index = '';
            axios(process.env.VUE_APP_API_URL + 'messenger/initiate/'+id+'/'+this.id).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.$root.$emit('action');
                this.current_index = 0;
                this.viewDetail (0);
            }).catch({});
        },

        deleteMessage (id) {
            axios(process.env.VUE_APP_API_URL + 'messenger/delete/'+id).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not perform action';
                }
               this.$toastr.s(response.message);
            }).catch({});
        },

        clearMessengerChat (id) {
            axios(process.env.VUE_APP_API_URL + 'messenger/clear-chat/'+id).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not perform action';
                }
               this.$toastr.s(response.message);
            }).catch({});
        },

        viewDetail (index, id) {
             axios(process.env.VUE_APP_API_URL + 'messenger/update/'+id).then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.$root.$emit('action');
            }).catch({});
            this.current_index = index;
            this.currentData = this.messenger[this.current_index];
        },

        formatDate(date){
          return moment(date).fromNow();
        },
    },

    mounted: function () {
       this.$root.$on('action', ()=>{
        this.fetchData()
      })
    },

    computed: {
        image_url: function() {
            return process.env.VUE_APP_IMAGE_URL;
        }
    },
}
</script>