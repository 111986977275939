<template>
  <div>
    <button v-if="show == false" class="open-button" @click="openForm()"><i style="font-size:20px" class="feather-message-circle mr-1"></i> Messages</button>

    <div class="chat-popup" id="myForm">
      <div class="box shadow-sm rounded bg-white osahan-chat form-container" style="margin-bottom: 50px; overflow: scroll; height: 450px">
                           
        <div class="row m-0">
        <div v-if="show_message == false" class="px-0">
        <h5 class="pl-3 pt-3 pr-3 border-bottom mb-0 pb-3">Messaging</h5> 
        <div class="osahan-chat-left">
        <div class="position-relative icon-form-control p-3 border-bottom">
        <i class="feather-search position-absolute"></i>
        <input placeholder="Search messages" v-model="tableData.search" @input="fetchUsers()" type="text" class="form-control">
        </div>
        <div class="osahan-chat-list">
        <a v-for="(data, index) in messenger" :key="index" style="cursor:pointer" @click="viewDetail(index, data.id)" class="p-3 d-flex align-items-center border-bottom osahan-post-header overflow-hidden">
            <div v-if="id == data.user_id" class="dropdown-list-image mr-3">
                <span v-if="data.sender">
                    <img v-if="data.sender.avatar" class="rounded-circle" :src="image_url+'users/'+data.sender.avatar" alt="">
                    <div v-else class="dropdown-list-image mr-3 d-flex align-items-center bg-primary justify-content-center rounded-circle text-white">{{data.sender.name.charAt(0)}}</div>
                </span>
            </div>
            <div v-else class="dropdown-list-image mr-3">
                <span v-if="data.user">
                    <img v-if="data.user.avatar" class="rounded-circle" :src="image_url+'users/'+data.user.avatar" alt="">
                    <div v-else class="dropdown-list-image mr-3 d-flex align-items-center bg-primary justify-content-center rounded-circle text-white">{{data.user.name.charAt(0)}}</div>
                </span>
            </div>
            <div v-if="data.status == 0" class="font-weight-bold mr-1 overflow-hidden">
                <div v-if="id == data.user_id" class="text-truncate">{{data.sender?data.sender.name:''}}</div> <div v-else class="text-truncate">{{data.user?data.user.name:''}}</div>
                <div v-if="data.details.length" class="small text-truncate overflow-hidden text-black-50"><i class="feather-check text-primary"></i>{{data.details?data.details[0].message:''}}</div>
            </div>
            <div v-else class="mr-1 overflow-hidden">
                <div v-if="id == data.user_id" class="text-truncate">{{data.sender?data.sender.name:''}}</div> <div v-else class="text-truncate">{{data.user?data.user.name:''}}</div>
                <div v-if="data.details.length" class="small text-truncate overflow-hidden text-black-50"><i class="feather-check text-primary"></i>{{data.details?data.details[0].message:''}}</div>
            </div>
            <span class="ml-auto mb-auto">
            <div v-if="data.details.length" class="text-right text-muted pt-1 small">{{data.details?formatDate(data.lastdetails[0].created_at):''}}</div>
            </span>
        </a>

        <a v-for="data in search_result" :key="data.id" style="cursor:pointer" @click="messageUser(data.id)" class="p-3 d-flex align-items-center border-bottom osahan-post-header overflow-hidden">
            <div class="dropdown-list-image mr-3">
                <img v-if="data.avatar" class="rounded-circle" :src="image_url+'users/'+data.avatar" alt="">
                <div v-else class="dropdown-list-image mr-3 d-flex align-items-center bg-primary justify-content-center rounded-circle text-white">{{data.name.charAt(0)}}</div>
            </div>
            <div class="font-weight-bold mr-1 overflow-hidden">
            <div class="text-truncate">{{data.name}}</div>
            </div>
        </a>

        </div>
      </div>
    </div>


    <div v-if="show_message == true" class="px-0">
        <div v-if="currentData" class="p-3 d-flex align-items-center  border-bottom osahan-post-header">
          <a @click="returnBack()" style="color:red; float:left; padding-bottom: 100px; cursor:pointer">X </a>
        <div v-if="id == currentData.user_id" class="font-weight-bold mr-1 overflow-hidden">
            <div class="text-truncate">{{currentData.sender?currentData.sender.name:''}} </div>
            <div class="small text-truncate overflow-hidden text-black-50">{{currentData.sender?currentData.sender.profession:''}} </div>
        </div>
        <div v-else class="font-weight-bold mr-1 overflow-hidden">
            <div class="text-truncate">{{currentData.user?currentData.user.name:''}} </div>
            <div class="small text-truncate overflow-hidden text-black-50">{{currentData.user?currentData.user.profession:''}} </div>
        </div>
        <span v-if="currentData" class="ml-auto">
       
        <div class="btn-group">
          <span style="padding-right: 5px">
            <a v-if="id == currentData.user_id" :href="'tel:'+currentData.sender.phone_number" type="button" class="btn btn-primary btn-sm rounded">
            <i class="feather-phone"></i></a>
            <a v-else :href="'tel:'+currentData.user.phone_number" type="button" class="btn btn-primary btn-sm rounded">
            <i class="feather-phone"></i></a>
          </span>

        <button type="button" class="btn btn-primary btn-sm rounded" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <i class="feather-more-vertical"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-right">
        <button class="dropdown-item" type="button" @click="deleteMessage(currentData.id)"><i class="feather-trash"></i> Delete </button>
        <button class="dropdown-item" type="button" @click="clearMessengerChat(currentData.id)"><i class="feather-x-circle"></i> Clear Chat</button>
        </div>
        </div>
        </span>
        </div>
        <div class="osahan-chat-box p-3 border-top border-bottom bg-light">
        <!-- <div class="text-center my-3">
        <span class="px-3 py-2 small bg-white shadow-sm  rounded">DEC 21, 2020</span>
        </div> -->
        <div v-for="(data, index) in currentData.details" :key="index" class="d-flex align-items-center osahan-post-header">
        <div class="dropdown-list-image mr-3 mb-auto">
            <span v-if="data.sender">
                <img v-if="data.sender.avatar" class="rounded-circle" :src="image_url+'users/'+data.sender.avatar" alt="">
                <div v-else class="dropdown-list-image mr-3 d-flex align-items-center bg-primary justify-content-center rounded-circle text-white">{{data.sender.name.charAt(0)}}</div>
            </span>
        </div>
        <div class="mr-1">
        <div class="text-truncate h6 mb-3">{{data.sender?data.sender.name:''}} </div>
        <p>{{data.message}}</p>
        </div>
        <span class="ml-auto mb-auto">
        <div class="text-right text-muted pt-1 small">{{formatDate(data.created_at)}}</div>
        </span>
        </div> 
        </div>
        <div v-if="currentData" class="w-100 border-top border-bottom">
            <form id="messenger-form">
                <input type="hidden" name="sender_id" :value="id"/>
                <input v-if="id == currentData.user_id" type="hidden" name="user_id" :value="currentData.sender_id"/>
                <input v-else type="hidden" name="user_id" :value="currentData.user_id"/>
                <textarea placeholder="Write a message…" name="message" class="form-control"></textarea>
            </form>
        </div>
        <div v-if="currentData" class="p-3 d-flex align-items-center">
        <span class="ml-auto">
        <button v-if="loading" type="button" class="btn btn-primary btn-sm rounded">
        <i class="feather-send"></i> sending.... </button>
        <button v-else type="button" @click="sendMessenger()" class="btn btn-primary btn-sm rounded">
        <i class="feather-send"></i> Send </button>
        </span>
        </div>
      </div>

  </div>
  </div>
      <form action="/action_page.php" class="">
        <button v-if="show == true" type="button" style="color:white" class="btn cancel close-button" @click="closeForm()">X Close</button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import $ from "jquery";

export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },

  created() {
     this.fetchData();
     this.fetchAllUsers();
  },

  data() {
        return {
            current_index: '',
            messenger:[],
            loading: false,
            id: localStorage.getItem('user'),
            search_result: [],
            tableData: {
                draw: 0,
                search: '',
            },
            show: false,
            show_message: false,
            currentData: {},
            users: [],
            message: {
                user_id: '',
                message: '',
                sender_id: localStorage.getItem('user'),
            }
        }
  },

  methods: {
    fetchData () {
        axios(process.env.VUE_APP_API_URL + 'messenger/fetch/'+localStorage.getItem('user')).then(response =>{
            if (! response.data.status == 200) {
                return this.errorMessage = 'Could not fetch data';
            }
            this.messenger = response.data;
        }).catch({});
    },

    viewDetail (index, id) {
        this.show_message = true;
        this.current_index = index;
        this.currentData = this.messenger[this.current_index];
          axios(process.env.VUE_APP_API_URL + 'messenger/update/'+id).then(response =>{
            if (! response.data.status == 200) {
                return this.errorMessage = 'Could not fetch data';
            }
            this.$root.$emit('action');
        }).catch({});        
    },

    fetchUsers (url = process.env.VUE_APP_API_URL + 'messenger/fetch-users/'+localStorage.getItem('user')) {
        this.show = true;
        this.tableData.draw++;
        axios(url, {params: this.tableData}).then(response =>{
            if (! response.data.status == 200) {
                this.show = false;
                return this.errorMessage = 'Could not fetch data';
            }
            this.show = false;
            this.search_result = response.data;
        }).catch({});
    },

    fetchAllUsers () {
        axios(process.env.VUE_APP_API_URL + 'users').then(response =>{
            if (! response.data.status == 200) {
                return this.errorMessage = 'Could not fetch data';
            }
            this.users = response.data;
        }).catch({});
    },

    sendMessenger () {
        this.loading = true;
        var form = $("#messenger-form")[0];
        var _data = new FormData(form);
        // _data.append('user_id', localStorage.getItem('user'));
        axios.post(process.env.VUE_APP_API_URL + 'messenger/send', _data)
        .then((response) => {
        if (response.status === 200) {
            this.$toastr.s(response.message);
            this.$root.$emit('action')
            this.show_message = false;
            $("#messenger-form")[0].reset();
            this.loading = false;
        } else {
            this.$toastr.e(response.data.message);
            this.loading = false;
        }
        }).catch((error) =>{
            this.$toastr.e(error.response.data.message);
            this.loading = false;
        })
    },

    updateMessage (id, detail_id) {
        axios(process.env.VUE_APP_API_URL + 'messenger/update/'+id+'/'+detail_id).then(response =>{
            if (! response.data.status == 200) {
                return this.errorMessage = 'Could not fetch data';
            }
            this.user = response.data;
        }).catch({});
    },

    messageUser (id) {
        this.current_index = '';
        axios(process.env.VUE_APP_API_URL + 'messenger/initiate/'+id+'/'+this.id).then(response =>{
            if (! response.data.status == 200) {
                return this.errorMessage = 'Could not fetch data';
            }
            this.$root.$emit('action');
            this.current_index = 0;
            this.viewDetail (0);
        }).catch({});
    },

    deleteMessage (id) {
        axios(process.env.VUE_APP_API_URL + 'messenger/delete/'+id).then(response =>{
            if (! response.data.status == 200) {
                return this.errorMessage = 'Could not perform action';
            }
            this.$toastr.s(response.message);
        }).catch({});
    },

    clearMessengerChat (id) {
        axios(process.env.VUE_APP_API_URL + 'messenger/clear-chat/'+id).then(response =>{
            if (! response.data.status == 200) {
                return this.errorMessage = 'Could not perform action';
            }
            this.$toastr.s(response.message);
        }).catch({});
    },

    openForm() {
      document.getElementById("myForm").style.display = "block";
      this.show = true;
    },

    closeForm() {
      document.getElementById("myForm").style.display = "none";
      this.show = false;
    },

    returnBack() {
      this.show_message = false;
    },

    formatDate(date){
      return moment(date).fromNow();
    },
  },

  mounted: function () {
       this.$root.$on('action', ()=>{
        this.fetchData()
      })
    },

    computed: {
        image_url: function() {
            return process.env.VUE_APP_IMAGE_URL;
        }
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {font-family: Arial, Helvetica, sans-serif;}
* {box-sizing: border-box;}

.open-button {
  background-color: rgb(14, 20, 91);
  color: white;
  padding: 3px 10px;
  border: none;
  cursor: pointer;
  opacity: 0.8;
  position: fixed;
  bottom: 10px;
  right: 28px;
}

.close-button {
  background-color: rgb(85, 4, 14);
  color: white;
  padding: 3px 10px;
  border: none;
  cursor: pointer;
  opacity: 0.8;
  position: fixed;
  bottom: 10px;
  right: 40px;
}

/* The popup chat - hidden by default */
.chat-popup {
  display: none;
  position: fixed;
  bottom: 0;
  right: 15px;
  border: 3px solid #f1f1f1;
  z-index: 9;
}

/* Add styles to the form container */
.form-container {
  max-width: 450px;
  padding: 10px;
  background-color: white;
}

@media (max-width: 460px) {
  .form-container {
    max-width: 350px;
  }
}

@media (max-width: 360px) {
  .form-container {
    max-width: 300px;
  }
}
/* Full-width textarea */
.form-container textarea {
  width: 100%;
  padding: 15px;
  margin: 5px 0 22px 0;
  border: none;
  background: #f1f1f1;
  resize: none;
  min-height: 100px;
}

/* When the textarea gets focus, do something */
.form-container textarea:focus {
  background-color: #ddd;
  outline: none;
}

/* Set a style for the submit/send button */
.form-container .btn {
  background-color: #04AA6D;
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-bottom:10px;
  opacity: 0.8;
}

/* Add a red background color to the cancel button */
.form-container .cancel {
  background-color: red;
}

/* Add some hover effects to buttons */
.form-container .btn:hover, .open-button:hover {
  opacity: 1;
}

/* ::-webkit-scrollbar-track {
  margin-top: 10px;
  margin-bottom: 10px;
} */
</style>
