<template>
    <div class="Register">
        <div class="bg-white">
            <div class="container">
                <div class="row justify-content-center align-items-center d-flex vh-100">
                    <div class="col-md-6 mx-auto">
                        <div class="osahan-login py-4">
                            <div class="text-center mb-4">
                                <a href="/"><img :src="image_url+'logo/'+company.logo" alt="" style="max-height: 130px"></a>
                                <h5 class="font-weight-bold mt-3">Join Structurecity</h5>
                                <p class="text-muted">Make the most of your professional life</p>
                            </div>
                            <form id="register-form">
                                <div class="form-row">
                                    <div class="col">
                                        <div class="form-group">
                                            <label class="mb-1">First name</label>
                                            <div class="position-relative icon-form-control">
                                                <i class="feather-user position-absolute"></i>
                                                <input type="text" name="first_name" class="form-control">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label class="mb-1">Last name</label>
                                            <div class="position-relative icon-form-control">
                                                <i class="feather-user position-absolute"></i>
                                                <input type="text" name="last_name" class="form-control">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col">
                                        <div class="form-group">
                                            <label class="mb-1">Email</label>
                                            <div class="position-relative icon-form-control">
                                                <i class="feather-at-sign position-absolute"></i>
                                                <input type="email" name="email" class="form-control">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label class="mb-1">Phone Number</label>
                                            <div class="row" style="padding: 0;">
                                                <div class="col-md-4">
                                                    <countrycode-2/>
                                                </div>
                                                <div class="col-md-8">
                                                    <input type="text" class="form-control" name="phone_number" placeholder="080---" oninput="this.value = this.value.replace(/[^0-9+]/g, '').replace(/(\..*?)\..*/g, '$1');" maxlength="11" minlength="9">
                                                </div>
                                            </div>
                                            <!-- <div class="position-relative icon-form-control">
                                                <i class="feather-phone position-absolute"></i>
                                                <input type="text" name="phone_number" class="form-control">
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group mb-0">
                                        <label class="mb-1 w-100">Are you a Construction Professional?</label>
                                        <div style="padding-left: 30px" class="custom-control custom-radio d-inline mr-3">
                                            <input type="radio" class="custom-control-input" name="is_professional" value="yes" v-model="is_professional" id="customCheck_yes">
                                            <label class="custom-control-label" for="customCheck_yes">Yes</label>
                                        </div>
                                        <div style="padding-left: 30px" class="custom-control custom-radio d-inline mr-3">
                                            <input type="radio" class="custom-control-input" name="is_professional" value="no" v-model="is_professional" id="customCheck_no">
                                            <label class="custom-control-label" for="customCheck_no">No</label>
                                        </div>
                                    </div>
                                </div><br/>
                                <div v-if="is_professional == 'yes'" class="mb-3">
                                    <label for="password" class="form-label">Select Your Profession </label>
                                    <select class="form-control rounded-pill" name="profession" v-model="profession">
                                    <option value="" disabled selected>--select--</option>
                                    <option v-for="(data, index) in professions" :key="index" :value="data.name">{{data.name}}</option>
                                    <option value="others">Others</option>
                                    </select>
                                </div>
                                <div v-if="is_professional == 'yes' && profession == 'others'" class="mb-3">
                                    <label for="other_profession" class="form-label">Your Profession</label>
                                    <input type="text" class="form-control rounded-pill" name="other_profession">
                                </div>
                                <div class="form-row">
                                    <div class="col">
                                        <div class="form-group">
                                            <label class="mb-1">Password</label>
                                            <div class="position-relative icon-form-control">
                                                <i class="feather-unlock position-absolute"></i>
                                                <input type="password" name="password" class="form-control">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label class="mb-1">Confirm Password</label>
                                            <div class="position-relative icon-form-control">
                                                <i class="feather-unlock position-absolute"></i>
                                                <input type="password" name="confirm_password" class="form-control">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="mb-1">You agree to our <a href="/terms-and-conditions">Terms of Use</a> and <a href="/privacy-policy">Privacy Policy</a>.</label>
                                </div>
                                <button v-if="loading" class="btn btn-primary btn-block text-uppercase" type="button"> loading....... </button>
                                <button v-else class="btn btn-primary btn-block text-uppercase" type="button" @click="register()"> Agree & Join </button>
                                <!-- <div class="text-center mt-3 border-bottom pb-3">
                                    <p class="small text-muted">Or register with</p>
                                    <div class="row">
                                        <div class="col-4">
                                            <button type="button" @click="AuthProvider('google')" class="btn btn-sm btn-outline-google btn-block"><i class="feather-google"></i> Google</button>
                                        </div>
                                        <div class="col-4">
                                            <button type="button" @click="AuthProvider('facebook')" class="btn btn-sm btn-outline-facebook btn-block"><i class="feather-facebook"></i> Facebook</button>
                                        </div>
                                        <div class="col-4">
                                            <button type="button" @click="AuthProvider('linkedin')" class="btn btn-sm btn-outline-facebook btn-block"><i class="feather-linkedin"></i> Linkedin</button>
                                        </div>
                                    </div>
                                </div> -->
                                <div class="py-3 d-flex align-item-center">
                                    <!-- <router-link to="/forgot-password">Forgot password?</router-link> -->
                                    <span class="ml-auto"> Already on Structurecity? <router-link class="font-weight-bold" to="/login">Sign in</router-link></span>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import $ from "jquery";
import Countrycode2 from '../../components/Countrycode2.vue';

export default {
    name: 'Register',
    components: {
        Countrycode2
        
    },

    created() {
        this.fectchCompany();
        this.fetchProfessions();
        // this.fectchInterests();
    },

    watch: {
      $route: {
          immediate: true,
          handler(to) {
              document.title = to.meta.title || 'Register';
          }
      },
  }, 

    methods: {
        register () {
                this.loading = true;
                var form = $("#register-form")[0];
                var _data = new FormData(form);
                axios.post(process.env.VUE_APP_API_URL + 'auth/register', _data)
            .then((response) => {
            if (response.status === 200) {
                this.$toastr.s(response.message);
                this.$router.push('/login')
                this.loading = false;
            } else {
                this.$toastr.e(response.data.message);
                this.loading = false;
            }
            }).catch((error) =>{
                this.$toastr.e(error.response.data.message);
                this.loading = false;
            })
        },

        fectchCompany () {
            axios(process.env.VUE_APP_API_URL + 'company').then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.company = response.data;
            }).catch({});
        },

        fectchInterests () {
            axios(process.env.VUE_APP_API_URL + 'interest').then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.interests = response.data;
                localStorage.setItem('interest', response.data)
            }).catch({});
        },

        fetchProfessions () {
            axios(process.env.VUE_APP_API_URL + 'professions').then(response =>{
                if (! response.data.status == 200) {
                    return this.errorMessage = 'Could not fetch data';
                }
                this.professions = response.data;
            }).catch({});
        },

        AuthProvider(provider) {
            var self = this

            this.$auth.authenticate(provider).then(response =>{

            self.SocialLogin(provider,response)

            }).catch(err => {
                console.log({err:err})
            })
        },

        SocialLogin(provider,response){
            this.$http.get(process.env.VUE_APP_BASE_URL + 'social/login/'+provider,response).then(response => {

                console.log(response.data)

            }).catch(err => {
                console.log({err:err})
            })
        },
    },
    data() {
        return {
            company: {},
            interests: [],
            loading: false,

            professions:[],
            profession:'',
            is_professional:'',
            show:false,
            country_tel_code: "234"
        }
    },
    computed: {
        image_url: function() {
            return process.env.VUE_APP_IMAGE_URL;
        }
    },

}
</script>