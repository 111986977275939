import Vue from 'vue';
// import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import App from './App.vue';
import VueRouter from 'vue-router';
import routes from "./router/index";
import axios from "@/plugins/axios.js";
import VueAxios from 'vue-axios';
import store from '@/store/index.js';
import VueToastr from 'vue-toastr';
const pagination = () => import('./components/Pagination.vue');

import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap-vue/dist/bootstrap-vue.css';
import VueSocialauth from 'vue-social-auth';
Vue.use(VueSocialauth, {

  providers: {
    github: {
      clientId: '',
      // redirectUri: 'https://www.structurecity.com/'
      redirectUri: '/auth/github/callback'
    }, 
    facebook: {
      clientId: '540955798046431',
      // redirectUri: 'https://www.structurecity.com/'
      redirectUri: '/auth/facebook/callback'
    },
    google: {
      clientId: '694574524846-akdj90qaiit6lidd5apctaj881e6s17t.apps.googleusercontent.com',
      // redirectUri: 'https://www.structurecity.com/'
      // redirectUri: window.location.origin+'/auth/google/callback'
      redirectUri: 'https://admin.structurecity.com/social/login/google/callback'
    },
    linkedin: {
      clientId: '77j7kk6pi6rsmi',
      // redirectUri: 'https://www.structurecity.com/'
      redirectUri: '/auth/linkedin/callback'
    }
  }

})
// import VueResource from 'vue-resource';
// Vue.use(VueResource);
Vue.component('InfiniteLoading', require('vue-infinite-loading'));

Vue.config.productionTip = false

// Vue.use(VueToastr, {
//   defaultTimeout: 3000,
//   defaultProgressBar: false,
//   defaultProgressBarValue: 0,
//   defaultType: "error",
//   defaultPosition: "toast-bottom-left",
//   defaultCloseOnHover: false,
//   defaultStyle: { "background-color": "red" },
//   defaultClassNames: ["animated", "zoomInUp"]
// });
window.Fire = new Vue()
Vue.use(VueToastr);
Vue.component("pagination", pagination);

Vue.use(store)
// Vue.use(BootstrapVue)
// Vue.use(IconsPlugin)

Vue.use(VueAxios, axios)
Vue.use(VueRouter)
const router = new VueRouter({
  mode: 'history',
  routes: routes
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
